import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _slice from 'lodash/slice'

import { useSnackbar } from 'notistack'

import { getSavedPosts } from 'src/redux/account/actions'
import { setPostFeed, clearPostFeed } from 'src/redux/feed-post/actions'

import Post from 'src/components/feed-post/Post'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from "@material-ui/lab/Pagination"

import useStyles from 'src/styles/components/personal-profile/savedPostsList'

const SavedPostsList = ({
    feed,
    getSavedPosts,
    setPostFeed,
    clearPostFeed,
}) => {
    const [ isPostsLoading, setIsPostsLoading ] = useState(null)
    const [page, setPage] = useState(1);
    
    useEffect(() => {
        setIsPostsLoading(true)
        getSavedPosts().then(posts => {
            console.log("SavedPostsList", posts)
            setPostFeed(posts)
        }).catch(err => {
            console.log(err)
            enqueueSnackbar("Something went wrong", { variant: "error", })
        }).finally(() => {
            setIsPostsLoading(false)
        })

        return () => clearPostFeed()
        
        // eslint-disable-next-line
    }, [])

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, [])

    const { enqueueSnackbar } = useSnackbar()

    const classes = useStyles()

    const rowPerPage = 5;
    const startIndex = (page - 1) * rowPerPage;
    const endIndex = page * rowPerPage;

    return !isPostsLoading ?
    <Box className={classes.list}>
            {_map(
                _slice(feed, startIndex, endIndex),
                post => <Post key={_get(post, "postId")} post={post} />
            )}
            {_get(feed, "length") ? <Box className={classes.paginationContainer}>
              <Pagination
                className={classes.pagination}
                count={Math.ceil(_get(feed, "length") / rowPerPage)}
                siblingCount={1}
                onChange={handleChangePage}
                page={page}
              />
            </Box> : null}
    </Box>
    : <Box className={classes.progressHolder}>
        <CircularProgress />
    </Box>   
}

const mapStateToProps = state => ({
    feed: _get(state, "feedPost.feed"),
})

const mapDispatchToProps = dispatch => ({
    getSavedPosts: () => dispatch(getSavedPosts()),
    setPostFeed: posts => dispatch(setPostFeed(posts)),
    clearPostFeed: () => dispatch(clearPostFeed()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SavedPostsList)
