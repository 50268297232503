import React, { useState, useEffect } from 'react'
import useStyles from "src/styles/components/dashboard/referralList";
import { ReactComponent as SortingIcon } from 'src/images/common/icon_sorting.svg';
import { ReactComponent as ArrowDownIcon } from 'src/images/common/icon_arrow_down.svg';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SortingPopUpMenu from 'src/components/dashboard/SortingPopUpMenu';
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from 'react-i18next';
import { countriesMap } from 'src/info/countries';
import defaultAvatar from 'src/images/common/default_avatar.png';
import moment from 'moment';
import StatusWidget from 'src/components/StatusWidget';

export default function ReferralList({
  referrals
}) {
  console.log('referrals: ', referrals);
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const [sortingAnchorEl, setSortingAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const rowPerPage = 10;
  const startIndex = (page - 1) * rowPerPage;
  const endIndex = page * rowPerPage;
  const [sortedReferrals, setSortedReferrals] = useState(referrals);
  const [sortingOption, setSortingOption] = useState({
    field: 'displayName',
    order: 'asc',
    text: t('tab-3.sorting.display-name-asc')
  });

  const sortingOptions = [
    {
      field: 'displayName',
      order: 'asc',
      text: t('tab-3.sorting.display-name-asc')
    },
    {
      field: 'displayName',
      order: 'desc',
      text: t('tab-3.sorting.display-name-desc')
    }
  ]

  const clickSortingButton = (event) => {
    setSortingAnchorEl(event.currentTarget);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const avatarError = (e) => {
    e.target.onerror = null;
    e.target.src = defaultAvatar;
  }

  const adjustSortingValue = (referral, field) => {
    if (field === 'displayName') {
      return referral.referredIdol[field].toLowerCase();
    } else {
      return referral.referredIdol[field];
    }
  }

  useEffect(() => {
    let newReferrals = JSON.parse(JSON.stringify(referrals));

    if (sortingOption.order === 'asc') {
      newReferrals.sort((a, b) => {
        if (adjustSortingValue(a, sortingOption.field) > adjustSortingValue(b, sortingOption.field)) {
          return 1
        } else if (adjustSortingValue(a, sortingOption.field) < adjustSortingValue(b, sortingOption.field)) {
          return -1
        } else {
          return 0
        }
      })
    } else {
      newReferrals.sort((a, b) => {
        if (adjustSortingValue(a, sortingOption.field) > adjustSortingValue(b, sortingOption.field)) {
          return -1
        } else if (adjustSortingValue(a, sortingOption.field) < adjustSortingValue(b, sortingOption.field)) {
          return 1
        } else {
          return 0
        }
      })
    }
    setSortedReferrals(newReferrals);
  }, [sortingOption, referrals])

  const getReferralStatus = (referral) => {
    if (moment(referral.expiryDate, 'YYYY-MM-DD').isBefore(moment())) {
      return 'finished'
    } else {
      return 'on-going'
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="h5" align="left">
          {t('tab-3.header')}
        </Typography>
        <div className={classes.sortingButton} onClick={clickSortingButton}>
          <SortingIcon className={classes.sortingIcon} />
          <Typography variant="caption" className={classes.sortingText}>
            {sortingOption.text}
          </Typography>
          <ArrowDownIcon className={classes.arrowDownIcon} />
        </div>
        <SortingPopUpMenu
          anchorEl={sortingAnchorEl}
          setAnchorEl={setSortingAnchorEl}
          setSortingOption={setSortingOption}
          sortingOptions={sortingOptions}
        />
      </div>
      <TableContainer component={Paper} classes={{ root: classes.tableRoot }}>
        <Table>
          <colgroup>
            <col style={{ width: "30%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography
                  variant="h6"
                  className={classes.textBold}
                >
                  {t('tab-3.creator-display-name')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="h6"
                  className={classes.textBold}
                >
                  {t('tab-3.region')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="h6"
                  className={classes.textBold}
                >
                  {t('tab-3.valid-until')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="h6"
                  className={classes.textBold}
                >
                  {t('tab-3.monthly-revenue-share')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="h6"
                  className={classes.textBold}
                >
                  {t('tab-3.status.header')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedReferrals.slice(startIndex, endIndex).map((referral) => (
              <TableRow
                key={referral.referredIdolId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  <div className={classes.flexContainer}>
                    <img
                      src={referral.referredIdol.avatar ? referral.referredIdol.avatar : defaultAvatar}
                      alt={'referred idol avatar'}
                      className={classes.avatar}
                      onError={(e) => avatarError(e)}
                    />
                    <Typography variant="body1" className={classes.textBold}>
                      {referral.referredIdol.displayName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {countriesMap[referral.referredIdol.countryCode]}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {referral.expiryDate}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" className={classes.textBold}>
                    {"$" + Intl.NumberFormat('zh-HK').format(
                      Number.isInteger(referral.referredIdol.sharedRevenue) ? referral.referredIdol.sharedRevenue : referral.referredIdol.sharedRevenue.toFixed(2)
                    )}
                    <Typography component="span" variant="caption">
                      USD
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  <StatusWidget
                    status={getReferralStatus(referral)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.paginationContainer}>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(referrals.length / rowPerPage)}
            siblingCount={1}
            onChange={handleChangePage}
            page={page}
          />
        </div>
      </TableContainer>
    </div>
  )

}