import React from 'react'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingButton({
  isLoading,
  labelText,
  labelProps,
  loadingProps,
  children,
  ...others
}) {
  return (
    <Button disabled={isLoading} {...others}>
      {!isLoading && (
        children
      )}
      {isLoading && <CircularProgress {...loadingProps} />}
    </Button>
  );
}