import React from 'react'
import useStyles from "src/styles/components/personal-profile/shareCard";
import {
  Typography,
  Paper,
  Button
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import { ReactComponent as ClipIcon } from 'src/images/common/icon_clip.svg';
import env from 'src/env';

export default function ShareCard({
  fanshipPageName
}) {
  const classes = useStyles();
  const { t } = useTranslation("personal-profile");

  const copyPageLink = () => {
    navigator.clipboard.writeText(`https://${env.domain}/creator/${fanshipPageName}`);
  }

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <Typography variant="h6" className={clsx(classes.textBold, classes.header)}>
        {t('share-immediately')}
      </Typography>
      <Typography variant="body1" className={classes.textBold}>
        {t('share-description')}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={copyPageLink}
      >
        <div className={classes.flexContainer}>
          <ClipIcon className={classes.icon} />
          <Typography variant="body2" className={classes.textBold}>
            {t('copy-link')}
          </Typography>
        </div>
      </Button>
    </Paper>
  )
}