import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    boxSizing: 'border-box',
    backgroundColor: 'white'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingContainer: {
    alignItems: 'center',
    height: 600
  },
  mainContainer: {
    width: '100%',
    maxWidth: 700
  },
  header: {
    marginBottom: 40
  },
  subHeader: {
    marginBottom: 20
  },
  section: {
    marginBottom: 40
  },
  avatar: {
    width: 100,
    height: 100,
    marginRight: 20,
    borderRadius: 40
  },
  button: {
    width: 150,
    height: 44,
    borderRadius: 100
  },
  
  deletebutton: {
    width: 150,
    height: 44,
    borderRadius: 100
  },
  displayName: {
    marginBottom: 20
  },
  textField: {
    marginBottom: 10
  },
  menuItem: {
    whiteSpace: "normal",
    fontSize: 18,
    lineHeight: "27px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: "22px",
    },
  },
  selectMenu: {
    maxHeight: 200,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      backgroundColor: "#E9ECEF",
      borderRadius: "30px",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "30px",
      width: "5px",
    },
  },
  selectMenuList: {
    width: "100% !important",
    padding: "0px !important",
  },
  emptyOption: {
    display: 'none'
  },
  selectLabel: {
    marginBottom: 5
  },
  yearMonthField: {
    marginRight: 15
  },
  deactivate: {
    borderBottom: '1px solid black',
    marginLeft: 440,
    display : 'flex',
    alignContent : 'flex-display',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  dialogButton: {
    width: '100%',
    height: 44,
    borderRadius: 100
  },
  dialogTitle: {
    marginBottom: 15
  },
  dialogMsgDiv: {
    minWidth: 250,
    paddingTop: 15,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: 40
  },
  textBold: {
    fontWeight: 'bold'
  },
  confirmDeactivateButton: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.error.light,
    }
  }
}), { index: 1 });

export default useStyles;