import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  textBold: {
    fontWeight: 'bold'
  },
  button: {
    borderRadius: 100,
    height: 44,
  },
  header: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  mainContainer: {
    minWidth: 220,
    marginTop: 15,
    boxSizing: 'border-box'
  }
}), { index: 1 });

export default useStyles;