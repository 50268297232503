import React from 'react'
import { useTranslation } from 'react-i18next';
import TextArea from './TextArea'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,    
        display: "flex",
        flexDirection: "column",
    }
})

const FormField = ({ input, meta: { touched, error }, helperText, translatorJson, ...props }) => {

    const { t } = useTranslation(translatorJson);

    const classes = useStyles()
    return <Box className={classes.root}>
        <TextArea
            {...input}
            error={(touched && error) ? true : false}   // ?
            helperText={helperText} // ?
            {...props}
        />
        {
            touched && error &&
            <FormHelperText error={true}>{t(error)}</FormHelperText>
        }
    </Box>
}

export default FormField
