import React from 'react'
import { Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import useStyles from "src/styles/components/sideFooter";
import { NavLink } from 'react-router-dom';

export default function Footer() {
  const classes = useStyles();
  const year = new Date().getFullYear();
  const { t } = useTranslation("footer");

  return (
    <div className={classes.root}>
      <div className={classes.flexContainer}>
        <Typography variant="caption" color="textSecondary">
          <NavLink to="/terms-conditions" className={classes.navLink}>
            {t("terms-and-conditions")}
          </NavLink>
        </Typography>
        <div className={classes.dot} />
        <Typography variant="caption" color="textSecondary">
          <NavLink to="/platform-usage-guide" className={classes.navLink}>
            {t("platform-usage-guide")}
          </NavLink>
        </Typography>
        <div className={classes.dot} />
        <Typography variant="caption" color="textSecondary">
          <NavLink to="/contact-us" className={classes.navLink}>
            {t("contact-us")}
          </NavLink>
        </Typography>
      </div>
      <Typography variant="caption" className={classes.copyright} color="textSecondary">
        {`© Fanship ${year}`}
      </Typography>
    </div>
  );
}