import React, { useCallback, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _trim from 'lodash/trim'

import { useSnackbar } from 'notistack'

import Reply from './Reply'
import NewComment from './NewComment'

import Box from '@material-ui/core/Box'

import useStyles from 'src/styles/components/feed-post/post.js'


const CommentReplyList = ({
    postId,
    commentId,
    commentOwner,
    replys,
    isReplyCommentFormOpen,
    setIsReplyCommentFormOpen,
      onDeletecomment
}) => {
    const { enqueueSnackbar } = useSnackbar()



    const onSubmit = useCallback((values, dispatch, props) => {
        console.log("reply to comment onSubmit", values)
        
        if(!_trim(_get(values, "comment"))) throw new Error("Required")

        // call api action
            // in action, update global state on success
        return props.replytoCommentforPost?.(_get(props, "postId"), _get(props, "commentId"), _get(values, "comment"))
        .then(() => {
            // at success, remove form
            setIsReplyCommentFormOpen(false)
        })
        .catch(err => {
            console.log("catch: reply to comment submit failed", err)
            enqueueSnackbar("Something went wrong", { variant: "error", })
            throw new Error(err)
        })

        // eslint-disable-next-line 
    }, [])


    const classes = useStyles()
    
    return (
        <Box className={classes.commentsList} style={{marginBottom: 0,}}>
            {
                isReplyCommentFormOpen &&
                <NewComment 
                    form={`reply-to-comment-${commentId}-for-post-${postId}`}
                    postId={postId}
                    commentId={commentId}
                    commentTo={commentOwner}
                    onSubmit={onSubmit}
                />
            }
            {_map(replys, (reply, index) => {
                return <Reply NewComment
                    key={_get(reply, "commentId")}
                    postId={postId}
                    commentId={commentId}
                    reply={reply}
                    onDeletecomment={onDeletecomment}

                />
            })}
        </Box>
    )
}

export default CommentReplyList
