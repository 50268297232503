import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    successMsg: {
        backgroundColor: "#9900FF",
        fontWeight: 700,
    },
    errorMsg: {
        fontWeight: 700,
    },
    warningMsg: {
        fontWeight: 700,
    },
    infoMsg: {
        fontWeight: 700,
    },
})

export default useStyles