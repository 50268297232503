import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from 'src/styles/components/simpleTabs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SimpleTabs({
  infoArr
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBarRoot}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          {
            infoArr.map((info, index) => {
              return (
                <Tab
                  key={`tab_${index}`}
                  label={info.header}
                  {...a11yProps(index)}
                  classes={{ selected: classes.selected, root: classes.tabRoot }}
                />
              )
            })
          }
        </Tabs>
      </AppBar>
      {
        infoArr.map((info, index) => {
          return (
            <TabPanel
              key={`tab_panel_${index}`}
              value={value}
              index={index}
            >
              {info.children}
            </TabPanel>
          )
        })
      }
    </div>
  );
}