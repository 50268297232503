import React, { useState, useEffect } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useLocation, useHistory } from 'react-router-dom'

import _get from 'lodash/get'
import _replace from 'lodash/replace'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import logo from 'src/images/common/fanship_logo_with_brand.svg';


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
    },
    paper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    media: {
        height: 400,
        alignSelf: "center",
    },
    title: {
        textAlign: "center",
        fontSize: 90,
        alignSelf: "center",
        color: theme.palette.grey[800],
        fontWeight: 600,
        fontFamily: "Noto Sans CJK TC",
    }
}))

const baseIOSLink = "com.shotly.fanship://"

const baseAndroidLink = "com.shotly.fanship://returnapp"

const platforms = ["android", "iOS"]

const Deeplink = () => {
    const [currentPlatform, setCurrentPlatform] = useState(null)
    const [search, setSearch] = useState(null)

    useEffect(() => {
        console.log(
            "isAndroid", isAndroid,
            "isIOS", isIOS
        )
        if (isAndroid) setCurrentPlatform(platforms[0])
        else if (isIOS) setCurrentPlatform(platforms[1])
        else setCurrentPlatform("other")

        console.log("location.search", _get(location, "search"))
        console.log("window.location.search", _get(window, "location.search"))
        setSearch(_get(window, "location.search"))

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!currentPlatform || !search) return
        console.log("useEffect for", "currentPlateform", currentPlatform, "search", search)

        if (currentPlatform === platforms[0]) {
            window.location.replace(`${baseAndroidLink}${search}`)
        }
        else if (currentPlatform === platforms[1]) {
            // window.location.replace(`${baseIOSLink}${search}`)
            const launchApp = () => {
                window.location.replace(`${baseIOSLink}${search}`);
                setTimeout(openWebApp, 1000);
            };
          
            const openWebApp = () => {
                window.location.replace("https://apps.apple.com/us/app/fanship/id1580018765");
            }

            launchApp();
        } else {
            console.log("other platform")
            history.replace(`/posts/${_replace(search, "?", "")}`)
        }

        // eslint-disable-next-line
    }, [currentPlatform, search])

    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()

    return (
        <Box className={classes.root}>

            <Paper 
                className={classes.paper}
                elevation={0}
            >
                {/* <Typography
                    className={classes.title}
                    gutterBottom variant="h1" component="h1"
                >
                    Welcome
                </Typography> */}
                <img 
                    src={logo}
                    alt="Fanship"
                    className={classes.media}
                />
            </Paper>
        </Box>

    )
}

export default Deeplink
