import React from 'react'
import useStyles from 'src/styles/pages/termsAndConditions';
import {
  Typography
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

export default function TermsAndConditions() {
  const classes = useStyles();
  const { t } = useTranslation("terms-condition");

  return (
    <div className={classes.mainContainer}>
      <div className={classes.textContainer}>
        <div className={classes.header}>
          <Typography variant="h3">
            條款及細則
          </Typography>
        </div>
        <div>
          <Typography variant="h4">
          {t('t2')}
           {/* 服務條款  */}
          </Typography>
        </div>
        <br />
        <Typography variant="body1">
        {t('t3')}
          {/* 歡迎使用 Fanship！ */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('t4')}
          {/* Fanship 開發的技術和服務讓用戶能夠彼此聯繫、建立社群以及拓展業務。您使用 Fanship和我們所提供的其他產品、功能、應用程式、服務、技術及軟體（以下簡稱「Fanship 產品」或「產品」）時，均受到本《服務條款》規範，但若我們明示另有適用條款（且本《服務條款》不適用）者，不在此限。這些產品皆由 Fanship, Inc 為您提供。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('t5')}
          {/* 我們不會對您使用 Fanship 或本條款涵蓋之其他產品或服務收取費用，但企業商家和組織會付費請我們向您顯示其商品和服務的廣告。只要使用我們的產品，即表示您同意我們向您顯示我們認為與您個人及興趣相關的廣告。我們會運用您的個人資料來決定該向您顯示哪些廣告。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('t6')}
          {/* 我們絕不會將您的個資販售給廣告商，且除非您授予我們特定權限，否則我們也不會與廣告商分享可識別您個人身分的資訊（例如姓名、電子郵件地址或其他聯絡資料）。然而廣告商可以告知我們想要顯示廣告的目標受眾類型，我們再針對可能會感興趣的對象顯示他們的廣告。我們為廣告商提供廣告成效報告，協助他們瞭解用戶與廣告內容的互動情形。請參考後續第 2 節瞭解詳情。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('t7')}
          {/* 我們的《資料政策》說明我們如何蒐集和使用您的個人資料來決定您看到的部分廣告內容，以及提供後續提及的所有服務。您也能隨時前往設定查看可用的隱私選項，瞭解我們如何使用您的資料。 */}
        </Typography>
        <br />

        <br />
        <Typography variant="h4">
        {t('t8')}
          {/* 1. 我們提供的服務 */}
        </Typography>
        <Typography variant="body1">
        {t('t9')}
          {/* 我們的使命是賦予人們打造社群的力量，攜手拉近世界距離。為了完成這項使命，我們提供多種產品和服務，詳述如下： */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t10')}
          {/* 讓您與您重視的用戶和組織建立聯繫： */}
        </Typography>
        <Typography variant="body1">
        {t('t11')}
          {/* 我們可協助您在所使用的各項 Fanship 產品中，搜尋您重視的用戶、社團、商家、組織等等，並與對方建立聯繫。我們會依據既有資料向您和其他用戶推薦內容，例如推薦您加入社團、參加活動、追蹤或傳送訊息給粉絲專頁、觀看節目，或是與某些用戶成為朋友。當人與人之間連結越緊密，社群就能日益壯大。若用戶都能與自己重視的對象、社團和組織交流聯繫，就表示我們的服務充分發揮效益。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t12')}
          {/* 展現真我本色，與他人交流您重視的事物： */}
        </Typography>
        <Typography variant="body1">
        {t('t13')}
          {/* 在 Fanship，您可以藉由多種方式展現自我，與親朋好友和其他用戶交流您看重的事物。舉例來說，您可以透過各項 Fanship 產品分享近況更新、相片、影片和限時動態，或是傳送訊息給一位朋友或多位用戶、建立活動或社團、新增內容到個人檔案等等。我們也會開發並持續探索各種應用科技的新方式，讓用戶運用擴增實境和 360 度影片等新科技，在 Fanship 建立和分享更精彩生動的內容。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t14')}
          {/* 協助您發掘可能感興趣的內容、商品和服務： */}
        </Typography>
        <Typography variant="body1">
        {t('t15')}
          {/* 許多企業商家和組織都在使用 Fanship 和其他 Fanship 產品，而我們會對您顯示他們的廣告、優惠和其他贊助內容，協助您探索他們提供的內容、商品及服務。您可於後續第 2 節取得更詳盡的說明。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t16')}
          {/* 打擊有害行為，為社群用戶提供保護和支援： */}
        </Typography>
        <Typography variant="body1">
        {t('t17')}
          {/* 用戶必須享有安全的使用體驗，Fanship 社群才能日漸茁壯。為此，我們在世界各地設有專業團隊，並致力於開發先進的技術系統，用以偵測產品濫用情形、有害他人的行為，以及我們可以出手援助或保護社群的時機。如果發現此類內容或行為，我們將採取適當措施，例如提供協助、移除內容、移除或限制特定功能的使用權限、將帳號停權或聯絡執法機關。如果偵測到用戶使用我們任一項產品從事濫用或有害行為，我們會與其他 Fanship 旗下企業分享資料。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t18')}
          {/* 運用並開發先進技術，為所有用戶提供安全實用的服務： */}
        </Typography>
        <Typography variant="body1">
        {t('t19')}
          {/* 我們運用和開發的先進技術包括人工智慧、機器學習系統和擴增實境等等，讓所有用戶都能安心使用我們的產品，不受身體條件或地理位置限制。舉例來說，此類技術能夠告訴視障用戶，透過 Fanship 或 Instagram 分享的相片或影片中包含哪些物品或人物。此外，我們打造出成熟的網路與通訊技術，協助更多用戶在資源不足的地區順利連上網路。我們更開發出自動化的系統，加強偵測濫用和危險活動並加以移除，避免社群用戶受害及產品信譽受損。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t20')}
          {/* 致力研究提升服務品質的方法： */}
        </Typography>
        <Typography variant="body1">
        {t('t21')}
          {/* 為了開發、測試及改善我們的產品，我們致力於各種研究，例如透過問卷調查、測試新功能並排解問題，分析我們擁有的用戶資料，以便瞭解用戶使用我們產品的方式。我們的《資料政策》說明我們如何運用資料協助研究進行，以便開發和提升服務品質。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t22')}
          {/* 為所有 Fanship 旗下企業產品提供流暢一致的使用體驗： */}
        </Typography>
        <Typography variant="body1">
        {t('t23')}
          {/* 我們的產品可協助您搜尋重要的用戶、社團、商家、組織等等，並與對方建立聯繫。我們的系統亦經過精心設計，讓您在使用不同的 Fanship 旗下企業產品時，都能獲得流暢一致的使用體驗。舉例來說，我們會使用您在 Fanship 與之互動過的用戶資料，讓您能輕鬆地在 Instagram 或 Messenger 和他們建立聯繫；您也可以透過 Messenger 與您在 Fanship 追蹤的商家溝通交流。 */}
        </Typography>

        <br />
        <Typography variant="h4">
        {t('t24')}
          {/* 為全球用戶提供服務： */}
        </Typography>
        <Typography variant="body1">
        {t('t25')}
          {/* 為了在全球各地提供服務，我們必須將內容和資料儲存並提供給我們在世界各地的資料中心和系統（包括您居住國家／地區以外之國家）。這些基礎設施可能由 Fanship, Inc.、Fanship Ireland Limited 或其旗下企業營運或控管。 */}
        </Typography>
        <br />
        <br />
      </div>
    </div>
  )
}