import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 19,
        paddingRight: 19,
    },
    paper: {
        padding: 20,
    },
    Group: {
        margin: "40px 0px",
        display: "flex",
        flexDirection: "column",
        gap: 20,
    },
    progressHolder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
    }
}))

export default useStyles