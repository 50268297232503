const env = {
  domain: process.env.REACT_APP_STAGE === "production" ? "www.fanship.io" : "dev.fanship.io",
  apiDomain:
    process.env.REACT_APP_STAGE === "production"
      ? "api.fanship.io"
      : "api-dev.fanship.io",
  facebookAppId: process.env.REACT_APP_STAGE === "production" ? '237629507896162' : "237629507896162",
  geolocationApiKey: process.env.REACT_APP_STAGE === "production" ? "f7666fa7e0064ed899e5df0861ddead0" : "f7666fa7e0064ed899e5df0861ddead0",
  googleWebClientId: process.env.REACT_APP_STAGE === "production" ? "259193292120-964dmu2jsv9hv09ha0516jomhs7vbpp8.apps.googleusercontent.com" : "259193292120-964dmu2jsv9hv09ha0516jomhs7vbpp8.apps.googleusercontent.com",
  appleBundleId: process.env.REACT_APP_STAGE === "production" ? "com.shotly.fanship" : "com.shotly.fanship",
  appleSignInServiceId: process.env.REACT_APP_STAGE === "production" ? "com.joinfanship" : "com.joinfanship",
  clientId: process.env.REACT_APP_STAGE === "production" ? "AUWhV8yDHGDOStOOujImvm7jbrxQyjlcpbuk1SfwgvUUsM9ejyj_zWl6FurbW9ktqo3mp1ifzfbbiOKH" : "AT-eOt7NYTeNOch3GKN6RfsONF5OVOOIKBOth1JCelaefTifA7QAHxVxMc4au2yUcg19IgEpYWQOUZu1",
  iv:
    process.env.REACT_APP_STAGE === "production"
      ? "91JcBFY0tr9g3RO3"
      : "91JcBFY0tr9g3RO3",
  securitykey:
    process.env.REACT_APP_STAGE === "production"
      ? "yBO89jLeTg7L0dDYXCVIeWWc8t5s22f2"
      : "yBO89jLeTg7L0dDYXCVIeWWc8t5s22f2",
  algorithm:
    process.env.REACT_APP_STAGE === "production"
      ? "aes-256-cbc"
      : "aes-256-cbc",
  streamKey:
    process.env.REACT_APP_STAGE === "production" ? null : "4mcjgmy3wdcb",
};

export default env;
