import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    marginRight: 15,
  },
  menuPaper: {
    borderRadius: 20,
    padding: 0,
    width: 'fit-content',
    minWidth: 150,
    '& $menuItem:last-child': {
      borderBottom: 'none'
    }
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: '10px 15px',
    "&.Mui-focusVisible": {
      backgroundColor: "white !important",
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  navLink: {
    color: 'inherit',
    textDecoration: 'none',
    background: 'transparent',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 16,
    marginRight: 15
  }
}), { index: 1 });

export default useStyles;