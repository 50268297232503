import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "src/styles/components/personal-profile/cropImageDialog";
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import clsx from "clsx";

export default function CropAvatarDialog({
  open,
  handleClose,
  setFormAvatar
}) {
  const { t } = useTranslation("edit-personal-profile");
  const classes = useStyles();
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 50, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  function handleConfirm(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        console.log('previewUrl: ', previewUrl);
        setFormAvatar(previewUrl);
        handleClose();
      },
      'image/png',
      1
    );
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paper }}
        scroll="body"
        maxWidth="md"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6" className={classes.header} align="center">
            {t('update-avatar')}
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentRoot }}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.mainContainer}>
            <Button
              component="label"
              color="primary"
              variant="contained"
              className={clsx(classes.button, classes.uploadButtonRoot)}
            >
              <Typography variant="body1">
                {t('upload-photo')}
              </Typography>
              <input
                multiple="files"
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                className={classes.uploadInput}
              />
            </Button>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              className={classes.reactCrop}
            />
            <div hidden>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0)
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {
            completedCrop &&
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.confirmButton, classes.button)}
              onClick={() =>
                handleConfirm(previewCanvasRef.current, completedCrop)}
            >
              <Typography variant="body1">
                {t('complete-cropping')}
              </Typography>
            </Button>
          }
        </DialogActions>
      </Dialog>
    </Grid>
  );
}