import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { followIdol, unfollowIdol, reportPost } from 'src/redux/feed-post/actions'
import { Grid } from "@material-ui/core";
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Popover from '@material-ui/core/Popover'
import defaultAvatar from 'src/images/common/default_avatar.png'
import followPng from 'src/images/common/follow_icon.png'
import unfollowPng from 'src/images/common/unfollow.png'
import alertPng from 'src/images/common/icon_black_alert_triangle.svg'
import deleteIcon from 'src/images/common/delete.svg'
import editIcon from 'src/images/common/edit.svg'
import LoadingButton from '../LoadingButton'
import useStyles from 'src/styles/components/feed-post/post.js'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

const reportCategories = [
    "this-is-span",
    "hate-speech-or-symbol",
    "violant-or-dangerous-organization",
    "selling-illegal-or-regulated-goods",
    "bullying-or-harassment",
    "infringement-of-intellectual-property-rights",
    "suicide-self-harm-or-eating-disorder",
    "scan-or-fraud",
    "false-information",
    "I-just-dont-like-it"
]
// report categories are translated for api body and UI.

const PostHead = ({
    post,
    onClickofEdit,
    onClickofDelete
}) => {
    const history = useHistory();
    const [moreOptionsBtnEl, setMoreOptionsBtnEl] = useState(null)
    const [isFollowBtnLoading, setIsFollowBtnLoading] = useState(false)
    const isIdol = useSelector(state => _get(state, 'auth.isIdol'));
    const userId = useSelector(state => _get(state, 'auth.userId'));
    const [visible, setvisible] = useState(true)
    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false)
    const [isConfirmReportDialogOpen, setIsConfirmReportDialogOpen] = useState(false)
    const [reportCategory, setReportCategory] = useState(null)
    const [isReportBtnLoading, setIsReportBtnLoading] = useState(false)
    const [isDeleteshow, setDeleteshow] = useState(false)

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const moreOptionsBtnClick = useCallback(event => setMoreOptionsBtnEl(_get(event, "currentTarget")), [])
    const closeMoreOptions = useCallback(() => {
        setTimeout(() => {
            setMoreOptionsBtnEl(null)
        }, 10)
    }, [])

    const handleFollowIdol = useCallback(() => {
        setIsFollowBtnLoading(true)
        if (_get(post, "idol.userFollowed") === false)
            return dispatch(followIdol(_get(post, "idol.userId")))
                .then(() => {
                    enqueueSnackbar(`Following ${_get(post, "idol.displayName")}`, { variant: "success" })
                    setTimeout(() => setMoreOptionsBtnEl(null), 500)
                })
                .catch(err => {
                    console.log(err)
                    enqueueSnackbar(err || "Something went wrong", { variant: "error", })
                })
                .finally(() => setIsFollowBtnLoading(false))

        if (_get(post, "idol.userFollowed") === true)
            return dispatch(unfollowIdol(_get(post, "idol.userId")))
                .then(() => {
                    enqueueSnackbar(`Unfollowed ${_get(post, "idol.displayName")}`, { variant: "success" })
                    setTimeout(() => setMoreOptionsBtnEl(null), 500)
                })
                .catch(err => {
                    console.log(err)
                    enqueueSnackbar(err || "Something went wrong", { variant: "error", })
                })
                .finally(() => setIsFollowBtnLoading(false))

        // eslint-disable-next-line 
    }, [post?.idol?.userId, post?.idol?.userFollowed])

    const openReportDialog = useCallback(() => {
        setIsReportDialogOpen(true)
        setTimeout(() => setMoreOptionsBtnEl(null), 500)
    }, [])
    const closeReportDialog = useCallback(() => setIsReportDialogOpen(false), [])

    const selectReportCatogory = useCallback((category) => {
        setIsReportDialogOpen(false)
        setReportCategory(category)

        setIsConfirmReportDialogOpen(true)
    }, [])

    // const openConfirmReportDialog = useCallback(() => setIsConfirmReportDialogOpen(true), [])
    const closeConfirmReportDialog = useCallback(() => {
        setIsConfirmReportDialogOpen(false)
        setReportCategory(null)
    }, [])

    const report = useCallback((category) => {
        setIsReportBtnLoading(true)
        dispatch(reportPost(_get(post, "postId"), t(category)))
            .then(() => {
                setIsConfirmReportDialogOpen(false)
                enqueueSnackbar(`Reported successfully`, { variant: "success" })
                // setTimeout(() => setMoreOptionsBtnEl(null), 500)
            })
            .catch(err => {
                console.log(err)
                enqueueSnackbar(err || "Something went wrong", { variant: "error", })
            })
            .finally(() => {
                setIsReportBtnLoading(false)
                setReportCategory(null)
            })

        // eslint-disable-next-line 
    }, [post?.postId])

    const classes = useStyles()

    const { t } = useTranslation("feed-post")

    return (
        <>
            <makeaPost
                onClose={() => { setvisible(false) }}
                open={visible}
                classes={{ paper: classes.paper }}
                scroll="body"
                maxWidth="xs"
            />
            <Dialog
                open={isReportDialogOpen}
                onClose={closeReportDialog}
                classes={{ paper: classes.dialog }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                >
                    <Typography variant="h6">{t("report")}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton}
                        onClick={closeReportDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.reportDialogContent}>
                    {
                        _map(
                            reportCategories,
                            category => <Button
                                key={category}
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={selectReportCatogory.bind(null, category)}
                            >
                                {t(category)}
                            </Button>
                        )
                    }
                </DialogContent>
            </Dialog>
            <Dialog
                open={isConfirmReportDialogOpen}
                onClose={closeConfirmReportDialog}
                classes={{ paper: classes.dialog }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                >
                    <Typography variant="h6">{t("confirm-report")}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton}
                        onClick={closeConfirmReportDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.confirmReportDialogContent}>
                    <DialogContentText className={classes.confirmDialogContentHead}>
                        {t("confirm-dialog-content-head")}
                    </DialogContentText>
                    <DialogContentText className={classes.confirmDialogCategory}>
                        {t(reportCategory)}
                    </DialogContentText>
                    <DialogContentText className={classes.confirmDialogContentBody}>
                        {t("confirm-dialog-content-body")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        className={classes.confirmReportBtn}
                        isLoading={isReportBtnLoading}
                        color="primary"
                        variant="contained"
                        onClick={report.bind(null, reportCategory)}
                    >
                        {t("confirm")}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box className={classes.head}>
                <Box className={classes.avatarHolder}>
                    <img
                        src={_get(post, "idol.avatar") || defaultAvatar}
                        alt="avatar"
                        placeholder={defaultAvatar}
                    />
                </Box>
                <Box className={classes.uploader}>
                    <Typography
                        component="h3"
                        variant="h6"
                        className={clsx(classes.textBold, classes.pointer)}
                        onClick={() => {
                            history.push(`/creator/${_get(post, "idol.displayName")}`)
                        }}
                    >{_get(post, "idol.displayName")}</Typography>
                    <Box className={classes.meta}>
                        <Typography variant="body1" component="span">{moment(_get(post, "createdAt")).fromNow()}</Typography>
                        <Box className={classes.dot} />
                        <Typography variant="body1" component="span">{_get(post, "idol.countryName", _get(post, "idol.countryCode"))}</Typography>
                    </Box>

                </Box>

                <Box>
                    <IconButton className={classes.moreOptionsBtn} onClick={moreOptionsBtnClick}>
                        <MoreHorizIcon />
                        <Popover
                            id={`more-options-for-${_get(post, "postId")}`}
                            open={Boolean(moreOptionsBtnEl)}
                            anchorEl={moreOptionsBtnEl}
                            onClose={closeMoreOptions}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {userId !== post?.userId ?
                                <Box className={classes.moreOptionsBox}>
                                    <Button
                                        className={classes.optionBtn}
                                        startIcon={<Icon className={classes.iconInBtn}>
                                            {_get(post, "idol.userFollowed")
                                                ?
                                                <img src={unfollowPng} alt="unfollow" height={20} />
                                                :
                                                <img src={followPng} alt="follow" height={20} />
                                            }
                                        </Icon>}
                                        onClick={handleFollowIdol}
                                        disabled={isFollowBtnLoading}
                                    >{_get(post, "idol.userFollowed") ? t("untrack") : t("track")}</Button>
                                    <Button
                                        className={classes.optionBtn}
                                        startIcon={<Icon className={classes.iconInBtn}>
                                            <img src={alertPng} alt="follow" height={20} />
                                        </Icon>}
                                        onClick={openReportDialog}
                                    >
                                        {t("report-post-content")}
                                    </Button>
                                </Box>
                                :
                                <Box className={classes.moreOptionsBox}>
                                    <Button
                                        className={classes.optionBtn}
                                        onClick={() => {setDeleteshow(true)}}
                                        disabled={isFollowBtnLoading}
                                        style={{color:'red'}}
                                        startIcon={<Icon className={classes.iconInBtn}>
                                         <img src={deleteIcon} alt="follow" height={20} />
                                        </Icon>}
                                       
                                    >
                                    {t("delete")}
                                    </Button>
                                    <Button
                                        className={classes.optionBtn}
                                        startIcon={<Icon className={classes.iconInBtn}>
                                            <img src={editIcon} alt="follow" height={20} />
                                        </Icon>}
                                        onClick={() => {                                     
                                            onClickofEdit(post)
                                        }}                                 >
                                        {t("edit")}
                                    </Button>
                                </Box>}
                        </Popover>
                    </IconButton>
                </Box>

            </Box>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Dialog
                    open={isDeleteshow}
                    classes={{ paper: classes.paper }}
                    maxWidth="xs"
                >
                    <DialogTitle className={classes.dialogTitle}>
                        <Typography variant="h6" className={classes.header} align="center">
                            {t('delete-post')}
                        </Typography>
                        <IconButton aria-label="close"
                            // className={classes.closeButtonplan}
                            onClick={() => { setDeleteshow(false) }} >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.makenewpost }} >
                        {t('are-you-sure-you-want-to-delete-this-post')}
                    </DialogContent>
                    <DialogActions style={{justifyContent:'space-between',display:'flex' }}>
                        <Button
                            component="label"
                            variant="contained"
                            className={classes.buttoncancel}
                            onClick={() => { setDeleteshow(false) }}
                        >
                            <Typography variant="body1">
                                {t('cancel')}
                            </Typography>
                        </Button>
                        <Button
                            component="label"
                            variant="contained"
                            className={classes.buttondelete}
                            onClick={() => { 
                                setDeleteshow(false)
                                onClickofDelete(post)
                            }}
                        >
                            <Typography variant="body1">
                                {t('delete')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </>
    )
}

export default PostHead
