import React, { useEffect, useState } from "react";
import axios from "axios";
import _get from "lodash/get";
import "./CustomChannelHeader.css";
import env from "src/env";
import { DialogView } from "../dialogue";
import menu from "src/images/icons/menu.png";

import { useSelector } from "react-redux";
import accountlockopenoutline from "src/images/icons/account-lock-open-outline.png";
import accountlockoutline from "src/images/icons/account-lock-outline.png";
const baseUrl = `https://${env.apiDomain}`;

const {
  useChannelStateContext,
  TypingIndicator,
  useChatContext,
  Avatar,
} = require("stream-chat-react");

const getAvatarGroup = (members) => {
  if (members.length === 1) {
    return (
      <div className="messaging__channel-header__avatars">
        <Avatar
          image={members[0].user?.image || ""}
          name={members[0].user?.id}
          size={35}
        />
      </div>
    );
  }
  return null;
};
const CustomChannelHeader = (props) => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const [channelName, setChannelName] = useState(channel?.data.name || "");
  const [title, setTitle] = useState("");

  const members = Object.values(channel.state?.members || {}).filter(
    (member) => member.user?.id !== client.user?.id
  );

  const [anchorEl, setAnchorEl] = useState(false);
  const [userinfo, setUserinfo] = useState(null);
  const [usermember, setUsermember] = useState(
    Object.keys(channel.state?.members)
  );
  const [isBlock, setIsBlock] = useState(false);
  const [userinfotemp, setUserinfotemp] = useState(
    Object.values(channel.state?.members)
  );
  const authinfo = useSelector((state) => state.auth);

  let userId = authinfo?.userId;
  let blockId = userId === usermember[1] ? usermember[0] : usermember[1];
  useEffect(() => {
    if (!channelName) {
      setTitle(
        members
          .map(
            (member) => member.user?.name || member.user?.id || "Unnamed User"
          )
          .join(", ")
      );
    }
    getallblockuserlist(blockId, userId);
  }, [channelName, members]);

  const getallblockuserlist = (blockId, userId) => {
    let token = localStorage.getItem("token");
    let body = {
      blockId: blockId,
    };
    axios
      .post(`${baseUrl}/get-block-users`, body, {
        headers: {
          Authorization: `${userId}_${token}`,
        },
      })
      .then((res) => {
        if (res.data?.success && res.data.data != null) {
          setUserinfo(res.data.data);
          setIsBlock(res.data.data.isBlockByMe);
          props.setChatuserinfo({
            data: res.data.data,
            receivername:
              userId === usermember[1]
                ? userinfotemp[0]?.user?.name
                : userinfotemp[1]?.user?.name,
          });
        } else {
          setIsBlock(false);
          props.setChatuserinfo(null);
        }
      });
  };

  const onBlock = () => {
    let token = localStorage.getItem("token");
    let body = {
      fanId: userId,
      blockId: blockId,
    };
    if (userId != blockId) {
      axios
        .post(`${baseUrl}/block-user`, body, {
          headers: {
            Authorization: `${userId}_${token}`,
          },
        })
        .then((res) => {
          if (res.data.success && res.data.data != null) setAnchorEl(false);
          setIsBlock(true);
          setUserinfo(res.data.data);

          props.setChatuserinfo({
            data: res.data.data,
            receivername:
              userId === usermember[1]
                ? userinfotemp[0]?.user?.name
                : userinfotemp[1]?.user?.name,
          });
        });
    }
  };
  const onUnblock = () => {
    let token = localStorage.getItem("token");

    let body = {
      uid: userinfo.uid,
    };
    axios
      .post(`${baseUrl}/unblock-user`, body, {
        headers: {
          Authorization: `${userId}_${token}`,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setAnchorEl(false);
          setIsBlock(false);
          props.setChatuserinfo(null);
        }
      });
  };

  return (
    <>
      <div className="messaging__channel-header">
        {getAvatarGroup(members)}
        <div className="channel-header__name">{channelName || title}</div>

        <div
          onClick={() => setAnchorEl(!anchorEl)}
          className="avatar-group"         
        >
          {(userinfo?.fanId === authinfo.userId &&
            userinfo?.isBlockByMe === true) ||
          userinfo === null ||
          userId !== userinfo.blockId ? (
            <img src={menu} height={20} />
          ) : null}
        </div>
        <DialogView
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          icon={isBlock ? accountlockopenoutline : accountlockoutline}
          text={isBlock ? "Unblock" : "Block"}
          onPress={() => (isBlock ? onUnblock() : onBlock())}
        />
        <TypingIndicator />
      </div>
    </>
  );
};

export default CustomChannelHeader;
