import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  appBarRoot: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: 20
  },
  selected: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold'
  },
  tabRoot: {
    minWidth: 0
  }
}), { index: 1 });

export default useStyles;