import _get from 'lodash/get';
import axios from 'axios';
import env from 'src/env';
import { updateCreatorProfile } from 'src/redux/auth/actions';

const baseUrl = `https://${env.apiDomain}`

export const editCreatorProfile = values => async (dispatch, getState) => {
  try {
    const auth = _get(getState(), "auth");

    console.log("editCreatorProfile values", values);
    const finalValues = {
      creatorPageName: values.creatorPageName,
      creatorPageDescription: values.creatorPageDescription,
      countryCode: values.countryCode,
      profileImage: values.profileImage,
      receivePaymentMethod: values.receivePaymentMethod,
      payoneerEmail: values.payoneerEmail
    }
    console.log("editCreatorProfile finalValues", finalValues);

    if (finalValues.hasOwnProperty("countryCode")) {
      console.log("value has countryCode", _get(finalValues, "countryCode"))
      finalValues.countryCode = _get(finalValues, "countryCode.code")
    }

    const { data } = await axios.put(
      `${baseUrl}/creator-profile-page`,
      finalValues,
      {
        headers: {
          Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
        }
      }
    )

    console.log("editCreatorProfile response", data)

    if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
      return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
    }

    dispatch(updateCreatorProfile(values.creatorPageName));

    return data
  } catch (err) {
    console.log(err)
    return Promise.reject(_get(err, "message", "Something went wrong"))
  }
}