export function checkIfPasswordLongEnough(password) {
  const minLength = 8;
  if (password.length < minLength) {
    return false;
  } else {
    return true;
  }
}

export function checkIfPasswordContainNumberOrSymbol(password) {
  const numbers = '1234567890';
  const symbols = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

  for (let char of password) {
    if (numbers.includes(char) || symbols.includes(char)) {
      return true;
    }
  }

  return false;
}

export function checkIfPasswordContainNameOrEmail(password, name, email) {
  if (password.includes(name) || password.includes(email)) {
    return true;
  } else {
    return false;
  }
}

export function checkSecurityLevelOfPassword(password) {
  const upperCases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCases = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '1234567890';
  const symbols = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

  const checkingMap = {
    upperCase: false,
    lowerCase: false,
    number: false,
    symbol: false
  }

  for (const char of password) {
    if (upperCases.includes(char)) {
      checkingMap.upperCase = true;
    } else if (lowerCases.includes(char)) {
      checkingMap.lowerCase = true;
    } else if (numbers.includes(char)) {
      checkingMap.number = true;
    } else if (symbols.includes(char)) {
      checkingMap.symbol = true;
    }
  }

  let mark = 0;
  for (const value of Object.values(checkingMap)) {
    if (value) {
      mark += 1;
    }
  }

  if (mark === 4 && password.length > 8) {
    return 'strong';
  } else if (mark >= 2 && password.length > 8) {
    return 'moderate';
  } else {
    return 'weak';
  }
}