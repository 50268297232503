import { push } from 'connected-react-router';
import { destroy } from 'redux-form';
import { loginFail, loginSuccess, logout } from 'src/redux/auth/actions';
import { setLoggedInUser } from 'src/redux/user/actions';
import { startLoading, finishLoading } from 'src/redux/loading/actions';
import { clearCreatorOnboardingState } from 'src/redux/creatorOnboarding/actions'
import env from 'src/env';
import { creatorOnboardingFormName } from 'src/pages/CreatorOnboardingForm';
import { initChatClient, setChatClient } from "src/redux/chat/actions";

import _get from 'lodash/get'

export function loginThunk(email, password) {
  return async (dispatch) => {
    dispatch(startLoading());
    const res = await fetch(`https://${env.apiDomain}/auth/log-in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      }),
    });
    const result = await res.json();
    const data = result.data;

    console.log('data: ', data);

    if (!result.success) {
      dispatch(loginFail(result.code));
    } else {
      dispatch(
        loginSuccess(
          data.userId,
          data.email,
          data.displayName,
          data.fullName,
          data.countryCode,
          data.avatar,
          data.language,
          data.isIdol,
          data.stream_token,
          data.access_token,
        )
      );
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('token', data.access_token);

      dispatch(initChatClient())
    }
    dispatch(finishLoading());
  };
}

export function restoreLoginThunk() {
  return async (dispatch) => {
    console.log("in restoreLoginThunk")
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (!token || !userId) {
      dispatch(loginFail(''));
      return;
    }

    const res = await fetch(
      `https://${env.apiDomain}/me`,
      {
        method: 'GET',
        headers: {
          Authorization: `${userId}_${token}`,
        },
      }
    );

    const result = await res.json();
    const data = result.data;
    console.log('result: ', result);

    if (!result.success) {
      dispatch(loginFail(result.code));
    } else {
      dispatch(
        loginSuccess(
          data.userId,
          data.email,
          data.displayName,
          data.fullName,
          data.countryCode,
          data.avatar,
          data.language,
          data.isIdol,
          data.stream_token,
          data.access_token || token,
        )
      );

      dispatch(initChatClient())
    }
  };
}

export function logoutThunk() {
  return async (dispatch, getState) => {
    try {

    dispatch(logout());
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    
    const chatClient = _get(getState(), "chat.chatClient")

    await fetch(`https://${env.apiDomain}/auth/logout`, {
      method: 'POST',
      headers: {
        Authorization: `${userId}_${token}`,
      },
    });

    await chatClient.disconnectUser()

    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    dispatch(setLoggedInUser(null))
    dispatch(clearCreatorOnboardingState())
    dispatch(destroy(creatorOnboardingFormName))
    dispatch(setChatClient(null))
    dispatch(push('/'));
    


    } catch(err) {
      console.log(err)
      
    }

  };
}

export function socialLoginThunk(socialMedia, socialToken) {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = {
      device: 'desktop'
    };

    if (socialMedia === 'facebook') {
      body['accessToken'] = socialToken
    } else {
      body['idToken'] = socialToken
    }

    const res = await fetch(`https://${env.apiDomain}/auth/social-log-in/${socialMedia}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const result = await res.json();
    const data = result.data;

    console.log('data: ', data);

    if (!result.success) {
      dispatch(loginFail(result.code));
    } else {
      dispatch(
        loginSuccess(
          data.userId,
          data.email,
          data.displayName,
          data.fullName,
          data.countryCode,
          data.avatar,
          data.language,
          data.isIdol,
          data.stream_token,
          data.access_token,
        )
      );
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('token', data.access_token);

      dispatch(initChatClient())
    }

    dispatch(finishLoading());
  }
}