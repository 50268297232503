import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  header: {
    marginBottom: 20
  },
  paypalButtonContainer: {
    padding: 40,
    position: 'relative',
    zIndex: 1
  },
  cardRoot: {
    maxWidth: 370,
    margin: 'auto'
  },
  summaryCard: {
    padding: 20,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  middleContainer: {
    paddingBottom: 20,
    paddingTop: 20,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    justifyContent: 'space-between'
  },
  textBold: {
    fontWeight: 'bold'
  },
  bottomContainer: {
    paddingTop: 20
  },
  dialogContent: {
    marginTop: 20,
    minWidth: 240
  },
  dialogTitle: {
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  dialogButton: {
    borderRadius: 100,
    marginTop: 40
  },
  successButton: {
    borderRadius: 100,
    marginTop: 40,
    width: 150
  },
  largeScreenLeftGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  largeScreenCardRoot: {
    margin: 0
  }
}), { index: 1 });

export default useStyles;