import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  yellowTick: {
    '& path': {
      stroke: theme.palette.warning.main
    }
  }
}), { index: 1 });

export default useStyles;