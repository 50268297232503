const fontFamily = [
  "Lato",
  "Noto Sans TC",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(", ")

const themeConfig = {
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontWeight: 700,
      fontSize: 36,
      lineHeight: "44px",
    },
    h2: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: "40px",
    },
    h3: {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: "36px"
    },
    h4: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "30px"
    },
    h5: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "28px"
    },
    h6: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "24px"
    },
    body1: {
      fontSize: 16,
      lineHeight: "24px"
    },
    body2: {
      fontSize: 14,
      lineHeight: "21px"
    },
    caption: {
      fontSize: 12,
      lineHeight: "18px"
    },
    overline: {
      fontSize: 10,
      lineHeight: "15px"
    }
  },
  palette: {
    primary: {
      main: "#9900FF",
      dark: "#3D0066"
    },
    secondary: {
      main: "#FDC500",
      light: "#FFD500"
    },
    success: {
      main: "#30D158",
      light: "#06D6A0"
    },
    warning: {
      main: "#FFD60A",
      light: "#FFD166"
    },
    error: {
      main: "#FF375F",
      light: "#EF476F"
    },
    info: {
      main: "#0BCBF5"
    },
    grey: {
      100: "#F8F9FA",
      200: "#E9ECEF",
      300: "#DEE2E6",
      400: "#CED4DA",
      500: "#ADB5BD",
      600: "#6C757D",
      700: "#495057",
      800: "#343A40",
      900: "#212529"
    }
  }
}

export default themeConfig;