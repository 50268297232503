import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    boxSizing: 'border-box',
    justifyContent: 'center',
    background: theme.palette.grey[100],
    width: '100%',
    padding: 30,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection:'column'
     },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  paperRoot: {
    padding: 40,
    borderRadius: '20px',
    border: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      padding: 10,
    }
  },
  header: {
    marginBottom: '10px'
  },
  description: {
    marginBottom: '40px'
  },
  socialMediaContainer: {
    marginBottom: '20px',
    '& div': {
      marginRight: 30
    },
    '& div:last-child': {
      marginRight: 0
    }
  },
  iconBubble: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 100,
    border: `2px solid ${theme.palette.grey[200]}`,
  },
  socialLoginIcon: {
    cursor: 'pointer'
  },
  lineContainer: {
    width: '100%',
    marginBottom: '20px'
  },
  line: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    alignSelf: 'center',
    flex: 1
  },
  or: {
    padding: '0 5px'
  },
  textField: {
    width: 430,
    marginBottom: '15px',
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
    width: "100%",
  },
  inputContainer: {
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column'
  },
  password: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    }
  },
  button: {
    borderRadius: '100px',
    padding: '10px',
    marginTop: 40
  },
  login: {
    marginLeft: '15px'
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  alertContainer: {
    marginBottom: 15
  },
  textBold: {
    fontWeight: 'bold'
  },
  facebookLoginWrapper: {
    '& span': {
      height: '24px !important',
      width: '24px !important',
    },
    '& button': {
      border: 'none',
      padding: '0 !important',
      borderRadius: '100px !important',
      height: '24px !important',
      width: '24px !important'
    }
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 30,
    background: 'white',
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 33,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: 4,
    marginBottom: 40
  }
}), { index: 1 });

export default useStyles;