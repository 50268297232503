import React, { useState, useEffect,Component, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, Field, initialize, clearFields, change } from 'redux-form'
import { useParams } from 'react-router'
import _get from 'lodash/get'
import _isObject from 'lodash/isObject'
import _find from 'lodash/find'
import _trim from 'lodash/trim'
import _includes from 'lodash/includes'

import { 
    submitTier,
    getTierById,
    setTierFormOpen,
    setEditTierId,
    setRemoveTierDialog,
    deleteTier,
    getAllPricePlans,
} from 'src/redux/creatorOnboarding/actions'
import { ReactComponent as CreatorIcon } from "src/images/login-page/Creator-Icon.svg";
import { ReactComponent as Creatoricon2 } from "src/images/login-page/Creator-icon2.svg";
import RenderTextField from 'src/components/renderTextField/FormField'
import RenderAutocomplete from 'src/components/renderAutocomplete/FormField'
import RenderTextArea from 'src/components/renderTextArea/FormField'
import RenderImage from 'src/components/renderImage/FormField'
import CropImageDialog from 'src/components/creator-onboarding/CropUploadImage';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Box, Paper, Typography, Button, FormGroup, FormControlLabel, Checkbox, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'  
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LoadingButton from 'src/components/LoadingButton';
import useStyles from 'src/styles/pages/creatorOnboaringForm'
import { useSnackbar } from 'notistack'
import UploadImg from "src/images/common/upload-white-64.png";

import { translatorJson } from './BasicInformationForm'

export const tierFormName = "tier-form"



const TierForm = (props) => {
    const [isLimitingSubscribers, setLimitingSubscribers ] = useState(true)
    const [ isCropImageOpen, setCropImageOpen ] = useState(false)
    const [ imageHeight, setImageHeight ] = useState(null)
    const [ isLoadingRemoveBtn, setIsLoadingRemoveBtn ] = useState(false)
    const [ pricePlans, setPricePlans ] = useState([])
    const { tierId } = useParams()
    const editTierId = useSelector(state => _get(state, "creatorOnboarding.editTierId")) || tierId
    const isRemoveTierDialogOpen = useSelector(state => _get(state, "creatorOnboarding.isRemoveTierDialogOpen"))

    useEffect(() => {
        setImageHeight(imageUploaderDivRef.current.clientWidth * 1/1)

        const getSavedData = () => {
            if(editTierId) {
                // get tier to initialize form values
                return dispatch(getTierById(editTierId)).then(data => {
                    console.log("useEffect for editTierId got data", data)
    
                    // manipulating for autocomplete options
                    // while saving, will be reverted to primitive
                    if(data.hasOwnProperty("isactive")) {
                        console.log("value has isactive", _get(data, "isactive"))
                        data.isactive = _get(data, "isactive") ? 
                        _find(
                            tierStatusOptions,
                            option => {
                                console.log("tier status option", option)
                                return _get(option, "value")
                            }
                        ) :
                        _find(
                            tierStatusOptions,
                            option => {
                                console.log("tier status option", option)
                                return !_get(option, "value")
                            }
                        )
    
                    }
            
                    if(data.hasOwnProperty("category")) {
                        console.log("value has category", _get(data, "category"))
                        data.category = _find(
                            categoryOptions,
                            option => {
                                console.log("tier status option", option)
                                return _get(data, "category") === _get(option, "value")
                            }
                        )
                    }
                    
                    // dispatch(initialize(tierFormName, data))
                    return data
                })
            }
        }

        const getPlans = () => {
            return dispatch(getAllPricePlans()).then(plans => {
                setPricePlans(plans)
                return plans
            })
        }

        Promise.all([
            getPlans(),
            getSavedData(),
        ]).then(([plans, data]) => {
            console.log("TierForm UseEffect Promise.all response", plans, data)
            if(data) {

                if(data.hasOwnProperty("price")) {
                    console.log("value has price", _get(data, "price"))
                    data.price = _find(
                        plans,
                        option => {
                            console.log("plans option", option)
                            return _get(data, "price") === _get(option, "price_android")
                        }
                    )
                }

                dispatch(initialize(tierFormName, data))
            }
        })


        return () => {
            // on unmount, removing editTierId
            if(editTierId) dispatch(setEditTierId(null))
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(props.submitFailed) {
            enqueueSnackbar("Something went wrong", { variant: "error", })
        }

        // eslint-disable-next-line
    }, [props.submitFailed])

    const imageUploaderDivRef = useRef(null)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const classes = useStyles({
        uploadDivHeight: imageHeight,
        isLoadingRemoveBtn,
    })
    const { t } = useTranslation(translatorJson);

    const tierStatusOptions = useMemo(() => ([
        {
            value: 1,
            label: t("published")
        },
        {
            value: 0,
            label: t("unpublish"),
        },

        // eslint-disable-next-line
    ]), []) 

    const categoryOptions = useMemo(() => ([
        {
            value: "self-defined",
            label: t("self-defined"),
        },
        // {
        //     value: "sms-chat-plan",
        //     label: t("sms-chat-plan"),
        // },
        // {
        //     value: "voice-messaging-plan",
        //     label: t("voice-messaging-plan"),
        // },
        // {
        //     value: "private-chat-plan",
        //     label: t("private-chat-plan"),
        // },

        // eslint-disable-next-line
    ]), [])
    
    const handleCheckbox = useCallback(event => {
        if(_get(event, "target.checked")) {

            setLimitingSubscribers(true)
        } else {
            setLimitingSubscribers(false)
            dispatch(clearFields(tierFormName, false, false, "capacity"))
        }

        // eslint-disable-next-line
    }, [isLimitingSubscribers])

    const handleCancelForm = useCallback(() => {
        dispatch(setTierFormOpen(false))
        
        if(_includes(
            _get(props, "location.pathname"),
            "/tier/form",
        )) {
            props?.history?.push?.("/my-creator-profile")
        }
        
        // eslint-disable-next-line
    }, [props?.location?.pathname, props?.history])

    const handleDelete = useCallback(async () => {
        try {
            setIsLoadingRemoveBtn(true)
            const data = await dispatch(deleteTier(editTierId))
            console.log("handleDelete response", data)
            setIsLoadingRemoveBtn(false)
            dispatch(setRemoveTierDialog(false))
            dispatch(setTierFormOpen(false))

            if(_includes(
                _get(props, "location.pathname"),
                "/tier/form",
            )) {
                props?.history?.push?.("/my-creator-profile")
            }
        } catch(err) {
            console.log(err)
        }

        // eslint-disable-next-line
    }, [editTierId])

    // eslint-disable-next-line
    const closeDeleteConfirmationDialog = useCallback(() => dispatch(setRemoveTierDialog(false)), [])

    const followUploadedUrl = useCallback(image => {
        dispatch(change(tierFormName, "image", image))
        editTierId && dispatch(change(tierFormName, "isImgChanged", true))

        // eslint-disable-next-line
    }, [])

    const isRouteComponent = _includes(
        _get(props, "location.pathname"),
        "/tier/form",
    ) ? true : false

    return (
        <>
        <CropImageDialog 
            open={isCropImageOpen}
            handleOpen={() => setCropImageOpen(true)}
            handleClose={() => setCropImageOpen(false)}
            followUploadedUrl={followUploadedUrl}
            // droppedFile={}
            accept="image/png, image/jpg, image/jpeg"
            updateBtnLabel={t('update-image')}
            aspectRatio={1 / 1}
        />
        {/* dialog for confirmation while removing a tier */}
        <Dialog
            open={isRemoveTierDialogOpen}
            onClose={closeDeleteConfirmationDialog}
            classes={{paper: classes.dialog}}
        >
            <DialogTitle 
                className={classes.customDialogTitle}
                // classes={{container: classes.dialogTitle}}
            >
                <Typography variant="h6">{t("remove-pair-tier")}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={closeDeleteConfirmationDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.customDialogContent}>
                    {t("remove-tier-dialog-content")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton 
                    onClick={handleDelete} 
                    className={classes.customRemoveBtn}
                    isLoading={isLoadingRemoveBtn}
                >
                    {t("confirm")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Box className={clsx(classes.formContainer, isRouteComponent && classes.routeFormContainer)}>
            <Box className={clsx(classes.formElement, isRouteComponent && classes.routeFormElement)}>
                <Typography className={classes.formElementTitle} variant="h5" component="h3">
                    {t("new-payment-tier")}
                </Typography>
                <Paper className={classes.formElementBody}>
                    <Box className={classes.inputGroup}>
                        <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("plan-level-name")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("must")}
                            </Typography>
                        </Box>
                        <Box className={classes.inputBox}>
                            <Field
                                name="title"
                                component={RenderTextField}
                                variant="outlined"
                                fullWidth={true}
                                translatorJson={translatorJson}
                            />
                        </Box>
                    </Box>

                    {/* <Box className={classes.inputGroup}> */}
                        {/* <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("plan-level-status")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("must")}
                            </Typography>
                        </Box> */}
                        {/* <Box className={classes.inputBox}>
                            <Field
                                name="isactive"       // ?
                                component={RenderAutocomplete}
                                options={tierStatusOptions}
                                getOptionLabel={option => {
                                    // console.log("Field autocomplete getOptionLabel option", option)
                                    return _isObject(option) ? _get(option, "label") : option
                                }}
                                fullWidth={true}
                                optionValueField="value"
                                textFieldProps={{
                                    variant: "outlined",
                                    fullWidth: true,
                                }}
                                translatorJson={translatorJson}
                            />
                        </Box> */}
                    {/* </Box> */}

                    <Box className={classes.inputGroup}>
                        <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("monthly-subscription-price")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("must")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("monthly-subscription-price-note")}
                            </Typography>
                        </Box>
                        <Box className={classes.inputBox}>
                            <Field
                                name="price"
                                component={RenderAutocomplete}
                                options={pricePlans}
                                getOptionLabel={option => {
                                    return _isObject(option) ? `${_get(option, "name")} - ${_get(option, "price_android")}$ - ${_get(option, "duration_month")} month` : option
                                }}
                                fullWidth={true}
                                // optionValueField="price_ios"
                                textFieldProps={{
                                    variant: "outlined",
                                    fullWidth: true,
                                }}
                                translatorJson={translatorJson}
                            />

                        </Box>
                    </Box>

                    <Box className={classes.inputGroup}>
                        <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("plan-grade-picture")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("must")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("plan-grade-picture-recommandation")}
                            </Typography>
                        </Box>
                        <Box className={classes.inputBox}>
                            
                            <Box 
                                ref={imageUploaderDivRef}
                                className={classes.uploadDiv}
                            >
                                <Box className={classes.uploadPhotosBtnContainer}>
                                    <Button 
                                        className={clsx(classes.button, classes.uploadBtn)}
                                        startIcon={<img src={UploadImg} alt="upload" height={20} />}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setCropImageOpen(true)}
                                    >
                                        <Typography>
                                            {t("upload-photos")}
                                        </Typography>
                                    </Button>
                                    
                                    <Field 
                                        name="image"
                                        component={RenderImage}
                                        alt={"image"}
                                        containerClass={classes.profileImageContainer}
                                        imageClass={classes.profileImage}
                                        deleteIconClass={classes.customDeleteIcon}
                                        editIconClass={classes.customEditIcon}
                                        handleDelete={() => {
                                            dispatch(change(tierFormName, "image", null))
                                        }}
                                        handleEdit={() => setCropImageOpen(true)}
                                        imageProps={{
                                            onLoad: event => {
                                                console.log("crop: image onLoad", event)
                                            }
                                        }}
                                        translatorJson={translatorJson}
                                    />
                                </Box>
                                
                            </Box>

                        </Box>
                    </Box>

                    {/* <Box className={classes.inputGroup}>
                        <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("plan-level-category")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("must")}
                            </Typography>
                        </Box>
                        <Box className={classes.inputBox}>
                            <Field
                                name="category"
                                component={RenderAutocomplete}
                                options={categoryOptions}
                                getOptionLabel={option => {
                                    // console.log("Field autocomplete getOptionLabel option", option)
                                    return _isObject(option) ? _get(option, "label") : option
                                }}
                                fullWidth={true}
                                optionValueField="value"
                                textFieldProps={{
                                    variant: "outlined",
                                    fullWidth: true,
                                }}
                                translatorJson={translatorJson}
                            />
                        </Box>
                    </Box> */}

                    <Box className={classes.inputGroup}>
                        <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("plan-level-description")}
                            </Typography>
                            <Typography className={classes.condition}>
                                {t("must")}
                            </Typography>
                           
                        </Box>
                        <Box className={classes.inputBox}>
                            <Field
                                name="description"
                                component={RenderTextArea}
                                className={classes.textArea}
                                placeholder={t("plan-level-description-placeholder")}
                                translatorJson={translatorJson}
                            />
                        </Box>
                        
                    </Box>

                    <Box className={classes.inputGroup}>
                        <Box className={classes.labelBox}>
                            <Typography className={classes.label} variant="h6">
                                {t("subscriber-limit")}
                            </Typography>
                        </Box>
                        <Box className={classes.inputBoxVertical}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox 
                                        checked={isLimitingSubscribers} 
                                        onChange={handleCheckbox} 
                                        color="primary"    
                                    />}
                                    label={t("limit-number-of-subscribers")}
                                />
                            </FormGroup>
                            <Field
                                name="capacity"
                                component={RenderTextField}
                                className={clsx(!isLimitingSubscribers && classes.none)}
                                variant="outlined"
                                fullWidth={true}
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                                placeholder={t("limit-number-of-subscribers-placeholder")}
                                translatorJson={translatorJson}
                            />
                        </Box>
                    </Box>
                    <Box style={{padding:20,borderRadius:"20px",borderColor:"#FF375F",borderWidth:"5px",marginBottom:20}}>
                            <Box>
                                <Typography style={{color:"#FF375F"}} variant="h6">
                                        <CreatorIcon/> {t("Please-make")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography style={{color:"#FF375F"}} variant="h6">
                                        <Creatoricon2 /> {t("Make-sure")}
                                </Typography>
                            </Box>
                    </Box>  
                    
                    <Box className={classes.actionBtnContainer} >
                        <LoadingButton
                            onClick={props.handleSubmit}
                            variant="contained"
                            color="primary"
                            className={clsx(classes.button, classes.release)}
                            type="submit"
                            isLoading={props.submitting}
                            loadingProps={{
                                size: 24
                            }}                        
                        >
                            {t("storage-level")}
                        </LoadingButton>
                        <Button
                            className={clsx(classes.button)}
                            onClick={handleCancelForm}
                        >
                            <Typography className={classes.a}>
                                {t("cancel")}
                            </Typography>
                        </Button>
                        {editTierId && <Box
                                className={classes.deleteIconContainer}
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => dispatch(setRemoveTierDialog(true))}
                                >
                                    <DeleteIcon
                                        style={{color: "red"}}
                                    />
                                </IconButton>
                            </Box>
                        }                   
                    </Box>
                   
                    
                </Paper>
            </Box>
        </Box>
        </>
    )
}

const validate = (values, props) => {

    // errors are translated in Field-components.
    const errors = {}
    console.log("tier form validate values", values)

    if(!_trim(_get(values, "title"))) errors["title"] = "must"

    // if(!_get(values, "isactive")) errors["isactive"] = "must" 

    if(!_trim(_get(values, "price"))) errors["price"] = "must"

    if(!_get(values, "image")) errors["image"] = "must" 

    if(!_get(values, "category")) errors["category"] = "must" 

    if(!_trim(_get(values, "description"))) errors["description"] = "must"

    return errors
}

const onSubmit = (values, dispatch, props) => {
    console.log("submitting new tier", values)
    
    return dispatch(submitTier(values, _get(props, "match.params.tierId")))
}

const onSubmitSuccess = (result, dispatch, props) => {
    // show dialog
    console.log("submitted successfully", result)
    dispatch(setTierFormOpen(false))

    // if on /tier/form or /tier/form/:tierId
    // route to /my-creator-profile
    if(_includes(
        _get(props, "location.pathname"),
        "/tier/form",
    )) {
        props?.history?.push?.("/my-creator-profile")
    }
}

const onSubmitFail = (errors, dispatch, submitError, props) => {
    console.log("submitting tier failed", errors, submitError)
}

export default reduxForm({
    form: tierFormName,
    validate,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
})(TierForm)
