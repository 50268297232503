import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: 20,
    padding: 20,
    width: 'fit-content',
    boxSizing: 'border-box',
    minWidth: 320,
    '& $menuItem:last-child': {
      borderBottom: 'none'
    }
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: '10px 15px',
    "&.Mui-focusVisible": {
      backgroundColor: "white !important",
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40
  },
  button: {
    borderRadius: 100,
    width: 100
  },
  inputRoot: {
    padding: '5px 10px',
    borderRadius: 20,
    backgroundColor: theme.palette.grey[100],
    width: '100%'
  },
  input: {
    padding: 0,
    fontSize: 16,
    lineHeight: '24px'
  },
  searchIcon: {
    marginRight: 5,
    '& path': {
      stroke: theme.palette.grey[500]
    }
  },
  optionsContainer: {
    overflowY: 'auto',
    marginTop: 20,
    maxHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    "&::-webkit-scrollbar": {
      backgroundColor: "#E9ECEF",
      borderRadius: "30px",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "30px",
      width: "5px",
    },
  }
}), { index: 1 });

export default useStyles;