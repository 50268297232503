import React, { useState, useRef, useEffect } from "react";
import { InputBase, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import useStyles from "src/styles/components/header/searchBar";
import { ReactComponent as SearchIcon } from "src/images/common/icon_search.svg";
import env from "src/env";
import { Paper, Button } from "@material-ui/core";
import SearchResultSingle from "src/components/header/SearchResultSingle";
import InfoDialog from "src/components/InfoDialog";
import _ from "lodash";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';


export default function SearchBar({
  isMobileSearchOpen,
  setIsMobileSearchOpen,
}) {
  const classes = useStyles({
    isMobileSearchOpen,
  });
  const { t } = useTranslation("header");
  const [searchTerm, setSearchTerm] = useState('');
  const [openPopUp, setOpenPopUp] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [recentSearches, setRecentSearches] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [currentlyActive, setCurrentlyActive] = useState(0);
  const dispatch = useDispatch();

  let mode = openPopUp && searchResults ? 'display search results' : 'display recent searches';

  const handleChangeSearchTerm = (e) => {
    setCurrentlyActive(0);
    setSearchTerm(e.target.value);
  }

  const search = async (searchTerm) => {
    console.log('search');
    setIsSearching(true);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const res = await fetch(`https://${env.apiDomain}/idols/search/${searchTerm}`, {
      method: 'GET',
      headers: {
        Authorization: `${userId}_${token}`,
      },
    });
    const result = await res.json();
    console.log('search result: ', result);
    if (result.success) {
      setSearchResults(result.data.Items.map(obj => ({ ...obj, isHighlight: false })));
    } else {
      setSearchResults([]);
    }
    setIsSearching(false);
  }

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    if (currentlyActive > 0) {
      clickSearchRecord(e, searchResults[currentlyActive - 1]);
    }
    if (!searchTerm) {
      return
    }

    await search(searchTerm);

    console.log('submit')
  }

  const debounceSearch = useRef(
    _.debounce(async (searchTerm) => {
      await search(searchTerm);
    }, 300)
  );

  useEffect(
    () => {
      if (searchTerm) {
        setIsSearching(true);
        debounceSearch.current(searchTerm);
      } else {
        setTimeout(() => {
          setSearchResults(null);
        }, 1000)
      }
    },
    [searchTerm]
  );

  const closePopUp = () => {
    console.log('close');
    setOpenPopUp(false);
    setSearchResults(null);
  };

  const handleClick = async () => {
    if (!recentSearches) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const res = await fetch(`https://${env.apiDomain}/idols/recent-search`, {
        method: 'GET',
        headers: {
          Authorization: `${userId}_${token}`,
        }
      });

      const result = await res.json();
      if (result.success) {
        setRecentSearches(result.data.map(obj => ({ ...obj, isHighlight: false })));
      } else {
        setRecentSearches([]);
      }
    }
    setOpenPopUp(true);
  }

  const clickSearchRecord = async (event, idol) => {
    console.log('add')
    event.stopPropagation();
    setSearchTerm('')
    setCurrentlyActive(0);
    setIsMobileSearchOpen(false)
    closePopUp();
    dispatch(push(`/creator/${idol.fanshipPageName}`));
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const res = await fetch(`https://${env.apiDomain}/idols/recent-search/${idol.userId}`, {
      method: 'POST',
      headers: {
        Authorization: `${userId}_${token}`
      }
    });

    const result = await res.json();

    console.log('result: ', result);

    const newRecentSearches = JSON.parse(JSON.stringify(recentSearches));
    const index = newRecentSearches.findIndex((search) => search.userId === idol.userId);
    if (index >= 0) {
      newRecentSearches.splice(index, 1);
    }
    newRecentSearches.unshift(idol);
    setRecentSearches(newRecentSearches);
  }

  const deleteASearch = async (event, idol) => {
    console.log('event: ', event);
    console.log('on click delete');
    event.preventDefault();
    event.stopPropagation();
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const res = await fetch(`https://${env.apiDomain}/idols/recent-search/${idol.userId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `${userId}_${token}`
      }
    });
    const result = await res.json();
    console.log('result: ', result);
    const newRecentSearches = JSON.parse(JSON.stringify(recentSearches));
    const index = newRecentSearches.findIndex((search) => search.userId === idol.userId);
    newRecentSearches.splice(index, 1);
    setRecentSearches(newRecentSearches);
  }

  const deleteAllRecentSearches = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const res = await fetch(`https://${env.apiDomain}/idols/recent-search`, {
      method: 'DELETE',
      headers: {
        Authorization: `${userId}_${token}`
      }
    });
    const result = await res.json();
    console.log('result: ', result);
    setRecentSearches([]);
    setDialogOpen(false);
  }

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const handleKeyDown = (e) => {
    // arrow up/down button should select next/previous list element
    let listItems;
    if (mode === 'display recent searches') {
      if (recentSearches && recentSearches.length > 0) {
        listItems = [...recentSearches];
      }
      console.log("listItems",listItems);

    } else {
      if (searchResults && searchResults.length > 0) {
        listItems = [...searchResults];
      }
    }
    if (e.keyCode === 38) {

      if (currentlyActive !== 0) {
        setCurrentlyActive(prevState => {
          return prevState - 1
        });
        for (let i = 0; i < listItems.length; i++) {
          listItems[i].isHighlight = false;
        }
        listItems[currentlyActive > 1 ? currentlyActive - 2 : currentlyActive - 1].isHighlight = true;
        setSearchResults(listItems);

      } else {
        setCurrentlyActive(0);
      }
    } else if (e.keyCode === 40) {
      setCurrentlyActive(prevState => (prevState === listItems.length) ? 0 : prevState + 1);
      if (listItems.length > currentlyActive) {
        for (let i = 0; i < listItems.length; i++) {
          listItems[i].isHighlight = false;
        }
        listItems[currentlyActive].isHighlight = true;
        setSearchResults(listItems);
      } else {
        setCurrentlyActive(0);
      }
    }
    if (mode === 'display recent searches') {
      setRecentSearches(listItems);
    } else {
      setSearchResults(listItems);
    }
  }

  return (
    <>
      <form
        className={clsx(classes.flexContainer, classes.root)}
        onSubmit={onSubmitSearch}
      >
        <InputBase
          placeholder={t("search-creator")}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
          }}
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          onMouseDown={handleClick}
          onKeyDown={handleKeyDown}
          onBlur={(event) => {
            if (!event.relatedTarget ||
              !event.relatedTarget.className ||
              !event.relatedTarget.className.includes('MuiDialog-container'))
              closePopUp()
            setCurrentlyActive(0);
          }}
        />
        <div
          className={classes.iconBubble}
          onMouseDown={onSubmitSearch}
        >
          <SearchIcon />
        </div>
        {
          openPopUp &&
          <Paper classes={{ root: classes.searchPaperRoot }}>
            {
              isSearching ?
                (
                  <div className={classes.spinnerWrapper}>
                    <CircularProgress size={40} />
                  </div>
                ) :
                <>
                  {
                    mode === 'display recent searches' &&
                    recentSearches && recentSearches.length > 0 &&
                    (
                      <>
                        <div className={clsx(classes.flexContainer, classes.recentSearchTop)}>
                          <Typography variant="body1" className={classes.textBold}>
                            {t('search-bar.recent')}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={clsx(classes.textBold, classes.deleteAll)}
                            color="primary"
                            onMouseDown={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              setOpenPopUp(true);
                              setDialogOpen(true);
                            }}
                          >
                            {t('search-bar.delete-all')}
                          </Typography>
                        </div>
                        {
                          recentSearches.map((recentSearch) => {
                            console.log("recentSearches: map recentSearches",recentSearch)
                            return (
                              <SearchResultSingle
                                key={`record_${recentSearch.userId}`}
                                onMouseDown={(event) => {
                                  console.log("SearchResultSingle: onMouseDown", recentSearch)
                                  clickSearchRecord(event, recentSearch)
                                }}
                                onKeyPress={(event) => {
                                  console.log("SearchResultSingle: onKeypress", recentSearches)
                                  clickSearchRecord(event, recentSearches)
                                }}
                                isHighlight={recentSearches.isHighlight}
                                avatar={recentSearch.avatar}
                                displayName={recentSearch.displayName}
                                fullName = {recentSearch.fullName}
                                // countryCode={recentSearch.countryCode}
                                deleteFunction={(event) => deleteASearch(event, recentSearch)}
                              />
                            )
                          })
                        }
                      </>
                    )
                  }
                  {
                    mode === 'display search results' && (
                      <>
                        {
                          searchResults.length > 0 ?
                            searchResults.map((searchResult) => {
                              return (
                                <SearchResultSingle
                                  key={`result_${searchResult.userId}`}
                                  onMouseDown={(event) => {
                                    console.log("SearchResultSingle: onMouseDown", searchResult)
                                    clickSearchRecord(event, searchResult)
                                  }}
                                  onKeyPress={(event) => {
                                    console.log("SearchResultSingle: onKeypress", searchResult)
                                    clickSearchRecord(event, searchResult)
                                  }}
                                  isHighlight={searchResult.isHighlight}
                                  avatar={searchResult.avatar}
                                  displayName={searchResult.displayName}
                                  fullName = {searchResult.fullName}
                                  // countryCode={searchResult.countryCode}
                                />
                              )
                            }) :
                            <div className={classes.noResult}>
                              <Typography variant="body1" className={classes.textBold}>
                                {t('search-bar.no-related-record')}
                              </Typography>
                            </div>
                        }
                      </>
                    )
                  }
                </>
            }
          </Paper>
        }
      </form>
      {isMobileSearchOpen && <IconButton
        onClick={() => {
          setIsMobileSearchOpen(false)
          setSearchTerm('')
        }}
      // clear search on cancel
      >
        <HighlightOffOutlinedIcon />
      </IconButton>}
      <div className={classes.dialogWrapper}>
        <InfoDialog
          open={dialogOpen}
          title={
            <Typography
              variant="h6"
              className={clsx(classes.textBold, classes.dialogHeader)}
              align="center"
            >
              {t('delete-all-dialog.header')}
            </Typography>
          }
          handleClose={closeDialog}
          button={
            <Button
              fullWidth
              className={classes.dialogButton}
              onClick={deleteAllRecentSearches}
            >
              <Typography variant="body1" className={classes.textBold}>
                {t('delete-all-dialog.confirm')}
              </Typography>
            </Button>
          }
        >
          <div className={classes.dialogContent}>
            <Typography
              variant="body1"
              align="left"
            >
              {t('delete-all-dialog.content')}
            </Typography>
          </div>
        </InfoDialog>
      </div>
    </>
  );
}