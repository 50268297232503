export const initialAuthState = {
  isAuthenticated: null,
  userId: null,
  email: null,
  displayName: null,
  fullName: null,
  countryCode: null,
  avatar: null,
  language: null,
  isIdol: null,
  stream_token: null,
  msg: ''
};