import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/contactUs';
import { Typography, TextField } from '@material-ui/core';
import ErrorHelperText from 'src/components/ErrorHelperText';
import LoadingButton from 'src/components/LoadingButton';
import InfoDialog from 'src/components/InfoDialog';
import clsx from 'clsx';
import env from 'src/env';

export default function ContactUs() {
  const { t } = useTranslation("contact-us");
  const { t: te } = useTranslation("error");
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [submitTimes, setSubmitTimes] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [apiErrorCode, setApiErrorCode] = useState('');

  const handleFieldChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'content':
        setContent(event.target.value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitTimes(submitTimes + 1);
    if (!name || !email || !content) {
      return;
    }

    setIsLoading(true);

    const res = await fetch(`https://${env.apiDomain}/user-support`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        content
      })
    })

    const result = await res.json();

    console.log('result: ', result);

    if (result.success) {
      setApiErrorCode('');
      setDialogOpen(true);
      setName('');
      setEmail('');
      setContent('');
      setSubmitTimes(0);
    } else {
      setApiErrorCode(result.code);
      setDialogOpen(true);
    }

    setIsLoading(false);
  }

  const handleClose = () => {
    setDialogOpen(false);
  }

  const DialogTitle = () => {
    return (
      <Typography
        variant="h6"
        className={clsx(classes.textBold, classes.dialogTitle)}
        align="center"
      >
        {apiErrorCode ? te('title') : t('success-message.header')}
      </Typography>
    )
  }

  const ConfirmButton = () => {
    return (
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        className={classes.confirmButton}
        onClick={handleClose}
      >
        <Typography variant="body1">
          {t("success-message.confirm")}
        </Typography>
      </LoadingButton>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <Typography
          variant="h3"
          className={classes.header}
        >
          {t('header')}
        </Typography>
        <Typography
          variant="h5"
          className={classes.subHeader}
        >
          {t('sub-header')}
        </Typography>

        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            value={name}
            onChange={(event) => handleFieldChange(event, 'name')}
            label={t('name')}
            variant="outlined"
            className={classes.textField}
            error={submitTimes > 0 && !name}
            helperText={
              submitTimes > 0 && !name &&
              <ErrorHelperText text={t('errors.name')} />
            }
          />
          <TextField
            fullWidth
            value={email}
            onChange={(event) => handleFieldChange(event, 'email')}
            label={t('email')}
            variant="outlined"
            className={classes.textField}
            InputProps={
              {
                type: 'email',
                name: 'email',
              }
            }
            error={submitTimes > 0 && !email}
            helperText={
              submitTimes > 0 && !email &&
              <ErrorHelperText text={t('errors.email')} />
            }
          />
          <TextField
            fullWidth
            value={content}
            onChange={(event) => handleFieldChange(event, 'content')}
            label={t('content')}
            variant="outlined"
            error={submitTimes > 0 && !content}
            helperText={
              submitTimes > 0 && !content &&
              <ErrorHelperText text={t('errors.content')} />
            }
            multiline
            minRows={5}
            maxRows={15}
          />

          <LoadingButton
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            isLoading={isLoading}
            loadingProps={{
              size: 24
            }}
          >
            <Typography variant="body1">
              {t("submit")}
            </Typography>
          </LoadingButton>
        </form>
        <InfoDialog
          open={dialogOpen}
          title={<DialogTitle />}
          button={<ConfirmButton />}
          handleClose={handleClose}
        >
          <Typography
            variant="body1"
            align="left"
            className={classes.dialogContent}
          >
            {apiErrorCode ? te(`code.${apiErrorCode}`) : t('success-message.content')}
          </Typography>
        </InfoDialog>
      </div>
    </div>
  )
}