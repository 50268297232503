import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
    },
    paginationContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '10px 0px'
    },
    pagination: {
        "& .MuiPaginationItem-root": {
          fontSize: 14,
          color: theme.palette.grey[400],
        },
        "& .Mui-selected": {
          fontWeight: "bold",
          color: "black",
          backgroundColor: "transparent",
        },
        "& svg": {
          fill: theme.palette.primary.main,
        },
        "& svg path": {
          stroke: theme.palette.primary.main,
        },
    },    
    progressHolder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
    },
}))

export default useStyles