import React from 'react'
import { Line } from "react-chartjs-2";
import moment from "moment";

export default function LineChart({
  labels,
  chartValues,
  chartColors,
  borderColor
}) {
  const weekdayMap = {
    '1': 'M',
    '2': 'T',
    '3': 'W',
    '4': 'T',
    '5': 'F',
    '6': 'S',
    '7': 'S'
  }


  let adjustedLabels = labels.length <= 7 ? labels.map((date) => {
    let weekday = moment(date, 'YYYY-MM-DD').isoWeekday();
    return weekdayMap[weekday]
  }) : labels.map((date) => {
    return moment(date, 'YYYY-MM-DD').format('M-D');
  })


  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight);
    gradient.addColorStop(0, chartColors[0]);
    gradient.addColorStop(1, chartColors[1]);

    return {
      labels: adjustedLabels,
      datasets: [
        {
          data: chartValues,
          backgroundColor: gradient,
          borderColor: borderColor,
          borderWidth: 0,
        }
      ]
    }
  }
  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        ticks: {
          display: labels.length <= 7
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: false,
          min: 0,
          userCallback: function (label) {
            if (Math.floor(label) === label) {
              return label;
            }
          }
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }],
    },
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  )
}