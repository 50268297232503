import React from 'react'
import useStyles from "src/styles/components/personal-profile/toBeCreatorCard";
import {
  Typography,
  Paper,
  Button
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import { useHistory } from "react-router-dom"

export default function ToBeCreatorCard() {
  const classes = useStyles();
  const { t } = useTranslation("personal-profile");
  const history = useHistory()

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <Typography variant="h6" className={clsx(classes.textBold, classes.header)}>
        {t('become-creator')}
      </Typography>
      <Typography variant="body1">
        {t('become-creator-description')}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => history.push("/creator/onboarding/form")}
      >
        <Typography variant="body2" className={classes.textBold}>
          {t('start-immediately')}
        </Typography>
      </Button>
    </Paper>
  )
}