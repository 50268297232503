import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import TermsAndConditions from './pages/TermsAndConditions';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'src/redux/store';
import './i18n';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PlatformUsageGuide from "./pages/PlatformUsageGuide";
import Deeplink from "./pages/Deeplink";

const Loader = () => (
  <div>loading...</div>
);

let requestParameter = window.location.search.substring(1);
let requestData = requestParameter.split("?");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
      <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
); 

// {console.log("request params: ", requestData, requestParameter)}
// {
//   requestData.length > 0 ? requestData[0] == "terms" ? <TermsAndConditions /> :
//   requestData[0] == "privacy" ? <PrivacyPolicy />:
//   requestData[0] == "platformguide" ? <PlatformUsageGuide /> :
//   requestData[0] == "applink" ? <Deeplink />: 
//   <App />:<App />
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
