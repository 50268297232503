import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/dashboard';
import {
  CircularProgress, Typography
} from "@material-ui/core";
import env from 'src/env';
import SimpleTabs from 'src/components/SimpleTabs';
import { push } from 'connected-react-router';
import moment from 'moment';
import Performance from 'src/components/dashboard/Performance';
import SubscriberList from 'src/components/dashboard/SubscriberList';
import ReferralList from 'src/components/dashboard/ReferralList';

export default function Dashboard() {
  const { t } = useTranslation("dashboard");
  const dispatch = useDispatch();
  const classes = useStyles();
  const isIdol = useSelector((state) => state.auth.isIdol);
  const [stat, setStat] = useState();
  const [subscribers, setSubscribers] = useState();
  const [tiers, setTiers] = useState();
  const [referrals, setReferrals] = useState();

  useEffect(() => {
    const getInfo = async () => {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const res1 = await fetch(`https://${env.apiDomain}/dashboard`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `${userId}_${token}`
          },
          body: JSON.stringify({
            fromDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
          })
        }
      );
      const result1 = await res1.json();
      console.log('result1: ', result1);
      if (result1.success) {
        setStat(result1.data);
      } else {
        console.log('error code: ', result1.code);
      }

      const res2 = await fetch(`https://${env.apiDomain}/subscribers`,
        {
          method: 'GET',
          headers: {
            Authorization: `${userId}_${token}`
          }
        }
      );
      const result2 = await res2.json();
      console.log('result2: ', result2);
      if (result2.success) {
        setSubscribers(result2.data);
      } else {
        console.log('error code: ', result2.code);
      }

      const res3 = await fetch(`https://${env.apiDomain}/idols/${userId}/tiers`,
        {
          method: 'GET',
          headers: {
            Authorization: `${userId}_${token}`
          }
        }
      );

      const result3 = await res3.json();
      console.log('result3: ', result3);
      if (result3.success) {
        setTiers(result3.data);
      } else {
        console.log('error code: ', result3.code);
      }

      const res4 = await fetch(`https://${env.apiDomain}/referred-idols`,
        {
          method: 'GET',
          headers: {
            Authorization: `${userId}_${token}`
          }
        }
      );
      const result4 = await res4.json();
      console.log('result3: ', result4);
      if (result4.success) {
        setReferrals(result4.data);
      } else {
        console.log('error code: ', result4.code);
      }
    }
    getInfo();
  }, [])

  useEffect(() => {
    if (!isIdol) {
      dispatch(push('/'))
    }
  }, [dispatch, isIdol])

  const LoadingContainer = () => {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={60} />
      </div>
    )
  }

  console.log('stat: ', stat);

  const infoArr = [
    {
      header: t('tab-1.header'),
      children:
        <>
          {stat && referrals ?
            <Performance
              stat={stat}
              referrals={referrals}
              setStat={setStat}
            /> :
            <LoadingContainer />}
        </>
    },
    {
      header: t('tab-2.header'),
      children:
        <>
          {
            subscribers && tiers && stat ?
              <SubscriberList
                subscribers={subscribers}
                tiers={tiers}
                stat={stat}
                setStat={setStat}
                setSubscribers={setSubscribers}
              /> :
              <LoadingContainer />
          }
        </>
    },
    {
      header: t('tab-3.header'),
      children:
        <>
          {
            referrals ?
              <ReferralList referrals={referrals} /> :
              <LoadingContainer />
          }
        </>
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <Typography variant="h3" align="left" className={classes.header}>
          {t('header')}
        </Typography>
        <SimpleTabs infoArr={infoArr} />
      </div>
    </div>
  )
}