import { initialLoadingState } from "src/redux/loading/state";

export function loadingReducer(state = initialLoadingState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'FINISH_LOADING':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}