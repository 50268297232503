import React from 'react'
import _map from 'lodash/map'
import clsx from 'clsx'

import { Box } from '@material-ui/core'  
import useStyles from 'src/styles/pages/creatorOnboaringForm'

const ProgressBar = ({ totalSteps = 0, currentStepIndex = 0 }) => {
    const classes = useStyles()

    return (
        <Box className={classes.progressBar}>
            {_map(
                [...Array(totalSteps)],
                (_, index) => <Box 
                key={index}
                className={clsx(
                    classes.progressStep, 
                    index <= currentStepIndex ? classes.complete : classes.incomplete,
                    index === currentStepIndex && classes.current,
                )}></Box>
            )}
        </Box>
    )
}

export default ProgressBar
