import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 40px',
    height: 69,
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    background: 'white',
    zIndex: 5
  },
 
  // @media screen and (max-w)
  // avatarHolder:{
  //   display:'flex',
  //   alignItems: 'center'
  // },

  rootmakeapost: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  searchBarContainer: {
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: "hidden",
    }
  },
  rightContainer: {
    gap: 20,
  },
  mobileSearch: {
    flex: 1,
    justifyContent: 'center',
    width: "90vw",
    maxWidth: 562,
    margin: "0px 10px",
    position: "absolute",
    top: 10,
    left: "50%",
    transform: 'translate(-50%, 0)',
  },
  iconBubble: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      // visibility: "hidden",
      display: "none",
    }
  },
  internetIcon: {
    width: 24,
    height: 24,
    cursor: 'pointer'
  },
  avatar: {
    borderRadius: 16,
    width: 40,
    height: 40,
  },
  makeavatar:{
    borderRadius: '16px',
    width: 40,
    height: 40,
  },
  hiddenIconsContainer: {
    display: "flex",

  },
  logo: {
    width: 51,
    height: 50
  },
  menuIcon: {
    transform: 'scale(1, -1)',
  },
  hideOnMobileSearch: {
    visibility: props => props.isMobileSearchOpen && "hidden",
  },
}), { index: 1 });

export default useStyles;