import React from 'react'
import { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import _get from 'lodash/get'
import _random from 'lodash/random'
import {
  setCroppedImageLoading,
  uploadFile,
  getAllPricePlans
} from 'src/redux/creatorOnboarding/actions'
import _find from 'lodash/find'
import _isObject from 'lodash/isObject'
import LoadingButton from 'src/components/LoadingButton';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import clsx from "clsx";
import { ReactComponent as LeftIcon } from "src/images/common/Shape.svg";
import { reduxForm, initialize } from 'redux-form'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  TextareaAutosize,
  Button,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "src/styles/components/creator-onboarding/cropUploadImage.js";
import { useSnackbar } from 'notistack'
import 'react-image-crop/dist/ReactCrop.css';
import defaultAvatar from 'src/images/common/default_avatar.png'
import CropImageDialog from 'src/components/creator-onboarding/CropUploadImage';
import UploadImg from "src/images/common/upload-white-64.png";
import env from 'src/env';
import axios from 'axios'

export const tierFormName = "tier-form"
const MakeaPost =
  ({
    handleOpen,
    handleClose,
    droppedFile,
    accept,
    post,
    isEdit,
    aspectRatio = 1 / 1

  }) => {
    const { t } = useTranslation("creator-onboarding");
    const [pricePlans, setPricePlans] = useState([]);
    const classes = useStyles();
    const imgRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar()
    const avatar = useSelector((state) => state.auth.avatar);
    const previewCanvasRef = useRef(null);
    const previewCanvasRefVideo = useRef(null);
    const [fileType, setFileType] = useState(null)
    const [visible, setvisible] = useState(true)
    const [isFreePlanShow, setIsFreePlanShow] = useState(false)
    const [isAlertShow, setAlertPlanShow] = useState(false)
    const [completedCrop, setCompletedCrop] = useState(null);
    const [upImg, setUpImg] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [isCropImageOpen, setCropImageOpen] = useState(false)
    const [isImgChanged, setIsImgChanged] = useState(false)
    const [mediaType, setmediaType] = useState("image")
    const [upvideo, setUpivideo] = useState();
    const [DropzoneAreavisible, setDropzoneAreavisible] = useState(false)
    const [DropzoneVideoAreavisible, setDropzoneVideoAreavisible] = useState(false)
    const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: aspectRatio });
    const dispatch = useDispatch()
    const isCroppedImageLoading = useSelector(state => _get(state, "creatorOnboarding.isCroppedImageLoading"))
    const [imgList, setImgList] = useState([])
    const [videoList, setvideoList] = useState([])
    const [height, setheight] = useState(0)
    const [width, setwidth] = useState(0)

    const [descText, setDescText] = useState("")



    const onSelectFile = (e) => {
      setmediaType('image')
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          console.log("crop: reader.addEventListener for load",
            // reader.result
          )
          setUpImg(reader.result)
        });
        // get file type
        console.log("crop: setting fileType", _get(e, "target.files[0].type"))
        setFileType(_get(e, "target.files[0].type"))
        setmediaType("image")
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    // upload video for select change 
    const onSelectvideo = (e) => {
      console.log("e.target.files", e)
      setmediaType('video')
      if (e.target.files && e.target.files.length > 0) {
        console.log("e.target.files", e.target.files);
        const reader = new FileReader();
        console.log("crop: setting fileType", _get(e, "target.files[0].type"))
        //setFileType(_get(e, "target.files[0].type"))
        console.log('fileTypeCheck', _get(e, "target.files[0].type"));
        reader.readAsDataURL(e.target.files[0]);
        //handleConfirmvideo(canvas, _get(e, "target.files[0].type"));
        // Handle video upload for upload video in aws
        handleConfirmvideo(e.target.files[0]);
      }
    };
    const getPlans = () => {

      let planIds = [];
      if (isEdit && post.selectTier) {
        planIds = post.selectTier
      }

      let freeplan = {
        name: 'free plan',
        price_android: '',
        ischecked: isEdit ? planIds.includes("00000") : true,
        duration_month: 0,
        planId: "00000"
      }
      let tempPlan = [];
      tempPlan.push(freeplan)

      return dispatch(getAllPricePlans()).then(plans => {

        plans.map((item) => {
          let temp = {
            name: item.name,
            price_android: item.price_android,
            ischecked: isEdit ? planIds.includes(item.planId) : false,
            duration_month: item.duration_month,
            planId: item.planId
          }
          tempPlan.push(temp)
        })

        setPricePlans(tempPlan)
        return tempPlan
      })
    }
    const handleEditPostData = (post) => {
      if (isEdit) {
        console.log("post.media", post.media);
        console.log("post.description", post.description);
        console.log("post.selectTier", post.selectTier);
        
        if (post.description) {
          setDescText(post.description);
        }
        if (post.height) {
          setheight(post.height);
        }
        if (post.width) {
          setwidth(post.width);
        }

        if (post.postType == "image") {
          setDropzoneAreavisible(true)
          if (post.media) {
            setImgList(post.media);
          }
        } else if (post.postType == "video") {
          setDropzoneVideoAreavisible(true)
          if (post.media) {
            setvideoList(post.media);
          }
        }
      }

    }
    useEffect(() => {
      handleEditPostData(post)

      Promise.all([
        getPlans(),
      ]).then(([plans, data]) => {
        console.log("TierForm UseEffect Promise.all response", plans, data)
        if (data) {

          if (data.hasOwnProperty("price")) {
            data.price = _find(
              plans,
              option => {
                console.log("plans option", option)
                return _get(data, "price") === _get(option, "price_android")
              }
            )
          }

          dispatch(initialize(tierFormName, data))
        }
      })


    }, []);

    const onLoad = useCallback((img) => {
      imgRef.current = img;
    }, []);

    useEffect(() => {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
      }
      if (droppedFile) {
       
        handleOpen()
        setUpImg(droppedFile.data)
        setUpivideo(droppedFile.data)
        setFileType(droppedFile.fileType)
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;

      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }, [completedCrop, droppedFile]);

    const [cropimg, setcropimg] = useState()

    function handleConfirm(canvas, crop) {

      if (!crop || !canvas || !_get(crop, "height") || !_get(crop, "width")) {
        return;
      }
      dispatch(setCroppedImageLoading(true))
      canvas.toBlob(
        async (blob) => {
          try {
            const blobUrl = window.URL.createObjectURL(blob);
            const file = await fetch(blobUrl).then(r => r.blob()).then(blobFile => new File([blobFile], `${_random(0, 9999)}`, { type: fileType }));
            console.log('upload file------',file)
            const image = await dispatch(uploadFile(file))
            let imgListtemp = imgList;
            imgListtemp.push(image);
            setImgList(imgListtemp)
            setIsImgChanged(true)
            setcropimg(image)
            dispatch(setCroppedImageLoading(false))
            setUpImg('')
          } catch (err) {
            console.log(err)
            enqueueSnackbar(err, { variant: "error", })
          }
        },
        fileType,
        1
      );
    }
    const handleConfirmvideo = async (file) => {
      setIsLoading(true)
      dispatch(setCroppedImageLoading(true))
      const video = await dispatch(uploadFile(file))
      const urlArr = video.split("%2F")
      const url = urlArr.join("/")
      setvideoList([...videoList, url]);
      setIsImgChanged(true)
      setIsLoading(false)
    }

    const oncrop = (c) => {
      setCompletedCrop(c)
    }
    const followUploadedUrl = (img) => {
      console.log("upload image url",img)
      let imgListtemp = imgList;
      imgListtemp.push(img);
      setImgList(imgListtemp);
      setIsImgChanged(true)

    }

    // fINAL POST API Call
    const onRelease = async (type) => {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);

      let arrayS3Urls;
      if (imgList && imgList.length > 0) {
        arrayS3Urls = imgList.map((a) => {
          const urlArr = a.split("%2F")
          const url = urlArr.join("/")
          return url
        });
      } else {
        arrayS3Urls = videoList
      }

      let arrSelectedTier = [];
      pricePlans.map((item) => {
        if (item.ischecked) {
          arrSelectedTier.push(item.planId);
        }
      })

      let accessId = arrSelectedTier.length > 0 ? 2 : 1
      if (arrSelectedTier.includes("00000")) {
        accessId = 1
      }

      let body = {
        title: "",
        description: descText,
        publishStatus: type,
        publishDate: currentTimeInSeconds,
        selectTier: arrSelectedTier,
        media: arrayS3Urls,
        idolId: userId,
        postType: mediaType,
        access: accessId,
        height: height,
        width: width,
      }
      let header = {
        headers: {
          'content-type': 'application/json',
          Authorization: `${userId}_${token}`
        }
      }
      let url = `https://${env.apiDomain}/posts`

      if (isEdit) {
        body['isImgChanged'] = isImgChanged
        url = url + `/${post.postId}`

        await axios.put(url, body, header).then(res => {
          console.log("response1", res?.data)
          if (res.status === 200) {
            setvisible(false)
            handleClose(1);
          }
        })
      }else {
        await axios.post(url, body, header).then(res => {
          console.log("response2", res?.data)
          if (res.status === 200) {
            setvisible(false)
            handleClose(1);
          }
        })
      }
    }

    const handleFieldChange = (e, key) => {
      switch (key) {
        case "desc":
          setDescText(e.target.value)
          break;
        // case "pricePlan":
        //   setArrSelectedTier(e?.product_ios_id)
      }
    }

    const handleplans = (value) => {
      let findPlanIndex = pricePlans.findIndex((plan) => { return plan.planId === value.planId });
      value.ischecked = !value.ischecked
      let plansTemp = [...pricePlans];
      plansTemp[findPlanIndex] = value;
      setPricePlans(plansTemp);
    }

    const valueName = () => {
      let tempval = '';
      pricePlans.map((item) => {
        if (item.ischecked) {
          if (tempval != "") {
            tempval = tempval + ", "
          }

          if (item.planId == "00000") {
            tempval = tempval + item.name
          } else {
            tempval = tempval + item.name + " $" + item.price_android
          }

          console.log("item.name", item.name);
        }
      })
      return tempval
    }
    const deleteimage = (index) => {
      console.log("index is ", index);
      let temp = [...imgList];
      temp.splice(index, 1)
      setImgList(temp)
      setIsImgChanged(true)
      console.log("img list count", imgList);
    }
    const deletevideo = (index) => {
      console.log("index is ", index);
      let temp = [...videoList];
      temp.splice(index, 1)
      setvideoList(temp)
      setIsImgChanged(true)
      console.log("img list count", videoList);
    }

    return (
      <>
        <CropImageDialog
          open={isCropImageOpen}
          handleOpen={() => setCropImageOpen(true)}
          handleClose={() => setCropImageOpen(false)}
          followUploadedUrl={followUploadedUrl}
          cropData={(cropItem) => {
            if (imgList.length == 0) {
              setheight(cropItem.height);
              setwidth(cropItem.width);
            }
          }}
          accept="image/png, image/jpg, image/jpeg"
          updateBtnLabel={t('update-image')}
          aspectRatio={1 / 1}
        />
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Dialog
            onClose={() => { setvisible(false) }}
            open={visible}
            classes={{ paper: classes.paper }}
            scroll="body"
            maxWidth="xs"
          >
            <DialogTitle disableTypography className={classes.dialogTitle}>
              <Typography variant="h6" className={classes.header} align="center">
                {t('new-post')}
              </Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                if ((imgList && imgList.lenght > 0) || (videoList && videoList.length > 0)) {
                  setAlertPlanShow(true)
                } else {
                  setvisible(false);
                  handleClose(0);
                }
              }}>
                <CloseIcon />
              </IconButton>
              {console.log("isFreePlanShow", isFreePlanShow)}
              <div>
                {isAlertShow ? (<div>
                  <Dialog
                    open={isAlertShow}
                    classes={{ paper: classes.paper }}
                    maxWidth="xs"
                  >
                    <DialogTitle disableTypography className={classes.dialogTitle}>
                      <Typography variant="h6" className={classes.header} align="center">
                        {t('save-to-draft')}
                      </Typography>
                      <IconButton aria-label="close"
                        className={classes.closeButtonplan}
                        onClick={() => { setAlertPlanShow(false) }} >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.makenewpost }} >
                      {t('discard-this-post')}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        component="label"
                        color="primary"
                        variant="contained"
                        className={classes.buttonrelease}
                        onClick={() => { setAlertPlanShow(false); setvisible(false); handleClose(0) }}
                      >
                        <Typography variant="body1">
                          {t('discard')}
                        </Typography>
                      </Button>
                      <Button
                        component="label"
                        color="primary"
                        variant="contained"
                        className={classes.buttonrelease}
                        onClick={() => { setAlertPlanShow(false) }}
                      >
                        <Typography variant="body1">
                          {t('cancel-post')}
                        </Typography>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>)
                  : null}
              </div>
            </DialogTitle>

            <DialogContent style={{ padding: 0 }}>
              <DialogContent classes={{ root: classes.makenewpost }}>
                <div className={classes.avatarHolder}>
                  <img
                    src={avatar ? avatar : defaultAvatar}
                    alt="avatar"
                    className={classes.avatar}
                  />
                  <TextareaAutosize
                    aria-label="添加描述..."
                    minRows={3}
                    placeholder="添加描述..."
                    value={descText}
                    onChange={(event) => handleFieldChange(event, 'desc')}
                    className={classes.postdescr}
                  />
                </div>
                <div hidden>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0)
                    }}
                  />
                </div>
              </DialogContent>
              <DialogContent style={{ padding: 0 }}>
                <div className={classes.planreq}>
                  <span>
                    {t('paid-plan-req')}
                  </span>
                  <Box style={{ borderRadius: '20px' }}>
                    <Button
                      style={{ alignItems: 'left', height: '40px', width: "auto", whiteSpace: "nowrap" }}
                      color="primary"
                      variant="contained"
                      className={clsx(classes.button, classes.freePlanBtn)}
                      onClick={() => {
                        setIsFreePlanShow(true)
                      }}>
                      {
                        valueName()
                      }
                    </Button>
                    {isFreePlanShow && (<div>
                      <Dialog
                        open={isFreePlanShow}
                        classes={{ paper: classes.paper }}
                        maxWidth="xs"
                      >
                        <DialogTitle disableTypography className={classes.dialogTitle}>
                          <Typography variant="h6" className={classes.header} align="center">
                            {t('choose-a-level')}
                          </Typography>
                          <IconButton aria-label="close"
                            className={classes.closeButtonplan}
                            onClick={() => { setIsFreePlanShow(false) }} >
                            <LeftIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent classes={{ root: classes.contentRoot }} >
                          <div className={classes.mainContainersCheck}>
                            <Grid>
                              <div className='col-md-6'>
                                {
                                  (pricePlans && pricePlans.length > 0) &&
                                  pricePlans.map((plan) => {
                                    return (
                                      <div key={plan.planId} className={classes.formCheck}>
                                        <input type='checkbox'
                                          className={classes.formcheckinput}
                                          id="abc" name="plans"
                                          checked={plan.ischecked}
                                          onChange={() => {
                                            handleplans(plan)
                                          }}
                                        />
                                        <label className='form-check-label'>{_isObject(plan) ? `${_get(plan, "name")} - ${_get(plan, "price_android")}$ - ${_get(plan, "duration_month")} month` : plan}</label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </Grid>
                            <DialogActions className={classes.levelConfirmBtn}>
                              <Button
                                component="label"
                                color="primary"
                                variant="contained"
                                className={classes.buttonrelease}
                                onClick={() => { setIsFreePlanShow(false) }}
                              >
                                <Typography variant="body1">
                                  {t('conform')}
                                </Typography>
                              </Button>
                            </DialogActions>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>)
                    }
                  </Box>
                </div>
              </DialogContent>
              <DialogContent classes={{ root: classes.contentRoot }} >
                {DropzoneAreavisible &&
                  <DialogContent classes={{ root: classes.contentRoot }} style={{ border: '2px dashed rgb(170 179 189)', paddingBottom: '20px', marginTop: '20px' }}>
                    <IconButton aria-label="close"
                      className={classes.closeButton}
                      onClick={handleClose} >
                      <CloseIcon />
                    </IconButton>
                    <div className={classes.mainContainer}>
                      <Box style={{ display: 'inline-flex', flexDirection: 'row', marginLeft: '10px', justifyContent: "center", alignItems: "center", height: '100%', overflowy: false }}>
                        {
                          imgList.map((img, index) => {
                            return (
                              <div className={classes.imgdiv}>
                                <img
                                  src={img}
                                  alt={"image"}
                                  className={classes.imgInput}
                                  onChange={(event) => handleEditPostData(post.media)}
                                />
                                <CloseIcon className={classes.imgIcon} onClick={() => deleteimage(index)} />
                              </div>
                            )
                          })
                        }
                        <Button
                          style={{ marginLeft: '10px', marginRight: '10px' }}
                          className={clsx(classes.button, classes.uploadBtn)}
                          startIcon={<img src={UploadImg} alt="upload" height={20} />}
                          variant="contained"
                          color="primary"
                          onClick={() => setCropImageOpen(true)}
                        >
                          <Typography variant="body1">
                            {t('upload-photo')}
                          </Typography>
                          <input
                            type="file"
                            accept={accept}
                            onChange={onSelectFile}
                            className={classes.uploadInput}
                          />
                        </Button>
                      </Box>
                      {/* {ImgList.length === 0 ? (
                        <Typography variant="body1">
                          {t('or-pull-the-photo')}
                        </Typography>) : ''} */}
                      {console.log("upImg", imgList.length)}
                      {upImg &&
                        <ReactCrop
                          src={upImg} // set image here on drag
                          onImageLoaded={onLoad}
                          crop={crop}
                          onChange={(cropItem) => {
                            setCrop(cropItem)
                            console.log("data height: ", cropItem.height, cropItem.width);
                          }}
                          onComplete={(c) => oncrop(c)}
                          className={classes.reactCrop} />
                      }
                      <div hidden>
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0)
                          }}
                        />
                      </div>

                    </div>
                    <DialogActions className={classes.dialogActions}>
                      {
                        upImg && completedCrop &&
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          className={clsx(classes.confirmButton, classes.button)}
                          onClick={() =>
                            handleConfirm(previewCanvasRef.current, completedCrop)
                          }
                          isLoading={isCroppedImageLoading}
                        >
                          <Typography variant="body1">
                            {t('complete-cropping')}
                          </Typography>
                        </LoadingButton>
                      }
                    </DialogActions>
                  </DialogContent>
                }
                {DropzoneVideoAreavisible &&
                  <DialogContent classes={{ root: classes.contentRoot }} style={{ border: '2px dashed rgb(170 179 189)', paddingBottom: '20px', marginTop: '20px' }}>
                    <div className={classes.mainContainer}>
                      <div hidden>
                        <canvas
                          ref={previewCanvasRefVideo}
                        />
                      </div>
                      <Box style={{ display: 'inline-flex', flexDirection: 'row', marginLeft: '10px', justifyContent: "center", alignItems: "center", height: '100%', overflowy: false }}>
                        <div className={classes.videoContainer}>
                          {
                            videoList.map((video, index) => {
                              return (
                                <div key={video} className={classes.videoContainer}>
                                  <video
                                    height="200px"
                                    width="200px"
                                    style={{ padding: "10px", alignSelf: 'center' }}
                                    controls
                                  >
                                    <source src={video} type='video/mp4' />
                                  </video>
                                  <CloseIcon className={classes.imgIcon} onClick={() => deletevideo(index)} />
                                </div>
                              )
                            })
                          }
                        </div>
                        <LoadingButton
                          component="label"
                          color="primary"
                          variant="contained"
                          className={clsx(classes.button, classes.uploadButtonRoot)}
                          isLoading={isLoading}
                        >
                          <Typography variant="body1">
                            {t('upload-video')}
                          </Typography>
                          <input
                            type="file"
                            accept=".mov,.mp4"
                            onChange={(e) => { onSelectvideo(e) }}
                            className={classes.uploadInput}
                          />
                        </LoadingButton>
                      </Box>
                      {/* {videoList.length === 0 ? (
                        <Typography variant="body1">
                          {t('or-pull-the-video')}
                        </Typography>) : ''} */}
                    </div>
                  </DialogContent>}
              </DialogContent>
            </DialogContent>

            <div style={{ paddingTop: 20 }}>
              {imgList.length === 0 && videoList.length === 0 ? (
                <DialogActions style={{ justifyContent: "space-between", paddingLeft: '0', paddingRight: '0', }}>
                  {(!DropzoneAreavisible && !DropzoneVideoAreavisible) &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: "space-between" }}>
                      <Button
                        style={{ maxWidth: '180px', width: "100%", height: '38px' }}
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={() => setDropzoneAreavisible(!DropzoneAreavisible)}
                      >
                        <Typography variant="body1">
                          {t('photo-post')}
                        </Typography>
                      </Button>
                      <Button
                        style={{ maxWidth: '180px', width: "100%", height: '38px' }}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setDropzoneVideoAreavisible(!DropzoneVideoAreavisible)}
                      >
                        <Typography variant="body1">
                          {t('video-post')}
                        </Typography>
                      </Button>
                    </div>
                  }

                </DialogActions>
              ) : (
                <DialogActions style={{ justifyContent: "space-between" }}>
                  <Button
                    size='large'
                    variant="contained"
                    color="primary"
                    className={classes.buttonrelease}
                    onClick={() => onRelease(2)}
                  >
                    <Typography variant="body1" >
                      {t('release')}
                    </Typography>
                  </Button>
                </DialogActions>
              )}
            </div>
          </Dialog>
        </Grid >
      </>
    );
  }



export default reduxForm({
  form: tierFormName,
})(MakeaPost)
