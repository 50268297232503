import React, { useState, useEffect, useCallback } from 'react'

import _includes from 'lodash/includes'

import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer'
import useStyles from "src/styles/components/layout";
import Sidebar from "src/components/sidebar/Sidebar";
import Header from "src/components/header/Header";
import NoUserHeader from "src/components/header/NoUserHeader";
import CreatorOnboardingHeader from "src/components/header/CreatorOnboardingHeader";
import Routes from 'src/components/Routes';
import { useSelector } from 'react-redux';
// import clsx from "clsx";
import Footer from "src/components/Footer";

export default function Layout({
  isAuthenticated
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  
  const classes = useStyles();
  const pathname = useSelector((state) => state.router.location.pathname);

  const isCreatorOnboarding = useSelector(() => _includes(pathname, "creator/onboarding"))
  
  useEffect(() => {
    isSidebarOpen && closeSidebar()
    
    // eslint-disable-next-line 
  }, [pathname])

  const toggleSidebar = useCallback(() => setIsSidebarOpen(() => !isSidebarOpen), [isSidebarOpen])
  const closeSidebar = useCallback(() => setIsSidebarOpen(false), [])
  // const openSidebar = useCallback(() => setIsSidebarOpen(true), [])

  const staticPaths = [
    '/',
    '/registration',
    '/forgot-password',
    '/reset-password',
    '/validate-email',
    '/contact-us',
    '/terms-conditions',
    '/platform-usage-guide',
    '/privacy-policy',
    '/validate-email',
    '/subscribe',
    "/t&c-mobile"
  ]


const staticMobileUrlPaths = [
  '/platform-usage-guide-mobile',
  '/privacy-policy-mobile',
    "/terms-conditions-mobile"
    
  ]


  // const { location } = this.props;
  // const query = new URLSearchParams(location?.search);
  


  return (
    <div className={classes.root}>
      {
        isAuthenticated && !isCreatorOnboarding && (
          <nav className={classes.drawer}>
          <Hidden mdDown implementation="css">
            <Drawer
              open={true}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              >
              <Sidebar 
                closeSidebar={closeSidebar} 
              />
            </Drawer>
          </Hidden>
          <Hidden lgUp implementation="css">
            <Drawer
              open={isSidebarOpen}
              onClose={closeSidebar}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="temporary"
              >
              <Sidebar />
            </Drawer>
          </Hidden>
          </nav>
        )
      }

      <div className={classes.rightContainer}>
        {
          isAuthenticated ?
            (
              isCreatorOnboarding ?
                <CreatorOnboardingHeader />
                : <Header 
                  toggleSidebar={toggleSidebar}
                />
            ) :
            (
              !(staticMobileUrlPaths.includes(pathname)) &&
            <NoUserHeader />
            )
        }
        <div className={classes.routeContainer}>
          <Routes />
        </div>
        {
          staticPaths.includes(pathname) &&
          <Footer />
        }
      </div>
    </div>
  )
}