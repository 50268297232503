import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    borderRadius: 20,
    padding: 20,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    height: 'fit-content'
  },
  textBold: {
    fontWeight: 'bold'
  },
  avatar: {
    marginRight: 15,
    width: 50,
    height: 50,
    borderRadius: 20
  }
}), { index: 1 });

export default useStyles;