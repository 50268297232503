import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _get from 'lodash/get'

import { getLoggedInUser } from 'src/redux/user/actions'

import ToBeCreatorCard from 'src/components/personal-profile/ToBeCreatorCard'
import ShareCard from 'src/components/personal-profile/ShareCard'
import InviteCreatorCard from 'src/components/personal-profile/InviteCreatorCard'
import SideFooter from 'src/components/SideFooter'

import Box from '@material-ui/core/Box'


const RightCards = () => {
    useEffect(() => {
        isIdol && dispatch(getLoggedInUser())
        
        // eslint-disable-next-line
    }, [])

    const dispatch = useDispatch()
    const isIdol = useSelector(state => _get(state, "auth.isIdol"))
    const user = useSelector(state => _get(state, "user.loggedInUser"))

    return (
        <Box>
            {!isIdol && <ToBeCreatorCard />}
            {isIdol && <ShareCard fanshipPageName={_get(user, "fanshipPageName")} />}
            {isIdol && <InviteCreatorCard referralCode={_get(user, "referralCode")} />}
            <SideFooter />
        </Box>
    )
}

export default RightCards
