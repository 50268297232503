import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    borderRadius: 20,
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'fit-content'
  },
  avatar: {
    width: 200,
    height: 200,
    borderRadius: 80,
    marginBottom: 20
  },
  name: {
    marginBottom: 5
  },
  subname: {
    marginBottom: 20,
    color:'gray'
  },
  roleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  idolRoleContainer: {
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 7
  },
  verificationContainer: {
    marginLeft: 10
  },
  textBold: {
    fontWeight: 'bold'
  },
  counterContainer: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    '& $counterDiv:first-child': {
      borderRight: `1px solid ${theme.palette.grey[200]}`
    }
  },
  counterDiv: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    height: 45,
    borderRadius: 100
  }
}), { index: 1 });

export default useStyles;