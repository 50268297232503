import axios from 'axios'

import _get from 'lodash/get'

import env from 'src/env';


const baseUrl = `https://${env.apiDomain}`

export const getMyNotificationSetting = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const userId = _get(auth, "userId")

        const { data } = await axios.get(
            `${baseUrl}/users/${userId}/notification-setting`,
            {
                headers: {
                    Authorization: `${userId}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getMyNotificationSetting response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const putMyNotificationSetting = notificationSetting => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const userId = _get(auth, "userId")

        const { data } = await axios.put(
            `${baseUrl}/users/${userId}/notification-setting`,
            {
                notificationSetting,
            },
            {
                headers: {
                    Authorization: `${userId}_${_get(auth, "access_token")}`
                }
            },
        )

        console.log("putMyNotificationSetting response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}
