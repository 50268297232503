import React, { useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import useStyles from 'src/styles/components/dashboard/performance';
import { useTranslation } from 'react-i18next';

export default function PaymentReceiptsTable({
  paymentReceipts
}) {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const [page, setPage] = useState(1);
  const rowPerPage = 10;
  const startIndex = (page - 1) * rowPerPage;
  const endIndex = page * rowPerPage;

  paymentReceipts.sort((a, b) => {
    if (a.transactionDate > b.transactionDate) {
      return 1
    } else if (a.transactionDate < b.transactionDate) {
      return -1
    } else {
      return 0
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <TableContainer component={Paper} classes={{ root: classes.tableRoot }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="h6" className={classes.textBold}>
                {t('tab-1.payment-receipt-records.reference-number')}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6" className={classes.textBold}>
                {t('tab-1.payment-receipt-records.amount')}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6" className={classes.textBold}>
                {t('tab-1.payment-receipt-records.payment-method')}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6" className={classes.textBold}>
                {t('tab-1.payment-receipt-records.transaction-date')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentReceipts.slice(startIndex, endIndex).map((paymentReceipt, index) => (
            <TableRow
              key={`payment_receipt_${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" component="th" scope="row">
                {paymentReceipt.referenceNumber}
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" className={classes.textBold}>
                  {"$" + Intl.NumberFormat('zh-HK').format(paymentReceipt.amount)}
                  <Typography component="span" variant="caption">
                    USD
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell align="center">{capitalizeFirstLetter(paymentReceipt.paymentMethod)}</TableCell>
              <TableCell align="center">{paymentReceipt.transactionDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.paginationContainer}>
        <Pagination
          className={classes.pagination}
          count={Math.ceil(paymentReceipts.length / rowPerPage)}
          siblingCount={1}
          onChange={handleChangePage}
          page={page}
        />
      </div>
    </TableContainer>
  )
}