import React from 'react'
import useStyles from "src/styles/components/personal-profile/avatarCard";
import {
  Typography,
  Paper,
  Button
} from "@material-ui/core";
import { ReactComponent as AvatarIcon } from "src/images/common/icon_avatar.svg";
import { ReactComponent as VerifiedIcon } from "src/images/common/icon_verified.svg";
import defaultAvatar from 'src/images/common/default_avatar.png'
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux'
import clsx from "clsx";

export default function AvatarCard({
  avatar,
  fullName,
  displayName,
  isIdol,
  numberOfFans,
  numberOfSubscribers
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("personal-profile");

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <img src={avatar ? avatar : defaultAvatar} alt="user avatar" className={classes.avatar} />
     
      <Typography variant="h5" className={classes.name}>
        {displayName}
      </Typography>
      <Typography variant="h8" className={classes.subname}>
        {fullName}
      </Typography>
      <div className={clsx(classes.roleContainer, isIdol && classes.idolRoleContainer)}>
        <div className={classes.flexContainer}>
          <AvatarIcon className={classes.icon} />
          <Typography variant="body1" className={isIdol ? classes.textBold : ''}>
            {isIdol ? t('creator') : t('normal-user')}
          </Typography>
        </div>
        {
          isIdol && (
            <div className={clsx(classes.verificationContainer, classes.flexContainer)}>
              <VerifiedIcon className={classes.icon} />
              <Typography variant="body1" color="textSecondary" className={classes.textBold}>
                {t('verified')}
              </Typography>
            </div>
          )
        }
      </div>
      {
        isIdol && (
          <>
            <div className={clsx(classes.flexContainer, classes.counterContainer)}>
              <div className={classes.counterDiv}>
                <Typography variant="body1">
                  {numberOfFans}
                </Typography>
                <Typography variant="body2" className={classes.textBold}>
                  {t('number-of-fans')}
                </Typography>
              </div>
              <div className={classes.counterDiv}>
                <Typography variant="body1">
                  {numberOfSubscribers}
                </Typography>
                <Typography variant="body2" className={classes.textBold}>
                  {t('number-of-subscribers')}
                </Typography>
              </div>
            </div>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => dispatch(push("/creator/onboarding/form"))}

            >
              <Typography variant="body2" align="center">
                {t('edit-creator-page')}
              </Typography>
            </Button>
          </>
        )
      }
    </Paper>
  )
}