import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'

import { 
    getPostById,
    setIsFeedLoading, 
    setPostFeed,
    clearPostFeed,
} from 'src/redux/feed-post/actions'

import Box from '@material-ui/core/Box'
import Post from 'src/components/feed-post/Post'
import useStyles from 'src/styles/pages/post'


const PostPage = ({
    getPostById,
    setIsFeedLoading,
    setPostFeed,
    feed,
    clearPostFeed,
    isFeedLoading,
}) => {
    useEffect(() => {
        setIsFeedLoading(true)
        getPostById(postId).then(post => {
            console.log("PostPage getPostById", post, !_isEmpty(post))
            if(post && !_isEmpty(post) && _get(post, "postId")) {
                setPostFeed([post], null)
                setIsFeedLoading(false)
            }
        })

        return () => clearPostFeed()
        // eslint-disable-next-line
    }, [])

    const { postId } = useParams()

    const classes = useStyles()

    return (
		<Box 
			className={classes.root}
		>
			<Box className={classes.space}></Box>
			<Box className={classes.main}>
                {
                    _map(feed, (post, index) => <Post 
                        key={_get(post, "postId")}
                        post={post} 
                    />)
                }
			</Box>
			<Box className={classes.space}>
			</Box>
		</Box>
    )
}

const mapStateToProps = state => ({
    feed: _get(state, "feedPost.feed"),
    isFeedLoading: _get(state, "feedPost.isFeedLoading"),
})

const mapDispatchToProps = dispatch => ({
    getPostById: id => dispatch(getPostById(id)),
    setIsFeedLoading: bool => dispatch(setIsFeedLoading(bool)),
    setPostFeed: (feed, toTime) => dispatch(setPostFeed(feed, toTime)),
    clearPostFeed: () => dispatch(clearPostFeed()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PostPage)
