import React from 'react'
import moment from 'moment'

import _get from 'lodash/get'

import Box from '@material-ui/core/Box'

import useStyles from 'src/styles/components/notification-center/notification'
import defaultAvatarPng from 'src/images/common/default_avatar.png'
import placeHolderPng from 'src/images/common/placeholder.png'
import { Typography } from '@material-ui/core'

const Notification = ({
    notification: {
        notificationId,
        subject,
        object,
        event,
        createdAt,
        keyInfo,
        yearMonthDay
    },
    ...prop
}) => {

    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box className={classes.avatarHolder}>
                <img
                    src={_get(subject, "avatar") || defaultAvatarPng}
                    alt="avatar"
                    className={classes.avatar}
                />
            </Box>
            <Box className={classes.main}>
                <Box className={classes.header}>
                    <Typography variant="body1" className={classes.user}>
                        {_get(subject, "displayName")}
                    </Typography>
                    <Typography variant="body2" className={classes.time}>
                        {moment(createdAt).fromNow()}
                    </Typography>
                </Box>
                <Typography variant="body1">
                    {event}
                </Typography>                
            </Box>
            <Box className={classes.infoImageHolder}>
                <img 
                    src={
                        _get(keyInfo, "postImage") ||
                        _get(keyInfo, "tierImage") ||
                        placeHolderPng
                    }
                    alt="keyImage"
                    className={classes.infoImage}
                />
            </Box>
        </Box>
    )
}

export default Notification
