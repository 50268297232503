import axios from 'axios'
import moment from 'moment';

import _get from 'lodash/get'
import _round from 'lodash/round'

import {
    SET_POST_FEED,
    CLEAR_POST_FEED,
    SET_IS_FEED_LOADING,
    SET_POST_LIKED,
    UNSET_POST_LIKED,
    SET_POST_SAVED,
    SET_POST_UNSAVED,
    NEW_COMMENT_TO_POST_SUCCESS,
    REPLY_TO_COMMENT_FOR_POST_SUCCESS,
    SET_COMMENTS_LIST_FOR_POST,
    SET_COMMENT_TO_POST_LIKED,
    UNSET_COMMENT_TO_POST_LIKED,
    SET_REPLY_TO_COMMENT_LIKED,
    UNSET_REPLY_TO_COMMENT_LIKED,
    SET_FEWER_COMMENTS_FOR_POST,
    SET_IDOL_FOLLOWED,
    SET_IDOL_UNFOLLOWED,
    UNSET_REPLY_TO_COMMENT_DELETE,
    UNSET_REPLY_DELETE
} from './actionTypes'
import env from 'src/env';


const baseUrl = `https://${env.apiDomain}`


export const setPostFeed = (feed = [], isDrag = false) => ({
    type: SET_POST_FEED,
    feed,
    isDrag
})

export const clearPostFeed = () => ({
    type: CLEAR_POST_FEED,
})

export const setIsFeedLoading = payload => ({
    type: SET_IS_FEED_LOADING,
    payload,
})

export const setPostLiked = postId => ({
    type: SET_POST_LIKED,
    postId,
})

export const unsetPostLiked = postId => ({
    type: UNSET_POST_LIKED,
    postId,
})

export const setPostSaved = postId => ({
    type: SET_POST_SAVED,
    postId,
})

export const setPostUnsaved = postId => ({
    type: SET_POST_UNSAVED,
    postId,
})

export const newCommentToPostSuccess = (postId, comment) => ({
    type: NEW_COMMENT_TO_POST_SUCCESS,
    postId,
    comment,
})

export const replyToCommentForPostSuccess = (postId, commentId, reply) => ({
    type: REPLY_TO_COMMENT_FOR_POST_SUCCESS,
    postId,
    commentId,
    reply,
})

export const setCommentsListForPost = (postId, comments) => ({
    type: SET_COMMENTS_LIST_FOR_POST,
    postId,
    comments,
})

export const setFewerCommentsForPost = postId => ({
    type: SET_FEWER_COMMENTS_FOR_POST,
    postId,
})

export const setCommentToPostLiked = (postId, commentId) => ({
    type: SET_COMMENT_TO_POST_LIKED,
    postId,
    commentId,
})

export const unsetCommentToPostLiked = (postId, commentId) => ({
    type: UNSET_COMMENT_TO_POST_LIKED,
    postId,
    commentId,
})

export const setReplyToCommentLiked = (postId, parentCommentId, replyId) => ({
    type: SET_REPLY_TO_COMMENT_LIKED,
    postId,
    parentCommentId,
    replyId,
})

export const unsetReplyToCommentLiked = (postId, parentCommentId, replyId) => ({
    type: UNSET_REPLY_TO_COMMENT_LIKED,
    postId,
    parentCommentId,
    replyId,
})

export const setIdolFollowed = idolId => ({
    type: SET_IDOL_FOLLOWED,
    idolId,
})

export const setIdolUnollowed = idolId => ({
    type: SET_IDOL_UNFOLLOWED,
    idolId,
})




export const unsetReplyToCommentDelete = (postId, parentCommentId, replyId) => ({
    type: UNSET_REPLY_TO_COMMENT_DELETE,
    postId,
    parentCommentId,
    replyId,
})

export const  unsetReplyToDelete=(postId,replyId)=>({
    type: UNSET_REPLY_DELETE,
    postId,
   replyId, 
})



export const getPostFeed = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")
        const isFeedLoading = _get(getState(), "feedPost.isFeedLoading")
        const limit = 5;
        const feedCount =  _get(getState(), "feedPost.feedCount")
        const isDragLoading =  _get(getState(), "feedPost.isDragLoading")

        if (isDragLoading) {
            return true
        }

        !isFeedLoading && 
        dispatch(setIsFeedLoading(true))
        // debugger
        const { data } = await axios.post(
            `${baseUrl}/posts/feed`,
            {
            
                page: feedCount/limit,
                limit: limit
            },
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )
  
        dispatch(setIsFeedLoading(false))

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        let drag = (data.data.length < limit);
       
        dispatch(setPostFeed(
            _get(data, "data"), drag
        ))
        console.log("drag",data)
        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const likePost = postId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/posts/${postId}/like`,
            null,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("likePost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        dispatch(setPostLiked(_get(data, "data.postId")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
} 


export const removeLikeToPost = postId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.delete(
            `${baseUrl}/posts/${postId}/like`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("removeLikeToPost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        dispatch(unsetPostLiked(_get(data, "data.postId")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
} 
// delete comment 
// export const deleteCommentByCommentId=commentId=>async(dispatch,getState)=>{
//    try{
//     const auth = _get(getState(), "auth")
//     const { data } = await axios.delete(`${baseUrl}/comments/${commentId}`,
//         {
//             headers: {
//                 Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
//             }
//         }

//     )
//     console.log("userid",data)
//       dispatch(setDeleteComment(_get(data),"commentId"))
//       parentCommentId
//       ? dispatch(setReplyToCommentLiked(postId, parentCommentId, currentId))
//       : dispatch(setCommentToPostLiked(postId, currentId))
//    return data
//    }
//    catch(err){
//     console.log(err)
//     return Promise.reject(_get(err, "message", "Something went wrong"))
//    }
// }

export const deleteCommentByCommentId = (postId, parentCommentId, commentId) => async (dispatch, getState) => {
    console.log("commentId",commentId)
    try {
        const auth = _get(getState(), "auth")
       
        const { data } =  await axios.delete(`${baseUrl}/comments/${commentId}`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("removeLikeToComment response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // update like flag in global state
        // of comment or reply
        // based on parentCommentId
        parentCommentId
        ? dispatch(unsetReplyToCommentDelete(postId, parentCommentId, commentId))
        :
        dispatch(unsetReplyToDelete(postId, commentId))
        return data


    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}

export const savePost = postId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/posts/${postId}/save`,
            null,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("savePost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        dispatch(setPostSaved(_get(data, "postId")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
} 


export const unsavePost = postId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.delete(
            `${baseUrl}/posts/${postId}/save`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("unsavePost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        dispatch(setPostUnsaved(_get(data, "postId")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
} 


export const newCommentToPost = (postId, content) => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/posts/${postId}/comments`,
            {
                content,
            },
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("newCommentToPost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // add this comment to global state post.comments
        dispatch(newCommentToPostSuccess(postId, _get(data, "data")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const replytoCommentforPost = (postId, commentId, content) => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/posts/${postId}/comments/${commentId}/reply`,
            {
                content,
            },
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("replytoCommentforPost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // add this reply to global state post.comment.reply
        dispatch(replyToCommentForPostSuccess(postId, commentId, _get(data, "data")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getCommentsForPost = postId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")
        
        const { data } = await axios.get(
            `${baseUrl}/posts/${postId}/comments`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getCommentsForPost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // replace comments array. comment list counts gets updated
        dispatch(setCommentsListForPost(postId, _get(data, "data")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const likeComment = (postId, parentCommentId, currentId) => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/posts/${postId}/comments/${currentId}/like`,
            null,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("likeComment response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // update like flag in global state
        // of comment or reply
        // based on parentCommentId
        parentCommentId
        ? dispatch(setReplyToCommentLiked(postId, parentCommentId, currentId))
        : dispatch(setCommentToPostLiked(postId, currentId))

        return data


    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const removeLikeToComment = (postId, parentCommentId, currentId) => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.delete(
            `${baseUrl}/comments/${currentId}/like`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("removeLikeToComment response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // update like flag in global state
        // of comment or reply
        // based on parentCommentId
        parentCommentId
        ? dispatch(unsetReplyToCommentLiked(postId, parentCommentId, currentId))
        : dispatch(unsetCommentToPostLiked(postId, currentId))

        return data


    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const followIdol = idolId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/idols/${idolId}/follow`,
            null,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("followIdol response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // update userFollowed flag in global state
        dispatch(setIdolFollowed(_get(data, "idolId")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}



export const unfollowIdol = idolId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.delete(
            `${baseUrl}/idols/${idolId}/follow`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("unfollowIdol response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        // update userFollowed flag in global state
        dispatch(setIdolUnollowed(_get(data, "idolId")))

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const reportPost = (postId, category) => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.post(
            `${baseUrl}/posts/${postId}/report`,
            { 
                category, 
            },
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("reportPost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const getLikesForPost = postId => async (dispatcn, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/posts/${postId}/likes`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("getLikesForPost response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const getLikesForComment = commentId => async (dispatcn, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/comments/${commentId}/likes`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("getLikesForComment response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const getPostById = postId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/posts/${postId}`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )

        console.log("getPostById response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))   
    }
}


export const getPostsByIdolId = idolId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/idols/${idolId}/posts`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getPostsByIdolId response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}