import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as form } from 'redux-form'
import { authReducer } from 'src/redux/auth/reducer';
import { loadingReducer } from 'src/redux/loading/reducer';
import { creatorOnboardingReducer } from 'src/redux/creatorOnboarding/reducer'
import { feedPostReducer } from 'src/redux/feed-post/reducer'
import { userReducer } from 'src/redux/user/reducer'
import { locationReducer } from 'src/redux/location/reducer'
import { chatReducer } from 'src/redux/chat/reducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  loading: loadingReducer,
  router: connectRouter(history),
  form,
  creatorOnboarding: creatorOnboardingReducer,
  feedPost: feedPostReducer,
  location: locationReducer,
  chat: chatReducer,
});

export default createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history))
  )
);