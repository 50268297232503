import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/forgotPassword';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import ErrorHelperText from 'src/components/ErrorHelperText';
import { Alert } from '@material-ui/lab'
import env from 'src/env';
import { push } from 'connected-react-router';
import login from 'src/images/pdf/login.png'
export default function ContactUs() {
  const { t } = useTranslation("forgot-password");
  const { t: te } = useTranslation("error");
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [submitTimes, setSubmitTimes] = useState(0);
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitTimes(submitTimes + 1);
    if (email) {
      const res = await fetch(`https://${env.apiDomain}/auth/reset-password-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email
        })
      });

      const result = await res.json();
      console.log('result: ', result);
      if (!result.success) {
        setAlert({
          severity: "error",
          msg: te(`code.${result.code}`)
        })
      } else {
        setAlert({
          severity: "success",
          msg: t('success-message')
        })
      }
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  return (
    <div className={classes.root}>
         <div style={{width:'50%'}}>
      <img src={login} style={{height:'90%',width:'90%'}}/>

      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        {alert && alert.severity !== 'success' &&
          <div className={classes.alert}>
            <Alert
              severity={alert.severity}
            >
              {alert.msg}
            </Alert>
          </div>
        }
        <Typography variant="h2" className={classes.header}>
          {t('header')}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {alert && alert.severity === "success" ?
            alert.msg :
            t('description')
          }
        </Typography>
        {
          (!alert || alert.severity !== "success") &&
          <form
            className={classes.form}
            onSubmit={onSubmit}
          >
            <TextField
              fullWidth
              value={email}
              onChange={handleEmailChange}
              label={t('email')}
              variant="outlined"
              className={classes.textField}
              InputProps={
                {
                  type: 'email',
                }
              }
              error={submitTimes > 0 && !email}
              helperText={
                submitTimes > 0 && !email &&
                <ErrorHelperText text={t('errors.email')} />
              }
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              <Typography variant="body1">
                {t("confirm")}
              </Typography>
            </Button>
          </form>
        }
        {
          alert && alert.severity === "success" &&
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            onClick={() => {
              dispatch(push('/'))
            }}
          >
            <Typography variant="body1">
              {t("login")}
            </Typography>
          </Button>
        }
      </Paper>
    </div>
  )
}