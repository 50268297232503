export default function formatNumber(number) {
  if (number > 1000000) {
    return (number / 1000000).toFixed(2) + 'm';
  } else if (number > 1000) {
    return Math.round(number / 1000) + 'k'
  } else {
    return Number.isInteger(number) ? number : number.toFixed(2)
  }
}

export function formatDecimal(number) {
  return Number.isInteger(number) ? number : number.toFixed(2);
}

export function nFormatter(num, digits = 2) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}