import { makeStyles } from "@material-ui/styles";
import { newHeight } from "../screenStyle";

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 20,
        marginBottom: 20,
    },
    head: {
        padding: 20,
        display: "flex",
        alignItems: "center",
    },
    buttonrelease: {
        display: 'flex',
        flexdirection: 'row',
        justifycontent: 'center',
        alignitems: 'center',
        padding: '10px',
        position: 'static',
        width: '390px',
        height: '44px',
        borderRadius: '20px',
        left: '0px',
        top: '306px',
    },
    buttoncancel : {
        display: 'flex',
        flexdirection: 'row',
        justifycontent: 'center',
        alignitems: 'center',
        color:'white',
        padding: '10px',
        backgroundColor:'#ADB5BD',
        position: 'static',
        width: '300px',
        height: '44px',
        borderRadius: '20px',
        left: '0px',
        top: '306px',
    },
    buttondelete : {
        display: 'flex',
        flexdirection: 'row',
        justifycontent: 'center',
        color:'white',
        alignitems: 'center',
        padding: '10px',
        backgroundColor:'#FF375F',
        position: 'static',
        width: '300px',
        height: '44px',
        borderRadius: '20px',
        left: '0px',
        top: '306px',
    },
    paper: {
        borderRadius: "20px !important",
        padding: 20,
        textAlign: "center",
        minWidth: 300,
        width: '100%',
        boxSizing: 'border-box'
      },
    avatarHolder: {
        height: props => props.avatarHolderHeight || 40,
        borderRadius: 16,
        overflow: "hidden",
        "& img": {
            height: "100%",
        }
    },
    uploader: {
        flexGrow: 1,
        padding: "0px 10px",
    },
    name: {
        fontFamily: "SF Pro Text",
        fontWeight: 700,
        // fontSize: 16,
        color: theme.palette.grey[900],
    },
    meta: {
        display: "flex",
        alignItems: "center",
        "& span": {
            fontFamily: "SF Pro Text",
            fontWeight: 400,
            // fontSize: 14,
            color: theme.palette.grey[600],
        },
    },
    dot: {
        height: 4,
        width: 4,
        borderRadius: '50%',
        margin: '0 10px',
        backgroundColor: theme.palette.grey[600],
    },
    titleBar: {
        padding: "0px 20px 15px",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        
    },
    title: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 400,
        // fontSize: 14,
        color: theme.palette.grey[900],
    },
    descriptionHolder: {
        display: "flex",
        alignItems: "center",
    },
    description: {
        textAlign: "start",
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 400,
        // fontSize: 12,
        color: theme.palette.grey[600],
        height: "fit-content",
    },
    descriptionIcon: {
        width: 16,
        marginRight: 7,
        transform: "scale(-1, 1)",
        color: theme.palette.grey[600],
    },
    postStatus: {
        padding: 20,
        display: "flex",
        alignItems: "center",
        gap: 35,
    },
    postStatusItem: {
        display: "flex",
        gap: 7
    },
    likeBtn: {
        padding: 0,
    },
    likeIcon: {
        
    },
    likedTrue: {
        color: "#FF375F",
    },
    likedFalse: {
        color: theme.palette.grey[900],
    },
    commentIcon: {
        color: theme.palette.grey[900],
    },
    saveBtn: {
        padding: 0,
    },
    saveIcon: {

    },
    savedTrue: {
        color: theme.palette.grey[900],
    },
    savedFalse: {
        color: theme.palette.grey[900],
    },
    postStatusItemLabel: {
        fontFamily: "SF Pro Text",
        fontWeight: 600,
        // fontSize: 14,
        color: theme.palette.grey[900],
    },
    divider: {
        margin: "0px 20px",
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    commentsList: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
        margin: "15px 0px",
    },
    commentsHolder: {
        padding: 20,
    },
    newCommentForm: {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    newCommentInputHolder: {
        flexGrow: 1,
    },
    newCommentInput: {
        height: 41,
        borderRadius: 20,
        backgroundColor: theme.palette.grey[100],
    },
    noBorder: {
        border: "none",
    },
    comment: {
        display: "flex",
        gap: 10,
    },
    commentBody: {
        flexGrow: 1,
    },
    commentTitleBar: {
        display: "flex",
    },
    commentOwner: {
        flexGrow: 1,
        fontFamily: "SF Pro Text",
        fontWeight: 700,
        // fontSize: 14,
        color: theme.palette.grey[900],
    },
    commentMeta: {
        fontFamily: "SF Pro Text",
        fontWeight: 400,
        // fontSize: 12,
        color: theme.palette.grey[600],
    },
    commentMain: {
        display: "flex",
    },
    commentContent: {
        flexGrow: 1,
        fontFamily: "SF Pro Text",
        fontWeight: 400,
        // fontSize: 14,
        color: theme.palette.grey[900],
    },
    commentStatus: {
        display: "flex",
        gap: 15,
        "& span": {
            fontFamily: "SF Pro Text",
            fontWeight: 700,
            // fontSize: 12,
            color: theme.palette.grey[600],    
        },
    },
    commentActions: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row-reverse",
        gap: 15,
    },
    commentActionBtn: {
        padding: 0,
    },
    pointer: {
        cursor: "pointer",
    },
    commentListMeta: {
        display: "flex",
        justifyContent: "space-between",
    },
    commentListMetaLabel: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 400,
        // fontSize: 14,
        color: theme.palette.grey[600],
    },
    commentListCount: {
        textAlign: "right",
        flexGrow: 1,
    },
    progressHolder: {
        display: "flex",
        justifyContent: "center",
    },
    textBold: {
        fontWeight: 'bold'
    },
    moreOptionsBtn: {
        padding: 5,
    },
    moreOptionsBox: {
        // padding: "20px 0px",
        width: 200,
        display: "flex",
        flexDirection: "column",
        // gap: 15,
    },
    optionBtn: {
        padding: "10px 20px",
        display: "flex",
        justifyContent: "flex-start",
    },
    iconInBtn: {
        display: "flex",
    },
    dialog: {
        padding: "0px 20px 20px",
        borderRadius: 20,
        width: 390,
    },
    dialogTitle: {
        // width: "100%",
        padding: "10px 0px",
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        "& h2": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // color: theme.palette.grey[900],

            "& h6": {
                textAlign: "left",
                flexGrow: 1,
                fontFamily: "Noto Sans CJK TC",
                fontWeight: 700,
                fontSize: 18,
                color: theme.palette.grey[900],
            }
        }
    },
    reportDialogContent: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        "& button": {
            height: 54,
            fontFamily: "Noto Sans CJK TC",
            fontWeight: 400,
            fontSize: 16,
            color: theme.palette.grey[900],
            "& span": {
                display: "flex",
                justifyContent: "flex-start",
                "& span": {
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                }
            }

        }
    },
    confirmReportDialogContent: {
        padding: 0,
    },
    confirmDialogContentHead: {
        fontFamily: "SF Pro Text",
        textAlign:'left',
        fontWeight: 400,
        fontSize: 16,
        color: theme.palette.grey[900],
        paddingBottom: 20,
    },
    confirmDialogCategory: {
        fontFamily: "SF Pro Text",
        fontWeight: 700,
        fontSize: 18,
        color: theme.palette.grey[900],
    },
    makenewpost : {
        textAlign:'left'
    },
    confirmDialogContentBody: {
        fontFamily: "SF Pro Text",
        fontWeight: 400,
        fontSize: 16,
        color: theme.palette.grey[600],
        paddingBottom: 30,
    },
    confirmReportBtn: {
        height: 44,
        width: "100%",
        borderRadius: 22,
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 700,
        fontSize: 16,
        color: "#FFFFFFF",
    },
    likesDialog: {
        height: newHeight(463),
    },
    searchInput: {
        height: 44,
        borderRadius: 22,
        backgroundColor: theme.palette.grey[100],
        margin: "15px 0px",
    },
    searchIcon: {
        color: theme.palette.grey[500],
    },
    likesList: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
        maxHeight: newHeight(500),
        overflow: "auto",
    },
    likesListItem: {
        display: "flex",
        gap: 10,
    },
    likeMeta: {
        fontFamily: "SF Pro Text",
        fontWeight: 400,
        // fontSize: 12,
        color: theme.palette.grey[600],
    }
}))

export default useStyles