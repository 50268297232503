import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, change } from "redux-form";

import _get from "lodash/get";
import _isObject from "lodash/isObject";
import _includes from "lodash/includes";
import _split from "lodash/split";
import _trim from "lodash/trim";

import {
  setIdentityProofLoading,
  uploadFile,
} from "src/redux/creatorOnboarding/actions";

import { countriesArray } from "src/info/countries";
import RenderTextField from "src/components/renderTextField/FormField";
import RenderAutocomplete from "src/components/renderAutocomplete/FormField";
import RenderTextArea from "src/components/renderTextArea/FormField";
import RenderLinkBtn from "src/components/renderLinkBtn/FormField";
import RenderImage from "src/components/renderImage/FormField";
import CropProfileImageDialog from "src/components/creator-onboarding/CropUploadImage";

import { creatorOnboardingFormName } from "src/pages/CreatorOnboardingForm";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Box, Paper, Typography, Button } from "@material-ui/core";
import LoadingButton from "src/components/LoadingButton";
import useStyles from "src/styles/pages/creatorOnboaringForm";
import { useSnackbar } from "notistack";
import UploadImg from "src/images/common/upload-white-64.png";

export const translatorJson = "creator-onboarding";

const BasicInformationForm = (props) => {
  const [didFreshlyMount, setDidFreshlymount] = useState(true);
  const [isCropProfileImageOpen, setCropProfileImageOpen] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageHeight, setProfileImageHeight] = useState(null);

  const dispatch = useDispatch();

  const isIdentityProofLoading = useSelector((state) =>
    _get(state, "creatorOnboarding.isIdentityProofLoading")
  );
  const identityProofS3 = useSelector((state) =>
    _get(state, `form.${creatorOnboardingFormName}.values.identityProof`)
  );
  const identityProofS3Name = useMemo(
    () => _split(identityProofS3, "%2F").pop() || "Uploaded Document",
    [identityProofS3]
  );
  const isIdol = useSelector((state) => _get(state, "auth.isIdol"));

  useEffect(() => {
    setProfileImageHeight((coverPhotoDivRef.current.clientWidth * 3) / 7);

    const coverPhotoDiv = coverPhotoDivRef.current;

    const handleDrag = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDragIn = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const handleDragOut = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const handleDrop = async (event) => {
      event.preventDefault();
      event.stopPropagation();

      console.log(
        "crop: handleDrop event files[0]",
        event.dataTransfer.files[0],
        "\nlength",
        _get(event, "dataTransfer.files.length"),
        "\nitems[0]",
        event.dataTransfer.items[0]
      );
      console.log("crop: fileType", _get(event, "dataTransfer.files[0].type"));
      if (_get(event, "dataTransfer.files.length") === 1) {
        const fileType = _get(event, "dataTransfer.files[0].type");
        if (
          !_includes(
            ["image/png", "image/jpg", "image/jpeg"],
            _get(event, "dataTransfer.files[0].type")
          )
        ) {
          console.log("file type is not supported");
          return;
        }

        // open crop dialog with this image
        console.log("crop: file", _get(event, "dataTransfer.files[0]"));

        const reader = new FileReader();
        reader.addEventListener("load", () => {
          console.log(
            "crop: file in reader.addEventListener for load",
            _get(event, "dataTransfer.files[0]")
          );
          console.log(
            "crop: reader.result in reader.addEventListener for load",
            reader.result,
            "fileType",
            fileType
          );

          setProfileImageFile({ data: reader.result, fileType });
        });
        reader.readAsDataURL(_get(event, "dataTransfer.files[0]"));
      } else if (_get(event, "dataTransfer.files.length") > 1) {
        console.log(
          "Do we handle uploading more than one cover-photos/profile-images?"
        );
      }
    };

    coverPhotoDiv.addEventListener("dragenter", handleDragIn);
    coverPhotoDiv.addEventListener("dragleave", handleDragOut);
    coverPhotoDiv.addEventListener("dragover", handleDrag);
    coverPhotoDiv.addEventListener("drop", handleDrop);

    return () => {
      coverPhotoDiv.removeEventListener("dragenter", handleDragIn);
      coverPhotoDiv.removeEventListener("dragleave", handleDragOut);
      coverPhotoDiv.removeEventListener("dragover", handleDrag);
      coverPhotoDiv.removeEventListener("drop", handleDrop);
    };
  }, []);

  useEffect(() => {
    if (!didFreshlyMount && props.submitFailed) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } else if (didFreshlyMount) {
      setDidFreshlymount(false);
    }

    // eslint-disable-next-line
  }, [props.submitFailed]);

  const { enqueueSnackbar } = useSnackbar();

  const uploadIdentityProofRef = useRef(null);
  const coverPhotoDivRef = useRef(null);

  const handleIdProofChange = useCallback(async (event) => {
    try {
      // set isLoading global
      dispatch(setIdentityProofLoading(true));
      console.log("upload id proof onChange event", event);
      const identityProof = await dispatch(
        uploadFile(_get(event, "target.files[0]"))
      );
      console.log("identityProof", identityProof);
      dispatch(
        change(creatorOnboardingFormName, "identityProof", identityProof)
      );
      // reset isLoading global
      dispatch(setIdentityProofLoading(false));
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err, { variant: "error" });
    }
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const followUploadedUrl = useCallback(
    (image) =>
      dispatch(change(creatorOnboardingFormName, "profileImage", image)),
    []
  );

  const classes = useStyles({
    uploadDivHeight: profileImageHeight,
  });

  const { t } = useTranslation(translatorJson);

  return (
    <>
      <CropProfileImageDialog
        open={isCropProfileImageOpen}
        handleOpen={() => setCropProfileImageOpen(true)}
        handleClose={() => setCropProfileImageOpen(false)}
        followUploadedUrl={followUploadedUrl}
        droppedFile={profileImageFile}
        accept="image/png, image/jpg, image/jpeg"
        updateBtnLabel={t("update-cover-photo")}
        aspectRatio={7 / 3}
      />
      <Box className={classes.formContainer}>
        <Box className={classes.formElement}>
          <Typography
            className={classes.formElementTitle}
            variant="h5"
            component="h3"
          >
            {t("creator-profile")}
          </Typography>
          <Paper className={classes.formElementBody}>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("creator-page-name")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Field
                  name="creatorPageName"
                  component={RenderTextField}
                  variant="outlined"
                  fullWidth={true}
                  translatorJson={translatorJson}
                />
              </Box>
            </Box>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("creator-content-description")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Field
                  name="creationContentDescription"
                  component={RenderTextField}
                  variant="outlined"
                  fullWidth={true}
                  placeholder={t("creator-content-description-placeholder")}
                  disabled={isIdol}
                  translatorJson={translatorJson}
                />
              </Box>
            </Box>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("creator-area")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Field
                  name="countryCode"
                  component={RenderAutocomplete}
                  options={countriesArray}
                  getOptionLabel={(option) => {
                    console.log(
                      "Field autocomplete getOptionLabel option",
                      option
                    );
                    return _isObject(option) ? _get(option, "name") : option;
                  }}
                  fullWidth={true}
                  optionValueField="code"
                  textFieldProps={{
                    variant: "outlined",
                    fullWidth: true,
                  }}
                  isCountryFilter={true}
                  translatorJson={translatorJson}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box className={classes.formElement}>
          <Typography
            className={classes.formElementTitle}
            variant="h5"
            component="h3"
          >
            {t("authentication")}
          </Typography>
          <Paper className={classes.formElementBody}>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("instagram-page-url")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.linkdomain}>
                  instagram.com/
                </Typography>
                <Field
                  name="instagramUsername"
                  component={RenderTextField}
                  variant="outlined"
                  fullWidth={true}
                  disabled={isIdol}
                  translatorJson={translatorJson}
                />
              </Box>
            </Box>
            {!isIdol && (
              <Box className={classes.inputGroup}>
                <Box className={classes.labelBox}>
                  <Typography className={classes.label} variant="h6">
                    {t("identity-document")}
                  </Typography>
                  <Typography className={classes.condition}>
                    {t("must")}
                  </Typography>
                </Box>
                <Box className={classes.inputBoxVertical}>
                  <input
                    ref={uploadIdentityProofRef}
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    onChange={handleIdProofChange}
                    hidden
                  />
                  <LoadingButton
                    className={clsx(classes.button, classes.uploadBtn)}
                    startIcon={
                      !isIdentityProofLoading && (
                        <img src={UploadImg} alt="upload" height={20} />
                      )
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => uploadIdentityProofRef.current.click()}
                    isLoading={isIdentityProofLoading}
                    loadingProps={{ size: 30 }}
                  >
                    <Typography>{t("upload-file")}</Typography>
                  </LoadingButton>
                  <Field
                    name="identityProof"
                    component={RenderLinkBtn}
                    className={classes.customBtn}
                    label={identityProofS3Name}
                    translatorJson={translatorJson}
                  />
                </Box>
              </Box>
            )}
          </Paper>
        </Box>
        <Box className={classes.formElement}>
          <Typography
            className={classes.formElementTitle}
            variant="h5"
            component="h3"
          >
            {t("creator-page-setting")}
          </Typography>
          <Paper className={classes.formElementBody}>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("cover-photo")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("cover-photo-recommandetion")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Box ref={coverPhotoDivRef} className={classes.uploadDiv}>
                  <Box className={classes.uploadPhotosBtnContainer}>
                    <Button
                      className={clsx(classes.button, classes.uploadBtn)}
                      startIcon={
                        <img src={UploadImg} alt="upload" height={20} />
                      }
                      variant="contained"
                      color="primary"
                      onClick={() => setCropProfileImageOpen(true)}
                    >
                      <Typography>{t("upload-photos")}</Typography>
                    </Button>
                    <Typography className={classes.miscText}>
                      {t("drag-option-label")}
                    </Typography>
                    <Field
                      name="profileImage"
                      component={RenderImage}
                      alt={"image"}
                      containerClass={classes.profileImageContainer}
                      imageClass={classes.profileImage}
                      deleteIconClass={classes.customDeleteIcon}
                      editIconClass={classes.customEditIcon}
                      handleDelete={() => {
                        dispatch(
                          change(
                            creatorOnboardingFormName,
                            "profileImage",
                            null
                          )
                        );
                      }}
                      handleEdit={() => setCropProfileImageOpen(true)}
                      imageProps={{
                        onLoad: (event) => {
                          console.log("crop: image onLoad", event);
                        },
                      }}
                      translatorJson={translatorJson}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("creator-page-url")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.linkdomain}>
                  fanship.com/
                </Typography>
                <Field
                  name="fanshipPageName"
                  component={RenderTextField}
                  variant="outlined"
                  fullWidth={true}
                  placeholder="pagename"
                  disabled={isIdol}
                  translatorJson={translatorJson}
                />
              </Box>
            </Box>
            <Box className={classes.inputGroup}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label} variant="h6">
                  {t("creator-page-description")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("must")}
                </Typography>
                <Typography className={classes.condition}>
                  {t("creator-page-description-note")}
                </Typography>
              </Box>
              <Box className={classes.inputBox}>
                <Field
                  name="creatorPageDescription"
                  component={RenderTextArea}
                  className={classes.textArea}
                  placeholder={t("creator-page-description-placeholder")}
                  translatorJson={translatorJson}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        {!isIdol && ( // does referralCode come in GET /creator-onboarding if isIdol?
          <Box className={classes.formElement}>
            <Typography
              className={classes.formElementTitle}
              variant="h3"
              component="h5"
            >
              {t("creator-referral-code")}
            </Typography>
            <Paper className={classes.formElementBody}>
              <Box className={classes.inputGroup}>
                <Box className={classes.labelBox}>
                  <Typography className={classes.label} variant="h6">
                    {t("creator-referral-code")}
                  </Typography>
                </Box>
                <Box className={classes.inputBoxVertical}>
                  <Field
                    name="referralCode"
                    component={RenderTextField}
                    variant="outlined"
                    fullWidth={true}
                    placeholder={t("referral-code-label")}
                    translatorJson={translatorJson}
                  />
                  <Typography className={classes.customStyledText}>
                    {t("referral-code-note-1-2")}
                    <Typography component="span">
                      {t("referral-code-note-2-2")}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
};

const validate = (values) => {
  const errors = {};
  // errors are translated in Field-components.
  if (!_trim(_get(values, "creatorPageName")))
    errors["creatorPageName"] = "must";

  if (!_trim(_get(values, "creationContentDescription")))
    errors["creationContentDescription"] = "must";

  if (!_get(values, "countryCode")) errors["countryCode"] = "must";

  if (!_trim(_get(values, "instagramUsername")))
    errors["instagramUsername"] = "must";

  if (!_get(values, "identityProof")) errors["identityProof"] = "must";

  if (!_get(values, "profileImage")) errors["profileImage"] = "must";

  if (!_trim(_get(values, "fanshipPageName")))
    errors["fanshipPageName"] = "must";

  if (!_trim(_get(values, "creatorPageDescription")))
    errors["creatorPageDescription"] = "must";

  return errors;
};

export default reduxForm({
  validate,
})(BasicInformationForm);
