import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    progressBar: {
        width: "100%",
        display: "flex",
        margin: "10px 0px",
    },
    progressStep: {
        flexGrow: 1,
        height: 10,
    },
    complete: {
        backgroundColor: theme.palette.primary.main,
    },
    incomplete: {
        backgroundColor: "#FFFFFF",
    },
    current: {
        borderRadius: "0px 5px 5px 0px",
    },
    main: {
        width: 874,
        maxWidth: "100%",
    },
    formHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    tab: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[900],
    },
    button: {
        width: 100,
        height: 38,
        borderRadius: 19,
        margin: "0px 5px",
        fontSize: 16,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[900],
    },
    Nextbutton: {
        width: 200,
        height: 38,
        borderRadius: 19,
        marginBottom : "40px",
        margin: "0px 200px",
        color: "#FFFFFF",
        alignContent : 450,
        fontSize: 16,
        display: "flex",
        fontFamily: "Noto Sans CJK TC",
        // color: theme.palette.grey[900],
    },
    Previousbutton: {
        width: 200,
        height: 38,
        borderRadius: 19,
        margin: "0px 200px",
        // color: "#FFFFFF",
        alignContent : 450,
        fontSize: 16,
        display: "flex",
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[900],
    },
    release: {
        fontWeight: 700,
        color: "#FFFFFF",
    },
    a: {
        // textDecoration: "underline"
        padding: "3px 0px",
        borderBottom: "solid 3px black",
        fontSize: 16,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[900],
    },
    formContainer: {
        margin: 10,
    },
    routeFormContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    formElement: {
        margin: "40px 0px",
    },
    routeFormElement: {
        width: 874,
    },
    formElementTitle: {
        fontSize: 18,
        fontFamily: "Noto Sans CJK TC",
        margin: "20px 0px",
        color: theme.palette.grey[900],
    },
    formElementBody: {
        padding: 20,
        borderRadius: 20,
    },
    inputGroup: {
        margin: 20,
        display: "flex",
    },
    labelBox: {
        width: 300,
    },
    label: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[900],
    },
    condition: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[500],
    },
    inputBox: {
        flexGrow: 2,
        display: "flex",
        alignItems: "center",
    },
    inputBoxVertical: {
        flexGrow: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
    linkdomain: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[500],
    },
    uploadBtn: {
        color: "#FFFFFF",
        width: 200,
        margin: 0,
    },
    uploadDiv: {
        flexGrow: 1,
        border: `dashed 1px ${theme.palette.grey[500]}`,
        position: "relative",
        overflow: "hidden",
        height: props => props.uploadDivHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    uploadPhotosBtnContainer: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    miscText: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 400,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[500],
    },
    textArea: {
        resize: "vertical",
        overflowY: "scroll",
        // width: "100%",
        minHeight: 150,
        padding: 10,
        fontSize: 16,
        fontFamily: "Noto Sans CJK TC",
        border: `solid 1px ${theme.palette.grey[400]}`,
        "& placeholder": {
            color: theme.palette.grey[500],
        }
    },
    customStyledText: {
        color: theme.palette.grey[900],
        "& span": {
            color: theme.palette.primary.main,
        }
    },
    customBtn: {
        height: 38,
        borderRadius: 19,
        margin: "5px 0px",
        fontSize: 16,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.primary.main
    },
    profileImageContainer: {
        alignSelf: "stretch",
        display: "flex",
        alignItems: "stretch",
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
    },
    profileImage: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    customDeleteIcon: {
        position: "absolute",
        top: 5,
        right: 50,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F0F0F0",
        },
    },
    customEditIcon: {
        position: "absolute",
        top: 5,
        right: 5,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F0F0F0",
        },
    },
    actionBtnContainer: {
        display: "flex",
        alignItems: "center",
    },
    deleteIconContainer: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
    },
    dialog: {
        padding: "0px 10px 10px",
        borderRadius: 20,
        width: 430,
    },
    customDialogTitle: {
        // width: "100%",
        padding: "10px 0px",
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
        "& h2": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: theme.palette.grey[900],

            "& h6": {
                textAlign: "center",
                flexGrow: 1,
                fontFamily: "Noto Sans CJK TC",
                fontWeight: 700,
                fontSize: 18,
                color: theme.palette.grey[900],
            }
        }
    },
    customDialogContent: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 400,
        fontSize: 16,
        color: theme.palette.grey[900],
    },
    customRemoveBtn: {
        width: "100%",
        height: 40,
        borderRadius: 20,
        backgroundColor: props => props.isLoadingRemoveBtn ? theme.palette.grey[300] : theme.palette.error.main,
        color: "#FFFFFF",
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 700,
        fontSize: 16,
        "&:hover": {
            backgroundColor: theme.palette.error.light,
        },
    },
    bold: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 700,
        fontSize: 16,
    },
    none: {
        display: "none",
    }
}))

export default useStyles