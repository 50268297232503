import axios from 'axios'
import convert from 'xml-js'

import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _split from 'lodash/split'

import {
    GET_PAID_TIER,
    SET_CURRENT_FORM_TAB,
    SET_IDENTITY_PROOF_LOADING,
    SET_PROFILE_IMAGE_LOADING,
    SET_TIER_FORM_OPEN,
    SET_EDIT_TIER_ID,
    SET_REMOVE_TIER_DIALOG,
    SET_PUTTING_CREATOR_ONBOARDING,
    CLEAR_CREATOR_ONBOARDING_STATE,
} from './actionTypes'

import env from 'src/env';


const baseUrl = `https://${env.apiDomain}`


export const setCurrentFormTab = payload => ({
    type: SET_CURRENT_FORM_TAB,
    payload,
})

export const setIdentityProofLoading = payload => ({
    type: SET_IDENTITY_PROOF_LOADING,
    payload,
})

export const setCroppedImageLoading = payload => ({
    type: SET_PROFILE_IMAGE_LOADING,
    payload,
})

export const setPaidTiersList = (payload) => ({
    type: GET_PAID_TIER,
    payload,
})

export const setTierFormOpen = payload => ({
    type: SET_TIER_FORM_OPEN,
    payload,
})

export const setEditTierId = payload => ({
    type: SET_EDIT_TIER_ID,
    payload,
})

export const setRemoveTierDialog = payload => ({
    type: SET_REMOVE_TIER_DIALOG,
    payload,
})

export const setIsPuttingCreatorOnboarding = payload => ({
    type: SET_PUTTING_CREATOR_ONBOARDING,
    payload,
})

export const clearCreatorOnboardingState = () => ({
    type: CLEAR_CREATOR_ONBOARDING_STATE,
})


export const uploadFile = file => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")
        console.log("auth", auth)
        console.log("file", file)
        const extension = _get(_split(_get(file, "type"), "/"), "[1]", "jpg","mp4")
        console.log("extension", extension)
        const uniqueName = `${(new Date()).toISOString().replace(/:|\.|-/g, '')}.${extension}`

        const { data: { url: s3url, fields: s3fields } } = await axios.post(
            `${baseUrl}/media`,
            {
                filename: uniqueName,
            },
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )
        console.log("/media response", "s3url", s3url, "s3fields", s3fields)

        const formData = new FormData()

        _forEach(s3fields, (value, key) => {
            console.log("key", key, "value", value)
            formData.append(key, value)
        })
        formData.append("file", file)


        const { data: xml } = await axios.post(
            s3url,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "response-content-type": "application/json"
                },
            }

        )
        console.log("s3 upload response xml", xml)
        const data = convert.xml2js(xml, { compact: true, spaces: 4 })
        console.log("s3 upload response data", data)
        const remoteUrl = _get(data, "PostResponse.Location._text")
        console.log("uploadedUrl", remoteUrl)
        return remoteUrl

    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getPaidTiersList = () => async (dispatch, getState) => {
    const auth = _get(getState(), "auth")
    try {
        const { data } = await axios.get(
            `${baseUrl}/tiers`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }

        )
        if (data.success) {
            dispatch(setPaidTiersList(_get(data, "data")))
        }
        return _get(data, "data")
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getTierById = tierId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")
        console.log("getTierById", tierId)

        const { data } = await axios.get(
            `${baseUrl}/tiers/${tierId}`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("fetched tier by id", data)

        if (_get(data, "errorMessage") || _get(data, "errorType")) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const submitTier = (values, tierId) => async (dispatch, getState) => {
    try {
        console.log("submitTier values", values, "tierId", tierId)
        const finalValues = { ...values }
        const auth = _get(getState(), "auth")
        const editTierId = _get(getState(), "creatorOnboarding.editTierId") || tierId
        console.log("submitTier editTierId", editTierId)

        // if (finalValues.hasOwnProperty("isactive")) {
        //     console.log("value has isactive", _get(finalValues, "isactive"))
        //     finalValues.isactive = 1
        // }
        finalValues.isactive = 1

        // if (finalValues.hasOwnProperty("category")) {
        //     console.log("value has category", _get(finalValues, "category"))
        //     finalValues.category = _get(finalValues, "category.value")
        // }
        finalValues.category = "self-defined"

        if (finalValues.hasOwnProperty("price")) {
            console.log("value has price", _get(finalValues, "price"))
            finalValues.planId = _get(finalValues, "price.planId")
            finalValues.price = _get(finalValues, "price.price_android")
        }

        console.log("submitTier final finalValues to post", finalValues)


        const { data } = editTierId
            ?
            await axios.put(
                `${baseUrl}/tiers/${editTierId}`,
                finalValues,
                {
                    headers: {
                        Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                    }
                }
            )
            :
            await axios.post(
                `${baseUrl}/tiers`,
                finalValues,
                {
                    headers: {
                        Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                    }
                }
            )

        console.log("submitTier response", data)

        if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data
    } catch (err) {
        console.log("posting tier failed", err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const deleteTier = tierId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")
        console.log("getTierById", tierId)

        const { data } = await axios.delete(
            `${baseUrl}/tiers/${tierId}`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("deleted tier by id", data)

        if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const submitCreatorOnboarding = values => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")
        const isPuttingCreatorOnboarding = _get(getState(), "creatorOnboarding.isPuttingCreatorOnboarding")

        console.log("submitCreatorOnboarding values", values)
        const finalValues = { ...values }
        console.log("submitCreatorOnboarding finalValues", finalValues)

        delete finalValues.confirmPayoneerEmail
        delete finalValues.updatedAt

        if (finalValues.hasOwnProperty("countryCode")) {
            console.log("value has countryCode", _get(finalValues, "countryCode"))
            finalValues.countryCode = _get(finalValues, "countryCode.code")
        }

        finalValues["referralCode"] = _get(finalValues, "referralCode", "")

        console.log("isIdol", _get(auth, "isIdol"))

        const { data } = _get(auth, "isIdol") || isPuttingCreatorOnboarding
            ? await axios.put(
                `${baseUrl}/creator-onboarding`,
                finalValues,
                {
                    headers: {
                        Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                    }
                }
            )
            : await axios.post(
                `${baseUrl}/creator-onboarding`,
                finalValues,
                {
                    headers: {
                        Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                    }
                }
            )

        console.log("submitCreatorOnboarding response", data)

        if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getCreatorOnboarding = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/creator-onboarding`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("action getCreatorOnboarding data", data)

        if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}



export const getMe = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/me`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("action getMe data", data)

        if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return data.data
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getAllPricePlans = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/price/plan`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("action getAllPricePlans data", data)

        if (_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data.Items")
    } catch (err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}
