import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/login';
import clsx from "clsx";
import {
  Paper,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import LoadingButton from 'src/components/LoadingButton';
import { Alert } from '@material-ui/lab'
import { ReactComponent as FacebookIcon } from "src/images/login-page/icon_facebook.svg";
import { ReactComponent as GoogleIcon } from "src/images/login-page/icon_google.svg";
import { ReactComponent as AppleIcon } from "src/images/login-page/icon_apple.svg";
import { ReactComponent as IconVisibility } from "src/images/login-page/icon_visibility.svg";
import { ReactComponent as IconVisibilityOff } from "src/images/login-page/icon_visibility_off.svg";
import { NavLink } from 'react-router-dom';
import { loginThunk, socialLoginThunk } from 'src/redux/auth/thunks';
import ErrorHelperText from 'src/components/ErrorHelperText';
import { push } from 'connected-react-router';
import env from 'src/env';
import FacebookLogin from 'react-facebook-login';
import AppleLogin from 'react-apple-login'
import GoogleLogin from 'react-google-login';
import _get from 'lodash/get'
import login from 'src/images/pdf/login.png'


export default function Login() {
  const { t } = useTranslation("login-page");
  const { t: te } = useTranslation("error");
  const classes = useStyles();
  const [pwFieldOneVisible, setPwFieldOneVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isLoading = useSelector((state) => state.loading.isLoading)
  const authMsg = useSelector((state) => state.auth.msg);
  console.log('is authenticated: ', isAuthenticated);
  const dispatch = useDispatch();
  const [submitTimes, setSubmitTimes] = useState(0);
  const pathToPush = useSelector(state => _get(state, "location.pushAfterAuth.path"))

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitTimes(submitTimes + 1);

    if (email && password) {
      dispatch(loginThunk(email, password))
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  useEffect(() => {
    if (isAuthenticated) {
      pathToPush ?
      dispatch(push(pathToPush)) :
      dispatch(push('/home'));
    }
  }, [isAuthenticated, dispatch, pathToPush])

  const responseFacebook = (response) => {
    console.log('facebook response: ', response);
    console.log(response.email);
    console.log(response.accessToken);
    dispatch(socialLoginThunk('facebook', response.accessToken));
  }

  const responseGoogle = (response) => {
    console.log('google response: ', response);
    const profileObj = response.profileObj;
    console.log(profileObj.email);
    console.log(response.tokenId);
    dispatch(socialLoginThunk('google', response.tokenId));
  }

  const responseApple = (response) => {
    try {
      console.log('apple response: ', response);
      if (response.error) {
        console.error('error: ', response.error);
      } else {
        dispatch(socialLoginThunk('apple', response.authorization.id_token));
      }
    } catch (err) {
      console.log('err: ', err);
    }
  }

  return (
    <div className={clsx(classes.mainContainer, classes.flexContainer)}  >
      <div style={{width:'50%'}}>
      <img src={login} style={{height:'90%',width:'90%'}}/>

      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography variant="h2" align="center" className={classes.header}>
          {t("login")}
        </Typography>
        <Typography variant="body1" align="center" className={classes.description}  >
          {t("description")}
        </Typography>
        <div className={clsx(classes.flexContainer, classes.socialMediaContainer)} >
          <div className={clsx(classes.iconBubble, classes.facebookLoginWrapper)}>
            <FacebookLogin
              appId={env.facebookAppId}
              autoLoad={false}
              fields="email"
              callback={responseFacebook}
              icon={<FacebookIcon width={24} height={24} className={classes.socialLoginIcon} />}
              textButton={null}
            />
          </div>
          <div className={classes.iconBubble}>
            <GoogleLogin
              clientId={env.googleWebClientId}
              buttonText={null}
              render={(renderProps) =>
                <GoogleIcon
                  onClick={renderProps.onClick}
                  width={24}
                  height={24}
                  className={classes.socialLoginIcon}
                />}
              onSuccess={responseGoogle}
              onFailure={(error) => console.error(error)}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <div className={classes.iconBubble}>
            <AppleLogin
              clientId={env.appleSignInServiceId}
              redirectURI={`https://${env.domain}/registration`}
              callback={responseApple}
              scope={'email'}
              usePopup={true}
              render={(renderProps) =>
                <AppleIcon
                  onClick={renderProps.onClick}
                  width={24}
                  height={24}
                  className={classes.socialLoginIcon}
                />}
            />
          </div>
        </div>
        <div className={clsx(classes.flexContainer, classes.lineContainer)}>
          <div className={classes.line} />
          <Typography variant="caption" color="textSecondary" className={classes.or}>
            {t('or')}
          </Typography>
          <div className={classes.line} />
        </div>
        <form
          className={classes.form}
          onSubmit={onSubmit}
        >
          <TextField
            value={email}
            onChange={handleEmailChange}
            label={t('email')}
            variant="outlined"
            className={classes.textField}
            InputProps={
              {
                type: 'email',
                name: 'email',
              }
            }
            error={submitTimes > 0 && !email}
            helperText={
              submitTimes > 0 && !email &&
              <ErrorHelperText text={t('errors.email')} />
            }
          />
          <TextField
            value={password}
            onChange={handlePasswordChange}
            label={t('password')}
            variant="outlined"
            className={clsx(classes.textField, classes.password)}
            InputProps={
              {
                type: pwFieldOneVisible ? 'text' : 'password',
                name: 'password',
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    <IconButton
                      onClick={() => setPwFieldOneVisible(!pwFieldOneVisible)}
                    >
                      {pwFieldOneVisible ?
                        <IconVisibilityOff /> :
                        <IconVisibility />
                      }
                    </IconButton>
                  </InputAdornment>
                ),
              }
            }
            error={submitTimes > 0 && !password}
            helperText={
              submitTimes > 0 && !password &&
              <ErrorHelperText text={t('errors.password')} />
            }
          />
          {
            authMsg && (
              <div className={classes.alertContainer}>
                <Alert severity="error">{te(`code.${authMsg}`)}</Alert>
              </div>
            )
          }
          <div className={classes.flexContainer}>
            <Typography variant="body1" className={classes.forgotPassword}>
              <NavLink to="/forgot-password" className={classes.navLink}>
                {t('forgot-password')}
              </NavLink>
            </Typography>
          </div>
          <LoadingButton
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            isLoading={isLoading}
            loadingProps={{
              size: 24
            }}
          >
            <Typography variant="body1">
              {t("confirm")}
            </Typography>
          </LoadingButton>
        </form>
        <div className={classes.flexContainer}>
          <Typography variant="body1">
            {t("not-yet-sign-up")}
          </Typography>
          <Typography variant="body1" color="primary" className={classes.signUp}>
            <NavLink to="/registration" className={classes.navLink}>
              {t("sign-up")}
            </NavLink>
          </Typography>
        </div>
      </Paper>
    </div>
  )
}