import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize, submit, setSubmitFailed, destroy } from 'redux-form'
import { useHistory } from 'react-router-dom'

import _get from 'lodash/get'
import _indexOf from 'lodash/indexOf'
import _find from 'lodash/find'

import BasicInformationForm from 'src/components/creator-onboarding/BasicInformationForm'
import PiadTier from 'src/components/creator-onboarding/PaidTier'
import TierForm from 'src/components/creator-onboarding/TierForm'

import {
    setCurrentFormTab,
    submitCreatorOnboarding,
    getCreatorOnboarding,
    setIsPuttingCreatorOnboarding,
    clearCreatorOnboardingState,
    getMe
} from 'src/redux/creatorOnboarding/actions'
import {
    editCreatorProfile
} from 'src/redux/creatorOnboarding/thunks';
import { countriesArray } from 'src/info/countries'
import { ReactComponent as Tab1 } from "src/images/Creator-Tabs/Tab1-fill.svg";
import { ReactComponent as Tab2 } from "src/images/Creator-Tabs/Tab2-fill.svg";
import { ReactComponent as Tab3 } from "src/images/Creator-Tabs/Tab3-fill.svg";
import { ReactComponent as Tab1Blank } from "src/images/Creator-Tabs/Tab1-blank.svg";
import { ReactComponent as Tab2Blank } from "src/images/Creator-Tabs/Tab2-blank.svg";
import { ReactComponent as Tab3Blank } from "src/images/Creator-Tabs/Tab3-blank.svg";

import clsx from 'clsx';
import { useTranslation } from 'react-i18next'

import { Box, Tabs, Tab, Typography, Button, Grid } from '@material-ui/core'
import useStyles from 'src/styles/pages/creatorOnboaringForm'
import { useSnackbar } from 'notistack'
import ProgressBar from 'src/components/creator-onboarding/ProgressBar'
import LoadingButton from 'src/components/LoadingButton';
import { ReactComponent as IconVisibility } from "src/images/login-page/icon_visibility.svg";
import PayoutMethod from 'src/components/creator-onboarding/PayoutMethod'

export const creatorOnboardingFormName = "creator-onboarding"
export const creatorOnboardingFormTabs = [
    "basic-information",
    "paid-plan",
    "collection-method",
]


const CreatorOnboardingForm = (props) => {
    const [tempNewTab, setTempNewTab] = useState(null)
    const [isReleaseLoading, setIsReleaseLoading] = useState(false)
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState(false);
    const classes = useStyles(props)

    const history = useHistory()

    const currentFormTab = useSelector(state => _get(state, 'creatorOnboarding.currentFormTab'));
    const isTierFormOpen = useSelector(state => _get(state, 'creatorOnboarding.isTierFormOpen'));
    const isIdol = useSelector(state => _get(state, 'auth.isIdol'));
    const alreadyLoadedForm = useSelector(state => _get(state, 'form.creator-onboarding.values'));

    useEffect(() => {
        console.log("creator on boarding form")
        if (!alreadyLoadedForm) {
            dispatch(getCreatorOnboarding()).then(async (data) => {
                console.log("in useEffect [] getCreatorOnboarding data", data)

                if (!_get(data, "data")) return

                dispatch(setIsPuttingCreatorOnboarding(true))

                const getCompatibleFormValues = values => {
                    values["countryCode"] = _find(
                        countriesArray,
                        obj => _get(obj, "code") === _get(values, "countryCode")
                    )
                    return values
                }

                if (isIdol) {
                    const currentData = await dispatch(getMe());
                    data.data.creatorPageName = currentData.fullName;
                    data.data.countryCode = currentData.countryCode;
                    data.data.creatorPageDescription = currentData.profile;
                    data.data.profileImage = currentData.profileImage ? currentData.profileImage : null;
                    data.data.payoneerEmail = currentData.payoneerEmail ? currentData.payoneerEmail : null;
                }

                console.log('data: ', data);

                dispatch(initialize(
                    creatorOnboardingFormName,
                    getCompatibleFormValues(_get(data, "data"))
                ))
            })
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log("tab: useEffect for tempNewTab", tempNewTab)
        if (tempNewTab) {
            !isTierFormOpen && dispatch(submit(creatorOnboardingFormName))
            console.log("tab: removing tempNewTab")
            setTempNewTab(null)
        }

        // eslint-disable-next-line
    }, [tempNewTab])

    console.log("currentFormTab", currentFormTab)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const handleTabChange = useCallback((e, tab) => {
        console.log("tab: handleTabChange", tab)
        if (_indexOf(creatorOnboardingFormTabs, tab) < _indexOf(creatorOnboardingFormTabs, currentFormTab)) {
            !isTierFormOpen && dispatch(setCurrentFormTab(tab))
            dispatch(setSubmitFailed(creatorOnboardingFormName))
        } else if (_indexOf(creatorOnboardingFormTabs, tab) > _indexOf(creatorOnboardingFormTabs, currentFormTab)) {
            console.log("tab: setting tempNewTab", tab)
            !isTierFormOpen && setTempNewTab(tab)
            // !isTierFormOpen && dispatch(submit(creatorOnboardingFormName))    // try submit in useEffect of tempNewTab
        }

        // eslint-disable-next-line
    }, [isTierFormOpen, currentFormTab])

    const onSubmitTab = useCallback(values => {
        console.log(`tab: onSubmit prop: BasicInformationForm named as '${creatorOnboardingFormName}' values`, values)
        return new Promise(resolve => {
            setTimeout(() => {
                console.log("resolving submit")
                resolve("test")
            }, 10)
        })
    }, [])

    const onHandleNext = () => {
        if (currentFormTab === creatorOnboardingFormTabs[0]) {
            handleTabChange(null, creatorOnboardingFormTabs[1])
            setIsPrevDisabled(false);
        } else if (currentFormTab === creatorOnboardingFormTabs[1]) {
            handleTabChange(null, creatorOnboardingFormTabs[2])
            setIsPrevDisabled(false);
        } else if (currentFormTab === creatorOnboardingFormTabs[2]) {
            setIsNextDisabled(false);
            console.log("onhandle")
            history.push("/home")
        }
       
    }
    const onHandlePrev = () => {
        if (currentFormTab === creatorOnboardingFormTabs[0]) {
            setIsPrevDisabled(true);
        } else if (currentFormTab === creatorOnboardingFormTabs[1]) {
            handleTabChange(null, creatorOnboardingFormTabs[0])
        } else if (currentFormTab === creatorOnboardingFormTabs[2]) {
            handleTabChange(null, creatorOnboardingFormTabs[1])
        }
    }
    const onSubmitSuccess = useCallback(() => {
        // console.log("tab: onSubmitSuccess", "tempNewTab", tempNewTab, "removing tempNewTab ")

        tempNewTab && dispatch(setCurrentFormTab(tempNewTab))
        setTempNewTab(null)

        // eslint-disable-next-line
    }, [tempNewTab])

    const onSubmitFail = useCallback(() => {
        console.log("tab: onSubmitFail", "tempNewTab", tempNewTab, "removing tempNewTab ")
        setTempNewTab(null)
    }, [tempNewTab])

    const callFinalSubmit = useCallback(() => {
        if (_indexOf(creatorOnboardingFormTabs, currentFormTab) === creatorOnboardingFormTabs.length - 1)
            dispatch(submit(creatorOnboardingFormName))

        // eslint-disable-next-line
    }, [currentFormTab])

    const onFinalSubmit = useCallback(values => {
        console.log("in onFinalSubmit values", values)
        setIsReleaseLoading(true)
        return dispatch(isIdol ? editCreatorProfile(values) : submitCreatorOnboarding(values)).then(() => {
            console.log("onFinalSubmit succeeded")

            dispatch(clearCreatorOnboardingState())

            // destroy form
            dispatch(destroy(creatorOnboardingFormName))

            enqueueSnackbar("Saved successfully", { variant: "success", })

            // use this to route somewhere on success
            history.replace("/")
        }).catch(err => {
            console.log("onFinalSubmit failed", err)
            enqueueSnackbar(err, { variant: "error", })
        }).finally(() => setIsReleaseLoading(false))

        // eslint-disable-next-line
    }, [])


    const { t } = useTranslation("creator-onboarding");
    return (
        <Box className={classes.root}>
            <ProgressBar totalSteps={3} currentStepIndex={_indexOf(creatorOnboardingFormTabs, currentFormTab)} />

            <Box className={classes.main}>
                <Box className={classes.formHeader}>
                    <Tabs
                        value={currentFormTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                    >
                        <Button style={{ width: "25%" }} onClick={(e)=>{handleTabChange(e,creatorOnboardingFormTabs[0])}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {currentFormTab === creatorOnboardingFormTabs[0] ? 
                                <Tab1 style={{ alignSelf: 'center' }} /> : 
                                <Tab1Blank style={{ alignSelf: 'center' }} />}
                                <Tab 
                                    className={classes.tab} 
                                    value={creatorOnboardingFormTabs[0]} 
                                    label={t("basic-information")} 
                                />
                            </div>
                        </Button>
                        <Button style={{ width: "25%" }} onClick={(e)=>{handleTabChange(e,creatorOnboardingFormTabs[1])}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {currentFormTab === creatorOnboardingFormTabs[1] ? 
                                <Tab2 style={{ alignSelf: 'center' }} /> : 
                                <Tab2Blank style={{ alignSelf: 'center' }} />}
                                <Tab 
                                    className={classes.tab} 
                                    value={creatorOnboardingFormTabs[1]} 
                                    label={t("paid-plan")} 
                                />
                            </div>
                        </Button>
                        <Button style={{ width: "25%" }} onClick={(e)=>{handleTabChange(e,creatorOnboardingFormTabs[2])}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {currentFormTab === creatorOnboardingFormTabs[2] ? 
                                <Tab3 style={{ alignSelf: 'center' }} /> : 
                                <Tab3Blank style={{ alignSelf: 'center' }} />}
                                <Tab className={classes.tab} value={creatorOnboardingFormTabs[2]} 
                                label={t("collection-method")} 
                            />
                            </div>
                        </Button>
                    </Tabs>
                    <Box>
                        <Button
                            className={clsx(classes.button)}
                            startIcon={<IconVisibility />}
                            onClick={() => history.push("/creator/onboarding/preview")}
                        >
                            <Typography
                                className={classes.a}
                            >
                                {t("preview")}
                            </Typography>
                        </Button>
                        {/* <LoadingButton
                            variant="contained"
                            color="primary"
                            className={clsx(classes.button, classes.release)}
                            // type="submit"
                            isLoading={isReleaseLoading}
                            loadingProps={{
                                size: 24
                            }}
                            onClick={callFinalSubmit}
                        > */}
                        {/* <Typography variant="body1"> */}
                        {/* {t("release")} */}
                        {/* </Typography> */}
                        {/* </LoadingButton> */}
                    </Box>
                </Box>

                {currentFormTab === creatorOnboardingFormTabs[0] && 
                <BasicInformationForm
                    form={creatorOnboardingFormName}
                    destroyOnUnmount={false}
                    onSubmit={onSubmitTab}
                    onSubmitSuccess={onSubmitSuccess}
                    onSubmitFail={onSubmitFail}
                />}
                {currentFormTab === creatorOnboardingFormTabs[1] && !isTierFormOpen && 
                <PiadTier
                    form={creatorOnboardingFormName}
                    destroyOnUnmount={false}
                    onSubmit={onSubmitTab}
                    onSubmitSuccess={onSubmitSuccess}
                    onSubmitFail={onSubmitFail}
                />}
                {currentFormTab === creatorOnboardingFormTabs[1] && isTierFormOpen && 
                <TierForm
                
                />}

                {currentFormTab === creatorOnboardingFormTabs[2] && 
                <PayoutMethod
                    form={creatorOnboardingFormName}
                    destroyOnUnmount={false}
                    onSubmit={onFinalSubmit}
                    onSubmitSuccess={onSubmitSuccess}    // mind, this also gets fired after submitting from inside(to validate)
                    // using then instead
                    onSubmitFail={onSubmitFail}          // using catch instead
                />}

                {/* on submit, clear form-data, specific fields of creatorOnboarding global state */}

            </Box>
            <Box className={classes.formHeader}>
                {/* <LoadingButton
               // className={clsx(classes.Previousbutton, classes.uploadBtn)}
                variant="contained"
                color="primary"
                onClick={onHandlePrev}
                isLoading={isReleaseLoading}
                loadingProps={{ size: 30 }}
                disabled={isPrevDisabled}
            >
                <Typography>
                    {t("Previous")}
                </Typography>
            </LoadingButton>
            <LoadingButton
               // className={clsx(classes.Previousbutton, classes.uploadBtn)}
                variant="contained"
                color="primary"
                onClick={onHandleNext}
                isLoading={isReleaseLoading}
                loadingProps={{ size: 30 }}
                disabled={isNextDisabled}
            >
                <Typography>
                    {t("Next")}
                </Typography>
            </LoadingButton> */}
                <LoadingButton
                    variant="contained"
                    className={clsx(classes.Previousbutton, classes.tab)}
                    type="submit"
                    isLoading={isReleaseLoading}
                    loadingProps={{
                        size: 24
                    }}
                    disabled={isPrevDisabled}
                    onClick={onHandlePrev}
                >
                    <Typography>
                        {t("previous")}
                    </Typography>
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    className={clsx(classes.Nextbutton, classes.tab)}
                    type="submit"
                    isLoading={isReleaseLoading}
                    disabled={isNextDisabled}
                    loadingProps={{
                        size: 24
                    }}
                    onSubmit={onHandleNext}
                    onClick={onHandleNext}
                >
                    <Typography>
                        {currentFormTab === creatorOnboardingFormTabs[2] ? t("release") : t("next")}
                    </Typography>
                </LoadingButton>
            </Box>
        </Box>


    )
}

export default CreatorOnboardingForm

