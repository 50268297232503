import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
    },
    avatarHolder: {
        // width: 35,
        height: 35,
        borderRadius: 14,
        overflow: "hidden",
    },
    avatar: {
        height: "100%",
    },
    main: {
        flexGrow: 1,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    user: {
        fontWeight: 600,
    },
    time: {
        color: theme.palette.grey[500],
        fontSize: 12,
    },
    infoImageHolder: {
        // width: 50,
        height: 50,
        borderRadius: 5,
        overflow: "hidden",
    },
    infoImage: {
        height: "100%",
    },
}))

export default useStyles