import React from 'react'
import useStyles from 'src/styles/pages/platformUsageGuide';
import {
  Typography
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
export default function PlatformUsageGuide() {
  const classes = useStyles();
  const { t } = useTranslation("Platform-usage-guide");
  return (
    <div className={classes.mainContainer}>
      <div className={classes.textContainer}>
        <div className={classes.header}>
          <Typography variant="h3">
            {t('pf1')}
            {/* 平台使用指引 */}
          </Typography>
        </div>
        <Typography variant="body1">
        {t('pf2')}
          {/* 用戶每天都會使用 Fanship 分享生活體驗、與親友互動及建立社群。我們為超過二十億用戶提供服務，讓每個國家／地區、不同文化、使用數十種不同語言的用戶能在這裡自由表達自己的想法。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('pf3')}
          {/* 我們知道讓用戶在 Fanship 盡情溝通交流的重要性，也十分認真看待保護我們服務不受濫用行為侵擾的職責。因此，我們制定了一套《社群守則》，說明 Fanship 允許及禁止的內容與行為。我們參考社群提供的意見，和科技、公共安全、人權等各領域專家的建議，擬定了這些政策。為了確保每個人的聲音都受到重視，我們致力在草擬政策時考量不同的觀點和理念、聆聽各方意見，尤其是平常可能會被忽略的個人和社群。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('pf4')}
          {/* 我們制定《社群守則》，一直都是以打造自由表達的平台，讓用戶盡情發聲為目標。這一點從未改變，日後也不會改變。要建立社群和拉近世界距離，關鍵在於讓用戶分享多元意見、體驗、想法和資訊。我們希望用戶可以公開討論自己重視的議題，即使有人不認同或反對也不會阻礙用戶表達意見。在某些情況下，可喚起公共意識的內容可能違反《社群守則》，但只要具有報導價值及符合公眾利益，我們仍會允許發佈。做出此决定的前提是，我們會權衡確認公眾利益價值是否大於可能造成傷害的風險，同時也會依據 Fanship 企業人權政策之規定，參考國際人權標準來做出決定。因此，我們會考量所有用戶（包括新聞機構和個人用戶）發佈內容的報導價值。舉例來說，若公眾確有知的權利，我們允許詳實描寫戰爭或戰爭後果的內容。 */}
        </Typography>
        <br />
        <Typography variant="body1">
          <b>
          {t('pf5')}
            {/* 真實性驗證： */}

          </b>
          {t('pf6')}
          {/* 我們希望確保用戶在 Fanship 看到的都是真實的內容。我們相信真實性有助打造更適合分享資訊的環境，這也正是為什麼我們不希望用戶利用 Fanship 來謊報身分或捏造職業。
          用戶安全：我們力求維護 Fanship 的安全環境。威嚇他人的言論可能會使對方覺得受到威脅、排擠或因此不敢表達意見，因此 Fanship 不允許出現這類內容。 */}
        </Typography>
        <br />
        <Typography variant="body1">
          <b>
            {/* 隱私： */}
            {t('pf7')}
          </b>
          {t('pf8')}
          {/* 我們致力保護個人隱私和資訊。隱私受到保護，用戶才能自由地表達意見，也能更輕鬆地選擇在 Fanship 分享資訊和互動交流的方式和時機。 */}
        </Typography>
        <br />
        <Typography variant="body1">
          <b>
          {t('pf9')}
            {/* 尊嚴無損： */}
            </b>
            {t('pf10')}
            {/* 我們相信每個人都擁有平等的尊嚴和權利，也期望用戶能尊重他人的尊嚴，不騷擾或貶低其他用戶。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('pf11')}
          {/* 《社群守則》保障世界各地的所有用戶，也適用於任何類別的內容。本守則會全面考量所有情況；舉例來說，即使發佈的內容並不構成仇恨內容，但如果違反其他政策，該內容仍然會遭移除。我們知道言論會因為用戶的本地社群、語言或背景而有不同的意思或影響。我們盡力考量到這些差異，同時在對用戶及其言論內容執行相關政策時，秉持一致且公平的原則。我們執行本守則的方式仰賴我們可取得的資訊。在某些情況下，這表示我們可能無法偵測出違反守則的內容和行為，而有些時候，執行規定可能僅限於我們已獲得額外資訊和背景資訊的情況。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('pf12')}
          {/* 用戶也可以針對可能違反《社群守則》的內容向我們提出檢舉，包括粉絲專頁、社團、個人檔案、個人的內容和留言。此外，我們也為用戶體驗提供不同的控制項，讓他們封鎖、取消追蹤或隱藏用戶和貼文。 */}
        </Typography>
        <br />
        <Typography variant="body1">
        {t('pf13')}
          {/* 違反 Fanship《社群守則》的後果，取決於情節輕重以及用戶過去的紀錄。舉例來說，對於初犯者我們可能只會給予警告，但如果持續違反規定，我們可能會限制其 Fanship 發文權限或停用其個人檔案。如果我們認為可能造成實際人身傷害，或對公共安全有直接威脅，我們也可能會通報執法機關處理。
          《社群守則》作為用戶的行為準則，列出了 Fanship 允許和禁止的行為，我們也要求 Fanship 社群的成員共同遵循本守則。 */}
        </Typography>
        <br />
        <br />
      </div>
    </div>
  )
}