import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reduxForm, Field, change } from "redux-form";

import _get from "lodash/get";
import _trim from "lodash/trim";

import { creatorOnboardingFormName } from "src/pages/CreatorOnboardingForm";
import RenderTextField from "src/components/renderTextField/FormField";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import LoadingButton from "src/components/LoadingButton";

import useStyles from "src/styles/pages/creatorOnboaringForm";

import { translatorJson } from "./BasicInformationForm";

const PayoutMethod = (props) => {
  useEffect(() => {
    dispatch(
      change(creatorOnboardingFormName, "receivePaymentMethod", "payoneer")
    );

    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  const { t } = useTranslation(translatorJson);
  const classes = useStyles();

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formElement}>
        <Typography
          className={classes.formElementTitle}
          variant="h5"
          component="h3"
        >
          {t("collection-method")}
        </Typography>
        <Paper className={classes.formElementBody}>
          <Box className={classes.inputGroup}>
            <Box className={classes.labelBox}>
              <Typography className={classes.label} variant="h6">
                {t("connect-payoneer-account")}
              </Typography>
              <Typography className={classes.condition}>{t("must")}</Typography>
            </Box>
            <Box className={classes.inputBox}>
              <Field
                name="payoneerEmail"
                component={RenderTextField}
                variant="outlined"
                label={t("email")}
                fullWidth={true}
                translatorJson={translatorJson}
              />
            </Box>
          </Box>
          <Box className={classes.inputGroup}>
            <Box className={classes.labelBox}></Box>
            <Box className={classes.inputBox}>
              <Field
                name="confirmPayoneerEmail"
                component={RenderTextField}
                variant="outlined"
                label={t("confirm-email")}
                fullWidth={true}
                translatorJson={translatorJson}
              />
            </Box>
          </Box>
          <Box className={classes.inputGroup}>
            <Box className={classes.labelBox}></Box>
            <Box className={classes.inputBoxVertical}>
              <LoadingButton
                className={clsx(
                  classes.button,
                  classes.uploadBtn,
                  classes.bold
                )}
                variant="contained"
                color="primary"
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                {t("connect-account")}
              </LoadingButton>
              <Typography
                className={classes.customStyledText}
                style={{ fontSize: 14 }}
              >
                {t("connect-payoneer-account-description")}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const validate = (values) => {
  const errors = {};

  // errors are translated in Field-components.
  if (!_trim(_get(values, "payoneerEmail")))
    errors["payoneerEmail"] = "email-required";
  else if (!/\S+@\S+\.\S+/.test(_get(values, "payoneerEmail")))
    errors["payoneerEmail"] = "invalid-email";

  if (_get(values, "payoneerEmail") !== _get(values, "confirmPayoneerEmail"))
    errors["confirmPayoneerEmail"] = "re-enter-email";

  return errors;
};

const onSubmitSuccess = () => {
  /// mind, this also gets fired after submitting from inside(to validate)
  console.log("payout-method-wizard passed validations: onSubmitSuccess");
};

export default reduxForm({
  validate,
  onSubmitSuccess,
})(PayoutMethod);
