import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/editPersonalProfile';
import clsx from "clsx";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  MenuItem
} from "@material-ui/core";
import LoadingButton from 'src/components/LoadingButton';
import env from 'src/env';
import defaultAvatar from 'src/images/common/default_avatar.png';
import ErrorHelperText from 'src/components/ErrorHelperText';
import { ReactComponent as ArrowDownIcon } from 'src/images/common/icon_arrow_down.svg';
import moment from 'moment';
import InfoDialog from 'src/components/InfoDialog';
import { logoutThunk } from 'src/redux/auth/thunks';
import CropAvatarDialog from 'src/components/personal-profile/CropAvatarDialog';
import { v1 as uuidv1 } from 'uuid';
import { updatePersonalProfile } from "src/redux/auth/actions";

export default function EditPersonalProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation("edit-personal-profile");
  const { t: te } = useTranslation("error");
  const classes = useStyles();
  const avatar = useSelector((state) => state.auth.avatar);
  const displayName = useSelector((state) => state.auth.displayName);
  const fullName = useSelector((state) => state.auth.fullName);
  const email = useSelector((state) => state.auth.email);
  const [user, setUser] = useState();
  const [formAvatar, setFormAvatar] = useState(avatar);
  const [formFullName, setFormFullName] = useState(fullName);
  const [formDisplayName, setFormDisplayName] = useState(displayName);
  const [submitTimes, setSubmitTimes] = useState(0);
  const [profile, setProfile] = useState('');
  const [gender, setGender] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [formEmail, setFormEmail] = useState(email);
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMsg, setDialogMsg] = useState('');
  const [dialogButton, setDialogButton] = useState();
  const [loadingDeactivate, setLoadingDeactivate] = useState();
  const [deactivated, setDeactivated] = useState(false);
  const [cropAvatarDialogOpen, setCropAvatarDialogOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const getUser = async () => {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const res = await fetch(`https://${env.apiDomain}/me`,
        {
          method: 'GET',
          headers: {
            Authorization: `${userId}_${token}`,
          },
        }
      );
      const result = await res.json();
      console.log('result: ', result);
      if (result.success) {
        const data = result.data;
        setUser(data);
        setProfile(data.profile);
        setGender(data.gender ? data.gender : '');
        if (data.birthDate) {
          setYear(moment(data.birthDate, 'YYYY-M-D').format('YYYY'));
          setMonth(moment(data.birthDate, 'YYYY-M-D').format('M'));
          setDay(moment(data.birthDate, 'YYYY-M-D').format('D'));
        }
        setPhone(data.phone ? data.phone : '');
      } else {
        console.log('error code: ', result.code);
      }
    }
    getUser();
  }, [])

  const handleFieldChange = (event, field) => {
    switch (field) {
      case 'fullName':
        setFormFullName(event.target.value);
        break;
      case 'displayName':
        setFormDisplayName(event.target.value);
        break;
      case 'profile':
        setProfile(event.target.value);
        break;
      case 'gender':
        setGender(event.target.value);
        break;
      case 'year':
        setYear(event.target.value);
        break;
      case 'month':
        setMonth(event.target.value);
        break;
      case 'day':
        setDay(event.target.value);
        break;
      case 'email':
        setFormEmail(event.target.value);
        break;
      case 'phone':
        const regex = /^[0-9\b]+$/;
         // if value is not blank, then test the regex
        if (event.target.value === '' || regex.test(event.target.value)) {
            setPhone(event.target.value)
        }
        break;
      default:
        break;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitTimes(submitTimes + 1);

    if (!formFullName || !formDisplayName || !formEmail) {
      return;
    }

    setIsLoading(true);

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const body = {
      fullName: formFullName,
      username: formDisplayName,
      email: formEmail,
      profile: profile,
      gender: gender,
      phone: phone
    }

    if (year && month && day) {
      body['birthDate'] = `${year}-${month}-${day}`;
    }

    let avatarUrl;
    if (formAvatar !== avatar) {
      const filename = uuidv1() + '.png';
      console.log('filename: ', filename)
      const mediaRes = await fetch(`https://${env.apiDomain}/media`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `${userId}_${token}`
        },
        body: JSON.stringify({
          filename: filename
        })
      });

      const mediaResult = await mediaRes.json();
      console.log('mediaResult: ', mediaResult);

      let formData = new FormData();
      for (const [key, value] of Object.entries(mediaResult.fields)) {
        formData.append(key, value);
      }
      console.log("check formAvatar", formAvatar)
      let file = await fetch(formAvatar).then(r => r.blob()).then(blobFile => new File([blobFile], filename, { type: "image/png" }));

      formData.append('file', file)

      const uploadRes = await fetch(mediaResult.url, {
        method: 'POST',
        body: formData
      })

      console.log('http code:', uploadRes.status);
      console.log('uploadRes: ', uploadRes);

      const uploadResult = await uploadRes.text();
      console.log('upload result: ', uploadResult);
      const parser = new DOMParser();
      const xmlDOM = parser.parseFromString(uploadResult, "text/xml");
      const value = xmlDOM.getElementsByTagName("Location")[0].childNodes[0].nodeValue;
      console.log(value)
      const url = value.split('%2F').join('/');

      body['isImgChanged'] = true;
      body['avatar'] = url;
      avatarUrl = url;
    }

    const res = await fetch(`https://${env.apiDomain}/users/${userId}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `${userId}_${token}`
      },
      body: JSON.stringify(body)
    });

    const result = await res.json();
    console.log('result: ', result);
    if (result.success) {
      dispatch(updatePersonalProfile(
        formEmail,
        formDisplayName,
        formFullName,
        avatarUrl ? avatarUrl : avatar
      ))
      if (avatarUrl) {
        setFormAvatar(avatarUrl);
      }
      setDialogTitle(t('success-dialog.header'));
      setDialogMsg(t('success-dialog.content'));
    } else {
      setDialogTitle(te('title'));
      setDialogMsg(te(`code.${result.code}`));
    }
    setDialogButton(<ConfirmButton onClick={closeDialog} />);
    setDialogOpen(true);

    setIsLoading(false);
  }

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const ConfirmButton = ({
    onClick
  }) => {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={onClick}
        className={classes.dialogButton}
      >
        <Typography variant="body1">
          {t('confirm')}
        </Typography>
      </Button>
    )
  }

  const clickDeactivate = () => {
    console.log('click');
    setDialogTitle(t('deactivate-account.header'));
    setDialogMsg(t('deactivate-account.confirm-message'));
    setDialogButton(<ConfirmDeactivateButton />);
    setDialogOpen(true);
  }

  const ConfirmDeactivateButton = () => {
    return (
      <LoadingButton
        fullWidth
        variant="contained"
        onClick={confirmDeactivate}
        className={clsx(classes.dialogButton, classes.confirmDeactivateButton)}
        isLoading={loadingDeactivate}
        loadingProps={{
          size: 24
        }}
      >
        {t('confirm')}
      </LoadingButton>
    )
  }

  const confirmDeactivate = async () => {
    setLoadingDeactivate(true)

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const res = await fetch(`https://${env.apiDomain}/users/${userId}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `${userId}_${token}`
      }
    });

    const result = await res.json();
    console.log('result: ', result);

    setDialogOpen(false);

    if (result.success) {
      setDeactivated(true);
      setDialogTitle(t('deactivate-account.header'));
      setDialogMsg(t('deactivate-account.success-message'));
      setDialogButton(<ConfirmButton onClick={() => dispatch(logoutThunk())} />);
    } else {
      setDialogTitle(te('title'));
      setDialogMsg(te(`code.${result.code}`));
      setDialogButton(<ConfirmButton onClick={closeDialog} />);
    }
    setDialogOpen(true);
    setLoadingDeactivate(false);
  }

  const closeCropAvatarDialog = () => {
    setCropAvatarDialogOpen(false);
  }

  return (
    <div className={clsx(classes.root, !user && classes.loadingContainer)}>
      {
        user ?
          <div className={classes.mainContainer}>
            <Typography variant="h3" className={classes.header}>
              {t('header')}
            </Typography>
            <form onSubmit={onSubmit}>
              <div className={classes.section}>
                <Typography variant="h5" className={classes.subHeader}>
                  {t('avatar')}
                </Typography>
                <div className={classes.flexContainer}>
                  <img
                    src={formAvatar ? formAvatar : defaultAvatar}
                    alt="user avatar"
                    className={classes.avatar}
                  />
                  <div>
                    <Typography variant="h4" className={classes.displayName}>
                      {displayName}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => setCropAvatarDialogOpen(true)}
                    >
                      <Typography variant="body1">
                        {t('update-avatar')}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
              <div className={classes.section}>
                <Typography variant="h5" className={classes.subHeader}>
                  {t('public-info')}
                </Typography>
                <TextField
                  fullWidth
                  value={formFullName}
                  onChange={(event) => handleFieldChange(event, 'fullName')}
                  label={t('fullName')}
                  variant="outlined"
                  className={classes.textField}
                  error={submitTimes > 0 && !formFullName}
                  helperText={
                    submitTimes > 0 && !formFullName &&
                    <ErrorHelperText text={t('errors.fullName')} />
                  }
                />
                <TextField
                  fullWidth
                  value={formDisplayName}
                  onChange={(event) => handleFieldChange(event, 'displayName')}
                  label={t('displayName')}
                  variant="outlined"
                  className={classes.textField}
                  error={submitTimes > 0 && !formDisplayName}
                  helperText={
                    submitTimes > 0 && !formDisplayName &&
                    <ErrorHelperText text={t('errors.displayName')} />
                  }
                />

                <TextField
                  fullWidth
                  value={profile}
                  onChange={(event) => handleFieldChange(event, 'profile')}
                  label={t('profile')}
                  variant="outlined"
                  multiline
                  minRows={5}
                  maxRows={15}
                />
              </div>
              <div className={classes.section}>
                <Typography variant="h5" className={classes.subHeader}>
                  {t('private-info')}
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.selectLabel}
                >
                  {t('gender')}
                </Typography>
                <TextField
                  className={classes.textField}
                  label={t('choose')}
                  fullWidth
                  variant="outlined"
                  select
                  value={gender}
                  onChange={(event) => handleFieldChange(event, 'gender')}

                  SelectProps={{
                    IconComponent: ArrowDownIcon,
                    MenuProps: {
                      classes: { paper: classes.selectMenu, list: classes.selectMenuList },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    },
                  }}
                >
                  <MenuItem
                    className={classes.emptyOption}
                    value=''
                  >
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    value='M'
                  >
                    {t('male')}
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    value='F'
                  >
                    {t('female')}
                  </MenuItem>
                </TextField>
                <Typography
                  variant="body1"
                  className={classes.selectLabel}
                >
                  {t('birth-date')}
                </Typography>
                <div className={classes.flexContainer}>
                  <TextField
                    className={clsx(classes.textField, classes.yearMonthField)}
                    fullWidth
                    variant="outlined"
                    select
                    value={year}
                    onChange={(event) => handleFieldChange(event, 'year')}

                    SelectProps={{
                      IconComponent: ArrowDownIcon,
                      MenuProps: {
                        classes: { paper: classes.selectMenu, list: classes.selectMenuList },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      className={classes.emptyOption}
                      value=''
                    >
                    </MenuItem>
                    {
                      new Array(120).fill(null).map((value, index) => {
                        const thisValue = currentYear - +index
                        return (
                          <MenuItem
                            key={`year_${thisValue}`}
                            className={classes.menuItem}
                            value={thisValue}
                          >
                            {`${thisValue} ${t('year')}`}
                          </MenuItem>
                        )
                      })
                    }
                  </TextField>
                  <TextField
                    className={clsx(classes.textField, classes.yearMonthField)}
                    fullWidth
                    variant="outlined"
                    select
                    value={month}
                    onChange={(event) => handleFieldChange(event, 'month')}

                    SelectProps={{
                      IconComponent: ArrowDownIcon,
                      MenuProps: {
                        classes: { paper: classes.selectMenu, list: classes.selectMenuList },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      className={classes.emptyOption}
                      value=''
                    >
                    </MenuItem>
                    {
                      new Array(12).fill(null).map((value, index) => {
                        const thisValue = +index + 1
                        return (
                          <MenuItem
                            key={`month_${thisValue}`}
                            className={classes.menuItem}
                            value={thisValue}
                          >
                            {`${thisValue} ${t('month')}`}
                          </MenuItem>
                        )
                      })
                    }
                  </TextField>
                  <TextField
                    className={clsx(classes.textField)}
                    fullWidth
                    variant="outlined"
                    select
                    value={day}
                    onChange={(event) => handleFieldChange(event, 'day')}
                    disabled={!(year && month)}
                    SelectProps={{
                      IconComponent: ArrowDownIcon,
                      MenuProps: {
                        classes: { paper: classes.selectMenu, list: classes.selectMenuList },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      className={classes.emptyOption}
                      value=''
                    >
                    </MenuItem>
                    {
                      new Array((year && month) ? moment(`${year}-${month}`, 'YYYY-M').daysInMonth() : 1).fill(null).map((value, index) => {
                        const thisValue = +index + 1
                        return (
                          <MenuItem
                            key={`day_${thisValue}`}
                            className={classes.menuItem}
                            value={thisValue}
                          >
                            {`${thisValue} ${t('day')}`}
                          </MenuItem>
                        )
                      })
                    }
                  </TextField>
                </div>
                <TextField
                  fullWidth
                  value={formEmail}
                  onChange={(event) => handleFieldChange(event, 'email')}
                  label={t('email')}
                  variant="outlined"
                  className={classes.textField}
                  error={submitTimes > 0 && !formEmail}
                  helperText={
                    submitTimes > 0 && !formEmail &&
                    <ErrorHelperText text={t('errors.email')} />
                  }
                />
                <TextField
                  fullWidth
                  value={phone}
                  onChange={(event) => handleFieldChange(event, 'phone')}
                  label={t('phone')}
                  variant="outlined"
                  className={classes.textField}
                />
              </div>
              <div className={classes.flexContainer}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  isLoading={isLoading}
                  loadingProps={{
                    size: 24
                  }}
                >
                  <Typography variant="body1">
                    {t("save")}
                  </Typography>
                </LoadingButton>
                <Typography
                  variant="body1"
                  className={classes.deactivate}
                  onClick={clickDeactivate}
                >
                  {t('deactivate-account.header')}
                </Typography>
              </div>
            </form>
            <InfoDialog
              open={dialogOpen}
              title={
                <Typography variant="h6"
                  align="center"
                  className={clsx(classes.textBold, classes.dialogTitle)}
                >
                  {dialogTitle}
                </Typography>
              }
              button={dialogButton}
              handleClose={deactivated ? () => dispatch(logoutThunk()) : closeDialog}
            >
              <div className={classes.dialogMsgDiv}>
                <Typography variant="body1" align="left">
                  {dialogMsg}
                </Typography>
              </div>
            </InfoDialog>
            <CropAvatarDialog
              open={cropAvatarDialogOpen}
              handleClose={closeCropAvatarDialog}
              setFormAvatar={setFormAvatar}
            />
          </div> :
          <CircularProgress size={60} />
      }
    </div>
  )
}