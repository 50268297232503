import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import _get from 'lodash/get'
import { useSnackbar } from 'notistack'

import { 
    likePost,
    removeLikeToPost,
    savePost,
    unsavePost,
    getLikesForPost,
} from 'src/redux/feed-post/actions'
import { nFormatter } from 'src/lib/formatters/number'

import LikesDialog from './LikesDialog'

import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined'
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined'
import BookmarkIcon from '@material-ui/icons/Bookmark'

import useStyles from 'src/styles/components/feed-post/post.js'

const PostStatus = ({
    postId,
    likeCount,
    commentCount,
    userSaved,
    userLiked,
}) => {
    const [ likeCountLocal, setLikeCountLocal ] = useState(likeCount)
    const [ userSavedLocal, setUserSavedLocal ] = useState(userSaved)
    const [ userLikedLocal, setUserLikedLocal ] = useState(userLiked)
    const [ isLikesDialogOpen, setIsLikesDialogOpen ] = useState(false)
    const [ likes, setLikes ] = useState(null)    // null and [] for empty-resposnse is used for condition in LikesDialog Dialog component
    const [ isLikeCoolingDown, setIsLikeCoolingDown ] = useState(false)
    const [ isSaveCoolingDown, setIsSaveCoolingDown ] = useState(false)


    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const handleSaveClick = useCallback(() => {
        if(isSaveCoolingDown) return

        setIsSaveCoolingDown(true)

        userSavedLocal
        ? dispatch(unsavePost(postId)).catch(err => {
            console.log("unsavePost failed", err)
            enqueueSnackbar(err || "Something went wrong", { variant: "error", })

        }).finally(() => setIsSaveCoolingDown(false))
        : dispatch(savePost(postId)).catch(err => {
            console.log("savePost failed", err)
            enqueueSnackbar(err || "Something went wrong", { variant: "error", })

        }).finally(() => setIsSaveCoolingDown(false))

        setUserSavedLocal(() => !userSavedLocal)

        // eslint-disable-next-line
    }, [ userSavedLocal, postId, isSaveCoolingDown ])

    
    const handleLikeClick = useCallback(() => {
        if(isLikeCoolingDown) return

        setIsLikeCoolingDown(true)

        userLikedLocal
        ? dispatch(removeLikeToPost(postId)).catch(err => {
            console.log("removeLikeToPost failed", err)
            enqueueSnackbar(err || "Something went wrong", { variant: "error", })
        }).finally(() => setIsLikeCoolingDown(false))
        : dispatch(likePost(postId)).catch(err => {
            console.log("likePost failed", err)
            enqueueSnackbar(err || "Something went wrong", { variant: "error", })
        }).finally(() => setIsLikeCoolingDown(false))

        setLikeCountLocal(() => likeCountLocal + (userLikedLocal ? (-1) : (1)))
        setUserLikedLocal(() => !userLikedLocal)

        // eslint-disable-next-line
    }, [ userLikedLocal, likeCountLocal, postId, isLikeCoolingDown ])

    const handleUsersLikedOpen = useCallback(() => {
        if(!likeCount) return

        dispatch(getLikesForPost(postId))
        .then(response => {
            console.log("getLikesForPost response", response)
            
            setLikes(_get(response, "data", []))
        })

        setIsLikesDialogOpen(true)

        // eslint-disable-next-line
    }, [postId, likeCount])

    const handleUsersLikedclose = useCallback(() => {
        setIsLikesDialogOpen(false)
        setLikes(null)
    }, [])


    const classes = useStyles()

    return (
        <>
        <LikesDialog
            open={isLikesDialogOpen}
            onClose={handleUsersLikedclose}
            likes={likes}
        />
        <Box className={classes.postStatus}>
            <Box className={classes.postStatusItem}>
                <IconButton 
                    className={classes.likeBtn}
                    onClick={handleLikeClick}
                >
                    {
                        userLikedLocal 
                        ? <FavoriteIcon className={clsx(classes.likeIcon, classes.likedTrue)} />
                        : <FavoriteBorderIcon className={clsx(classes.likeIcon, classes.likedFalse)}/>
                    }
                </IconButton>
                <Typography className={clsx(classes.postStatusItemLabel, likeCount && classes.pointer)} variant="body1" component="span"
                    onClick={handleUsersLikedOpen}
                >
                    {nFormatter(likeCountLocal, 1)}
                </Typography>
            </Box>
            {
                commentCount ?
                <Box className={classes.postStatusItem}>
                    <QuestionAnswerOutlinedIcon className={classes.commentIcon} />
                    <Typography className={classes.postStatusItemLabel} variant="body1" component="span">
                        {nFormatter(commentCount, 1)}
                    </Typography>
                </Box> : null
            }
            <Box className={classes.postStatusItem}>
                <IconButton 
                    className={classes.saveBtn}
                    onClick={handleSaveClick}
                >
                    {
                        userSavedLocal
                        ? <BookmarkIcon className={clsx(classes.saveIcon, classes.savedTrue)}/>
                        : <BookmarkBorderOutlinedIcon className={clsx(classes.saveIcon, classes.savedFalse)}/>
                    }
                </IconButton>
            </Box>
        </Box>
        </>
    )
}

export default PostStatus
