import React from 'react'
import useStyles from "src/styles/components/header/searchResultSingle";
import {
  Typography,
} from "@material-ui/core";
import { countriesMap } from 'src/info/countries';
import defaultAvatar from 'src/images/common/default_avatar.png';
import { ReactComponent as DeleteIcon } from 'src/images/common/icon_cross.svg';
import clsx from 'clsx';

export default function SearchResultSingle({
  avatar,
  displayName,
  fullName,
  countryCode,
  deleteFunction,
  isHighlight,
  ...others
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, isHighlight ? classes.listItem : '')} {...others}>
      <div className={classes.flexContainer}>
        <img src={avatar ? avatar : defaultAvatar} alt="creator avatar" className={classes.avatar} />
        <div onKeyPress={others?.onKeyPress}>
          <Typography variant="body1">
            {displayName}
          </Typography>
          <Typography variant="body1">
            {fullName}
          </Typography>
          {/* <Typography variant="body1"
            className={classes.textBold}
            color="textSecondary"
          >
            {countriesMap[countryCode]}
          </Typography> */}
        </div>
      </div>
      {deleteFunction &&
        <DeleteIcon
          width={24}
          height={24}
          className={classes.deleteIcon}
          onMouseDown={deleteFunction}
        />
      }
    </div>
  )
}