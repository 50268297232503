import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: 15,
    width: '100%',
    boxSizing: 'border-box',
    borderTop: `1px solid ${theme.palette.grey[200]}`
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  mainContainer: {
    justifyContent: 'space-between'
  },
  leftContainer: {
    margin: '5px 0'
  },
  copyright: {
    marginLeft: 5,
    marginRight: 30
  },
  navigation: {
    margin: '5px 30px 5px 0'
  },
  dot: {
    height: 2,
    width: 2,
    borderRadius: '50%',
    margin: '0 5px',
    backgroundColor: 'black'
  },
  internetIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5
  },
  languageContainer: {
    margin: '5px 0',
    cursor: 'pointer'
  },
  language: {
    borderBottom: '1px solid black'
  },
  navLink: {
    textDecoration: 'none',
    color: 'black'
  }
}), { index: 1 });

export default useStyles;