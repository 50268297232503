import React, { useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import _get from "lodash/get";

import {
  getPaidTiersList,
  setTierFormOpen,
} from "src/redux/creatorOnboarding/actions";

import PiadTierView from "./PaidTierView";

import { useTranslation } from "react-i18next";

import clsx from "clsx";

import useStyles from "src/styles/pages/paidTire";
import { useSnackbar } from "notistack";
import { Box, Typography, Button, Grid } from "@material-ui/core";
import { ReactComponent as AddIcon } from "src/images/common/plus.svg";

const gap = 15;

const PiadTier = ({ paidTiersList, getPaidTiersList, setTierFormOpen }) => {
  const classes = useStyles({
    flexContainerMainWidth: "80vw",
    gap,
  });
  const { t } = useTranslation("creator-onboarding");

  useEffect(() => {
    console.log("PaidTier mounted");

    getPaidTiersList().catch((err) => {
      console.log(err);
      enqueueSnackbar(err, { variant: "error" });
    });
    // eslint-disable-next-line
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formElement}>
        <Typography
          className={classes.formElementTitle}
          variant="h5"
          component="h3"
        >
          {t("paid-plan")}
        </Typography>
        <Grid container spacing={3} className={classes.flexContainerMain}>
          {paidTiersList.map((item, index) => {
            return (
              <Grid key={index} item sm={12} md={6} lg={4}>
                <PiadTierView item={item} />
              </Grid>
            );
          })}
          <Grid item sm={12} md={6} lg={4}>
            <Box className={classes.addCard}>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.addButton)}
                startIcon={<AddIcon />}
                onClick={() => setTierFormOpen(true)}
              >
                <Typography variant="body1">{t("new-payment-tier")}</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  paidTiersList: _get(state, "creatorOnboarding.paidTiersList"),
});

const mapDispatchToProps = (dispatch) => ({
  getPaidTiersList: () => dispatch(getPaidTiersList()),
  setTierFormOpen: (boolean) => dispatch(setTierFormOpen(boolean)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({})(PiadTier));
