import React, { useState } from 'react'
import useStyles from "src/styles/components/dashboard/performance";
import {
  Typography,
  useTheme,
  CircularProgress
} from "@material-ui/core";
import FollowersAndSubscribersCard from 'src/components/dashboard/FollowersAndSubscribersCard';
import RevenueCard from 'src/components/dashboard/RevenueCard';
import OtherStatCard from 'src/components/dashboard/OtherStatCard';
import PaymentReceiptsTable from 'src/components/dashboard/PaymentReceiptsTable';
import AccountBalanceCard from 'src/components/dashboard/AccountBalanceCard';
import NextPaymentCard from 'src/components/dashboard/NextPaymentCard';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from 'src/images/common/icon_calendar.svg';
import { ReactComponent as ArrowDownIcon } from 'src/images/common/icon_arrow_down.svg';
import FilterPopUpMenu from 'src/components/dashboard/FilterPopUpMenu';
import moment from 'moment';
import env from 'src/env';

const generateRequestBodyByFilter = (filter) => {
  let fromDate;
  let toDate;

  switch (filter) {
    case 'past-7-days':
      fromDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
      toDate = moment().format('YYYY-MM-DD');
      break;
    case 'past-14-days':
      fromDate = moment().subtract(13, 'days').format('YYYY-MM-DD');
      toDate = moment().format('YYYY-MM-DD');
      break;
    case 'past-30-days':
      fromDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
      toDate = moment().format('YYYY-MM-DD');
      break;
    case 'past-90-days':
      fromDate = moment().subtract(89, 'days').format('YYYY-MM-DD');
      toDate = moment().format('YYYY-MM-DD');
      break;
    case 'this-week':
      fromDate = moment().startOf('week').format('YYYY-MM-DD');
      toDate = moment().endOf('week').format('YYYY-MM-DD');
      break;
    case 'last-week':
      fromDate = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
      toDate = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
      break;
    case 'this-month':
      fromDate = moment().startOf('month').format('YYYY-MM-DD');
      toDate = moment().endOf('month').format('YYYY-MM-DD');
      break;
    case 'last-month':
      fromDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      toDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      break;
    default:
      break;
  }

  return {
    fromDate,
    toDate
  }
}


export default function Performance({
  stat,
  referrals,
  setStat
}) {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('past-7-days');
  const [loadingInfo, setLoadingInfo] = useState(false);

  const clickFilterButton = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const onSelect = async (filter) => {
    setLoadingInfo(true);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const res1 = await fetch(`https://${env.apiDomain}/dashboard`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `${userId}_${token}`
        },
        body: JSON.stringify(generateRequestBodyByFilter(filter))
      }
    );
    const result1 = await res1.json();
    console.log('result1: ', result1);
    if (result1.success) {
      let newStat = JSON.parse(JSON.stringify(stat));
      let data = result1.data;
      newStat.followCounts = data.followCounts;
      newStat.likeCounts = data.likeCounts;
      newStat.subscriptionCounts = data.subscriptionCounts;
      setStat(newStat);
    } else {
      console.log('error code: ', result1.code);
    }
    setLoadingInfo(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant="h5" align="left" className={classes.subHeader}>
          {t('tab-1.main-stat.header')}
        </Typography>
        <div className={classes.mainStatRow}>
          <div className={classes.rightMargin}>
            <FollowersAndSubscribersCard
              stat={stat}
            />
          </div>
          <div className={classes.rightMargin}>
            <RevenueCard
              stat={stat}
              referrals={referrals}
            />
          </div>
          <div className={classes.mainStatLastColumn}>
            <AccountBalanceCard
              accountBalance={1276000}
              onClick={() => console.log('click')}
            />
            <NextPaymentCard
              nextPaymentAmount={1116000}
            />
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.otherStatSectionTopContainer}>
          <Typography variant="h5" align="left" className={classes.subHeader}>
            {t('tab-1.other-stat.header')}
          </Typography>
          <div className={classes.filterButton} onClick={clickFilterButton}>
            <CalendarIcon className={classes.icon} />
            <Typography variant="caption" className={classes.filterText}>
              {t(`tab-1.other-stat.filters.${filter}`)}
            </Typography>
            <ArrowDownIcon className={classes.arrowDownIcon} />
          </div>
          <FilterPopUpMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setFilter={setFilter}
            onSelect={onSelect}
          />
        </div>
        {
          loadingInfo ?
            <div className={classes.loadingContainer}>
              <CircularProgress size={60} />
            </div> :
            <div className={classes.flexContainer}>
              <div className={classes.rightMargin}>
                <OtherStatCard
                  title={t('tab-1.other-stat.number-of-likes')}
                  counts={stat.likeCounts}
                  chartColors={['rgba(153, 0, 255, 0.5)', 'rgba(153, 0, 255, 0)']}
                  borderColor={theme.palette.primary.main}
                />
              </div>
              <div className={classes.rightMargin}>
                <OtherStatCard
                  title={t('tab-1.other-stat.number-of-followers')}
                  counts={stat.followCounts}
                  chartColors={['rgba(253, 197, 0, 0.5)', 'rgba(253, 197, 0, 0)']}
                  borderColor={theme.palette.secondary.main}
                />
              </div>
              <div>
                <OtherStatCard
                  title={t('tab-1.other-stat.number-of-subscribers')}
                  counts={stat.subscriptionCounts}
                  chartColors={['rgba(255, 55, 95, 0.5)', 'rgba(255, 55, 95, 0)']}
                  borderColor={theme.palette.error.main}
                />
              </div>
            </div>
        }
      </div>
      <div className={classes.section}>
        <Typography variant="h5" align="left" className={classes.subHeader}>
          {t('tab-1.payment-receipt-records.header')}
        </Typography>
        <PaymentReceiptsTable
          paymentReceipts={stat.paymentReceipts}
        />
      </div>
    </div>
  )
}