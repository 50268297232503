import React from 'react'
import { Typography } from "@material-ui/core";
import { Doughnut, Chart } from "react-chartjs-2";
import { roundedEdgePlugin1 } from "src/lib/chart";
import useStyles from "src/styles/components/dashboard/performance";
Chart.pluginService.register(roundedEdgePlugin1);

export default function DoughnutChart({
  labels,
  chartValues,
  chartColors,
  bigFigure,
  centerSubHeader
}) {
  const classes = useStyles();
  const data = {
    labels,
    datasets: [
      {
        data: chartValues,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    cutoutPercentage: 82,
    plugins: {
      p1: true,
    }
  }

  return (
    <div className={classes.doughnutChart}>
      <Doughnut data={data} options={options} />
      <div className={classes.textContainerInChart}>
        {bigFigure}
        <Typography variant="body2" color="textSecondary">
          {centerSubHeader}
        </Typography>
      </div>
    </div>
  )
}