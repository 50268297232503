import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 16,
    top: 16,
    padding: 0,
    color: "#999999",
    [theme.breakpoints.down("xs")]: {
      right: 8,
      top: 8,
    },
  },
  dialogTitle: {
    padding: 0,
    textAlign: "left",
  },
  paper: {
    borderRadius: "20px !important",
    padding: 20,
    textAlign: "center"
  },
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  }
}), { index: 1 });

export default useStyles;