import React, { useState } from 'react'
import { ReactComponent as Logo } from 'src/images/common/fanship_logo.svg';
import { ReactComponent as InternetIcon } from 'src/images/common/icon_internet.svg';
import { Container, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import useStyles from "src/styles/components/footer";
import { NavLink } from 'react-router-dom';
import LanguageDialog from 'src/components/header/LanguageDialog';

export default function Footer() {
  const classes = useStyles();
  const year = new Date().getFullYear();
  const { t, i18n } = useTranslation("footer");
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

  const onClickInternet = () => {
    setLanguageDialogOpen(true);
  }

  const closeLanguageDialog = () => {
    setLanguageDialogOpen(false);
  }

  return (
    <>
      <div className={classes.root}>
        <Container className={clsx(classes.flexContainer, classes.mainContainer)}>
          <div className={classes.flexContainer}>
            <div className={clsx(classes.flexContainer, classes.leftContainer)}>
              <Logo width={31} height={30} />
              <Typography variant="caption" className={classes.copyright}>
                {`© Fanship ${year}`}
              </Typography>
            </div>
            <div className={clsx(classes.flexContainer, classes.navigation)}>
              <Typography variant="caption">
                <NavLink to="/terms-conditions" className={classes.navLink}>
                  {t("terms-and-conditions")}
                </NavLink>
              </Typography>
              <div className={classes.dot} />
              <Typography variant="caption">
                <NavLink to="/platform-usage-guide" className={classes.navLink}>
                  {t("platform-usage-guide")}
                </NavLink>
              </Typography>
              <div className={classes.dot} />
              <Typography variant="caption">
                <NavLink to="/contact-us" className={classes.navLink}>
                  {t("contact-us")}
                </NavLink>
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.flexContainer, classes.languageContainer)}
            onClick={onClickInternet}
          >
            <div className={classes.internetIcon}>
              <InternetIcon width={14} height={14} />
            </div>
            <Typography variant="caption" className={classes.language}>
              {i18n.language === 'en' ? "English" : i18n.language === 'zh-CHT' ? "繁體中文" : "日本語"}
            </Typography>
          </div>
        </Container>
      </div>
      <LanguageDialog
        open={languageDialogOpen}
        handleClose={closeLanguageDialog}
      />
    </>
  );
}
