import { initialAuthState } from 'src/redux/auth/state';

export function authReducer(state = initialAuthState, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        userId: action.userId,
        email: action.email,
        displayName: action.displayName,
        fullName: action.fullName,
        countryCode: action.countryCode,
        avatar: action.avatar,
        language: action.language,
        isIdol: action.isIdol,
        stream_token: action.stream_token,
        access_token: action.access_token,
        msg: '',
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        isAuthenticated: false,
        msg: action.msg,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        userId: null,
        email: null,
        displayName: null,
        fullName: null,
        countryCode: null,
        avatar: null,
        language: null,
        isIdol: null,
        stream_token: null,
        access_token: null,
        msg: '',
      };

    case "UPDATE_PERSONAL_PROFILE":
      return {
        ...state,
        email: action.email,
        displayName: action.displayName,
        fullName: action.fullName,
        avatar: action.avatar,
      }

    case "UPDATE_LANGUAGE":
      return {
        ...state,
        language: action.language
      }

    case "UPDATE_CREATOR_PROFILE":
      return {
        ...state,
        fullName: action.fullName
      }
    default:
      return state;
  }
}