import axios from 'axios'

import _get from 'lodash/get'

import {
    SET_LOGGED_IN_USER,
} from './actionTypes'

import env from 'src/env';


const baseUrl = `https://${env.apiDomain}`



export const setLoggedInUser = payload => ({
    type: SET_LOGGED_IN_USER,
    payload,
})


export const getLoggedInUser = useId => async (dispatch, getState) => {
    try {

        const auth = _get(getState(), "auth")
        const userId = _get(auth, "userId")

        const { data } = await axios.get(
            `${baseUrl}/users/${userId}`,
            {
                headers: {
                    Authorization: `${userId}_${_get(auth, "access_token")}`
                }
            }
        )

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        dispatch(setLoggedInUser(_get(data, "data")))
        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}