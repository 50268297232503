import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as InternetIcon } from 'src/images/common/icon_internet.svg';
import defaultAvatar from 'src/images/common/default_avatar.png'
import SearchBar from 'src/components/header/SearchBar';
import clsx from "clsx";
import useStyles from "src/styles/components/header/header";
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PopUpMenu from 'src/components/header/PopUpMenu';
import LanguageDialog from 'src/components/header/LanguageDialog';
import { ReactComponent as FanshipLogo } from 'src/images/common/fanship_logo.svg';
import { ReactComponent as MenuIcon } from 'src/images/common/icon_sorting.svg'
import { ReactComponent as SearchIcon } from "src/images/common/icon_search.svg";
import { useTranslation } from 'react-i18next';
export default function Header({
  toggleSidebar,
}) {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false)
  const classes = useStyles({
    isMobileSearchOpen,
  });
  const { t } = useTranslation("header");

  // const fullName = useSelector((state) => state.auth.fullName);
  const displayName = useSelector((state) => state.auth.displayName);
  const avatar = useSelector((state) => state.auth.avatar);
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

  const onClickAvatar = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const onClickInternet = () => {
    setLanguageDialogOpen(true);
  }

  const closeLanguageDialog = () => {
    setLanguageDialogOpen(false);
  }
  
  return (
    <>
      <div className={clsx(classes.root, classes.flexContainer)}>
        <Hidden lgUp implementation="js" className={classes.hiddenIconsContainer}>
          <IconButton size="medium"
            className={classes.hideOnMobileSearch}
            onClick={toggleSidebar}
          >
            <MenuIcon className={clsx(classes.logo, classes.menuIcon)} />
          </IconButton>
          <NavLink to="/" className={classes.hideOnMobileSearch}>
            <FanshipLogo className={classes.logo} />
          </NavLink>
        </Hidden>

        <div className={clsx(classes.flexContainer, !isMobileSearchOpen && classes.searchBarContainer, isMobileSearchOpen && classes.mobileSearch)}>
          <SearchBar
            isMobileSearchOpen={isMobileSearchOpen}
            setIsMobileSearchOpen={setIsMobileSearchOpen}
          />
        </div>
        <div className={clsx(classes.flexContainer, classes.rightContainer, classes.hideOnMobileSearch)}>
          {!isMobileSearchOpen && <div
            className={clsx(classes.iconBubble)}
            onMouseDown={() => setIsMobileSearchOpen(true)}
          >
            <SearchIcon />
          </div>}
          <InternetIcon
            className={classes.internetIcon}
            onClick={onClickInternet}
          />
          <img
            src={avatar ? avatar : defaultAvatar}
            alt="avatar"
            className={classes.avatar}
            onClick={onClickAvatar}
          />
          <PopUpMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            // fullName={fullName}
            displayName={displayName}
            avatar={avatar ? avatar : defaultAvatar}
          />
        </div>
       
      </div>
      {
        languageDialogOpen &&
        <LanguageDialog
          open={languageDialogOpen}
          handleClose={closeLanguageDialog}
        />
      }

    </>
  );
}