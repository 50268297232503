import React from 'react'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "src/styles/components/infoDialog";

export default function InfoDialog({
  open,
  title,
  button,
  handleClose,
  children,
}) {
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paper }}
        scroll="body"
        maxWidth="md"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          {title}
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentRoot }}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div>{children}</div>
        </DialogContent>
        <DialogActions>
          {button}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
