import _get from 'lodash/get'
import _map from 'lodash/map'
import _slice from 'lodash/slice'

import {
    SET_POST_FEED,
    CLEAR_POST_FEED,
    SET_IS_FEED_LOADING,
    SET_POST_LIKED,
    UNSET_POST_LIKED,
    SET_POST_SAVED,
    SET_POST_UNSAVED,
    NEW_COMMENT_TO_POST_SUCCESS,
    REPLY_TO_COMMENT_FOR_POST_SUCCESS,
    SET_COMMENTS_LIST_FOR_POST,
    SET_COMMENT_TO_POST_LIKED,
    UNSET_COMMENT_TO_POST_LIKED,
    SET_REPLY_TO_COMMENT_LIKED,
    UNSET_REPLY_TO_COMMENT_LIKED,
    SET_FEWER_COMMENTS_FOR_POST,
    SET_IDOL_FOLLOWED,
    SET_IDOL_UNFOLLOWED,
  UNSET_REPLY_TO_COMMENT_DELETE,
  UNSET_REPLY_DELETE
} from './actionTypes'

import initialState from "./initialState"


const reduceLikePost = (state, postId, bool) => {
    const feed = _map(_get(state, "feed"), post => {
        // console.log("reduceLikePost post", post)
        if(_get(post, "postId") === postId) {
            return {
                ...post,
                userLiked: bool,
                likeCount: (_get(post, "likeCount") || 0) + (bool ? (+1) : (-1)),
            }
        }
        return post
    }) 
    return {
        ...state,
        feed,
    }
}

const reduceSavedPost = (state, postId, bool) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            return {
                ...post,
                userSaved: bool
            }
        }
        return post
    })
    return {
        ...state,
        feed,
    }
}

const reduceNewCommentToPost = (state, postId, comment) => {
    console.log("in reduceNewCommentToPost state", state, "postId", postId, "comment", comment)
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            console.log("in reduceNewCommentToPost feed map post", post)
            return {
                ...post,
                comments: [
                    comment, 
                    ..._get(post, "comments", []),
                ],
                commentCount: (_get(post, "commentCount") || 0) + 1
            }
        }
        return post
    })

    console.log("reduceNewCommentToPost feed", feed)
    return {
        ...state,
        feed,
    }
}

const reduceReplyToCommentForPost = (state, postId, commentId, reply) => {

    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            console.log("in reduceNewCommentToPost feed map post", post)
            return {
                ...post,
                comments: _map(_get(post, "comments"), comment => {
                    if(_get(comment, "commentId") === commentId) {
                        return {
                            ...comment,
                            childComments: [
                                reply,
                                ..._get(comment, "childComments", [])
                            ],
                            replyCount: (_get(comment, "replyCount") || 0) + 1
                        }
                    }
                    return comment
                }),
                commentCount: _get(post, "commentCount") + 1,
            }
        }
        return post
    }

    )
    return {
        ...state,
        feed,
    }
}

const reduceAllCommentsForPost = (state, postId, comments) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            // console.log("in reduceAllCommentsForPost feed map post", post)
            return {
                ...post,
                comments,
                // commentCount: _get(comments, "length"),
            }
        }
        return post
    })

    return {
        ...state,
        feed,
    }
}

const reduceFewerCommentsForPost = (state, postId) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            return {
                ...post,
                comments: _slice(
                    _get(post, "comments"),
                    0, 3,
                ),
            }
        }
        return post
    })
    return {
        ...state,
        feed,
    }
}

const reduceLikeCommentToPost = (state, postId, commentId, bool) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            post["comments"] = _map(_get(post, "comments"), comment => {
                if(_get(comment, "commentId") === commentId) {
                    return {
                        ...comment,
                        userLiked: bool,
                        likeCount: (_get(comment, "likeCount") || 0) + (bool ? (+1) : (-1)),
                    }
                }
                return comment
            })
        }
        return post
    }) 
    return {
        ...state,
        feed,
    }
}

const reduceLikeReplyToComment = (state, postId, parentCommentId, replyId, bool) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "postId") === postId) {
            return {
                ...post,
                comments: _map(_get(post, "comments"), comment => {
                    if(_get(comment, "commentId") === parentCommentId) {
                        comment["childComments"] = _map(_get(comment, "childComments"), reply => {
                            if(_get(reply, "commentId") === replyId) {
                                return {
                                    ...reply,
                                    userLiked: bool,
                                    likeCount: (_get(reply, "likeCount") || 0) + (bool ? (+1) : (-1)),
                                }
                            }
                            return reply
                        })
                    }
                    return comment
                }),
            }
        }
        return post
    }) 
    return {
        ...state,
        feed,
    }
}

export const reduceFollowedIdol = (state, idolId, bool) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "idol.userId") === idolId) {
            return {
                ...post,
                idol: {
                    ..._get(post, "idol"),
                    userFollowed: bool,
                }
            }
        }
        return post
    })

    return {
        ...state,
        feed,
    }
}

export const reduceDeleteComment = (state, commentId, bool) => {
    const feed = _map(_get(state, "feed"), post => {
        if(_get(post, "commentId") === commentId) {
            return {
                ...post,
                idol: {
                    ..._get(post, "idol"),
                    deleteComment: bool,
                }
            }
        }
        return post
    })

    return {
        ...state,
        feed,
    }
}


export const feedPostReducer = (state = initialState, action) => {
    console.log("feedPostReducer state", state.feed.reverse())
    switch(action.type) {
        case SET_POST_FEED: {
            // console.log(`_get(state, "feed").length` ,_get(state, "feed").length)
            console.log(`action.isDrag` , action.isDrag)
            return {
                ...state,
                feed: [
                    ..._get(state, "feed"),
                    ...action.feed,
                ],
                feedCount: _get(state, "feed").length + action.feed.length,
                isDragLoading: action.isDrag
            }
        }
           
        case CLEAR_POST_FEED:
            return {
                ...state,
                feed: [],
                feedCount:0,
                isDragLoading:false,
            }
        case SET_IS_FEED_LOADING:
            return {
                ...state,
                isFeedLoading: action.payload,
            }
        case SET_POST_LIKED:
            return reduceLikePost(state, action.postId, true)
        case UNSET_POST_LIKED:
            return reduceLikePost(state, action.postId, false)
        case SET_POST_SAVED:
            return reduceSavedPost(state, action.postId, true)
        case SET_POST_UNSAVED:
            return reduceSavedPost(state, action.postId, false)
        case NEW_COMMENT_TO_POST_SUCCESS:
            return reduceNewCommentToPost(state, action.postId, action.comment)
        case REPLY_TO_COMMENT_FOR_POST_SUCCESS:
            return reduceReplyToCommentForPost(state, action.postId, action.commentId, action.reply)
        case SET_COMMENTS_LIST_FOR_POST:
            return reduceAllCommentsForPost(state, action.postId, action.comments)
        case SET_FEWER_COMMENTS_FOR_POST:
            return reduceFewerCommentsForPost(state, action.postId)
        case SET_COMMENT_TO_POST_LIKED:
            return reduceLikeCommentToPost(state, action.postId, action.commentId, true)
        case UNSET_COMMENT_TO_POST_LIKED:
            return reduceLikeCommentToPost(state, action.postId, action.commentId, false)
        case SET_REPLY_TO_COMMENT_LIKED:
            return reduceLikeReplyToComment(state, action.postId, action.parentCommentId, action.replyId, true)
        case UNSET_REPLY_TO_COMMENT_LIKED:
            return reduceLikeReplyToComment(state, action.postId, action.parentCommentId, action.replyId, false)
        case SET_IDOL_FOLLOWED:
            return reduceFollowedIdol(state, action.idolId, true)
        case SET_IDOL_UNFOLLOWED:
            return reduceFollowedIdol(state, action.idolId, false)
            case UNSET_REPLY_TO_COMMENT_DELETE:
                return reduceDeleteComment(state,action.postId, action.parentCommentId, action.replyId, true)   
                case UNSET_REPLY_DELETE:
                    return reduceDeleteComment(state,action.postId, action.replyId, true)   
                     
        default:
            return state
    }
}

