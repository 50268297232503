import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    switchRoot: {
        width: 55,
        height: 34,
        padding: 0,
        margin: 0,
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            color: "#FFFFFF",
            "& + $track": {
                backgroundColor: theme.palette.grey[900],
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: theme.palette.grey[900],
            border: "6px solid #fff",
        },
    },
    switchThumb: {
        width: 32,
        height: 32,
    },
    track: {
        borderRadius: 34 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        boxSizing: "border-box",
        backgroundColor: theme.palette.grey[100],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
    switchIcon: {
        backgroundColor: "#FFFFFF",
        color: theme.palette.grey[900],
        borderRadius: 34 / 2,
        width: 32,
        height: 32,
    }
}));

export default useStyles;
