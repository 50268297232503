import { makeStyles } from "@material-ui/styles";
import { newWidth } from "../../../src/styles/components/screenStyle";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        marginTop: 16,
        gap: "0px 16px"
    },
    container: {
        flexGrow: 10,
        display: "flex",
    },
    spaceLeft: {
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            flexGrow: 2,
        }
    },
    main: {
        flexGrow: 1,
    },
    spaceRight: {
        minWidth: 290,
        position: 'relative',
        minHeight: 100,
    },
    rightCards: {
        maxWidth: 290,
        minWidth: 270,
        boxSizing: "border-box",
        position: "sticky",
        top: 84,
        maxHeight: "calc(100vh - 104px)",
        overflow: "auto"
    },
    avatarHolder: {
        display: 'flex',
        alignItems: 'center'
    },
    makeavatar: {
        borderRadius: '16px',
        width: 40,
        height: 40,
    },
    makeBtn: {
        display: 'flex',
        paddingLeft: '15px',
        alignItems: 'center',
        maxWidth: "100%",
        flex: 1,
        backgroundColor: '#F8F9FA',
        borderRadius: '50px',
        marginLeft: '20px',
        paddingLeft: '20px',
        padding: '8px 20px',
    },
    makepostbox: {
        // position: 'fixed',
        // left:'20px',
        // right:'auto',
        // maxWidth: 'calc(100vw - 330px)',
        // margin: 'auto',
        // top: '90px',
        // marginLeft:'20px',
        // marginRight:'20px',
        padding: '20px',
        // boxSizing: 'border-box',
        // width: '100%',
        zIndex: 99,
        borderRadius: '20px',
        boxShadow: '0px 0px 6px 0px #8d8d8d5c',
        backgroundColor: '#fff',
        [theme.breakpoints.up('lg')]: {
            width: newWidth(530),
        }
    },


}))

export default useStyles