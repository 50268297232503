import { language } from "./LanguageTranslate";


export function getMultiLanguageValue(value, code) {
  switch (code) {
    case "zh":
      return language.chinese[value];
      case "ja":
        return language.japanese[value];
      case "en":
        return language.english[value];
    default:
      return  language.english[value]
   
  }
}

