import React from 'react'
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText';

const FormField = ({ input, meta: { touched, error }, label, translatorJson, ...props }) => {
    const { t } = useTranslation(translatorJson);

    return input.value ? 
    <Button href={input.value} color="primary" variant="outlined" target="_blank" {...props}>{label}</Button>
    : (touched && error) ?
    <FormHelperText error={true} style={{padding: "0px 14px"}}>{t(error)}</FormHelperText>
    : null
}

export default FormField
