import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/changePassword';
import {
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import LoadingButton from 'src/components/LoadingButton';
import {
  checkIfPasswordLongEnough,
  checkIfPasswordContainNumberOrSymbol,
  checkSecurityLevelOfPassword
} from "src/lib/validators/password";
import ErrorHelperText from 'src/components/ErrorHelperText';
import env from 'src/env';
import { ReactComponent as IconVisibility } from "src/images/login-page/icon_visibility.svg";
import { ReactComponent as IconVisibilityOff } from "src/images/login-page/icon_visibility_off.svg";
import clsx from 'clsx';
import AccountFormHint from "src/components/AccountFormHint";

export default function ChangePassword() {
  const { t } = useTranslation("change-password");
  const { t: te } = useTranslation("error");
  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPwFieldVisible, setOldPwFieldVisible] = useState(false);
  const [pwFieldOneVisible, setPwFieldOneVisible] = useState(false);
  const [pwFieldTwoVisible, setPwFieldTwoVisible] = useState(false);
  const [submitTimes, setSubmitTimes] = useState(0);
  const [success, setSuccess] = useState(false);
  const [apiErrorCode, setApiErrorCode] = useState();
  const [isLoading, setIsLoading] = useState();
  let securityLevel = newPassword ? checkSecurityLevelOfPassword(newPassword) : null;

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitTimes(submitTimes + 1);
    if (
      !oldPassword ||
      !newPassword ||
      !confirmPassword ||
      securityLevel === 'low' ||
      !checkIfPasswordLongEnough(newPassword) ||
      !checkIfPasswordContainNumberOrSymbol(newPassword) ||
      newPassword !== confirmPassword
    ) {
      return;
    }

    setIsLoading(true);

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const res = await fetch(`https://${env.apiDomain}/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userId}_${token}`
      },
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword
      })
    });

    const result = await res.json();
    console.log('result: ', result);
    if (result.success) {
      setSuccess(true);
    } else {
      setApiErrorCode(result.code);
    }

    setIsLoading(false);
  }

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    if (apiErrorCode === 'WRONG_OLD_PASSWORD') {
      setApiErrorCode(null);
    }
  }

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  }

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  }

  function checkHintTypeBySecurityLevel(level) {
    switch (level) {
      case 'strong':
        return 'success';
      case 'moderate':
        return 'warning';
      case 'weak':
        return 'error';
      default:
        break
    }
  }

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.mainContainer}>
        <div className={classes.topContainer}>
          <Typography variant="h3" align="left">
            {t('header')}
          </Typography>
          {
            success &&
            <Typography variant="body1" className={classes.description}>
              {t('success-message')}
            </Typography>
          }
        </div>
        {
          !success &&
          <form
            className={classes.form}
            onSubmit={onSubmit}
          >
            <TextField
              fullWidth
              value={oldPassword}
              onChange={handleOldPasswordChange}
              label={t('old-password')}
              variant="outlined"
              className={clsx(classes.textField, classes.password)}
              InputProps={
                {
                  type: oldPwFieldVisible ? 'text' : 'password',
                  name: 'password',
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <IconButton
                        onClick={() => setOldPwFieldVisible(!oldPwFieldVisible)}
                      >
                        {oldPwFieldVisible ?
                          <IconVisibilityOff /> :
                          <IconVisibility />
                        }
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              }
              error={submitTimes > 0 && !oldPassword}
              helperText={
                submitTimes > 0 && !oldPassword &&
                <ErrorHelperText text={t('errors.old-password')} />
              }
            />
            <TextField
              fullWidth
              value={newPassword}
              onChange={handleNewPasswordChange}
              label={t('new-password')}
              variant="outlined"
              className={clsx(classes.textField, classes.password)}
              InputProps={
                {
                  type: pwFieldOneVisible ? 'text' : 'password',
                  name: 'password',
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <IconButton
                        onClick={() => setPwFieldOneVisible(!pwFieldOneVisible)}
                      >
                        {pwFieldOneVisible ?
                          <IconVisibilityOff /> :
                          <IconVisibility />
                        }
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              }
              error={submitTimes > 0 && !newPassword}
              helperText={
                submitTimes > 0 && !newPassword &&
                <ErrorHelperText text={t('errors.new-password')} />
              }
            />
            <TextField
              fullWidth
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              label={t('new-password')}
              variant="outlined"
              className={clsx(classes.confirmPassword, classes.password)}
              InputProps={
                {
                  type: pwFieldTwoVisible ? 'text' : 'password',
                  name: 'password',
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <IconButton
                        onClick={() => setPwFieldTwoVisible(!pwFieldTwoVisible)}
                      >
                        {pwFieldTwoVisible ?
                          <IconVisibilityOff /> :
                          <IconVisibility />
                        }
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              }
              error={submitTimes > 0 && !confirmPassword}
              helperText={
                submitTimes > 0 && !confirmPassword &&
                <ErrorHelperText text={t('errors.confirm-password')} />
              }
            />
            {
              newPassword &&
              <AccountFormHint
                type={checkHintTypeBySecurityLevel(securityLevel)}
                text={`${t('password-security-level')}${t(securityLevel)}`}
              />
            }
            {
              submitTimes > 0 && !checkIfPasswordLongEnough(newPassword) &&
              <AccountFormHint type="error" text={t('errors.too-short')} />
            }
            {
              submitTimes > 0 && !checkIfPasswordContainNumberOrSymbol(newPassword) &&
              <AccountFormHint type="error" text={t('errors.must-include-number-or-symbol')} />
            }
            {
              submitTimes > 0 && (newPassword !== confirmPassword) &&
              <AccountFormHint type="error" text={t('errors.passwords-not-match')} />
            }
            {
              apiErrorCode &&
              <AccountFormHint type="error" text={te(`code.${apiErrorCode}`)} />
            }
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              loadingProps={{
                size: 24
              }}
              isLoading={isLoading}
            >
              <Typography variant="body1">
                {t("save")}
              </Typography>
            </LoadingButton>
          </form>
        }
      </div>
    </div>
  )
}