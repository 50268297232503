import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import _get from 'lodash/get'

import { getUserById } from 'src/redux/account/actions';

import useStyles from 'src/styles/pages/personalProfile';
import clsx from "clsx";
import {
  CircularProgress
} from "@material-ui/core";
import AvatarCard from 'src/components/personal-profile/AvatarCard'
import ProfileCard from 'src/components/personal-profile/ProfileCard';
import SimpleTabs from 'src/components/personal-profile/SimpleTabs';
import RightCards from 'src/components/RightCards'
import Box from '@material-ui/core/Box'

function PersonalProfile({
  currentUserId,
  isIdol,
  avatar,
  fullName,
  displayName,
  getUserById,
}) {
  const [user, setUser] = useState();

  const classes = useStyles();

  console.log('user:', user);

  useEffect(() => {

    getUserById(currentUserId)
    .then(user => {
      setUser(user)
    })

  }, [currentUserId, getUserById])


  return (
    <div className={clsx(classes.mainContainer, !user && classes.loadingContainer)}>
      {
        user ?
          <>
            <Box className={classes.space} />
            
            <AvatarCard
              avatar={avatar}
              isIdol={isIdol}
              fullName={fullName}
              displayName={displayName}
              numberOfFans={user.numberOfFans ? user.numberOfFans : 0}
              numberOfSubscribers={user.numberOfSubscribers ? user.numberOfSubscribers : 0}
            />

            <div className={classes.middleColumn}>
              <ProfileCard
                fullName={fullName}
                displayName={displayName}
                joinYear={new Date(user.createdAt).getFullYear()}
                profile={user.profile}
              />
              <SimpleTabs />
            </div>

            
            <Box 
              // className={classes.rightCards}
            >
              <RightCards />
            </Box>

            <Box className={classes.space} />
          </> :
          <CircularProgress size={60} />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  currentUserId: _get(state, "auth.userId"),
  isIdol: _get(state, "auth.isIdol"),
  avatar: _get(state, "auth.avatar"),
  fullName: _get(state, "auth.fullName"),
  displayName: _get(state, "auth.displayName"),

})

const mapDispatchToProps = dispatch => ({
  getUserById: userId => dispatch(getUserById(userId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalProfile)