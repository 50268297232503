import React from 'react'
import useStyles from "src/styles/components/sidebar";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

export default function SubItem({
  text,
  extraClassName
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.subItem, extraClassName)}>
      <Typography variant="body1">
        {text}
      </Typography>
    </div>
  )
}