import { creatorOnboardingFormTabs } from 'src/pages/CreatorOnboardingForm'

export const initialState = {
    currentFormTab: creatorOnboardingFormTabs[0],
    isIdentityProofLoading: false,
    isProfileImageLoading: false,
    paidTiersList: [],
    isCroppedImageLoading: false,
    isTierFormOpen: false,
    editTierId: null,
    isRemoveTierDialogOpen: false,
    isPuttingCreatorOnboarding: false,
}