import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _slice from 'lodash/slice'
import _filter from 'lodash/filter'
import _trim from 'lodash/trim'

import { getMyMembershipList } from 'src/redux/account/actions'

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import Membership from './Membership'
import useStyles from 'src/styles/components/personal-profile/membership'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from "@material-ui/lab/Pagination";


const MembershipList = ({
    getMyMembershipList,
}) => {
    const [ membershipList, setMembershipList ] = useState(null) 
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("")
    
    const rowPerPage = 5;
    const startIndex = (page - 1) * rowPerPage;
    const endIndex = page * rowPerPage;
  

    useEffect(() => {
        getMyMembershipList().then(membershipList => {
            setMembershipList(membershipList)
        }).catch(err => {
            console.log(err)
            enqueueSnackbar("Something went wrong", { variant: "error", })
        })

        // eslint-disable-next-line
    }, [getMyMembershipList])

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, [])

    const handleSearch = useCallback(event => {
        setSearch(_get(event, "target.value"))
        setPage(1)
    }, [])

    const { enqueueSnackbar } = useSnackbar()

    const filteredList = _filter(
        membershipList,
        membership => {
            const regex = new RegExp(_trim(search), "i")
            return regex.test(_get(membership, "creatorName"))
        }
    )

    const { t } = useTranslation('personal-profile')

    const classes = useStyles()

    return membershipList ?
        <Box className={classes.list}>
            {
                _get(membershipList, "length") ? <TextField
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                        className: classes.searchInput,
                        classes: {
                            notchedOutline: classes.noBorder,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        )
                    }}
                    variant="outlined"
                    fullWidth={true}
                    placeholder={t("search-place-holder")}
                /> : null
            }
            {_map(
                _slice(filteredList, startIndex, endIndex),
                membership => <Membership key={_get(membership, "tierId")} membership={membership} />
            )}
            {_get(membershipList, "length") ? <Box className={classes.paginationContainer}>
              <Pagination
                className={classes.pagination}
                count={Math.ceil(_get(filteredList, "length") / rowPerPage)}
                siblingCount={1}
                onChange={handleChangePage}
                page={page}
              />
            </Box> : null}
        </Box>
        : <Box className={classes.progressHolder}>
            <CircularProgress />
        </Box>        
    
}

const mapDispatchToProps = dispatch => ({
    getMyMembershipList: () => dispatch(getMyMembershipList()),
})

export default connect(
    null,
    mapDispatchToProps,
)(MembershipList)
