import React, { useState, useEffect } from 'react';
import logo from "src/images/common/fanship_logo_with_brand.svg";
import { ReactComponent as HomeIcon } from "src/images/sidebar/icon_home.svg";
import { ReactComponent as ChatIcon } from "src/images/sidebar/icon_chat.svg";
import { ReactComponent as ActivityIcon } from "src/images/sidebar/icon_activity.svg";
import { ReactComponent as ArrowDownIcon } from "src/images/common/icon_arrow_down.svg"
import { ReactComponent as AccountIcon } from "src/images/sidebar/icon_account.svg";
import { ReactComponent as CreatorManagementIcon } from "src/images/sidebar/icon_creator_management.svg";
import { ReactComponent as SettingIcon } from "src/images/sidebar/icon_setting.svg";

import clsx from "clsx";
import useStyles from "src/styles/components/sidebar";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import Item from "src/components/sidebar/Item";
import SubItem from "src/components/sidebar/SubItem";
import SettingSidebar from "src/components/sidebar/SettingSidebar";
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _includes from 'lodash/includes';

export default function Sidebar({
  closeSidebar,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("sidebar");
  const [settingSidebarOpen, setSettingSidebarOpen] = useState(false);
  const [accordion1Expanded, setAccordion1Expanded] = useState(false);
  const [accordion2Expanded, setAccordion2Expanded] = useState(false);
  const isIdol = useSelector((state) => state.auth.isIdol);
  const currentPathname = useSelector((state) => state.router.location.pathname);

  const section1 = [
    {
      icon: <HomeIcon />,
      text: t('home'),
      href: '/home'
    },
    {
      icon: <ChatIcon />,
      text: t('chat'),
      href: '/chat'
    },
    {
      icon: <ActivityIcon />,
      text: t('activity'),
      href: '/activity'
    }
  ]

  const collapseSection1 = [
    {
      text: t('personal-profile'),
      href: '/personal-profile'
    },
    {
      text: t('tier-management'),
      href: '/tier-management'
    },
    {
      text: t('saved-posts'),
      href: '/saved-posts'
    }
  ]

  const collapseSection2 = [
    {
      text: t('creator-profile'),
      href: '/my-creator-profile'
    },
    {
      text: t('dashboard'),
      href: '/dashboard'
    }
  ]

  const handleClickSetting = () => {
    setSettingSidebarOpen(!settingSidebarOpen);
  }

  const handleChangeAccordion1 = () => {
    console.log('click');
    setAccordion1Expanded(!accordion1Expanded);
  }

  const handleChangeAccordion2 = () => {
    setAccordion2Expanded(!accordion2Expanded);
  }

  useEffect(() => {
    if (settingSidebarOpen) {
      setAccordion1Expanded(false);
      setAccordion2Expanded(false);
    }
  }, [settingSidebarOpen])


  return (
    <div className={clsx(classes.flexContainer, classes.sidebar, settingSidebarOpen && classes.expand)}>
      <div className={clsx(classes.root, settingSidebarOpen ? classes.narrowSidebar : classes.openSidebar)}>
        <div className={classes.mainContainer}>
          <div className={clsx(classes.logoContainer)}>
            <img
              src={logo}
              alt="fanship logo"
              className={classes.logo}
              onClick={() =>  {
                if (currentPathname !== "/home") {
                  history.push("/home")
                }
                return;
              }}
            />
          </div>

          {
            section1.map((item, index) => (
              <NavLink to={item.href}
                key={`item_${index}`}
                className={clsx(classes.navLink)}
              >
                <Item
                  icon={item.icon}
                  text={settingSidebarOpen ? '' : item.text}
                  extraClassName={currentPathname.includes(item.href) && classes.currentNavLink}
                  onClick={() => setSettingSidebarOpen(false)}
                />
              </NavLink>
            ))
          }

          <Accordion
            expanded={accordion1Expanded}
            onChange={handleChangeAccordion1}
            classes={{ root: classes.accordion }}>
            <AccordionSummary
              classes={{
                root: classes.accordionSummary,
                expandIcon: classes.accordionExpandIcon,
              }}
              expandIcon={settingSidebarOpen ? '' :
                <ArrowDownIcon className={classes.arrowDownIcon} />}
            >
              <Item
                icon={<AccountIcon />}
                text={settingSidebarOpen ? '' : t('account')}
                extraClassName={settingSidebarOpen ? '' : classes.accordionHeader}
                onClick={() => setSettingSidebarOpen(false)}
              />
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <div className={classes.subItemContainer}>
                {
                  collapseSection1.map((item, index) => (
                    <NavLink to={item.href}
                      key={`subitem_${index}`}
                      className={classes.navLink}
                    >
                      <SubItem
                        text={item.text}
                        extraClassName={currentPathname.includes(item.href) && classes.currentNavLink}
                      />
                    </NavLink>
                  ))
                }
              </div>
            </AccordionDetails>
          </Accordion>
          {
            isIdol &&
            <Accordion
              expanded={accordion2Expanded}
              onChange={handleChangeAccordion2}
              classes={{ root: classes.accordion }}>
              <AccordionSummary
                classes={{
                  root: classes.accordionSummary,
                  expandIcon: classes.accordionExpandIcon,
                }}
                expandIcon={settingSidebarOpen ? '' :
                  <ArrowDownIcon className={classes.arrowDownIcon} />}
              >
                <Item
                  icon={<CreatorManagementIcon />}
                  text={settingSidebarOpen ? '' : t('creator-management')}
                  extraClassName={settingSidebarOpen ? '' : classes.accordionHeader}
                  onClick={() => setSettingSidebarOpen(false)}
                />
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                <div className={classes.subItemContainer}>
                  {
                    collapseSection2.map((item, index) => (
                      <NavLink to={item.href}
                        key={`subitem_${index}`}
                        className={classes.navLink}
                      >
                        <SubItem
                          key={`subitem_${index}`}
                          text={item.text}
                        />
                      </NavLink>
                    ))
                  }
                </div>
              </AccordionDetails>
            </Accordion>
          }
          <div className={classes.bottomContainer}>
            <Item
              icon={<SettingIcon />}
              text={settingSidebarOpen ? '' : t('setting')}
              onClick={handleClickSetting}
            />
          </div>
        </div>

      </div>
      <SettingSidebar open={settingSidebarOpen} />
    </div>
  );
}