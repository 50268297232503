export const language={
    chinese : {
        
            "p1": "《私隱政策》",
            "p2": "您同意不得發佈任何有損及他人名譽之言論或行為；不得公佈可能侵犯他人隱私的信息; 不得違反任何其他用戶權利的信息; 不得侵犯任何一方專利、商標或版權的留言; 及機密和/或商業敏感的留言。本公司不會為任何錯誤、遺漏的留言負責，亦不會對因使用留言的資料而導致的損害承擔責任；您需承擔使用溝通服務時可能引致的風險；本公司不作任何形式的保證，關於您使用的溝通服務，或任何第三方使用的溝通服務；和本公司不會對任何因使用（或相關的）溝通服務所產生的任何損失或損害承擔責任。",
            "p3": "當您在本網站的公眾範圍發帖、參與討論或分享任何內容，您所提交的內容將會對公眾公開。為保障您的私隱，我們建議您在該等公眾範圍提交、分享或發佈任何個人資料或敏感資訊時（例如（但不限於）您的全名、地址、電話號碼），或任何可能使其他用戶或第三方找到您或取得您相關資料的其他資訊時，務必小心謹慎。您須為使用本平台、提交或發佈到本平台的所有內容、在您帳戶下發生的任何及所有活動負上全責，以及確保、保護及保衛您帳戶的保密性及安全。我們毋須對由於違反上述任何一項，或您使用及在本網站上參與活動，或您的帳戶被擅自使用而導致的任何申索、損失及損害承擔法律責任。    ",
            "p4": "一般而言本平台在您同意的情況下收集個人身分識別資料（例如您的姓名、電郵地址、聯絡地址、電話號碼、付款資料、出生日期等），其中包括（但不限於）登記加入我們的論壇、參加抽獎及完成調查時。您可以選擇不向我們提供若干個人資料，但我們因此或會無法處理您的申請，或您可能無法使用我們提供的多個特別功能，例如在我們的論壇上發帖。    ",
            "p5": "您不得在未經本公司書面同意之情況下，為自己或他人於本網站上進行營利行為或非營利行為之宣傳；您應擔保其所提出之關於特別資格之審查資料，其真實性、準確性與合法性，若因違反此項義務而致本公司受有損害，應負擔賠償責任。    ",
            "p6": "當使用本平台時，您必須同意及承擔以下責任：您不會非法或違法地使用本公司的平台，並只作個人，非商業用途；您不會使用任何軟件，器材或儀器去（或嘗試去）干預本平台的運作；如您透過本平台建立帳戶，或向本公司提交任何個人資料，您有責任保障帳戶安全（用戶名稱、密碼、或任何個人資料）及不向任何人士披露您的帳戶資料；您有責任保證資料準確性，並且時刻更新個人資料。如出現因爲您所提供的資料出錯而導致本公司提供的服務或內容出現差錯，本公司不會付上任何法律責任；您有責任檢查及確保於本公司的平台上列出的保險公司之產品或服務的適用性、實用性、質素、充足性及供應情況，切合您的個人用途。",
            "p7": "會員於註冊程序完成後，視為已授權本公司就其個人資料於本公司經營之必要範圍內，擁有使用之權利。爲了可以根據您的所需而提供合適的服務，我們有可能收集一些個人資料。同時，您已表示您已經閱讀和理解本私隱政策，並同意我們收集您的個人資料並將其用於本政策中所敘的所有目的及相關用途。我們將保留更新本私隱政策的權利。",
            "p8": "有關您在本隱私政策下之權利的任何查詢，或關於您私隱的任何其他事宜，可發送至  privacy@joinfanship.com。您有權要求查閱及／或更正我們持有的您的個人資料。如您希望提出相關要求，請發送電郵至  info@joinfanship.com聯絡我們，電郵標題請註明「個人資料查閱／更正要求」。若中、英文版本有歧義之處，概以中文版本為準。此隱私政策之最後更新日期為2021年5月21日。",
       
            // termsandcondition
            "t1":"條款及細則",
            "t2":"服務條款",
            "t3":"歡迎使用 Fanship!",
            "t4":"Fanship 開發的技術和服務讓用戶能夠彼此聯繫、建立社群以及拓展業務。您使用 Fanship和我們所提供的其他產品、功能、應用程式、服務、技術及軟體（以下簡稱「Fanship 產品」或「產品」）時，均受到本《服務條款》規範，但若我們明示另有適用條款（且本《服務條款》不適用）者，不在此限。這些產品皆由 Fanship, Inc 為您提供。",
            "t5":"我們不會對您使用 Fanship 或本條款涵蓋之其他產品或服務收取費用，但企業商家和組織會付費請我們向您顯示其商品和服務的廣告。只要使用我們的產品，即表示您同意我們向您顯示我們認為與您個人及興趣相關的廣告。我們會運用您的個人資料來決定該向您顯示哪些廣告。",
            "t6":"我們絕不會將您的個資販售給廣告商，且除非您授予我們特定權限，否則我們也不會與廣告商分享可識別您個人身分的資訊（例如姓名、電子郵件地址或其他聯絡資料）。然而廣告商可以告知我們想要顯示廣告的目標受眾類型，我們再針對可能會感興趣的對象顯示他們的廣告。我們為廣告商提供廣告成效報告，協助他們瞭解用戶與廣告內容的互動情形。請參考後續第 2 節瞭解詳情。",
            "t7":"我們的《資料政策》說明我們如何蒐集和使用您的個人資料來決定您看到的部分廣告內容，以及提供後續提及的所有服務。您也能隨時前往設定查看可用的隱私選項，瞭解我們如何使用您的資料。",
            "t8":"1. 我們提供的服務",
            "t9":"我們的使命是賦予人們打造社群的力量，攜手拉近世界距離。為了完成這項使命，我們提供多種產品和服務，詳述如下：",
            "t10":"讓您與您重視的用戶和組織建立聯繫：",
            "t11":"我們可協助您在所使用的各項 Fanship 產品中，搜尋您重視的用戶、社團、商家、組織等等，並與對方建立聯繫。我們會依據既有資料向您和其他用戶推薦內容，例如推薦您加入社團、參加活動、追蹤或傳送訊息給粉絲專頁、觀看節目，或是與某些用戶成為朋友。當人與人之間連結越緊密，社群就能日益壯大。若用戶都能與自己重視的對象、社團和組織交流聯繫，就表示我們的服務充分發揮效益。",
            "t12":"展現真我本色，與他人交流您重視的事物：",
            "t13":"在 Fanship，您可以藉由多種方式展現自我，與親朋好友和其他用戶交流您看重的事物。舉例來說，您可以透過各項 Fanship 產品分享近況更新、相片、影片和限時動態，或是傳送訊息給一位朋友或多位用戶、建立活動或社團、新增內容到個人檔案等等。我們也會開發並持續探索各種應用科技的新方式，讓用戶運用擴增實境和 360 度影片等新科技，在 Fanship 建立和分享更精彩生動的內容。",
            "t14":"協助您發掘可能感興趣的內容、商品和服務：",
            "t15":"許多企業商家和組織都在使用 Fanship 和其他 Fanship 產品，而我們會對您顯示他們的廣告、優惠和其他贊助內容，協助您探索他們提供的內容、商品及服務。您可於後續第 2 節取得更詳盡的說明。",
            "t16":"打擊有害行為，為社群用戶提供保護和支援：",
            "t17":"用戶必須享有安全的使用體驗，Fanship 社群才能日漸茁壯。為此，我們在世界各地設有專業團隊，並致力於開發先進的技術系統，用以偵測產品濫用情形、有害他人的行為，以及我們可以出手援助或保護社群的時機。如果發現此類內容或行為，我們將採取適當措施，例如提供協助、移除內容、移除或限制特定功能的使用權限、將帳號停權或聯絡執法機關。如果偵測到用戶使用我們任一項產品從事濫用或有害行為，我們會與其他 Fanship 旗下企業分享資料。",
            "t18":"運用並開發先進技術，為所有用戶提供安全實用的服務：",
            "t19":"我們運用和開發的先進技術包括人工智慧、機器學習系統和擴增實境等等，讓所有用戶都能安心使用我們的產品，不受身體條件或地理位置限制。舉例來說，此類技術能夠告訴視障用戶，透過 Fanship 或 Instagram 分享的相片或影片中包含哪些物品或人物。此外，我們打造出成熟的網路與通訊技術，協助更多用戶在資源不足的地區順利連上網路。我們更開發出自動化的系統，加強偵測濫用和危險活動並加以移除，避免社群用戶受害及產品信譽受損。",
            "t20":"致力研究提升服務品質的方法：",
            "t21":"為了開發、測試及改善我們的產品，我們致力於各種研究，例如透過問卷調查、測試新功能並排解問題，分析我們擁有的用戶資料，以便瞭解用戶使用我們產品的方式。我們的《資料政策》說明我們如何運用資料協助研究進行，以便開發和提升服務品質。",
            "t22":"為所有 Fanship 旗下企業產品提供流暢一致的使用體驗：",
            "t23":"我們的產品可協助您搜尋重要的用戶、社團、商家、組織等等，並與對方建立聯繫。我們的系統亦經過精心設計，讓您在使用不同的 Fanship 旗下企業產品時，都能獲得流暢一致的使用體驗。舉例來說，我們會使用您在 Fanship 與之互動過的用戶資料，讓您能輕鬆地在 Instagram 或 Messenger 和他們建立聯繫；您也可以透過 Messenger 與您在 Fanship 追蹤的商家溝通交流。",
            "t24":"為全球用戶提供服務：",
            "t25":"為了在全球各地提供服務，我們必須將內容和資料儲存並提供給我們在世界各地的資料中心和系統（包括您居住國家／地區以外之國家）。這些基礎設施可能由 Fanship, Inc.、Fanship Ireland Limited 或其旗下企業營運或控管。",
           
            //PlatformUsageGuideMobile
            "pf1":"平台使用指引",
            "pf2":"用戶每天都會使用 Fanship 分享生活體驗、與親友互動及建立社群。我們為超過二十億用戶提供服務，讓每個國家／地區、不同文化、使用數十種不同語言的用戶能在這裡自由表達自己的想法。",
            "pf3":"我們知道讓用戶在 Fanship 盡情溝通交流的重要性，也十分認真看待保護我們服務不受濫用行為侵擾的職責。因此，我們制定了一套《社群守則》，說明 Fanship 允許及禁止的內容與行為。我們參考社群提供的意見，和科技、公共安全、人權等各領域專家的建議，擬定了這些政策。為了確保每個人的聲音都受到重視，我們致力在草擬政策時考量不同的觀點和理念、聆聽各方意見，尤其是平常可能會被忽略的個人和社群。",
            "pf4":"我們制定《社群守則》，一直都是以打造自由表達的平台，讓用戶盡情發聲為目標。這一點從未改變，日後也不會改變。要建立社群和拉近世界距離，關鍵在於讓用戶分享多元意見、體驗、想法和資訊。我們希望用戶可以公開討論自己重視的議題，即使有人不認同或反對也不會阻礙用戶表達意見。在某些情況下，可喚起公共意識的內容可能違反《社群守則》，但只要具有報導價值及符合公眾利益，我們仍會允許發佈。做出此决定的前提是，我們會權衡確認公眾利益價值是否大於可能造成傷害的風險，同時也會依據 Fanship 企業人權政策之規定，參考國際人權標準來做出決定。因此，我們會考量所有用戶（包括新聞機構和個人用戶）發佈內容的報導價值。舉例來說，若公眾確有知的權利，我們允許詳實描寫戰爭或戰爭後果的內容。",
            "pf5":"真實性驗證：",
            "pf6":"我們希望確保用戶在 Fanship 看到的都是真實的內容。我們相信真實性有助打造更適合分享資訊的環境，這也正是為什麼我們不希望用戶利用 Fanship 來謊報身分或捏造職業。用戶安全：我們力求維護 Fanship 的安全環境。威嚇他人的言論可能會使對方覺得受到威脅、排擠或因此不敢表達意見，因此 Fanship 不允許出現這類內容。",
            "pf7":"隱私：",
            "pf8":"我們致力保護個人隱私和資訊。隱私受到保護，用戶才能自由地表達意見，也能更輕鬆地選擇在 Fanship 分享資訊和互動交流的方式和時機。",
            "pf9": "尊嚴無損：",
            "pf10":"我們相信每個人都擁有平等的尊嚴和權利，也期望用戶能尊重他人的尊嚴，不騷擾或貶低其他用戶。",
            "pf11":"《社群守則》保障世界各地的所有用戶，也適用於任何類別的內容。本守則會全面考量所有情況；舉例來說，即使發佈的內容並不構成仇恨內容，但如果違反其他政策，該內容仍然會遭移除。我們知道言論會因為用戶的本地社群、語言或背景而有不同的意思或影響。我們盡力考量到這些差異，同時在對用戶及其言論內容執行相關政策時，秉持一致且公平的原則。我們執行本守則的方式仰賴我們可取得的資訊。在某些情況下，這表示我們可能無法偵測出違反守則的內容和行為，而有些時候，執行規定可能僅限於我們已獲得額外資訊和背景資訊的情況。",
            "pf12":"用戶也可以針對可能違反《社群守則》的內容向我們提出檢舉，包括粉絲專頁、社團、個人檔案、個人的內容和留言。此外，我們也為用戶體驗提供不同的控制項，讓他們封鎖、取消追蹤或隱藏用戶和貼文。",
            "pf13":"違反 Fanship《社群守則》的後果，取決於情節輕重以及用戶過去的紀錄。舉例來說，對於初犯者我們可能只會給予警告，但如果持續違反規定，我們可能會限制其 Fanship 發文權限或停用其個人檔案。如果我們認為可能造成實際人身傷害，或對公共安全有直接威脅，我們也可能會通報執法機關處理。《社群守則》作為用戶的行為準則，列出了 Fanship 允許和禁止的行為，我們也要求 Fanship 社群的成員共同遵循本守則。"
      
        },
    japanese:{
        
            "p1": "プライバシーポリシー",
            "p2": "他人の評判を損なうような発言や行動を投稿したり、他人のプライバシーを侵害する可能性のある情報を投稿したり、他のユーザーの権利を侵害したり、特許を侵害するメッセージを投稿したりしないことに同意するものとします。いずれかの当事者の商標または著作権、および機密および/または商業的に機密性の高いメッセージ。当社は、メッセージの誤りや脱落について責任を負わず、メッセージ内の情報を使用することにより生じた損害についても責任を負いません。通信サービスを使用する際に発生する可能性のあるリスクを負担する必要があります。当社は責任を負いません。コミュニケーションサービス、または第三者が使用するコミュニケーションサービスの使用、およびコミュニケーションサービスの使用に起因する（または関連する）損失または損害について、当社は責任を負わないものとします。",
            "p3": "このウェブサイトのパブリックドメインで投稿、ディスカッションへの参加、またはコンテンツの共有を行うと、送信したコンテンツが一般に公開されます。プライバシーを保護するために、個人情報または機密情報（氏名、住所、電話番号など）またはその他の情報を送信、共有、または公開することをお勧めします。データに関するその他の情報を取得します。お客様は、プラットフォームの使用、プラットフォームに送信または投稿するすべてのコンテンツ、アカウントで発生するすべての活動、およびアカウントの機密性とセキュリティを確保、保護、および防御する責任を単独で負います。上記のいずれかの違反、またはこのウェブサイトへのあなたの使用と参加、またはあなたのアカウントが許可なく使用されたことに起因するいかなる請求、損失、損害についても、当社は責任を負わないものとします。",
            "p4": "通常、プラットフォームは、参加時の登録を含む（ただしこれらに限定されない）お客様の同意を得て、個人を特定できる情報（名前、電子メールアドレス、連絡先、電話番号、支払い情報、生年月日など）を収集します。フォーラムで、スイープステークを入力し、調査を完了します。特定の個人データを提供しないことを選択することもできますが、その結果、アプリケーションを処理できない場合や、フォーラムへの投稿など、当社が提供する多くの特別な機能を使用できない場合があります。",
            "p5": "会社の書面による同意なしに、このWebサイトで営利または非営利の行動を公表することは許可されていません。彼らが提供する特別な資格に関するレビュー情報の信憑性と正確性、および合法性を保証する必要があります。この義務の違反により会社が損害を被った場合、会社は補償の責任を負うものとします。",
            "p6": "このプラットフォームを使用する場合は、次の責任に同意し、負担する必要があります。会社のプラットフォームを違法または違法に使用せず、個人的な非営利目的でのみ使用します。ソフトウェア、機器、または機器を使用しない（または使用しようとしない） ）移動）このプラットフォームの運用を妨害する;このプラットフォームを介してアカウントを作成するか、会社に個人情報を送信する場合、アカウントのセキュリティ（ユーザー名、パスワード、または個人情報）を保護する責任がありますアカウントデータ;データを正確に保ち、​​個人データを最新の状態に保つのはあなたの責任です。あなたが提供する情報の誤りにより会社が提供するサービスまたはコンテンツに誤りがあった場合、会社は法的責任を負わないものとします。あなたは会社のプラットフォームにリストされている保険会社の製品をチェックおよび確認する責任があります。お客様の個人的な使用に対するサービスの適合性、有用性、品質、妥当性、および可用性。",
            "p7": "登録手続きが完了すると、会員は、会社の業務に必要な範囲内で個人データを使用する権利を会社に付与したものとみなされます。お客様のニーズに応じた適切なサービスを提供するために、当社はいくつかの個人データを収集する場合があります。同時に、あなたはこのプライバシーポリシーを読んで理解し、このポリシーに記載されているすべての目的および関連する目的での個人データの収集とその使用に同意することを示しました。当社は、このプライバシーポリシーを更新する権利を留保します。",
            "p8": "このプライバシーポリシーに基づくお客様の権利に関するお問い合わせ、またはお客様のプライバシーに関するその他の事項については、privacy   @ joinfanship.comまでお問い合わせください。お客様には、当社が保有するお客様の個人データへのアクセスおよび/または訂正を要求する権利があります。関連するリクエストをご希望の場合は、 info @ joinfanship.comに「個人データアクセス/修正リクエスト」という件名のメールを送信してご連絡ください  。中国語版と英語版の間に不一致がある場合は、中国語版が優先されます。このプライバシーポリシーの最終更新日は2021年5月21日です",
          
        // termsandcondition
        "t1":"規約と条件",
        "t2":"利用規約",
        "t3":"ようこそ Fanship!",
        "t4":"Fanship は、ユーザーが相互に接続し、コミュニティを構築し、ビジネスを成長させるためのテクノロジとサービスを開発しています。 Fanshipおよび当社が提供するその他の製品、機能、アプリケーション、サービス、テクノロジー、およびソフトウェア（以下、「Fanship製品」または「製品」と呼びます）の使用は、当社が別段の定めを明示しない限り、これらの利用規約に準拠します。 (そして、これらの利用規約は適用されません)。 これらの製品は、Fanship, Inc. によって提供されます。",
        "t5":"Fanshipまたは本規約の対象となるその他の製品またはサービスの使用に対して料金を請求することはありませんが、企業の商人や組織は、商品やサービスを宣伝するために料金を支払います. 当社の製品を使用することにより、お客様は、お客様の個人および興味に関連すると思われる広告を表示する場合があることに同意するものとします。 当社は、お客様の個人データを使用して、表示する広告を決定します。",
        "t6":"お客様の個人情報を広告主に販売することはありません。また、お客様が特定の許可を与えない限り、お客様を個人的に特定する情報 (名前、電子メール アドレス、その他の連絡先情報など) を広告主と共有することはありません)。 ただし、広告主は、広告を表示したいターゲットオーディエンスのタイプを私たちに伝えることができ、興味を持っている可能性のある人々に広告を表示します. ユーザーが広告コンテンツとどのようにやり取りしているかを理解するのに役立つ広告パフォーマンス レポートを広告主に提供します。 詳細については、次のセクション 2 を参照してください。",
        "t7":"当社のデータ ポリシーでは、お客様が表示する一部の広告のコンテンツを決定し、後述するすべてのサービスを提供するために、お客様の個人データをどのように収集および使用するかについて説明しています。 また、いつでも [設定] にアクセスして、利用可能なプライバシー オプションを確認し、データの使用方法を確認することもできます。",
        "t8":"1. 私たちが提供するサービス",
        "t9":"私たちの使命は、人々がコミュニティを構築し、世界をより緊密にする力を与えることです。 この使命を達成するために、以下に詳述するさまざまな製品とサービスを提供しています。",
        "t10":"大切なユーザーや組織とつながることができます:",
        "t11":"あなたが使用しているさまざまなFanship製品で、大切なユーザー、社会、企業、組織などを見つけてつながることができます. 既存のデータを使用して、クラブへの参加、イベントへの参加、ファンページのフォローまたはメッセージの送信、番組の視聴、特定のユーザーとの友達になることなどを推奨するなど、あなたや他のユーザーにコンテンツを推奨します。 人と人のつながりが強ければ強いほど、コミュニティは強くなります。 ユーザーが大切にしている対象、社会、組織とコミュニケーションをとることができれば、それは私たちのサービスが十分に効果的であることを意味します。",
        "t12":"本当の自分を見せて、あなたが大切にしていることを他の人と伝えましょう:",
        "t13":"Fanship では、自分自身を表現し、自分、友人、家族、および他のユーザーにとって重要なことを伝える方法がたくさんあります。 たとえば、さまざまな Fanship 製品を通じて最近の更新、写真、ビデオ、タイムラプスの更新を共有したり、友人や複数のユーザーにメッセージを送信したり、イベントやソーシャルを作成したり、コンテンツをプロフィールに追加したりできます。 また、ユーザーが拡張現実や 360 度ビデオなどの新しいテクノロジーを使用して、よりエキサイティングなコンテンツを作成し、Fanship で共有できるように、テクノロジーを適用する新しい方法を開発し、探求し続けます。",
        "t14":"お客様が興味を持ちそうなコンテンツ、商品、およびサービスを見つけやすくするために、次のことを行います。",
        "t15":"Fanship およびその他の Fanship 製品は、多くの企業、企業、および組織によって使用されており、提供するコンテンツ、商品、およびサービスを発見するのに役立つように、それらの広告、オファー、およびその他のスポンサー コンテンツを表示します。 後続のセクション 2 で、より詳細な手順を取得できます。",
        "t16":"有害な行動と闘い、コミュニティ ユーザーの保護とサポートを提供します。",
        "t17":"Fanship コミュニティが繁栄するためには、ユーザーが安全なエクスペリエンスを提供する必要があります。 そのために、私たちは世界中に専任チームを配置し、製品の乱用、他者に有害な行動、およびコミュニティを支援または保護できる時期を検出するための高度な技術システムの開発に取り組んでいます。 そのようなコンテンツや行動に気付いた場合、支援の提供、コンテンツの削除、特定の機能へのアクセスの削除または制限、アカウントの一時停止、法執行機関への連絡など、適切な措置を講じます。 ユーザーが当社の製品のいずれかを使用して虐待的または有害な行動に関与していることが検出された場合、当社は他の Fanship 所有のビジネスとデータを共有します。",
        "t18":"すべてのユーザーに安全で実用的なサービスを提供するために、高度な技術を利用および開発します。",
        "t19":"人工知能、機械学習システム、拡張現実など、私たちが使用および開発している高度な技術は、身体の状態や地理的な場所に関係なく、すべてのユーザーが安心して当社の製品を使用できるようにするためのものです。 たとえば、このようなテクノロジーは、Fanship や Instagram で共有されている写真やビデオに含まれているオブジェクトや人物を視覚障害のあるユーザーに伝えることができます。 さらに、十分なリソースのない地域でより多くのユーザーがスムーズにインターネットに接続できるように、成熟したネットワークおよび通信テクノロジを開発しました。 また、虐待的で危険な活動の検出と削除を強化する自動システムを開発し、コミュニティ ユーザーの被害と製品の評判の低下を防ぎます。",
        "t20":"サービス品質を向上させる方法を研究することをお約束します。",
        "t21":"製品を開発、テスト、改善するために、調査、新機能のテスト、問題のトラブルシューティングを通じて、ユーザーが製品をどのように使用しているかを理解するために、ユーザーデータを分析するなど、さまざまな調査に取り組んでいます。 当社のデータポリシーでは、サービスの品質を開発および改善するために、データを使用して調査を促進する方法について説明しています。",
        "t22":"すべての Fanship 法人向け製品でスムーズで一貫したエクスペリエンスを提供します。",
        "t23":"当社の製品は、重要なユーザー、社会、企業、組織などを見つけてつながるのに役立ちます。 私たちのシステムは、Fanshipのさまざまな企業向け製品でスムーズで一貫した体験を提供するように慎重に設計されています. たとえば、Fanship でやり取りしたユーザーのプロファイルを使用して、Instagram や Messenger で簡単に接続できるようにしたり、Fanship でフォローしている企業と Messenger を介して通信したりできます。",
        "t24":"グローバルユーザーへのサービス:",
        "t25":"世界中でサービスを提供するために、コンテンツとデータを保存し、世界中のデータセンターとシステムに提供する必要があります (お客様の居住国以外の国を含む)。 これらのインフラストラクチャは、Fanship, Inc.、Fanship Ireland Limited、またはその子会社によって運営または管理される場合があります。",
  
          //PlatformUsageGuideMobile
          "pf1":"プラットフォームの使用ガイドライン",
          "pf2":"ユーザーは毎日 Fanship を使用して、人生経験を共有し、友人や家族と交流し、コミュニティを構築しています。 私たちは 20 億人以上のユーザーにサービスを提供し、あらゆる国、文化、数十の言語のユーザーに自分自身を表現する自由を提供しています。",
          "pf3":"私たちは、Fanship でユーザーとのつながりを保つことがいかに重要であるかを理解しており、私たちのサービスを悪用から保護する責任を非常に真剣に受け止めています。 そのため、Fanship で許可されていることと許可されていないことを説明する一連のコミュニティ ガイドラインを作成しました。 これらのポリシーは、コミュニティからの意見と、技術、公共の安全、人権などの分野の専門家からのアドバイスを基に作成されました。 すべての人の声が確実に聞こえるようにするため、ポリシーの起草時にさまざまな視点やアイデアを検討し、すべての人、特に通常見過ごされがちな個人やコミュニティの声に耳を傾けることに取り組んでいます。",
          "pf4":"私たちは、自由な表現のプラットフォームを作り、ユーザーが自由に発言できるようにすることを目的として、「コミュニティ コード」を策定します。 これは今までも、これからも変わることはありません。 コミュニティを構築し、世界を近づける鍵は、ユーザーが多様な意見、経験、アイデア、情報を共有できるようにすることです。 私たちは、ユーザーが価値のある問題について率直に議論できることを願っています。誰かが反対したり、反対したりしても、ユーザーが意見を表明することを妨げることはありません。 場合によっては、啓発コンテンツがコミュニティ ガイドラインに違反している可能性がありますが、報道価値があり、公共の利益にかなうものである限り、許可されます。 この決定は、公益の価値が危害の可能性を上回るかどうかを検討し、ファンシップの企業人権方針に従って国際的な人権基準を参照して決定することを前提としています。 そのため、報道機関や個人ユーザーを含むすべてのユーザーが投稿したコンテンツの報道価値を考慮しています。 たとえば、戦争やその結果についての詳細な説明は、一般の人々が知る権利を持っている場合に許可されます。",
          "pf5":"真正性の検証:",
          "pf6":"ファンシップでユーザーが見るものが本物であることを確認したいと考えています。 私たちは、信憑性が情報を共有するためのより良い環境を作るのに役立つと信じています. ユーザーの安全: Fanshipでは安全な環境を維持するよう努めています。 威圧的なコメントは、彼らに威圧感を与えたり、疎外されたり、自分の意見を表明することを思いとどまらせたりする可能性があるため、Fanship はそのようなコンテンツを許可しません。",
          "pf7":"プライバシー：",
          "pf8":"私たちは、個人のプライバシーと情報を保護することをお約束します。 プライバシーが保護され、ユーザーは自由に意見を表明でき、Fanship で情報を共有し、やり取りする方法とタイミングを簡単に選択できます。",
          "pf9": "尊厳を失うことはありません:",
          "pf10":"私たちは、誰もが平等な尊厳と権利を持っていると信じており、ユーザーが他のユーザーの尊厳を尊重し、他のユーザーに嫌がらせや侮辱をしないことを期待しています.",
          "pf11":"コミュニティ コードは、世界中のすべてのユーザーを保護し、あらゆる種類のコンテンツに適用されます。 このコードはすべての状況を考慮に入れています。たとえば、投稿がヘイト コンテンツを構成していなくても、他のポリシーに違反している場合は削除されます。 私たちは、ユーザーの地域社会、言語、またはバックグラウンドに基づいて、スピーチが異なる意味や影響を与える可能性があることを理解しています. ユーザーとそのコンテンツに関するポリシーへの一貫した公正なアプローチを維持しながら、これらの違いを考慮に入れるよう最善を尽くしています。 この規範を実施する方法は、入手可能な情報に依存しています。 場合によっては、これは、規範に違反するコンテンツや行動を検出できないことを意味する場合もあれば、取得した追加情報や背景情報に限定して執行が行われる場合もあります。",
          "pf12":"ユーザーは、ファン ページ、社会、プロフィール、個人的なコンテンツ、コメントなど、コミュニティ ガイドラインに違反する可能性のあるコンテンツを報告することもできます。 さらに、ユーザー エクスペリエンスのためのさまざまなコントロールを提供し、ユーザーや投稿をブロック、追跡解除、または非表示にすることができます。",
          "pf13":"Fanship のコミュニティ ガイドラインに違反した場合の結果は、状況の重大度とユーザーの過去の記録によって異なります。 たとえば、初犯の場合は警告のみが与えられる場合がありますが、ルールに違反し続ける場合は、ファンシップの投稿権限を制限したり、プロフィールを無効にしたりすることがあります. また、実際の人身傷害が発生した可能性がある、または公共の安全に直接的な脅威があると思われる場合は、法執行機関に通知する場合があります。 コミュニティ規範は、Fanship で許可されていることと許可されていないことを概説するユーザーの行動規範であり、Fanship コミュニティのメンバーには、この規範を遵守するようお願いしています。"
    
    },
    english:{
        
            "p1": "Privacy Policy",
            "p2": "You agree not to post any remarks or actions that are detrimental to the reputation of others; not to post information that may infringe on the privacy of others; not to violate the rights of any other user; not to post messages that infringe the patents, trademarks or copyrights of any party; and confidential and/or Commercially sensitive message. The company will not be responsible for any errors or omissions in the message, nor will it be liable for damages caused by using the information in the message; you need to bear the risks that may arise when using the communication service; the company does not make any form of guarantee. the Communication Services you use, or the Communication Services used by any third party; and the Company shall not be liable for any loss or damage arising from the use of (or related to) Communication Services.",
            "p3": "When you post, participate in discussions or share any content in the public domain of this website, the content you submit will be made available to the public. To protect your privacy, we recommend that you submit, share or publish any personal data or sensitive information (such as (but not limited to) your full name, address, phone number) or any other Use caution when users or third parties find you or obtain other information about your data. You are solely responsible for your use of the Platform, all content you submit or post to the Platform, any and all activities that occur under your account, and for ensuring, protecting and safeguarding the confidentiality and security of your account. We shall not be liable for any claims, losses and damages arising out of a breach of any of the above, or your use and participation in this website, or your account being used without authorization.",
            "p4": "Generally, the Platform collects personally identifiable information (such as your name, email address, contact address, telephone number, payment information, date of birth, etc.) with your consent, including (but not limited to) registering to join our When participating in forums, entering sweepstakes, and completing surveys. You may choose not to provide us with certain personal data, but as a result we may not be able to process your application, or you may not be able to use many of the special features we offer, such as posting on our forums.",
            "p5": "You are not allowed to publicize profit-making or non-profit behaviors on this website for yourself or others without the written consent of the company; you should guarantee the authenticity and accuracy of the review information on special qualifications provided by them. and legality, if the company suffers damage due to breach of this obligation, it shall be liable for compensation.",
            "p6": "When using this platform, you must agree and undertake the following responsibilities: you will not use the company's platform illegally or illegally, and only for personal, non-commercial use; you will not use any software, equipment or instruments to (or attempt to) Go) to interfere with the operation of this platform; if you create an account through this platform, or submit any personal information to the company, you are responsible for protecting the security of your account (username, password, or any personal information) and not to disclose your information to anyone. Account data; it is your responsibility to keep your data accurate and to keep your personal data up to date. If there is an error in the services or content provided by the company due to errors in the information you provide, the company will not pay any legal responsibility; you are responsible for checking and ensuring the insurance company products listed on the company's platform. or the suitability, usefulness, quality, adequacy and availability of the Services for your personal use.",
            "p7": "After the registration process is completed, members are deemed to have authorized the company to have the right to use their personal data within the necessary scope of the company's operations. In order to provide suitable services according to your needs, we may collect some personal data. At the same time, you have indicated that you have read and understood this Privacy Policy and agree to our collection of your personal data and its use for all purposes and related purposes described in this Policy. We reserve the right to update this Privacy Policy.",
            "p8": "Any inquiries regarding your rights under this Privacy Policy, or any other matters regarding your privacy, may be directed to   privacy@joinfanship.com . You have the right to request access to and/or correction of your personal data held by us. If you wish to make a relevant request, please contact us by sending an email to   info@joinfanship.com with the subject line 'Personal Data Access/Correction Request'.If there is any discrepancy between the Chinese and English versions, the Chinese version shall prevail.This Privacy Policy was last updated on May 21, 2021.",
            // termsandcondition
            "t1":"Terms and Conditions",
            "t2":"Terms of Service",
            "t3":"Welcome Fanship!",
            "t4":"Fanship develops technologies and services that allow users to connect with each other, build communities, and grow their businesses. Your use of Fanship and other products, features, applications, services, technologies and software provided by us (hereinafter referred to as `Fanship Products` or `Products`) are governed by these Terms of Service, unless we expressly state otherwise The terms apply (and these Terms of Service do not apply). These products are brought to you by Fanship, Inc.",
            "t5":"We will not charge you a fee for using Fanship or other products or services covered by these Terms, but corporate merchants and organizations will pay us to advertise their goods and services to you. By using our products, you agree that we may show you advertisements that we believe are relevant to your individual and interests. We use your personal data to decide which advertisements to show you.",
            "t6":"We will never sell your personal information to advertisers, and we will not share information that personally identifies you (such as name, email address or other contact information) with advertisers unless you have given us specific permissions ). Advertisers, however, can tell us the type of target audience we want to show their ads to, and we show their ads to those who might be interested. We provide advertisers with ad performance reports to help them understand how users interact with ad content. Please refer to the subsequent Section 2 for details.",
            "t7":"Our Data Policy describes how we collect and use your personal data to determine the content of some of the advertisements you see and to provide all the services mentioned later. You can also go to Settings at any time to see the available privacy options and learn how we use your data.",
            "t8":"1. Services we provide",
            "t9":"Our mission is to empower people to build communities and bring the world closer together. To accomplish this mission, we offer a variety of products and services, which are detailed below:",
            "t10":"Allows you to connect with the users and organizations you value:",
            "t11":"We can help you find and connect with your valued users, societies, businesses, organizations, and more in the various Fanship products you use. We use existing data to recommend content to you and other users, such as recommending you to join a club, attend an event, follow or message a fan page, watch a show, or befriend certain users. The stronger the connection between people, the stronger the community can be. If users can communicate with the objects, societies and organizations they value, it means that our services are fully effective.",
            "t12":"Show your true self and communicate with others what you value:",
            "t13":"At Fanship, there are many ways you can express yourself and communicate what matters to you, friends, family, and other users. For example, you can share recent updates, photos, videos, and time-lapse updates through various Fanship products, or send messages to a friend or multiple users, create events or societies, add content to your profile, and more. We will also develop and continue to explore new ways to apply technology, allowing users to use new technologies such as augmented reality and 360-degree videos to create and share more exciting content on Fanship.",
            "t14":"To assist you in discovering content, goods and services that may be of interest to you:",
            "t15":"Fanship and other Fanship products are used by many businesses, businesses and organizations, and we show you their ads, offers, and other sponsored content to help you discover the content, goods, and services they offer. You can get more detailed instructions in the subsequent Section 2.",
            "t16":"Combat harmful behavior and provide protection and support for community users:",
            "t17":"Users must have a secure experience for the Fanship community to thrive. To that end, we have dedicated teams around the world and are committed to developing advanced technology systems to detect product abuse, behavior that is harmful to others, and when we can help or protect our communities. If we become aware of such content or behavior, we will take appropriate action, such as providing assistance, removing content, removing or restricting access to certain features, suspending accounts, or contacting law enforcement. If a user is detected to be engaged in abusive or harmful behavior using any of our products, we share data with other Fanship-owned businesses.",
            "t18":"Utilize and develop advanced technology to provide safe and practical services to all users:",
            "t19":"The advanced technologies we use and develop include artificial intelligence, machine learning systems, augmented reality, and more, so that all users can use our products with peace of mind, regardless of physical condition or geographic location. For example, such technology can tell visually impaired users which objects or people are included in photos or videos shared through Fanship or Instagram. In addition, we have created mature network and communication technologies to help more users connect to the Internet smoothly in under-resourced areas. We have also developed automated systems to enhance the detection and removal of abusive and dangerous activities, preventing community user harm and product reputation damage.",
            "t20":"Committed to researching ways to improve service quality:",
            "t21":"In order to develop, test and improve our products, we engage in various research, such as analyzing the user data we have to understand how users use our products, through surveys, testing new features and troubleshooting problems. Our Data Policy describes how we use data to facilitate research in order to develop and improve the quality of our services.",
            "t22":"Provides a smooth and consistent experience across all Fanship corporate products:",
            "t23":"Our products help you find and connect with important users, societies, businesses, organizations, and more. Our system has also been carefully designed to provide you with a smooth and consistent experience across different Fanship corporate products. For example, we use the profiles of users you've interacted with on Fanship so you can easily connect with them on Instagram or Messenger; you can also communicate with businesses you're following on Fanship through Messenger.",
            "t24":"Serving global users:",
            "t25":"In order to provide services around the world, we must store and provide content and data to our data centers and systems around the world (including countries other than your country of residence). These infrastructures may be operated or controlled by Fanship, Inc., Fanship Ireland Limited or its subsidiaries.",
      
              //PlatformUsageGuideMobile
              "pf1":"Platform usage guidelines",
              "pf2":"Users use Fanship every day to share life experiences, interact with friends and family, and build communities. We serve more than two billion users, giving users in every country, culture, and dozens of languages the freedom to express themselves.",
              "pf3":"We know how important it is to keep our users connected at Fanship and take our responsibility to protect our services from abuse very seriously. That's why we've created a set of Community Guidelines that describe what's and isn't allowed in Fanship. We developed these policies with input from the community and advice from experts in fields such as technology, public safety, human rights, and more. To ensure that everyone’s voice is heard, we are committed to considering different viewpoints and ideas when drafting our policies, listening to everyone, especially individuals and communities that might ordinarily be overlooked.",
              "pf4":"We formulate the `Community Code`` with the goal of creating a platform for free expression and allowing users to speak freely. This has never changed and will not change in the future. The key to building communities and bringing the world closer is to allow users to share diverse opinions, experiences, ideas and information. We hope that users can openly discuss issues they value, even if someone disagrees or disagrees, it will not hinder users from expressing their opinions. In some cases, public awareness content may violate the Community Guidelines, but we will allow it as long as it is newsworthy and in the public interest. The premise of making this decision is that we will weigh whether the value of the public interest outweighs the risk of possible harm, and will also make a decision with reference to international human rights standards in accordance with Fanship's corporate human rights policy. Therefore, we consider the reporting value of content posted by all users, including news organizations and individual users. For example, we allow detailed descriptions of war or its consequences if the public has a right to know.",
              "pf5":"Authenticity verification:",
              "pf6":"We want to make sure that what users see on Fanship is the real thing. We believe authenticity helps create a better environment for sharing information, which is why we don't want users to use Fanship to lie about their identities or make up their careers. User Safety: We strive to maintain a safe environment at Fanship. Intimidating comments may make them feel intimidated, marginalized, or discouraged from expressing their opinions, so Fanship does not allow such content.",
              "pf7":"privacy:",
              "pf8":"We are committed to protecting personal privacy and information. Privacy is protected, users can express their opinions freely, and it is easier to choose how and when to share information and interact on Fanship.",
              "pf9": "No dignity lost:",
              "pf10":"We believe that everyone has equal dignity and rights, and expect users to respect the dignity of others and not harass or demean other users.",
              "pf11":"The Community Code protects all users around the world and applies to any type of content. This code takes all circumstances into consideration; for example, even if a post does not constitute hate content, it will still be removed if it violates other policies. We understand that speech can have a different meaning or impact based on a user's local community, language or background. We do our best to take these differences into account while maintaining a consistent and fair approach to our policies regarding users and their content. The way we enforce this Code relies on the information available to us. In some cases, this means that we may not be able to detect content and conduct that violates the Code, and in other cases, enforcement may be limited to additional information and background information we have obtained.",
              "pf12":"Users can also report content that may violate the Community Guidelines, including fan pages, societies, profiles, personal content and comments. Additionally, we provide different controls for the user experience, allowing them to block, untrack or hide users and posts.",
              "pf13":"Consequences for violating Fanship's Community Guidelines depend on the severity of the circumstances and the user's past record. For example, first-time offenders may only be given a warning, but if they continue to violate the rules, we may limit their Fanship posting rights or disable their profile. We may also notify law enforcement agencies if we believe that actual personal injury may be caused, or there is a direct threat to public safety. The Community Code is a user code of conduct that outlines what is and isn't allowed at Fanship, and we ask members of the Fanship community to abide by this code."
        
          
    }
}