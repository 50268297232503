import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
    width: 'fit-content',
    zIndex: 0
  },
  section: {
    marginBottom: 40,
  },
  subHeader: {
    marginBottom: 20
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  doughnutChart: {
    position: 'relative',
    margin: '0px -60px',
    zIndex: 1
  },
  textContainerInChart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1
  },
  paperRoot: {
    borderRadius: 20,
    padding: 20,
    width: 370,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column'
  },
  rightMargin: {
    marginRight: 16
  },
  chartHeading: {
    marginBottom: 20
  },
  legendSection: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: -20,
    paddingLeft: 20
  },
  legend: {
    width: '50%',
    marginBottom: 20,
    paddingLeft: 10,
    boxSizing: 'border-box'
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 100,
    marginRight: 5
  },
  textBold: {
    fontWeight: 'bold'
  },
  tableRoot: {
    borderRadius: 20,
    '& .MuiTableCell-root': {
      fontSize: 16
    },
    minWidth: 800,
    maxWidth: 1200
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '10px 0'
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      fontSize: 14,
      color: theme.palette.grey[400],
    },
    "& .Mui-selected": {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "transparent",
    },
    "& svg": {
      fill: theme.palette.primary.main,
    },
    "& svg path": {
      stroke: theme.palette.primary.main,
    },
  },
  filterButton: {
    borderRadius: 100,
    backgroundColor: theme.palette.grey[100],
    padding: '5px 15px',
    cursor: 'pointer',
    width: 140,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  otherStatSectionTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    marginRight: 10
  },
  filterText: {
    flex: 1
  },
  arrowDownIcon: {
    marginLeft: 10,
    '& path': {
      stroke: theme.palette.grey[600]
    }
  },
  menuPaper: {
    borderRadius: 20,
    padding: 0,
    width: 'fit-content',
    minWidth: 140,
    '& $menuItem:last-child': {
      borderBottom: 'none'
    }
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: '10px 15px',
    "&.Mui-focusVisible": {
      backgroundColor: "white !important",
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  loadingContainer: {
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accountBalanceCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 20,
    flex: 1
  },
  button: {
    borderRadius: 100
  },
  mainStatLastColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  nextPaymentCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
  nextPaymentCardContent: {
    flex: 1
  },
  mainStatRow: {
    display: 'flex'
  }
}), { index: 1 });

export default useStyles;