import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 100,
    width: 'fit-content',
    padding: '2px 8px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto'
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 100,
    marginRight: 5
  },
  green: {
    backgroundColor: 'rgba(48, 209, 88, 0.15)',
    color: theme.palette.success.main
  },
  greenDot: {
    backgroundColor: theme.palette.success.main
  },
  grey: {
    backgroundColor: 'rgba(173, 181, 189, 0.15)',
    color: theme.palette.grey[500]
  },
  greyDot: {
    backgroundColor: theme.palette.grey[500]
  },
  textBold: {
    fontWeight: 'bold'
  }
}), { index: 1 });

export default useStyles;