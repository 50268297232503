import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
    width: 'fit-content',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
  sortingButton: {
    borderRadius: 100,
    backgroundColor: theme.palette.grey[100],
    padding: '5px 15px',
    cursor: 'pointer',
    width: 190,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  sortingIcon: {
    marginRight: 10
  },
  sortingText: {
    flex: 1
  },
  arrowDownIcon: {
    marginLeft: 10,
    '& path': {
      stroke: theme.palette.grey[600]
    }
  },
  tableRoot: {
    borderRadius: 20,
    '& .MuiTableCell-root': {
      fontSize: 16
    },
    minWidth: 1000
  },
  textBold: {
    fontWeight: 'bold'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  columnHeader: {
    flex: 1
  },
  filterIcon: {
    cursor: 'pointer'
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '10px 0'
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      fontSize: 14,
      color: theme.palette.grey[400],
    },
    "& .Mui-selected": {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "transparent",
    },
    "& svg": {
      fill: theme.palette.primary.main,
    },
    "& svg path": {
      stroke: theme.palette.primary.main,
    },
  },
  refundButton: {
    width: 100
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 12,
    marginRight: 10
  },
  priceTagIcon: {
    marginRight: 5
  },
  tiersCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40
  },
  dialogTitle: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  dialogContent: {
    marginTop: 15,
    minWidth: 300,
    maxWidth: 400
  },
  dialogButton: {
    width: 150
  },
  button: {
    borderRadius: 100
  }
}), { index: 1 });

export default useStyles;