import axios from 'axios'

import _get from 'lodash/get'

import env from 'src/env';


const baseUrl = `https://${env.apiDomain}`

export const getMyNotifications = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/notifications`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getMyNotifications response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}
