import React from 'react'
import useStyles from "src/styles/components/sidebar";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

export default function Item({
  icon,
  text,
  extraClassName,
  ...other
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.flexContainer, classes.item, extraClassName)} {...other}>
      <div className={classes.icon}>
        {icon}
      </div>

      <Typography variant="body1">
        {text}
      </Typography>
    </div>
  )
}