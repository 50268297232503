import React, { useState } from 'react'
import {
  FormControlLabel,
  Checkbox,
  Fade,
  Menu,
  Button,
  InputBase
} from '@material-ui/core';
import useStyles from "src/styles/components/dashboard/searchFilterPopUpPanel";
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'src/images/common/icon_search.svg';

export default function SearchFilterPopUpPanel({
  anchorEl,
  setAnchorEl,
  placeholder,
  setFilter,
  type,
  options
}) {
  const classes = useStyles();
  const open = !!anchorEl;
  const { t } = useTranslation("dashboard");
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedArr, setCheckedArr] = useState([]);

  let searchedOptions;
  if (options) {
    searchedOptions = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  const closeMenu = () => {
    setSearchTerm('');
    setAnchorEl(null);
  };

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  }

  const clear = () => {
    setSearchTerm('');
    setCheckedArr([]);
    setFilter(null);
  }

  const apply = (event) => {
    event.preventDefault();
    if (type === 'search') {
      setFilter(searchTerm);
    } else {
      if (checkedArr.length === 0) {
        setFilter(null);
      } else {
        setFilter(checkedArr);
      }
    }

    closeMenu();
  }

  const checkBoxOnChange = (e) => {
    let newCheckedArr = JSON.parse(JSON.stringify(checkedArr));

    if (newCheckedArr.includes(e.target.value)) {
      newCheckedArr.splice(newCheckedArr.indexOf(e.target.value), 1);
      setCheckedArr(newCheckedArr);
    } else {
      newCheckedArr.push(e.target.value);
      setCheckedArr(newCheckedArr);
    }
  };

  const checkCheckBox = (value) => {
    if (checkedArr.includes(value)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Menu
        elevation={4}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 40,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 220,
        }}
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        <form onSubmit={apply}>
          <InputBase
            placeholder={placeholder}
            classes={{
              root: classes.inputRoot,
              input: classes.input,
            }}
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            startAdornment={<SearchIcon height={24} width={24} className={classes.searchIcon} />}
            autoFocus
          />
          {
            searchedOptions &&
            <div className={classes.optionsContainer}>
              {
                searchedOptions.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      checked={checkCheckBox(option.value)}
                      control={<Checkbox color="primary" />}
                      label={option.label}
                      labelPlacement="end"
                      onChange={(e) => checkBoxOnChange(e)}
                    />
                  )
                })
              }
            </div>
          }
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
              onClick={clear}
            >
              {t('tab-2.filters.clear')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={apply}
              type="submit"
            >
              {t('tab-2.filters.apply')}
            </Button>
          </div>
        </form>
      </Menu>
    </>
  );
}