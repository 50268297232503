import React from 'react'
import { Typography, Paper, Button } from '@material-ui/core';
import useStyles from 'src/styles/components/dashboard/performance';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function AccountBalanceCard({
  accountBalance,
  onClick
}) {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");

  return (
    <Paper className={clsx(classes.paperRoot, classes.accountBalanceCard)}>
      <Typography variant="h5" align="left">
        {t('tab-1.main-stat.account-balance')}
      </Typography>
      <div>
        <Typography variant="h1">
          {'$' + Intl.NumberFormat('zh-HK').format(
            Number.isInteger(accountBalance) ? accountBalance : accountBalance.toFixed(2)
          )}
          <Typography component="span" color="textSecondary" variant="caption">
            USD
          </Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('tab-1.main-stat.next-time-will-receive')}
        </Typography>
      </div>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={onClick}
      >
        {t('tab-1.main-stat.early-payment-receipt')}
      </Button>
    </Paper>
  )
}