import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';


const PrivateRoute = ({ component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const Component = component;
  if (Component == null) {
    return null;
  }

  if (isAuthenticated === null) {
    return null;
  }

  let render;

  if (isAuthenticated) {
    render = (props) => (
      <>
        <div>
          <Component {...props} />
        </div>
      </>
    )
  } else {
    render = (props) => (
      <Redirect to={{
        pathname: "/",
        state: { from: props.location }
      }} />
    )
  }
  return <Route {...rest} render={render} />
}

export default PrivateRoute;