import React from 'react'
import { useHistory } from 'react-router-dom'
//useLocation
// import _get from 'lodash/get'
// import _includes from 'lodash/includes'

import logo from 'src/images/common/fanship_logo.svg';
import clsx from "clsx";
import useStyles from "src/styles/components/header/creatorOnboardingHeader";
import { Container } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

export default function CreatorOnboardingHeader() {
	const history = useHistory()
	// const location = useLocation()

	const classes = useStyles();
	const { t } = useTranslation("creator-onboarding");

	return (
		<div className={classes.root}>
			<Container className={clsx(classes.flexContainer, classes.container)}>
				<img
					src={logo}
					alt="fanship logo"
					className={classes.logo}
					onClick={() => history.push("/home")}
				/>
				{/* {
					_includes(
						_get(location, "pathname"),
						"/creator/onboarding/form"
					) ? (
						<Typography
							variant="button"
							className={classes.a}
						>
							{t("save-and-close")}
						</Typography>
					) : _includes(
						_get(location, "pathname"),
						"/creator/onboarding/preview"
					) ? (
						<Typography
							variant="button"
							className={classes.a}
							onClick={() => history.push("/creator/onboarding/form?from=preview")}
							// why query from=preview?
						>
							{t("back-to-form")}
						</Typography>
					) : null
				} */}

			</Container>
		</div>
	);
}