import { makeStyles } from '@material-ui/core/styles'
import _get from 'lodash/get'

const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
    },
    item: {
        position: "relative",
        width: "100%",
        height: 0,
        paddingTop: "100%",
        backgroundImage:  props => `url(${_get(props, "image")})`,        
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 20,
        overflow: "hidden",
    },
    progressHolder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
    },
    statusBox: {
        position : 'absolute',
        background : '#30D158',
        width : 101, 
        height :38,
        right : 10,
        top : 10,
        borderRadius: 100,
        display : 'flex',
        justifyContent : 'center',
        alignItems: "center",
        gap: 7,
    },
    statusText: {
        color: "#FFFFFF",
        fontWeight: 700,
    },
    detailsBox: {
        position: 'absolute',
        bottom: 0,
        background: `rgba(33, 37, 41, 0.5)`,
        backdropFilter: `blur(10px)`,
        width: '100%',
        height: "fit-content",
        padding: 10,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    detailsHead: {
        display: "flex",
    },
    detailsHeadLeft: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    title: {
        color: "#FFFFFF",
        fontSize: 18,
        fontWeight: 700,
    },
    postCount: {
        display: "flex",
        alignItems: "center",
        gap: 7,
    },
    postIcon: {
        color: "#FFFFFF"
    },
    postCountNumber: {
        color: "#FFFFFF",
        fontSize: 12,
        fontWeight: 700,
    },
    detailsHeadRight: {
        display: "flex",
        alignItems: "center",
    }, 
    priceNumber: {
        color: "#FFFFFF",
        fontSize: 28,
        fontWeight: 700,
    },
    priceLabel: {
        color: "#FFFFFF",
        fontSize: 12,
    },
    detailsBody: {
        color: "#FFFFFF",
        fontSize: 14,
    },
    paginationContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '10px 0px'
    },
    searchInput: {
        height: 44,
        borderRadius: 22,
        backgroundColor: theme.palette.grey[100],
        margin: "15px 0px",
    },
    searchIcon: {
        color: theme.palette.grey[500],
    },
    pagination: {
        "& .MuiPaginationItem-root": {
          fontSize: 14,
          color: theme.palette.grey[400],
        },
        "& .Mui-selected": {
          fontWeight: "bold",
          color: "black",
          backgroundColor: "transparent",
        },
        "& svg": {
          fill: theme.palette.primary.main,
        },
        "& svg path": {
          stroke: theme.palette.primary.main,
        },
    },    
    noBorder: {
        border: "none",
    },
}))

export default useStyles