import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Preview } from 'src/components/creator-onboarding/Preview';
import useStyles from 'src/styles/pages/creatorProfile';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import env from 'src/env';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _get from 'lodash/get'

function CreatorProfile({
  isAuthenticated,
  clearPathToPushOn,
  posts,
}) {

  const { t: te } = useTranslation("error");
  const classes = useStyles();
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [pageInfo, setPageInfo] = useState();
  const [apiErrorCode, setApiErrorCode] = useState();
  const [paidTiersList, setPaidTiersList] = useState();
  const { fanshipPageName } = useParams();
  
  useEffect(() => {
    const getInfo = async () => {

      setLoadingInfo(true);

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const res1 = await fetch(`https://${env.apiDomain}/fanshipPageNames/${fanshipPageName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userId}_${token}`
        }
      });

      const result1 = await res1.json();
      console.log('result1: ', result1);

      let idolId;

      if (result1.success) {
        const data = result1.data;
        idolId = data.userId;
        setPageInfo(data);
        apiErrorCode && setApiErrorCode(null)
      } else {
        if (result1.code) {
          setApiErrorCode(result1.code);
        } else {
          setApiErrorCode('UNEXPECTED_ERROR');
        }
      }

      const res2 = await fetch(`https://${env.apiDomain}/idols/${idolId}/tiers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userId}_${token}`
        }
      })

      const result2 = await res2.json();
      console.log('result2: ', result2);

      if (result2.success) {
        const data = result2.data;
        setPaidTiersList(data);
        apiErrorCode && setApiErrorCode(null)
      } else {
        if (result2.code) {
          if (result2.code === "IDOL_HAS_NO_TIERS") {
            setLoadingInfo(false);
            return
          }
          setApiErrorCode(result2.code);
        } else {
          setApiErrorCode('UNEXPECTED_ERROR');
        }
      }

      setLoadingInfo(false);
    }

    getInfo()
    
    // eslint-disable-next-line
  }, [fanshipPageName])

  return (
    <div>
      {
        loadingInfo ?
          <div className={clsx(classes.loadingContainer, classes.root)}>
            <CircularProgress size={60} />
          </div> :
          apiErrorCode ?
            <div className={classes.root}>
              <Alert severity="error">
                {te(`code.${apiErrorCode}`)}
              </Alert>
            </div> :
            <>
              {pageInfo &&
                <Preview
                  id={pageInfo.userId}
                  isIdol={pageInfo.isIdol}
                  userFollowed={pageInfo.userFollowed}
                  isEntitledToChat={pageInfo.isEntitledToChat}
                  coverPhoto={pageInfo.profileImage}
                  avatar={pageInfo.avatar}
                  displayName={pageInfo.displayName}
                  paidTiersList={paidTiersList}
                  fullName={pageInfo.fullName}
                  numberOfFans={pageInfo.numberOfFans}
                  numberOfSubscribers={pageInfo.numberOfSubscribers}
                  profile={pageInfo.profile}
                  getPaidTiersList={() => { }}
                  getLoggedInUser={() => {
                    return new Promise((resolve) => { resolve() })
                  }}
                  isAuthenticated={isAuthenticated}
                  clearPathToPushOn={clearPathToPushOn}
                  posts={posts}
                />
              }
            </>
      }
    </div>
  )
}


const mapStateToProps = state => ({
  isAuthenticated: _get(state, "auth.isAuthenticated"),
  clearPathToPushOn: _get(state, "location.pushAfterAuth.clearOn"),
  posts: _get(state, "feedPost.feed"),
})

export default connect(
  mapStateToProps,
)(CreatorProfile)