import { makeStyles } from "@material-ui/styles";
import { newWidth } from "../screenStyle";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        minHeight: "110vh",
        [theme.breakpoints.up('lg')]: {
            width: newWidth(562),
        }
    },
    progressHolder: {
        display: "flex",
        justifyContent: "center",
        margin: "30px 0px",
        height: 200,
    },

}))

export default useStyles