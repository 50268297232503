import React from 'react'
import TextFieldMui from '@material-ui/core/TextField'

const TextField = ({ error, label, helperText, ...props }) => {
    return <TextFieldMui
        error={error}
        label={label}
        helperText={helperText}
        {...props}
    />
}

export default TextField
