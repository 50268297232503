import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: '10px 0px'
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      fontSize: 14,
      color: theme.palette.grey[400],
    },
    "& .Mui-selected": {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "transparent",
    },
    "& svg": {
      fill: theme.palette.primary.main,
    },
    "& svg path": {
      stroke: theme.palette.primary.main,
    },
  },
  progressHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
}), { index: 1 });

export default useStyles;