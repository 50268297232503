import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 15px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  listItem: {
    backgroundColor: theme.palette.grey[200]
  },
  textBold: {
    fontWeight: 'bold'
  },
  avatar: {
    marginRight: 15,
    width: 50,
    height: 50,
    borderRadius: 20
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteIcon: {
    cursor: 'pointer',
    '& path': {
      stroke: theme.palette.grey[600]
    }
  }
}), { index: 1 });

export default useStyles;