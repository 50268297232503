import React from 'react'
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import useStyles from "src/styles/components/dashboard/performance";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";

export default function FilterPopUpMenu({
  anchorEl,
  setAnchorEl,
  setFilter,
  onSelect
}) {
  const classes = useStyles();
  const open = !!anchorEl;
  const { t } = useTranslation("dashboard");

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const clickItem = (option) => {
    setFilter(option);
    onSelect(option);
    closeMenu()
  }

  const filterOptions = ['past-7-days', 'past-14-days', 'past-30-days', 'past-90-days', 'this-week', 'last-week', 'this-month', 'last-month'];

  return (
    <>
      <Menu
        elevation={4}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        {
          filterOptions.map((option) => {
            return (
              <MenuItem
                key={option}
                className={clsx(classes.menuItem)}
                onClick={() => clickItem(option)}
              >
                <Typography variant="caption">
                  {t(`tab-1.other-stat.filters.${option}`)}
                </Typography>

              </MenuItem>
            )
          })
        }
      </Menu>
    </>
  );
}