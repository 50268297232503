import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    background: theme.palette.grey[100],
    width: '100%',
    padding: 30,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection:'column'
     },
  },
  paperRoot: {
    borderRadius: 20,
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: 510
  },
  alert: {
    marginBottom: 15,
    width: '100%'
  },
  header: {
    marginBottom: 10
  },
  description: {
    marginBottom: 40
  },
  form: {
    width: '100%',
  },
  textField: {
    marginBottom: 40
  },
  button: {
    borderRadius: '100px',
    padding: '10px',
  }
}), { index: 1 })

export default useStyles;