import React from 'react'
import useStyles from "src/styles/components/personal-profile/creatorCard";
import {
  Typography,
  Paper
} from "@material-ui/core";
import { countriesMap } from 'src/info/countries';
import defaultAvatar from 'src/images/common/default_avatar.png';

export default function CreatorCard({
  avatar,
  displayName,
  countryCode
}) {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <img src={avatar ? avatar : defaultAvatar} alt="creator avatar" className={classes.avatar} />
      <div>
        <Typography variant="body1">
          {displayName}
        </Typography>
        <Typography variant="body1"
          className={classes.textBold}
          color="textSecondary"
        >
          {countriesMap[countryCode]}
        </Typography>
      </div>
    </Paper>
  )
}