import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: '10px 20px',
    width: props => !props.isMobileSearchOpen && '40%',
    borderRadius: 100,
    position: 'relative',
    flexGrow: props => props.isMobileSearchOpen && 1,
  },
  inputRoot: {
    padding: 0,
    backgroundColor: theme.palette.grey[100],
    flex: 1
  },
  input: {
    padding: 0,
    fontSize: 16,
    lineHeight: '24px'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconBubble: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    cursor: 'pointer'
  },
  searchPaperRoot: {
    maxHeight: 350,
    overflowY: 'auto',
    position: 'absolute',
    top: 44,
    left: '50%',
    minWidth: 350,
    transform: 'translate(-50%,0)',
    "&::-webkit-scrollbar": {
      backgroundColor: "#E9ECEF",
      borderRadius: "30px",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "30px",
      width: "5px",
    },
  },
  textBold: {
    fontWeight: 'bold'
  },
  recentSearchTop: {
    justifyContent: 'space-between',
    padding: '10px 15px'
  },
  deleteAll: {
    cursor: 'pointer'
  },
  noResult: {
    padding: '10px 15px',
    cursor: 'default'
  },
  dialogHeader: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  dialogButton: {
    height: 44,
    borderRadius: 100,
    backgroundColor: theme.palette.error.main,
    color: 'white',
    marginTop: 40,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  dialogContent: {
    marginTop: 15,
    maxWidth: 400
  },
  dialogWrapper: {
    position: 'absolute'
  },
  spinnerWrapper: {
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'center'
  }
}), { index: 1 });

export default useStyles;