import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/pages/registration';
import clsx from "clsx";
import {
  Paper,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import LoadingButton from 'src/components/LoadingButton';
import { ReactComponent as FacebookIcon } from "src/images/login-page/icon_facebook.svg";
import { ReactComponent as GoogleIcon } from "src/images/login-page/icon_google.svg";
import { ReactComponent as AppleIcon } from "src/images/login-page/icon_apple.svg";
import { ReactComponent as IconVisibility } from "src/images/login-page/icon_visibility.svg";
import { ReactComponent as IconVisibilityOff } from "src/images/login-page/icon_visibility_off.svg";
import { NavLink } from 'react-router-dom';
import ErrorHelperText from 'src/components/ErrorHelperText';
import AccountFormHint from 'src/components/AccountFormHint';
import { push } from 'connected-react-router';
import {
  checkIfPasswordLongEnough,
  checkIfPasswordContainNumberOrSymbol,
  checkSecurityLevelOfPassword,
  checkIfPasswordContainNameOrEmail
} from "src/lib/validators/password";
import { checkHintTypeBySecurityLevel } from "src/lib/accounts";
import env from "src/env";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login'
import { loginSuccess } from 'src/redux/auth/actions';
import _get from 'lodash/get'
import login from 'src/images/pdf/login.png'
export default function Registration() {
  const { t } = useTranslation("registration");
  const { t: te } = useTranslation("error");
  const classes = useStyles();
  const [pwFieldOneVisible, setPwFieldOneVisible] = useState(false);
  const [pwFieldTwoVisible, setPwFieldTwoVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [submitTimes, setSubmitTimes] = useState(0);
  const [apiErrorCode, setApiErrorCode] = useState();
  const [success, setSuccess] = useState(false);
  const [picture, setPicture] = useState();
  const [socialToken, setSocialToken] = useState();
  const [socialMedia, setSocialMedia] = useState();
  const [countryCode, setCountryCode] = useState();
  const [authData, setAuthData] = useState();
  let securityLevel = password ? checkSecurityLevelOfPassword(password) : null;
  const pathToPush = useSelector(state => _get(state, "location.pushAfterAuth.path"))

  useEffect(() => {
    const getUserLocation = async () => {
      const res = await fetch(`https://api.geoapify.com/v1/ipinfo?&apiKey=${env.geolocationApiKey}`)
      const result = await res.json();
      console.log('geo location: ', result);
      setCountryCode(result.country.iso_code);
    }
    getUserLocation();
  }, [])

  console.log('country code: ', countryCode);
  console.log("social token: ", socialToken);
  console.log('socialMedia: ', socialMedia);

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitTimes(submitTimes + 1);

    if (
      !username ||
      !email ||
      !password ||
      !confirmPassword ||
      username.length < 3 ||
      securityLevel === 'low' ||
      !checkIfPasswordLongEnough(password) ||
      !checkIfPasswordContainNumberOrSymbol(password) ||
      checkIfPasswordContainNameOrEmail(password, username, email) ||
      password !== confirmPassword
    ) {
      return;
    }

    setIsLoading(true);

    const url = socialMedia ?
      `https://${env.apiDomain}/auth/social-sign-up/${socialMedia}` :
      `https://${env.apiDomain}/auth/sign-up`;

    let body = {
      username: username,
      email: email,
      password: password,
      countryCode: countryCode,
      device: 'desktop'
    }

    if (picture) {
      body['avatar'] = picture
    }

    if (socialMedia === 'facebook') {
      body['accessToken'] = socialToken;
    } else if (socialMedia === 'google' || socialMedia === 'apple') {
      body['idToken'] = socialToken;
    }

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    });

    const result = await res.json();
    console.log('result: ', result);

    if (result.success) {
      const data = result.data;
      setSuccess(true);
      setAuthData(data);
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('token', data.access_token);
    } else {
      setApiErrorCode(result.code);
    }

    setIsLoading(false);
  }

  const handleFieldChange = (event, field) => {
    switch (field) {
      case 'username':
        setUsername(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(event.target.value);
        break;
      default:
        break;
    }
  }

  const responseFacebook = (response) => {
    console.log('facebook response: ', response);
    console.log(response.email);
    console.log(response.picture);
    console.log(response.accessToken);

    setEmail(response.email);
    setPicture(response.picture.data.url);
    setSocialToken(response.accessToken);
    setSocialMedia('facebook');
  }

  const responseGoogle = (response) => {
    console.log('google response: ', response);
    const profileObj = response.profileObj;
    setEmail(profileObj.email);
    setPicture(profileObj.imageUrl);
    setSocialToken(response.tokenId);
    setSocialMedia('google');
  }

  const responseApple = (response) => {
    try {
      console.log('apple response: ', response);
      if (response.error) {
        console.error('error: ', response.error);
      } else {
        setEmail(response.user.email);
        setSocialToken(response.authorization.id_token);
        setSocialMedia('apple');
      }
    } catch (err) {
      console.log('err: ', err);
    }
  }

  return (
    <div className={clsx(classes.mainContainer, classes.flexContainer)}>
        <div style={{width:'50%'}}>
      <img src={login} style={{height:'90%',width:'90%'}}/>

      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography variant="h2" align="center" className={classes.header}>
          {(socialMedia || success) ? t("social.header") : t("header")}
        </Typography>
        {
          !success &&
          <>
            <Typography variant="body1" align="center" className={classes.description}>
              {socialMedia ? t("social.description") : t("description")}
            </Typography>
            {!socialMedia &&
              <>
                <div className={clsx(classes.flexContainer, classes.socialMediaContainer)}>
                  <div className={clsx(classes.iconBubble, classes.facebookLoginWrapper)}>
                    <FacebookLogin
                      appId={env.facebookAppId}
                      autoLoad={false}
                      fields="email,picture"
                      callback={responseFacebook}
                      icon={<FacebookIcon
                        className={classes.socialLoginIcon}
                        width={24}
                        height={24}
                      />}
                      textButton={null}
                    />
                  </div>
                  <div className={classes.iconBubble}>
                    <GoogleLogin
                      clientId={env.googleWebClientId}
                      buttonText={null}
                      render={(renderProps) =>
                        <GoogleIcon
                          onClick={renderProps.onClick}
                          width={24}
                          height={24}
                          className={classes.socialLoginIcon}
                        />}
                      onSuccess={responseGoogle}
                      onFailure={(error) => console.error(error)}
                      cookiePolicy={'single_host_origin'}
                    />
                  </div>
                  <div className={classes.iconBubble}>
                    <AppleLogin
                      clientId={env.appleSignInServiceId}
                      redirectURI={`https://${env.domain}/registration`}
                      callback={responseApple}
                      scope={'email'}
                      usePopup={true}
                      render={(renderProps) =>
                        <AppleIcon
                          onClick={renderProps.onClick}
                          width={24}
                          height={24}
                          className={classes.socialLoginIcon}
                        />}
                    />
                  </div>
                </div>
                <div className={clsx(classes.flexContainer, classes.lineContainer)}>
                  <div className={classes.line} />
                  <Typography variant="caption" color="textSecondary" className={classes.or}>
                    {t('or')}
                  </Typography>
                  <div className={classes.line} />
                </div>
              </>
            }
            {
              picture &&
              <div className={classes.avatarWrapper}>
                <img
                  src={picture}
                  alt="user avatar"
                  className={classes.avatar}
                />
              </div>
            }
            <form
              className={classes.form}
              onSubmit={onSubmit}
            >
              <div className={classes.inputContainer}>
                <TextField
                  value={username}
                  onChange={(event) => handleFieldChange(event, 'username')}
                  label={t('username')}
                  variant="outlined"
                  className={classes.textField}
                  error={submitTimes > 0 && !username}
                  helperText={
                    submitTimes > 0 && !username &&
                    <ErrorHelperText text={t('errors.username')} />
                  }
                />
                <TextField
                  value={email}
                  onChange={(event) => handleFieldChange(event, 'email')}
                  label={t('email')}
                  variant="outlined"
                  className={classes.textField}
                  InputProps={
                    {
                      type: 'email',
                      name: 'email',
                    }
                  }
                  error={submitTimes > 0 && !email}
                  helperText={
                    submitTimes > 0 && !email &&
                    <ErrorHelperText text={t('errors.email')} />
                  }
                />
                <TextField
                  value={password}
                  onChange={(event) => handleFieldChange(event, 'password')}
                  label={t('password')}
                  variant="outlined"
                  className={clsx(classes.textField, classes.password)}
                  InputProps={
                    {
                      type: pwFieldOneVisible ? 'text' : 'password',
                      name: 'password',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                        >
                          <IconButton
                            onClick={() => setPwFieldOneVisible(!pwFieldOneVisible)}
                          >
                            {pwFieldOneVisible ?
                              <IconVisibilityOff /> :
                              <IconVisibility />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  }
                  error={submitTimes > 0 && !password}
                  helperText={
                    submitTimes > 0 && !password &&
                    <ErrorHelperText text={t('errors.password')} />
                  }
                />
                <TextField
                  value={confirmPassword}
                  onChange={(event) => handleFieldChange(event, 'confirmPassword')}
                  label={t('confirm-password')}
                  variant="outlined"
                  className={clsx(classes.textField, classes.password)}
                  InputProps={
                    {
                      type: pwFieldTwoVisible ? 'text' : 'password',
                      name: 'password',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                        >
                          <IconButton
                            onClick={() => setPwFieldTwoVisible(!pwFieldTwoVisible)}
                          >
                            {pwFieldTwoVisible ?
                              <IconVisibilityOff /> :
                              <IconVisibility />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  }
                  error={submitTimes > 0 && !confirmPassword}
                  helperText={
                    submitTimes > 0 && !confirmPassword &&
                    <ErrorHelperText text={t('errors.confirm-password')} />
                  }
                />
                {
                  password &&
                  <AccountFormHint
                    type={checkHintTypeBySecurityLevel(securityLevel)}
                    text={`${t('password-security-level')}${t(securityLevel)}`}
                  />
                }
                {
                  submitTimes > 0 && username.length < 3 &&
                  <AccountFormHint type="error" text={t('errors.username-too-short')} />
                }
                {
                  submitTimes > 0 && !checkIfPasswordLongEnough(password) &&
                  <AccountFormHint type="error" text={t('errors.too-short')} />
                }
                {
                  submitTimes > 0 && !checkIfPasswordContainNumberOrSymbol(password) &&
                  <AccountFormHint type="error" text={t('errors.must-include-number-or-symbol')} />
                }
                {
                  submitTimes > 0 && checkIfPasswordContainNameOrEmail(password, username, email) &&
                  <AccountFormHint type="error" text={t('errors.must-not-container-name-or-email')} />
                }
                {
                  submitTimes > 0 && (password !== confirmPassword) &&
                  <AccountFormHint type="error" text={t('errors.passwords-not-match')} />
                }
                {
                  apiErrorCode &&
                  <AccountFormHint type="error" text={te(`code.${apiErrorCode}`)} />
                }
              </div>

              <Typography variant="caption">
                {t('agreement.part-1')}
                <span className={classes.textBold}>
                  {t('agreement.part-2')}
                </span>
                {t('agreement.part-3')}
                <NavLink to="/terms-conditions" target="_blank" className={classes.navLink}>
                  <Typography variant="caption" color="primary" component="span">
                    {t("agreement.part-4")}
                  </Typography>
                </NavLink>
                {t("agreement.part-5")}
                <NavLink to="/privacy-policy" target="_blank" className={classes.navLink}>
                  <Typography variant="caption" color="primary" component="span">
                    {t("agreement.part-6")}
                  </Typography>
                </NavLink>
                {t("agreement.part-7")}
              </Typography>

              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                isLoading={isLoading}
                loadingProps={{
                  size: 24
                }}
              >
                <Typography variant="body1">
                  {t("submit")}
                </Typography>
              </LoadingButton>
            </form>
            <div className={classes.flexContainer}>
              <Typography variant="body1">
                {t("already-registered")}
              </Typography>
              <Typography variant="body1" color="primary" className={classes.login}>
                <NavLink to="/" className={classes.navLink}>
                  {t("login")}
                </NavLink>
              </Typography>
            </div>
          </>
        }
        {
          success &&
          <>
            <Typography variant="body1">
              {
                t('success-message')
              }
            </Typography>
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              isLoading={isLoading}
              loadingProps={{
                size: 24
              }}
              onClick={() => {
                setIsLoading(true);
                console.log('auth data: ', authData);
                dispatch(
                  loginSuccess(
                    authData.userId,
                    authData.email,
                    authData.displayName,
                    authData.fullName,
                    authData.countryCode,
                    authData.avatar,
                    authData.language,
                    authData.isIdol,
                    authData.stream_token,
                    authData.access_token,
                  )
                );

                pathToPush ?
                dispatch(push(pathToPush)) :
                dispatch(push('/home'))
              }}
            >
              <Typography variant="body1">
                {
                  t('continue')
                }
              </Typography>
            </LoadingButton>
          </>
        }

      </Paper>
    </div>
  )
}