import React from "react";

// import _get from 'lodash/get'
// import _isObject from 'lodash/isObject'

import { useTranslation } from "react-i18next";

import Autocomplete from "./Autocomplete";
import TextField from "../renderTextField/TextField";

import { matchSorter } from "match-sorter";

const RenderAutocomplete = ({
  input,
  meta: { touched, error },
  options,
  optionValueField,
  textFieldProps,
  helperText,
  isCountryFilter,
  translatorJson,
  ...props
}) => {
  const { t } = useTranslation(
    translatorJson
  );

  const filterOptions = (
    options,
    { inputValue }
  ) =>
    matchSorter(options, inputValue, {
      keys: [
        {
          threshold:
            matchSorter.rankings
              .STARTS_WITH,
          key: "name",
        },
        "name",
      ],
    });

  return (
    <Autocomplete
      options={options}
      filterOptions={isCountryFilter && filterOptions}
      renderInput={(params) => {
        // console.log("RenderAutocomplete renderInput params", params)
        return (
          <TextField
            error={
              touched && error
                ? true
                : false
            }
            helperText={
              touched &&
              error &&
              (helperText || t(error))
            }
            {...params}
            {...textFieldProps}
          />
        );
      }}
      value={input.value}
      onChange={(event, value) => {
        // console.log("RenderAutocomplete onChange value", value)
        // if(optionValueField && _isObject(value)) return input.onChange(_get(value, optionValueField))
        return input.onChange(value);
      }}
      {...props}
    />
  );
};

export default RenderAutocomplete;
