import React from 'react'
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import useStyles from "src/styles/components/dashboard/sortingPopUpMenu";
import clsx from "clsx";

export default function SortingPopUpMenu({
  anchorEl,
  setAnchorEl,
  setSortingOption,
  sortingOptions
}) {
  const classes = useStyles();
  const open = !!anchorEl;

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const clickItem = (option) => {
    setSortingOption(option);
    closeMenu()
  }

  return (
    <>
      <Menu
        elevation={4}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        {
          sortingOptions.map((option, index) => {
            return (
              <MenuItem
                key={`option_${index}`}
                className={clsx(classes.menuItem)}
                onClick={() => clickItem(option)}
              >
                <Typography variant="caption">
                  {option.text}
                </Typography>

              </MenuItem>
            )
          })
        }
      </Menu>
    </>
  );
}