import _get from 'lodash/get'
import { StreamChat } from 'stream-chat'

import env from '../../env'

import { 
    SET_CHAT_CLIENT, 
} from "./actionTypes";

export const setChatClient = chatClient => ({
    type: SET_CHAT_CLIENT,
    chatClient,
})

export const initChatClient = () => async (dispatch, getState) => {
    try {
        const userStreamToken = _get(getState(), "auth.stream_token")
        const userId = _get(getState(), "auth.userId")
        const userDisplayName = _get(getState(), "auth.displayName")
        const userAvatar = _get(getState(), "auth.avatar")
      
        // connect stream chat user
        const chatClient = StreamChat.getInstance(env.streamKey)
        const user = {
            id: userId,
            name: userDisplayName,
            image: userAvatar,
        }
        
        console.log("chat: chatclient before connect user", chatClient, "user", user)

        await chatClient.connectUser(
            user,
            userStreamToken,
        )

        console.log("chat: chatclient after connect user", chatClient)
        dispatch(setChatClient(chatClient))

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong")) 
    }
}


export const initChatChannel = (userIdArr) => async (dispatch, getState) => {
    try {
        const chatClient = _get(getState(), "chat.chatClient")

        const channel = chatClient.channel(
            'messaging', 
            { 
                members: userIdArr, 
            }
        )

        await channel.create()

        return Promise.resolve()

    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong")) 
    }
}