import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import clsx from 'clsx'

import _get from 'lodash/get'

import { 
    likeComment, 
    removeLikeToComment, 
    // getLikesForComment 
    deleteCommentByCommentId
} from 'src/redux/feed-post/actions'
import { nFormatter } from 'src/lib/formatters/number'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import LikesDialog from './LikesDialog'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import useStyles from 'src/styles/components/feed-post/post.js'

import defaultAvatar from 'src/images/common/default_avatar.png'


const Reply = ({
    postId,
    commentId,
    reply,
    onDeletecomment
}) => {
const commentUserId =_get(reply, "user.userId")
const userId = localStorage.getItem('userId');
    console.log("reply",userId,commentUserId )
    const [ userLikedLocal, setUserLikedLocal ] = useState(_get(reply, "userLiked"))
    const [ likeCountLocal, setLikeCountLocal ] = useState(_get(reply, "likeCount"))
    const [ deleteCommentLocal, setDeleteCommentLocal ] = useState(_get(reply, "commentCount"))
    const [ isLikeCoolingDown, setIsLikeCoolingDown ] = useState(false)
    const [ isLikesDialogOpen, setIsLikesDialogOpen ] = useState(false)
    const [ likes, setLikes ] = useState(null)    // null and [] for empty-resposnse is used for condition in LikesDialog Dialog component
     const [isDeleteComment,setIsDeleteComment]=useState(false)
    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const handleConfirmation=()=>{
         const confirmBox = window.confirm(
              "Do you really want to delete this comment?"
            )
           return confirmBox
    } 
    
    const handleDeleteClick = useCallback(() => {
        let flag= handleConfirmation()
        
    if(flag){
        setIsDeleteComment(true)
       dispatch(deleteCommentByCommentId(postId,_get(reply, "parentCommentId"),_get(reply, "commentId")))
   }
  
      
    },[isDeleteComment])

    const handleLikeClick = useCallback(() => {
        if(isLikeCoolingDown) return

        setIsLikeCoolingDown(true)

        userLikedLocal
        ? dispatch(removeLikeToComment(postId, _get(reply, "parentCommentId"),_get(reply, "commentId")))
        .catch(err => {
            console.log("removeLikeToComment failed", err)
            enqueueSnackbar(err || "Something went wrong", { variant: "error", })
        }).finally(() => setIsLikeCoolingDown(false))
        : dispatch(likeComment(postId, _get(reply, "parentCommentId"),_get(reply, "commentId")))
        .catch(err => {
            console.log("removeLikeToComment failed", err)
            enqueueSnackbar(err || "Something went wrong", { variant: "error", })
        }).finally(() => setIsLikeCoolingDown(false))

        setLikeCountLocal(() => likeCountLocal + (userLikedLocal ? (-1) : (1)))
        setUserLikedLocal(() => !userLikedLocal)
        
        // eslint-disable-next-line
    }, [userLikedLocal, likeCountLocal, postId, reply?.parentCommentId, reply?.commentId, isLikeCoolingDown])

    // const handleUsersLikedOpen = useCallback(() => {
    //     if(!_get(reply, "likeCount")) return

    //     dispatch(getLikesForComment(_get(reply, "commentId")))
    //     .then(response => {
    //         console.log("getLikesForPost response", response)
            
    //         setLikes(_get(response, "data", []))
    //     })

    //     setIsLikesDialogOpen(true)

    //     // eslint-disable-next-line
    // }, [postId, reply?.likeCount])

    const handleUsersLikedclose = useCallback(() => {
        setIsLikesDialogOpen(false)
        setLikes(null)
    }, [])


    const classes = useStyles()
	const { t } = useTranslation("feed-post")

    
    return (
        <>
        <LikesDialog
            open={isLikesDialogOpen}
            onClose={handleUsersLikedclose}
            likes={likes}
        />

        <Box className={classes.comment}>
            <Box className={classes.avatarHolder}>
                <img 
                    src={_get(reply, "user.avatar") || defaultAvatar}
                    alt="avatar"
                    placeholder={defaultAvatar}
                />
            </Box>
            <Box className={classes.commentBody}>
                <Box className={classes.commentTitleBar}>
                    <Typography className={classes.commentOwner} variant="body1" component="h4">
                        {_get(reply, "user.displayName")}
                    </Typography>
                    <Typography className={classes.commentMeta} variant="body2" component="span">
                        {moment(_get(reply, "publishTime")).fromNow()}
                    </Typography>
                </Box>
                <Box className={classes.commentMain}>
                    <Typography className={classes.commentContent} variant="body1">
                        {_get(reply, "content")}
                    </Typography>
                </Box>
                {/* implement delete functionality */}
        {(commentUserId===userId)&&
                (<Box className={classes.commentMain} onClick={isDeleteComment?onDeletecomment:""}  onClickCapture={()=>handleDeleteClick()}>
                    <Typography className={classes.pointer} variant="body1" >
                        {t("delete")}
                    </Typography>
                    </Box>
                    )

        }   
               
                <Box className={classes.commentStatus}>
                    {likeCountLocal ? <Typography className={clsx(_get(reply, "likeCount") 
                        // && classes.pointer
                    )} variant="body2" component="span"
                        // onClick={handleUsersLikedOpen}   hiding likes-dialog for replies for now.
                    >
                        {
                        // likeCountLocal > 1
                        // ? 
                        `${nFormatter(likeCountLocal, 1)} ${t("like")}`
                        // : `${likeCountLocal} Like`
                        } 
                    </Typography> : null}
                    <Box className={classes.commentActions}>
                        {/* <IconButton className={classes.commentActionBtn}>
                            <MoreHorizIcon fontSize="small" />
                        </IconButton> */}
                        <IconButton className={classes.commentActionBtn}
                            onClick={handleLikeClick}
                        >
                            {
                                userLikedLocal
                                ? <FavoriteIcon fontSize="small" className={clsx(classes.likeIcon, classes.likedTrue)} />
                                : <FavoriteBorderIcon fontSize="small" className={clsx(classes.likeIcon, classes.likedFalse)}/>
                            }
                        </IconButton>
                    </Box>
                </Box>

            </Box>
        </Box>
        </>
    )
}

export default Reply
