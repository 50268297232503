import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 16,
    top: 16,
    padding: 0,
    color: "#999999",
    [theme.breakpoints.down("xs")]: {
      right: 8,
      top: 8,
    },
  },
  avatar : {
    hight: "60px", width: "50px", borderRadius: "20px", alignItems: "center"
  },
  imgInput: {
    borderRadius: "20px !important",
    textAlign: "center",
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    boxSizing: 'border-box'
  },
  imgIcon : {
    backgroundColor: '#FF375F',
    position:'absolute',
    right:10,
    top:10,
    color:'white',
    borderColor:'white', 
    height: '35px', 
    width: '35px', 
    borderRadius:"20px"
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  videoContainer: {
    display: 'flex',
    flexDirection: 'row',
    position:'relative',
    justifyContent: 'space-between'
  },

  dialogTitle: {
    padding: 0,
    textAlign: "left",
  },
  paper: {
    borderRadius: "20px !important",
    padding: 20,
    textAlign: "center",
    minWidth: 300,
    width: '100%',
    boxSizing: 'border-box'
  },
  contentRoot: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  postdescr: {
    border: 'none',
    marginLeft: '10px',
    resize: 'none',
    marginLeft: '10px',
    width: '79%',
    outline: 'none',
    boxSizing: 'border-box',
  },
  uploadimg: {
    borderWidth: '1px',
    borderColor: '#000',
  },
  paidplanreq : {
    width:"100%",
    marginRight:"10px",
    alignSelf:"center", 
    textAlign:"left"
  },
  freeplanshow : {
    alignItems:'left',
    height: '40px',
    width:"auto", 
    whiteSpace:"nowrap"
  },
  imgdiv : {
    marginRight:'10px',
    position:'relative',
    display:"flex", 
    flexDirection: 'row', 
    borderRadius:"10px"
  },
  planreq: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  postprice: {
    width: '100%',
    height: '100%',
  },
  pricebutton: {
    backgroundColor: "#90f !important",
    borderRadius: '50px',
    width: '100px',
    height: '30px',
    overflow: 'hidden',
    borderRadius: '50px',
    padding: 0,
    $hover: {
      backgroundColor: '#90f',
    },
  },
  MuiAutocompleteinputRoot: {
    padding: 0,
  },
  header: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    fontWeight: 'bold'
  },
  mainContainer: {
    marginTop: 15
  },
  button: {
    borderRadius: 100,
    height: 44,
    width: 150
  },
  buttonrelease: {
    display: 'flex',
    flexdirection: 'row',
    justifycontent: 'center',
    alignitems: 'center',
    padding: '10px',
    position: 'static',
    width: '390px',
    height: '44px',
    borderRadius: '20px',
    left: '0px',
    top: '306px',
  },
  uploadButtonRoot: {
    position: "relative",
  },
  uploadInput: {
    position: "absolute",
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  confirmButton: {
    marginTop: 40
  },
  reactCrop: {
    marginTop: 15
  },
  makenewpost: {
    borderBottom: '1px solid #DEE2E6',
    padding: '15px 0px',
  },
  freePlanBtn: {
    width: '105px',
  },
  mainContainersCheck: {
    textAlign: 'left',
    paddingTop: '15px',
  },
  formCheck: {
    paddingBottom: '15px',
  },
  formcheckinput: {
    width: '18px',
    height: '18px',
    margin: 0,
    marginRight: '10px',
  },
  levelConfirmBtn: {
    padding: 0,
    paddingTop: '25px',
  },
  closeButtonplan: {
    left: '5px',
    right: 'auto',
    position: 'absolute',
    top: '15px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }

}), { index: 1 });



export default useStyles;