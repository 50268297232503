export const roundedEdgePlugin1 = {

  id: 'p1',
  afterUpdate(chart) {
    const reversedData = chart.getDatasetMeta(0).data.slice().reverse();
    reversedData.forEach((arc) => {
      arc.round = {
        x: (chart.chartArea.left + chart.chartArea.right) / 2,
        y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
        radius: (chart.outerRadius + chart.innerRadius) / 2,
        thickness: (chart.outerRadius - chart.innerRadius) / 2,
        backgroundColor: arc._model.backgroundColor,
      };
    });
  },

  afterDatasetsDraw(chart) {
    const { ctx } = chart.chart;

    const reversedData = chart.getDatasetMeta(0).data.slice().reverse();
    const reversedControllerData = chart.getDatasetMeta(0).controller._data.slice().reverse();
    const startIndex = reversedControllerData[0] !== 0 ? 0 : reversedControllerData[1] !== 0 ? 1 : 2;
    const lastIndex = reversedControllerData[reversedData.length - 1] !== 0 ? reversedData.length - 1 : reversedControllerData[reversedData.length - 2] !== 0 ? reversedData.length - 2 : reversedData.length - 3;

    reversedData.forEach((arc, index) => {
      if (reversedControllerData[index] === 0) {
        return;
      }

      const startAngle = Math.PI / 2 - arc._view.startAngle;
      const endAngle = Math.PI / 2 - arc._view.endAngle;

      ctx.save();
      ctx.translate(arc.round.x, arc.round.y);
      ctx.fillStyle = arc.round.backgroundColor;
      ctx.beginPath();

      if (index !== startIndex) {
        if (index === lastIndex) {
          ctx.arc(
            arc.round.radius * Math.sin(startAngle),
            arc.round.radius * Math.cos(startAngle),
            arc.round.thickness,
            0,
            2 * Math.PI
          );
        }
        ctx.arc(
          arc.round.radius * Math.sin(endAngle),
          arc.round.radius * Math.cos(endAngle),
          arc.round.thickness,
          0,
          2 * Math.PI
        );
      }
      ctx.closePath();
      ctx.fill();
      ctx.restore();
    });
  },
}