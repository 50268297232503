import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
import _get from 'lodash/get'
import _forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FollowedIdols from 'src/components/personal-profile/FollowedIdols';
import MembershipList from 'src/components/personal-profile/MembershipList';
import SavedPostsList from './SavedPostsList';
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/components/personal-profile/simpleTabs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SimpleTabs() {
  const [value, setValue] = useState(null);
  const { pathname } = useLocation()
  const history = useHistory()
  
  const { t } = useTranslation("personal-profile");

  const infoArr = useMemo(() => [
    {
      header: t('creator'),
      path: "/personal-profile",
      children: <FollowedIdols />,
    },
    {
      header: t('my-membership'),
      path: "/tier-management",
      children: <MembershipList />,
    },
    {
      header: t('saved-posts'),
      path: "/saved-posts",
      children: <SavedPostsList />,
    }
    
  ], [t])

  useEffect(() => {
    console.log("Personal-profile: simple tabs pathname", pathname)
    _forEach(infoArr, ({ path }, index) => {
      console.log("Personal-profile: forEach path", path, "index", index)
      if(path === pathname) setValue(index)
    })
  }, [pathname, infoArr])

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    console.log("Personal-profile: tab handleChange newValue", newValue)
    history.push(_get(infoArr, `[${newValue}].path`))
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBarRoot}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          {
            infoArr.map((info, index) => {
              console.log("SimpleTabs: infoArr map", info)
              return (
                <Tab
                  key={`tab_${index}`}
                  label={info.header}
                  {...a11yProps(index)}
                  classes={{ selected: classes.selected, root: classes.tabRoot }}
                />
              )
            })
          }
        </Tabs>
      </AppBar>
      {
        infoArr.map((info, index) => {
          return (
            <TabPanel
              key={`tab_panel_${index}`}
              value={value}
              index={index}
            >
              {info.children}
            </TabPanel>
          )
        })
      }
    </div>
  );
}