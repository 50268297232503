import { 
    SET_TO_PUSH_AFTER_AUTH,
} from "./actionTypes";
import initialState from "./initialState";

export const locationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TO_PUSH_AFTER_AUTH:
            return {
                ...state,
                pushAfterAuth: action.payload,
            }
        default:
            return state
    }
}
