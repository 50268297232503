import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  copyright: {
    marginTop: 10
  },
  dot: {
    height: 2,
    width: 2,
    borderRadius: '50%',
    margin: '0 5px',
    backgroundColor: theme.palette.grey[500]
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
}), { index: 1 });

export default useStyles;