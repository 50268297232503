import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    marginLeft: -14,
    paddingTop: 5
  },
  icon: {
    marginRight: 5
  },
  errorText: {
    color: theme.palette.error.main
  }
}), { index: 1 });

export default useStyles;