import React, {
  useState,
  useEffect,
  useMemo,
} from "react";
import { connect } from "react-redux";

import _get from "lodash/get";

import { getLoggedInUser } from "src/redux/user/actions";
import TextareaAutosize from 'react-textarea-autosize';

import {
  Chat,
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Thread,
  Window,
  Attachment,
  useMessageInputContext,
  useChannelActionContext,
  MessageInputSmall,
  AutoCompleteTextarea,
  
  
} from "stream-chat-react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import "stream-chat-react/dist/css/index.css";
import CustomChannelHeader from "src/components/chat/CustomChannelHeader";
import { Button, TextField } from "@material-ui/core";
// const { sendMessage } = useChannelActionContext();
const customStyles = {
  "--primary-color": "#9900FF",
  "--md-font": "1.2rem",
  "--xs-m": "1.2rem",
  "--xs-p": "1.2rem",
};


export const tanslatorjson="chat"
const ChatRoute = ({
  userId,
  chatClient,
  getLoggedInUser
}) => {
  const { t } = useTranslation(tanslatorjson);
 const [chatuserinfo,setChatuserinfo]=useState(null)
 const authinfo = useSelector((state) => state.auth)
  //
  useEffect(() => {
    if (userId) {
      // check user.isEntitledToChat
      getLoggedInUser(userId);
    }
  }, [getLoggedInUser, userId,chatuserinfo]);

  const filters = useMemo(
    () => ({
      members: { $in: [userId] },
    }),
    [userId]
  );
// console.log("chatroute--------------",chatuserinfo?.data)

const messageContainer=()=>{
 
  let message=` ${t("you-have-block")} ${chatuserinfo?.receivername}. ${t("to-continue-contacting")} ${chatuserinfo?.receivername}, ${t("please-unblock.")}`
  let message1=`${chatuserinfo?.receivername} ${t("blocked-you-please-wait-to-unblock")}`

    return(
   
      
      <div style={{height:30,position:"absolute",bottom:30,width:'100%',textAlign:'center',paddingInlineEnd:20,marginRight:30,backgroundColor:'#fff',color:'red'}}>
        {(chatuserinfo?.data?.fanId==authinfo?.userId)?message:message1}
        </div>
    
  )
}


  if (!chatClient)
    return <div>no chat client</div>;

  return (
    <div className="custom-scrollbar">
    <Chat
      client={chatClient}
      theme="messaging light"
      customStyles={customStyles}
    >
      <ChannelList
        filters={filters}
        // sort={sort}
      />
      <Channel TypingIndicator={() => null} 
        // Input={CustomInput}
        Attachment={Attach}
        FileUploadIcon={Attach}
      >
        <Window>
          <CustomChannelHeader setChatuserinfo={setChatuserinfo}/>
          <MessageList />
             {chatuserinfo==null ?<MessageInput/>:
            
             messageContainer()
          
             }
        </Window>
        <Thread />
      </Channel>
    </Chat>
    </div> 
  );
};

const Attach = () => {
  return(
    <div
    style={{height:"0px",width:"0px"}}
    />
  )
}
const mapStateToProps = (state) => ({
  userId: _get(state, "auth.userId"),
  chatClient: _get(
    state,
    "chat.chatClient"
  ),
});

const mapDispatchToProps = (
  dispatch
) => ({
  getLoggedInUser: (userId) =>
    dispatch(getLoggedInUser(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatRoute);
