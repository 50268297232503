import _get from 'lodash/get'
import { 
    SET_TO_PUSH_AFTER_AUTH,
} from "./actionTypes";
import { encodeMessage } from "src/lib/encrypt";

export const setToPushAfterAuth = payload => ({
    type: SET_TO_PUSH_AFTER_AUTH,
    payload,
})

export const getSubscribeQuery = (tierId,) => (dispatch, getState) => {
    try {
        const userId = _get(getState(), "auth.userId")
        const accessTokan = _get(getState(), "auth.access_token")
    
        return `${encodeMessage(userId)}_${encodeMessage(`${userId}_${accessTokan}`)}_${encodeMessage(tierId)}`
    } catch(err) {
        console.log(err)
        return new Error(_get(err, "message", "Something went wrong"))
    }
}