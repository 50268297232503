import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    width: '100%',
    boxSizing: 'border-box'
  },
  loadingContainer: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: 25,
    borderRadius: '100px',
    padding: '10px',
    width: 150
  }
}), { index: 1 })

export default useStyles;