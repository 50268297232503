import React, { useState } from 'react'
import { ReactComponent as FanshipLogo } from 'src/images/common/fanship_logo.svg';
import { ReactComponent as InternetIcon } from 'src/images/common/icon_internet.svg';
import clsx from "clsx";
import useStyles from "src/styles/components/header/noUserHeader";
import { Container } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import LanguageDialog from 'src/components/header/LanguageDialog';
import {
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

export default function NoUserHeader() {
  const classes = useStyles();
  const { t } = useTranslation('header');
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

  const onClickInternet = () => {
    setLanguageDialogOpen(true);
  }

  const closeLanguageDialog = () => {
    setLanguageDialogOpen(false);
  }

  return (
    <>
      <div className={classes.root}>
        <Container className={clsx(classes.flexContainer, classes.container)}>
          <NavLink to="/">
            <FanshipLogo className={classes.logo} />
          </NavLink>
          <div>
<Button
              
              variant="contained"
              color="primary"
              className={[classes.button]}
              style={{borderRadius:'25px'}}
              type="submit"
            >
              <Typography variant="body1">
                {t("become-a-creator")}
              </Typography>
            </Button>
           
          <InternetIcon
     style={{marginLeft:'37px',marginBottom:'-9px'}}
            className={[classes.internetIcon]}
            onClick={onClickInternet} 
          />
         
          </div>
        </Container>
      </div>
 <LanguageDialog
        open={languageDialogOpen}
        handleClose={closeLanguageDialog}
      /> 
    </>
  );
}