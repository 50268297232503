import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import env from "../env";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useTranslation } from 'react-i18next';
import useStyles from 'src/styles/components/payment';
import { Grid, Typography, Paper, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as ExploringImg } from "src/images/common/exploring_locations.svg";
import clsx from 'clsx';
import InfoDialog from "src/components/InfoDialog";

const crypto = require("crypto");

const Payment = () => {
  const { t } = useTranslation("subscribe");
  const { t: te } = useTranslation("error");
  const classes = useStyles();
  const [todayDate, setTodayDate] = useState("");
  const [tierId, setTierId] = useState(undefined);
  const [tier, setTier] = useState(undefined);
  const [userId, setUserId] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogHeader, setInfoDialogHeader] = useState("");
  const [infoDialogMsg, setInfoDialogMsg] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let requestParameter = window.location.search.substring(1);
  let requestData = requestParameter.split("_");

  const closeDialog = () => {
    console.log('close dialog');
    setInfoDialogOpen(false);
  }

  useEffect(() => {
    let dateObj = moment(new window.Date()).format("YYYY/MM/DD");
    setTodayDate(dateObj);

    if (requestData.length === 3) {
      const tempUserId = getDecodeValue(requestData[0]);
      const tempAccessToken = getDecodeValue(requestData[1]);
      const tempTierId = getDecodeValue(requestData[2]);

      console.log(
        "userId",
        tempUserId,
        "accessToken",
        tempAccessToken,
        "tierId",
        tempTierId
      );

      setTierId(tempTierId);
      setUserId(tempUserId);
      setAccessToken(tempAccessToken);
    }
  }, [requestData]);

  /**
   * Get decode values
   * @param {*} value
   * @returns
   */
  const getDecodeValue = (value) => {
    const initVector = Buffer.from(env.iv);
    const SecurityKey = Buffer.from(env.securitykey);
    let encryptedText = Buffer.from(value, "hex");
    let decipher = crypto.createDecipheriv(
      env.algorithm,
      SecurityKey,
      initVector
    );
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    console.log("decrypted: ", decrypted.toString());

    return decrypted.toString();
  };

  useEffect(() => {
    if (tierId) {
      const getTier = async () => {
        try {
          const res = await fetch(`https://${env.apiDomain}/tiers/${tierId}`, {
            method: "Get",
            headers: {
              Authorization: `${accessToken}`,
            },
          });

          const result = await res.json();
          console.log("result: ", result);
          if (result.success) {
            console.log("paypal plan id: ", result.data.paypalPlanId);
            setTier(result.data);
          }
        } catch (err) {
          console.log("err: ", err);
        }
      };

      getTier();
    }
  }, [userId, tierId, accessToken]);

  const _createSubscription = (data, actions) => {
    console.log("plan_id: ", tier.paypalPlanId);
    return actions.subscription.create({
      plan_id: `${tier.paypalPlanId}`,
    });
  };

  const _onApprove = async (data, actions) => {
    console.log("actions: ", actions);
    console.log("subscription: ", data.subscriptionID);

    const subscriptionObj = {
      subscriptionID: data.subscriptionID,
      status: 'SUCCESS'
    }

    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(subscriptionObj));

    setPaymentSuccess(true);

    try {
      const res = await fetch(
        `https://${env.apiDomain}/tiers/${tierId}/subscribe`,
        {
          method: "POST",
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            subscriptionId: data.subscriptionID,
            data,
            platform: "web",
          }),
        }
      );

      const result = await res.json();
      console.log("result: ", result);
      if (result.success) {
        console.log("data: ", result.data);
      } else {
        console.log("err: ", result.code);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const _onError = (err) => {
    console.log(err);

    let errObj = {
      err: err,
      status: "FAILED",
    };

    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(errObj));

    if (err !== "Canceled") {
      setInfoDialogHeader(te("title"));
      setInfoDialogMsg(te("code.UNEXPECTED_ERROR"));
      setInfoDialogOpen(true);
    }
  };

  useEffect(() => {
    console.log("update tier value: ", tier);
  }, [tier]);

  return (
    <>
      <div
        className={classes.root}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} className={isLargeScreen && classes.largeScreenLeftGrid}>
            <div className={clsx(classes.cardRoot, isLargeScreen && classes.largeScreenCardRoot)}>
              {
                !paymentSuccess ?
                  <>
                    <Typography variant="h3" align="left" className={classes.header}>
                      {t('header')}
                    </Typography>
                    <Typography variant="body1" align="left">
                      {t('description')}
                    </Typography>
                    <div className={classes.paypalButtonContainer}>
                      {tier && (
                        <PayPalButtons
                          style={{ shape: "pill" }}
                          createSubscription={(data, details) =>
                            _createSubscription(data, details)
                          }
                          onApprove={(data, actions) => _onApprove(data, actions)}
                          onCancel={() => _onError("Canceled")}
                          onError={(err) => _onError(err)}
                        />
                      )}
                    </div>
                  </> :
                  <>
                    <Typography variant="h3" align="left" className={classes.header}>
                      {t('success.header')}
                    </Typography>
                    <Typography variant="body1" align="left">
                      {t('success.description')}
                    </Typography>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.successButton}
                      onClick={() => dispatch(push('/'))}
                    >
                      {t('success.view-content')}
                    </Button>
                  </>
              }

            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {
              !paymentSuccess ?
                <>
                  {tier && (
                    <Paper classes={{ root: clsx(classes.summaryCard, classes.cardRoot, isLargeScreen && classes.largeScreenCardRoot) }}>
                      <Typography variant="h5">{t('summary')}</Typography>
                      <div className={clsx(classes.flexContainer, classes.middleContainer)}>
                        <Typography variant="body1" className={classes.textBold}>
                          {tier.title}
                        </Typography>
                        <Typography variant="h4" color="primary">
                          ${tier.price}
                          <Typography variant="body2" component="span" color="textSecondary">
                            {'/' + t('per-month')}
                          </Typography>
                        </Typography>
                      </div>
                      <div className={clsx(classes.flexContainer, classes.middleContainer)}>
                        <Typography variant="body1">
                          {t('first-payment')}
                        </Typography>
                        <Typography variant="body1" className={classes.textBold}>
                          {todayDate}
                        </Typography>
                      </div>
                      <div className={classes.bottomContainer}>
                        <Typography variant="body2" className={classes.textBold}>
                          {t('auto-payment')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {t('agree-t&c')}
                        </Typography>
                      </div>
                    </Paper>
                  )}
                </> :
                <div className={classes.cardRoot}>
                  <ExploringImg />
                </div>
            }
          </Grid>
        </Grid>
      </div>
      <InfoDialog
        open={infoDialogOpen}
        title={
          <Typography variant="h6" className={clsx(classes.textBold, classes.dialogTitle)}>
            {infoDialogHeader}
          </Typography>
        }
        button={
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.dialogButton}
            onClick={closeDialog}
          >
            {t("confirm")}
          </Button>
        }
        handleClose={closeDialog}
      >
        <Typography variant="body1" align="left" className={classes.dialogContent}>
          {infoDialogMsg}
        </Typography>
      </InfoDialog>
    </>
  );
};

export default Payment;
