import { makeStyles } from "@material-ui/styles"
import { newHeight, newWidth } from "../screenStyle"

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        position: "relative",
        display: "inline-block",
    },
    dummy: {
        marginTop: "100%",
    },
    mediaList: {
        "& p": {
            background: "black",
            fontSize: "12px !important",
            borderRadius: "30px",
            marginRight: "5px",
            padding: "0px 10px !important",
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            height: 25,
        }
    },
    imageHolder: {
        [theme.breakpoints.up('lg')]: {
            height: newWidth(562),
        },
        [theme.breakpoints.down('md')]: {
            height: newHeight(600), 
        }
    },
    image: {
        objectFit: "cover",
        objectPosition: "center center",
        height: "100%",
    },
    videoHolder: {
        [theme.breakpoints.up('lg')]: {
            width: newWidth(562),
        },
        width: "100%",
        height: "fit-content",
    },
    video: {
        width: "100%",
    },
}))

export default useStyles