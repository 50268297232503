import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import _get from "lodash/get";
import { setEditTierId } from "src/redux/creatorOnboarding/actions";
import CropAvatarDialog from "src/components/creator-onboarding/LockedplanDailog";
import { Paper, Typography, Button } from "@material-ui/core";
import useStyles from "src/styles/pages/paidTire";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconVisibility } from "src/images/common/icon_edit.svg";
import { ReactComponent as UnlockedIcon } from "src/images/common/icon_unlock.svg";
import { ReactComponent as LockIcon } from "src/images/common/icon_lock.svg";
import { ReactComponent as MailOpenIcon } from "src/images/common/icon_mail_open.svg";
import { push, replace } from "connected-react-router";
import {
  setToPushAfterAuth,
  getSubscribeQuery,
} from "src/redux/location/actions";
import LockedplanDailog from "src/components/creator-onboarding/LockedplanDailog";
const PiadTierView = (props) => {
  console.log(
    "rendering PiadTierView with props",
    props,
    _get(props, "item.tierId", null)
  );
  useEffect(() => {
    if (
      replaceToTierPayment &&
      _get(props, "item.tierId") === replaceToPaymentFor &&
      !_get(props, "item.userSubscribed")
    ) {
      console.log("replacing to payment for tier", replaceToPaymentFor);
      dispatch(
        replace(
          `/subscribe/?${dispatch(
            getSubscribeQuery(_get(props, "item.tierId"))
          )}`
        )
      );
      // check to clear pushAfterAuth on current component
      clearPathToPushOn === "PaidTierView" &&
        dispatch(setToPushAfterAuth(null));
    }
    // eslint-disable-next-line
  }, []);
  const avatar = useSelector((state) => state.auth.avatar);
  const [formAvatar, setFormAvatar] = useState(avatar);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userId = useSelector((state) => state.auth.userId);
  const isAuthenticated = useSelector((state) =>
    _get(state, "auth.isAuthenticated")
  );
  const replaceToTierPayment = useSelector((state) =>
    _get(state, "location.pushAfterAuth.replaceToTierPayment")
  );
  const replaceToPaymentFor = useSelector((state) =>
    _get(state, "location.pushAfterAuth.tierId")
  );
  const clearPathToPushOn = useSelector((state) =>
    _get(state, "location.pushAfterAuth.clearOn")
  );
  const { fanshipPageName } = useParams();
  const isTierOwner = props.item.userId === userId;
  const classes = useStyles({
    // formElementBodyWidth: props.tierWidth,
  });
  const [LockedpanDailogOpen, setLockedpanDailogOpen] = useState(false);
  const { t } = useTranslation("creator-onboarding");
  const { t: tcp } = useTranslation("creator-profile");
  var date =
    new Date(props.item.createdAt).getFullYear() +
    "年" +
    new Date(props.item.createdAt).getMonth() +
    "月" +
    new Date(props.item.createdAt).getDate() +
    "日";
  const closeLockedpanDailog = () => {
    setLockedpanDailogOpen(false);
  };
  const handleSubscribe = useCallback(() => {
    if (isAuthenticated) {
      !_get(props, "item.userSubscribed") &&
        dispatch(
          push(
            `/subscribe/?${dispatch(
              getSubscribeQuery(_get(props, "item.tierId"))
            )}`
          )
        );
    } else {
      dispatch(
        setToPushAfterAuth({
          path: `/creator/${fanshipPageName}`,
          replaceToTierPayment: true,
          tierId: _get(props, "item.tierId"),
          clearOn: "PaidTierView",
        })
      );
      dispatch(push("/registration"));
    }
    // eslint-disable-next-line
  }, [
    isAuthenticated,
    fanshipPageName,
    props?.item?.tierId,
    props?.item?.userSubscribed,
  ]);
  console.log("props.item.followingCount", props.item.followingCount);
  return (
    <>
      {props.item.userId === "admin2021" ? (
        <Paper className={classes.formElementBody}>
          <div
            className={clsx(
              classes.flexPaidContainerMain,
              !isTierOwner && classes.nonTierOwnerTierHeading
            )}
          >
            <Typography variant="h6" className={classes.textBold}>
              {props.item.title}
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "#9900FF",
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <img src={props.item.image} alt="tier-img" width={"100%"}></img>
            </div>
            {/* pending work */}
            <div className={classes.flexPaidContainerMain}>
              {isTierOwner ? (
                <div>
                  <div className={classes.flexContainer}></div>
                  <div className={classes.flexContainer}></div>
                </div>
              ) : props.item.capacity ? (
                <div
                  className={classes.quotaContainer}
                  style={{ color: "#FFFFFF" }}
                >
                  <Typography variant="body2">
                    {tcp("following-Count", { number: props.item })}
                  </Typography>
                </div>
              ) : (
                <div className={classes.flexContainer}>
                  <MailOpenIcon className={classes.mailIcon} />
                  <Typography
                    variant="caption"
                    className={classes.textBold}
                    style={{ color: "#FFFFFF" }}
                  >
                    {props.item.postCount}
                    {tcp("post")}
                  </Typography>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <Typography
                  className={classes.priceFont}
                  style={{ color: "#FFFFFF" }}
                >
                  ${props.item.price}
                </Typography>
                <Typography
                  className={classes.textInput}
                  style={{ color: "#FFFFFF" }}
                >
                  {t("per-month")}
                </Typography>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: 63,
                marginTop: 20,
              }}
            >
              <Typography
                className={classes.description}
                style={{ color: "#FFFFFF" }}
              >
                {props.item.description}
              </Typography>
            </div>
          </div>
          <LockedplanDailog
            open={LockedpanDailogOpen}
            handleClose={closeLockedpanDailog}
            setFormAvatar={setFormAvatar}
            handleSubscribe={handleSubscribe}
          />
        </Paper>
      ) : (
        <Paper className={classes.formElementBody}>
          <div
            className={clsx(
              classes.flexPaidContainerMain,
              !isTierOwner && classes.nonTierOwnerTierHeading
            )}
          >
            <Typography variant="h6" className={classes.textBold}>
              {props.item.title}
            </Typography>
            <div>
              {isTierOwner && (
                <Button
                  onClick={() => {
                    if (
                      _get(location, "pathname") === "/creator/onboarding/form"
                    ) {
                      dispatch(setEditTierId(_get(props, "item.tierId", null)));
                      return;
                    }
                    // if on /my-creator-profile
                    // dispatch(setEditTierId(_get(props, "item.tierId", null)))
                    // route to /tier/form/:tierId
                    if (_get(location, "pathname") === "/my-creator-profile") {
                      dispatch(setEditTierId(_get(props, "item.tierId", null)));
                      history.push(
                        `/tier/form/${_get(props, "item.tierId", null)}`
                      );
                    }
                  }}
                  variant="contained"
                  color="primary"
                  className={clsx(classes.button)}
                  startIcon={<IconVisibility />}
                >
                  <Typography variant="body1">{t("edit")}</Typography>
                </Button>
              )}
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <img src={props.item.image} alt="tier-img" width={"100%"}></img>
          </div>
          <div className={classes.flexPaidContainerMain}>
            {isTierOwner ? (
              <div>
                <div className={classes.flexContainer}>
                  <Typography className={classes.textInput}>
                    {t("release-date") + " : "}
                  </Typography>
                  <Typography className={classes.textInput}>{date}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography className={classes.textInput}>
                    {t("number-of-subscribers") + " : "}
                  </Typography>
                  <Typography className={classes.textInput}>
                    {props.item.subscriberCount}
                  </Typography>
                </div>
              </div>
            ) : props.item.capacity ? (
              <div className={classes.quotaContainer}>
                <Typography variant="body2">
                  {tcp("available-quota", {
                    number: +props.item.capacity - +props.item.subscriberCount,
                  })}
                </Typography>
              </div>
            ) : (
              <div className={classes.flexContainer}>
                <MailOpenIcon className={classes.mailIcon} />
                <Typography variant="caption" className={classes.textBold}>
                  {props.item.postCount}
                  {tcp("post")}
                </Typography>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <Typography className={classes.priceFont}>
                ${props.item.price}
              </Typography>
              <Typography className={classes.textInput}>
                {t("per-month")}
              </Typography>
            </div>
          </div>
          {!isTierOwner && (
            <div className={classes.buttonContainer}>
              <Button
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: props.item.userSubscribed
                    ? "#30D158"
                    : "#FF375F",
                  borderRadius: "100px",
                }}
                // className={[props.item.userSubscribed ? classes.unlockedButton : classes.lockedButton]}
                onClick={handleSubscribe}
                disabled={
                  props.item.capacity &&
                  +props.item.capacity - +props.item.subscriberCount <= 0
                }
              >
                {props.item.userSubscribed ? (
                  <UnlockedIcon className={classes.lockIcon} />
                ) : (
                  <LockIcon className={classes.lockIcon} />
                )}
                <Typography
                  variant="body1"
                  className={classes.textBold}
                  style={{ color: "white" }}
                >
                  {props.item.userSubscribed ? tcp("unlocked") : tcp("locked")}
                </Typography>
              </Button>
            </div>
          )}
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              height: 30,
              marginTop: 20,
            }}
          >
            <Typography className={classes.description}>
              {props.item.description}
            </Typography>
          </div>
          <div
            style={{
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 30,
            }}
          >
            <Typography className={classes.a}>{t("see-more")}</Typography>
          </div>
          <LockedplanDailog
            open={LockedpanDailogOpen}
            handleClose={closeLockedpanDailog}
            setFormAvatar={setFormAvatar}
            handleSubscribe={handleSubscribe}
          />
        </Paper>
      )}
    </>
  );
};
export default PiadTierView;
