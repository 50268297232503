import React from 'react'
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormField = ({ input, meta: { touched, error }, alt, containerClass, imageClass, deleteIconClass, editIconClass, handleDelete, handleEdit, imageProps, translatorJson, ...props }) => {

    const { t } = useTranslation(translatorJson);

    return input.value ? 
    <div className={containerClass}>
        <img className={imageClass} src={input.value} alt={alt} {...imageProps}/>
        <IconButton className={deleteIconClass} size="small" aria-label="delete"
            onClick={handleDelete}
        >
            <DeleteOutlinedIcon />
        </IconButton>
        <IconButton className={editIconClass} size="small" aria-label="edit"
            onClick={handleEdit}
        >
            <EditIcon />
        </IconButton>

        {
            touched && error && 
            <FormHelperText error={true}>{t(error)}</FormHelperText>
        }        
    </div>
    : 
        (touched && error) ?
        <FormHelperText error={true}>{t(error)}</FormHelperText>
        : null
        
}

export default FormField
