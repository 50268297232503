import React from 'react'
import useStyles from 'src/styles/pages/privacyPolicy';
import {
  Typography
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';



export default function PrivacyPolicy() {
  const classes = useStyles();
  const { t } = useTranslation("privacy-policy");
  return (
    <div className={classes.mainContainer}>
      <div className={classes.textContainer}>
        <div className={classes.header}>
          <Typography variant="body1">
          {t('p1')}
          </Typography>        
        </div>
        <div>
          <Typography variant="body1">
            {t('p2')}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1">
            {t('p3')}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1">
            {t('p4')}
          </Typography>
        </div>
        <br />       
        <div>
          <Typography variant='body1'>
            {t('p5')}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant='body1'>
            {t('p6')}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant='body1'>
            {t('p7')}            
          </Typography>
        </div>
        <div>
          <Typography variant='body1'>
            {t('p8')}  
          </Typography>
        </div>
      </div>
    </div>
  )
}