import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
      width: 260,
    }
  },
  drawerPaper: {
    // position: 'relative'
    // width: 260,
  },
  rightContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  routeContainer: {
    flex: 1
  }
}), { index: 1 });

export default useStyles;