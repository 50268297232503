import React from 'react'
import { useTranslation } from 'react-i18next';
import TextField from './TextField'

const FormField = ({ input, meta: { touched, error }, label, helperText, translatorJson, ...props }) => {

    const { t } = useTranslation(translatorJson);
    
    return <TextField
        {...input}
        error={(touched && error) ? true : false}
        label={label}
        helperText={ touched && error && (helperText || t(error))}
        {...props}
    />
}

export default FormField
