export const SET_POST_FEED = "SET_POST_FEED"
export const CLEAR_POST_FEED = "CLEAR_POST_FEED"
export const SET_IS_FEED_LOADING = "SET_IS_FEED_LOADING"
export const SET_POST_LIKED = "SET_POST_LIKED"
export const UNSET_POST_LIKED = "UNSET_POST_LIKED"
export const SET_POST_SAVED = "SET_POST_SAVED"
export const SET_POST_UNSAVED = "SET_POST_UNSAVED"
export const NEW_COMMENT_TO_POST_SUCCESS = "NEW_COMMENT_TO_POST_SUCCESS"
export const REPLY_TO_COMMENT_FOR_POST_SUCCESS = "REPLY_TO_COMMENT_FOR_POST_SUCCESS"
export const SET_COMMENTS_LIST_FOR_POST = "SET_COMMENTS_LIST_FOR_POST"
export const SET_FEWER_COMMENTS_FOR_POST = "SET_FEWER_COMMENTS_FOR_POST"
export const SET_COMMENT_TO_POST_LIKED = "SET_COMMENT_TO_POST_LIKED"
export const UNSET_COMMENT_TO_POST_LIKED = "UNSET_COMMENT_TO_POST_LIKED"
export const SET_REPLY_TO_COMMENT_LIKED = "SET_REPLY_TO_COMMENT_LIKED"
export const UNSET_REPLY_TO_COMMENT_LIKED = "UNSET_REPLY_TO_COMMENT_LIKED"
export const SET_IDOL_FOLLOWED = "SET_IDOL_FOLLOWED"
export const SET_IDOL_UNFOLLOWED = "SET_IDOL_UNFOLLOWED"
export const UNSET_REPLY_TO_COMMENT_DELETE = "UNSET_REPLY_TO_COMMENT_DELETE"
export const  UNSET_REPLY_DELETE= "UNSET_REPLY_DELETE"