import React from 'react'
import useStyles from "src/styles/components/accountFormHint";
import { Typography } from "@material-ui/core";
import { ReactComponent as CrossIcon } from "src/images/common/icon_cross.svg";
import { ReactComponent as TickIcon } from "src/images/common/icon_tick.svg";
import clsx from "clsx";

export default function AccountFormHint({
  type,
  text
}) {
  const classes = useStyles();

  function renderIconByType(type) {
    switch (type) {
      case 'error':
        return <CrossIcon />
      case 'success':
        return <TickIcon />
      case 'warning':
        return <TickIcon className={classes.yellowTick} />
      default:
        break;
    }
  }

  function renderClassNameByType(type) {
    switch (type) {
      case 'error':
        return classes.error;
      case 'success':
        return classes.success;
      case 'warning':
        return classes.warning;
      default:
        break;
    }
  }

  return (
    <div className={clsx(classes.flexContainer, classes.root)}>
      <div className={classes.icon}>
        {renderIconByType(type)}
      </div>
      <Typography variant="caption"
        className={renderClassNameByType(type)}
      >
        {text}
      </Typography>
    </div>
  )
}