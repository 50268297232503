import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import _get from 'lodash/get'
// import _trim from 'lodash/trim'

import { newCommentToPost , replytoCommentforPost} from 'src/redux/feed-post/actions'
import RenderTextField from 'src/components/renderTextField/FormField'

import Box from '@material-ui/core/Box'

import useStyles from 'src/styles/components/feed-post/post.js'

import defaultAvatar from 'src/images/common/default_avatar.png'


const NewComment = ({
    handleSubmit,
    submitting,
    postId,
    commentTo,
    avatar,
}) => {
    const classes = useStyles()
    return (
        <Box className={classes.newCommentForm}>
            <Box className={classes.avatarHolder}>
                <img
                    src={avatar ? avatar : defaultAvatar}
                    alt="avatar"
                    placeholder={defaultAvatar}
                >
                </img>
            </Box>
            <Box className={classes.newCommentInputHolder}>

                <Field 
                    name="comment"
                    component={RenderTextField}
                    InputProps={{
                        className: classes.newCommentInput,
                        classes: {
                            notchedOutline: classes.noBorder,
                        }
                    }}
                    variant="outlined"
                    fullWidth={true}
                    onKeyPress={(event, key) => {
                        if(_get(event, "key") === "Enter") handleSubmit()
                    }}
                    disabled={submitting}
                    placeholder={`以 ${commentTo} 留言...`}
                />
            </Box>
        </Box>
    )
}

const mapStateToProps = state => ({
    avatar: _get(state, "auth.avatar"),
})

const mapDispatchToProps = dispatch => ({
    newCommentToPost: (postId, content) => dispatch(newCommentToPost(postId, content)),
    replytoCommentforPost: (postId, commentId, reply) => dispatch(replytoCommentforPost(postId, commentId, reply)),
})

// const validate = values => {
    
    // errors must be imported through translator.
//     const errors = {}
    
//     if(!_trim(_get(values, "comment"))) errors["comment"] = "必須"

//     return errors
// }

const onSubmitSuccess = (result, dispatch, props) => {
    console.log("new comment onSubmitSuccess")
    props.initialize?.()
}

const onSubmitFail = () => {
    console.log("new comment onSubmitFail")
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
        // validate,
        // touchOnBlur: false,
        enableReinitialize: true,
        onSubmitSuccess,
        onSubmitFail,
    })(NewComment)
)
