import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { getMyNotifications } from 'src/redux/notification-center/actions'

import Notification from 'src/components/notification-center/Notification'

import useStyles from 'src/styles/pages/notificationCenter'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from "@material-ui/core/CircularProgress"

const NotificationCenter = ({
    getMyNotifications,
}) => {
    const [ notifications, setNotifications ] = useState(null)

    useEffect(() => {
        getMyNotifications().then(notifications => {
            setNotifications(notifications)
        }).catch(err => {
            console.log(err)
            enqueueSnackbar("Something went wrong", { variant: "error", })
        })

        // eslint-disable-next-line
    }, [])

    const { t } = useTranslation("notification-center")
    const { enqueueSnackbar } = useSnackbar()

    const classes = useStyles()

    return (
        <Container maxWidth="sm" className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h3">
                    {t("activity")}
                </Typography>
                {
                    notifications ? <>
                        {
                            _get(notifications, "today.length") ? <Box className={classes.Group}>
                                <Typography variant="h5">
                                    {t("today")}
                                </Typography>
                                {
                                    _map(_get(notifications, "today"), notification => <Notification 
                                        key={_get(notification, "notificationId")}
                                        notification={notification}
                                    />)
                                }
                            </Box> : null
                        }
                        {
                            _get(notifications, "this-week.length") ? <Box className={classes.Group}>
                                <Typography variant="h5">
                                    {t("this-week")}
                                </Typography>
                                {
                                    _map(_get(notifications, "this-week"), notification => <Notification 
                                        key={_get(notification, "notificationId")}
                                        notification={notification}
                                    />)
                                }
                            </Box> : null
                        }
                        {
                            _get(notifications, "earlier.length") ? <Box className={classes.Group}>
                                <Typography variant="h5">
                                    {t("earlier")}
                                </Typography>
                                {
                                    _map(_get(notifications, "earlier"), notification => <Notification 
                                        key={_get(notification, "notificationId")}
                                        notification={notification}
                                    />)
                                }
                            </Box> : null
                        }
                    </> : <Box className={classes.progressHolder}>
                    <CircularProgress />
                  </Box>
                }
            </Paper>
        </Container>
    )
}

const mapDispatchToProps = dispatch => ({
    getMyNotifications: () => dispatch(getMyNotifications()),
})

export default connect(
    null,
    mapDispatchToProps,
)(NotificationCenter)
