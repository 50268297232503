import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'


import _get from 'lodash/get'
import _trim from 'lodash/trim'

import { getCommentsForPost } from 'src/redux/feed-post/actions'

import { useSnackbar } from 'notistack'

import NewComment from './NewComment'
import CommentsList from './CommentsList'
import CommentsListMeta from './CommentsListMeta'

import Box from '@material-ui/core/Box'
import useStyles from 'src/styles/components/feed-post/post.js'


const   CommentsHolder = ({
    postId,
    commentCount,
    postOwnerName,
    comments,
    onDeletecomment
}) => {
    const [ isCommentsListLoading, setIsCommentsListLoading ] = useState(false)

    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const classes = useStyles()

    const onSubmit = useCallback((values, dispatch, props) => {
        console.log("new comment onSubmit", values)
        
        if(!_trim(_get(values, "comment"))) throw new Error("Required")
        
        return props.newCommentToPost?.(_get(props, "postId"), _get(values, "comment"))
        .catch(err => {
            console.log("catch: new comment submit failed", err)
            enqueueSnackbar("Something went wrong", { variant: "error", })
            throw new Error(err)
        })

        // eslint-disable-next-line 
    }, [])

    const handleLoadMoreComments = useCallback(() => {
        setIsCommentsListLoading(true)

        // get all(0-n) comments for the post 
        dispatch(getCommentsForPost(postId))
        .catch(err => {
            console.log("getCommentsForPost failed", err)
            enqueueSnackbar("Something went wrong", { variant: "error", })
        }).finally(() => {
            setIsCommentsListLoading(false)
        })

        // eslint-disable-next-line 
    }, [postId])

    return (
        <Box className={classes.commentsHolder}>
            <NewComment 
                form={`comment-for-post-${postId}`}
                postId={postId}
                commentTo={postOwnerName}
                onSubmit={onSubmit}
            />
            {commentCount ? <>
                <CommentsList 
                    postId={postId}
                    comments={comments}
                    isCommentsListLoading={isCommentsListLoading}
                    onDeletecomment={onDeletecomment}
                />
                <CommentsListMeta 
                    postId={postId}
                    comments={comments}
                    commentCount={commentCount}
                    handleLoadMoreComments={handleLoadMoreComments}
                />
            </> : null}
        </Box>
    )
}

export default CommentsHolder
