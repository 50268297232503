import React from 'react'
import { Typography, Paper } from '@material-ui/core';
import useStyles from 'src/styles/components/dashboard/performance';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function NextPaymentCard({
  nextPaymentAmount,
}) {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");

  return (
    <Paper className={clsx(classes.paperRoot, classes.nextPaymentCard)}>
      <Typography variant="h5" align="left" className={classes.nextPaymentCardContent}>
        {t('tab-1.main-stat.next-payment-receipt')}
      </Typography>
      <div className={classes.nextPaymentCardContent}>
        <Typography variant="h1">
          {'$' + Intl.NumberFormat('zh-HK').format(
            Number.isInteger(nextPaymentAmount) ? nextPaymentAmount : nextPaymentAmount.toFixed(2)
          )}
          <Typography component="span" color="textSecondary" variant="caption">
            USD
          </Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('tab-1.main-stat.expected-next-receipt-date', { year: 2021, month: 8, day: 1 })}
        </Typography>
      </div>
    </Paper>
  )
}