import React, { useState,useEffect } from 'react'

import Feed from 'src/components/feed-post/Feed'
import RightCards from 'src/components/RightCards'
import Box from '@material-ui/core/Box'
import defaultAvatar from 'src/images/common/default_avatar.png'
import useStyles from 'src/styles/pages/home'
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Paper, Button } from "@material-ui/core";
import MakeaPost from 'src/components/dashboard/makeaPost';
import env from 'src/env'
import axios from 'axios'
import {
    Typography
} from "@material-ui/core";

const Home = (post) => {
    console.log("post",post)
    const { t } = useTranslation("Home");
    const [makePostDialogOpen, setmakePostDialogOpen] = useState(false);    
    const [isEdit, setIsEdit] = useState(false); 
    const [isRefresh, setIsRefresh] = useState(false); 
    const [postData, setPostData] =  useState();
    const isIdol = useSelector(state => _get(state, 'auth.isIdol'));
    const displayName = useSelector((state) => state.auth.displayName);
    console.log("state.auth", useSelector((state) => state.auth));
    const classes = useStyles()
    const avatar = useSelector((state) => state.auth.avatar);
    const closeMakePostDialog = (data) => {
        setIsEdit(false)
        setmakePostDialogOpen(false);
        if(data == 1 ){
            setIsRefresh(true)
        }else{
            setIsRefresh(false)
        }
        
    }

    const onClickMakePost = () => {
        setIsRefresh(false)
        setmakePostDialogOpen(true);
    }

    const dialogView = () => {
        return (
            <Box className={classes.root} >
                <Box className={classes.spaceLeft}></Box>
                <Box className={classes.main}>
                    <div className={classes.makepostbox}>
                        <Paper className={{ root: classes.makepostpaper }} style={{ boxShadow: 'none' }}>
                            <Box className={classes.avatarHolder}>
                                <img
                                    src={avatar ? avatar : defaultAvatar}
                                    alt="avatar"
                                    className={classes.makeavatar}/>
                                <Box style={{ padding: 0, width: "100%" }}>
                                    <Button style={{ padding: 0, width: "100%", backgroundColor: "transparent" }}
                                        onClick={onClickMakePost}>
                                        <Box className={classes.makeBtn}>
                                            <Typography style={{ color: '#ADB5BD' }}>
                                                {displayName}
                                            </Typography>
                                            <Typography style={{ color: '#ADB5BD' }} variant="body1">
                                                {t(' > Make-a-Post')}
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>
                    </div>
                    {
                        makePostDialogOpen &&
                        <MakeaPost
                            post={postData}
                            isEdit={isEdit}
                            open={makePostDialogOpen}
                            handleClose = {(data) => {
                                closeMakePostDialog(data)
                            }}
                        />
                    }
                </Box>
                <Box style={{ width: '290px' }}>

                </Box>
            </Box>
        );
    };
    useEffect(()=>{
        console.log("isIdol",isIdol)
    },[])

    return (
        <>
            {isIdol &&
                dialogView()
            }
            <Box
                className={classes.root}
            >
                <Box className={classes.spaceLeft}></Box>
                <Box className={classes.main}>
                    <Feed 
                        isRefresh = {
                            isRefresh
                        }
                        handleCloseRefresh = {() =>
                            setIsRefresh(false)
                        }
                        onClickofEdit={(data) => { 
                            
                            setIsEdit(true)    
                            setPostData(data)
                            setmakePostDialogOpen(true)
                        }}
                    />
                </Box>
                <Box className={classes.spaceRight}>
                    <Box className={classes.rightCards}>
                        <RightCards />
                    </Box>
                </Box>
            </Box>


        </>
    )
}
export default Home