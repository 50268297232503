export function newHeight(value) {
    const screenHeight = window.innerHeight;
    const heightScale = screenHeight / 1024;
    
    return heightScale * value + "px"
}

export function newWidth(value) {
    const screenWidth = window.innerWidth;
    const widthScale = screenWidth / 1600;
    
    return widthScale * value + "px"
}