import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { getPaidTiersList, setTierFormOpen } from 'src/redux/creatorOnboarding/actions'
import { getLoggedInUser } from 'src/redux/user/actions'
import { setToPushAfterAuth } from 'src/redux/location/actions'
import { getPostsByIdolId, setPostFeed, clearPostFeed } from 'src/redux/feed-post/actions'
import { initChatChannel } from 'src/redux/chat/actions'

import { creatorOnboardingFormName } from 'src/pages/CreatorOnboardingForm'
import PiadTierView from './PaidTierView';


import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Link from '@material-ui/core/Link'
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoadingButton from "src/components/LoadingButton";
import Post from 'src/components/feed-post/Post';
import defaultAvatar from 'src/images/common/Default_Avatar_2.png'
import placeHolderPng from 'src/images/common/placeholder.png'
import { ReactComponent as VerifiedIcon } from 'src/images/common/icon_verified.svg';
import { ReactComponent as MoreCircleIcon } from 'src/images/common/icon_more_circle.svg';
import { ReactComponent as AlertTriangleIcon } from 'src/images/common/icon_black_alert_triangle.svg';
import { ReactComponent as AddIcon } from "src/images/common/plus.svg";

import useStyles from 'src/styles/components/creator-onboarding/preview';
import clsx from 'clsx';

import env from 'src/env';

const gap = 15

const tabs = ["paid-plan", "post"]

export const Preview = ({
    id = localStorage.getItem('userId'),
    isIdol = true,
    userFollowed = false,
    isEntitledToChat = false,
    coverPhoto,
    avatar,
    displayName,
    paidTiersList,
    fullName,
    numberOfFans,
    numberOfSubscribers,
    profile,
    getPaidTiersList,
    getLoggedInUser,
    setTierFormOpen,
    // setToPushAfterAuth,
    isAuthenticated,
    clearPathToPushOn,
    posts,
}) => {
    console.log("Preview: render")
    const [currentTab, setCurrentTab] = useState(tabs[0])
    // const [tierListWidth, setTierListWidth] = useState(null)
    const [showPreviewBtn, setShowPreviewBtn] = useState(null)
    const [showFullPreview, setShowFullPreview] = useState(null)
    const [hasFollowed, setHasFollowed] = useState(userFollowed);
    const [updatingFollow, setUpdatingFollow] = useState(false);
    const [adjustedNumberOfFans, setAdjustedNumberOfFans] = useState(numberOfFans);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [avatarSrc, setAvatarSrc] = useState(avatar)
    const [coverPhotoSrc, setCoverPhotoSrc] = useState(coverPhoto)
    const dispatch = useDispatch();
    const { pathname } = useLocation()
    const history = useHistory()
    const { fanshipPageName } = useParams();
    // const tierListRef = useRef(null)
    const profileRef = useRef(null)
    const myId = localStorage.getItem('userId');
    const actionMenuOpen = !!anchorEl;

    const closeActionMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // setTierListWidth(_get(tierListRef, "current.clientWidth"))

        getPaidTiersList()

        getLoggedInUser().catch(err => {
            console.log(err)
        })

        clearPathToPushOn === "CreatorPreview" && dispatch(setToPushAfterAuth(null))

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log("useEffect for profile", profile, _get(profileRef, "current.clientHeight"), _get(profileRef, "current.scrollHeight"))

        if (_get(profileRef, "current.scrollHeight") > 48) {
            setShowPreviewBtn(true)
            setShowFullPreview(false)
        } else {
            setShowPreviewBtn(false)
            setShowFullPreview(false)
        }
    }, [profile])

    useEffect(() => {
        const getPosts = async () => {
            setLoadingPosts(true);

            const posts = await dispatch(getPostsByIdolId(id))

            console.log("posts by tierId", posts)

            dispatch(setPostFeed(posts))

            setLoadingPosts(false);
        }

        getPosts();

        return () => dispatch(clearPostFeed())

        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        setHasFollowed(userFollowed);
    }, [userFollowed, id])

    useEffect(() => {
        setAdjustedNumberOfFans(numberOfFans);
    }, [numberOfFans, id])

    const classes = useStyles({
        gap,
        showFullPreview,
    })

    const { t } = useTranslation("creator-onboarding");
    const { t: tcp } = useTranslation("creator-profile");

    const onClickFollow = async () => {
        if(!isAuthenticated && fanshipPageName) {
            // check isAuthenticated. 
            // if not, store /creator/:fanshipPageName
            // handle to clear pushAfterAuth    
            
            dispatch(setToPushAfterAuth({
                path: `/creator/${fanshipPageName}`,
                clearOn: "CreatorPreview",
            }))    
            dispatch(push("/registration"))
            return
        }

        setUpdatingFollow(true);
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const res = await fetch(`https://${env.apiDomain}/idols/${id}/follow`, {
            method: hasFollowed ? "DELETE" : "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${userId}_${token}`
            }
        })

        const result = await res.json();

        console.log('follow result: ', result);

        if (result.success) {
            if (hasFollowed) {
                setAdjustedNumberOfFans(adjustedNumberOfFans - 1);
            } else {
                setAdjustedNumberOfFans(adjustedNumberOfFans + 1);
            }
            setHasFollowed(!hasFollowed);
        } else {
            console.log('code: ', result.code);
        }
        setUpdatingFollow(false);
    }

    const handleTabs = useCallback((e, tab) => {
        if(!isAuthenticated && fanshipPageName) {
            // check isAuthenticated. 
            // if not, store /creator/:fanshipPageName
            // handle to clear pushAfterAuth    
            
            dispatch(setToPushAfterAuth({
                path: `/creator/${fanshipPageName}`,
                clearOn: "CreatorPreview",
            }))    
            dispatch(push("/registration"))
            return
        }
        
        setCurrentTab(tab)

        // eslint-disable-next-line
    }, [isAuthenticated, fanshipPageName])


    const startChat = useCallback(() => {
        dispatch(initChatChannel([myId, id]))
        .then(() => {
            console.log("chat channel has been created successfully", id, myId)
        })

    }, [id, myId])

    return (
        <Container
            maxWidth="lg"
            className={classes.container}
        >
            <Box className={classes.coverPhotoBox}>
                <img
                    src={coverPhotoSrc ? coverPhotoSrc : placeHolderPng}
                    alt="cover"
                    width="100%"
                    onError={() => {
                        setCoverPhotoSrc(placeHolderPng) 
                    }}
                ></img>
            </Box>
            <Box className={classes.avatarContainer}>
                <Box className={classes.avatarBox}>
                    <Box className={classes.avatarHolderOut}>
                        <Box className={classes.avatarHolder}>
                            <img
                                src={avatarSrc ? avatarSrc : defaultAvatar}
                                alt="avatar"
                                width="100%"
                                onError={() => {
                                    setAvatarSrc(defaultAvatar) 
                                }}
                            >
                            </img>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.followersCount}>
                <Box className={classes.countGroup}>
                    <Typography variant="caption" className={classes.count}>{adjustedNumberOfFans || 0}</Typography>
                    <Typography variant="caption" className={classes.countLabel}>{t("number-of-fans")}</Typography>
                </Box>
                <Box style={{ width: 250 }}></Box>
                <Box className={classes.countGroup}>
                    <Typography variant="caption" className={classes.count}>{numberOfSubscribers || 0}</Typography>
                    <Typography variant="caption" className={classes.countLabel}>{t("number-of-subscribers")}</Typography>
                </Box>
            </Box>
            <Box className={classes.profile}>
                <div className={classes.flexContainer}>
                    <Typography variant="h1" component="h1" className={classes.displayName}>{fullName || displayName}</Typography>
                    {
                        isIdol && (
                            <div className={clsx(classes.flexContainer, classes.verifyStatusContainer)}>
                                <VerifiedIcon />
                                <Typography variant="body1" color="textSecondary">
                                    {t('verified')}
                                </Typography>
                            </div>
                        )
                    }
                </div>
                <Box className={classes.details}>
                    {/* <Typography>鄭家純（日版：テイ　イエズミ）</Typography>
                    <Typography>英文名：ili （追求一個對稱的名字，讀音為一粒）... more:更多 / less:較少的</Typography> */}
                    <Typography
                        ref={profileRef}
                        className={classes.preview}
                    >
                        {profile}
                    </Typography>
                    {
                        showPreviewBtn &&
                        <Link
                            className={classes.showPreviewBtn}
                            onClick={() => setShowFullPreview(showFullPreview => !showFullPreview)}
                        >
                            {showFullPreview ? "較少的" : "... 更多"}
                        </Link>
                    }
                </Box>
                {
                    id === myId ?
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => dispatch(push("/creator/onboarding/form"))}
                        >{t("edit-information")}
                        </Button> :
                        <div className={classes.flexContainer}>
                            <LoadingButton
                                color="primary"
                                variant={hasFollowed ? "outlined" : "contained"}
                                className={clsx(classes.button, classes.rightSpace)}
                                onClick={onClickFollow}
                                isLoading={updatingFollow}
                                loadingProps={{ size: 24 }}
                            >
                                {hasFollowed ? tcp('followed') : tcp('follow')}
                            </LoadingButton>
                            {
                                isEntitledToChat &&
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className={clsx(classes.button, classes.rightSpace)}
                                    onClick={startChat}
                                >
                                    {tcp("send-message")}
                                </Button>
                            }
                            <MoreCircleIcon
                                className={classes.moreIconCircle}
                                onClick={(event) => { setAnchorEl(event.currentTarget) }}
                            />
                            <Menu
                                elevation={4}
                                classes={{ paper: classes.menuPaper, list: classes.menuList }}
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 44,
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                keepMounted
                                open={actionMenuOpen}
                                onClose={closeActionMenu}
                                TransitionComponent={Fade}
                            >
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={closeActionMenu}
                                    // report idol has not been implemented!
                                >
                                    <div className={classes.flexContainer}>
                                        <AlertTriangleIcon className={classes.alertTriangleIcon} />
                                        <Typography variant="body1">
                                            {tcp('report-user')}
                                        </Typography>
                                    </div>
                                </MenuItem>
                            </Menu>
                        </div>
                }
            </Box>
            <Box className={classes.main}>
                <Tabs
                    className={classes.tabs}
                    value={currentTab}
                    onChange={handleTabs}
                    indicatorColor="primary"
                >
                    <Tab className={classes.tab} value={tabs[0]} label={t("paid-plan")} />
                    <Tab className={classes.tab} value={tabs[1]} label={t("post")} />
                </Tabs>
                {
                    currentTab === tabs[0] && <Grid
                        container
                        spacing={3}
                        // ref={tierListRef}
                        className={classes.tiersList}
                    >
                        {
                            _map(
                                paidTiersList,
                                (tier, index) => <Grid key={index} item sm={12} md={6} lg={4}>

                                <PiadTierView
                                    item={tier}
                                    // tierWidth={(tierListWidth - (2 * gap)) / 3 - 1}
                                />

                                </Grid>
                            )
                        }
                        {
                            (pathname === "/my-creator-profile") &&
                            <Grid item sm={12} md={6} lg={4}>
                                <Box className={classes.addCard}> 
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={clsx(classes.addButton)}
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            setTierFormOpen(true)
                                            history.push("/tier/form")
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {t("new-paid-plan")}
                                        </Typography>
                                    </Button>    
                                </Box>
                            </Grid>
                        }
                    </Grid>
                }
                {
                    currentTab === tabs[1] &&
                    <Box className={classes.postList}>
                    {
                        loadingPosts ?
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={60} />
                        </div> :
                        
                        _map(posts,
                            (post, index) => 
                            <Post
                                key={_get(post, "postId", index)}
                                post={post}
                            />
                        )
                    }
                    </Box>
                }
            </Box>

        </Container>
    )
}




const mapStateToProps = state => {
    const selector = formValueSelector(creatorOnboardingFormName)

    const isCreatorOnboarding = window.location.pathname === "/creator/onboarding/preview";

    return isCreatorOnboarding ? {
        isIdol: _get("auth.isIdol"),
        coverPhoto: selector(state, "profileImage"),
        avatar: _get(state, "auth.avatar"),
        displayName: _get(state, "auth.displayName"),
        paidTiersList: _get(state, "creatorOnboarding.paidTiersList"),
        fullName: selector(state, "creatorPageName"),
        numberOfFans: _get(state, "creatorOnboarding.user."),
        numberOfSubscribers: _get(state, "creatorOnboarding.user.numberOfSubscribers"),
        profile: selector(state, "creatorPageDescription"),
    } : {
        isIdol: _get("auth.isIdol"),
        coverPhoto: _get(state, "creatorOnboarding.user.profileImage"),
        avatar: _get(state, "auth.avatar"),
        displayName: _get(state, "auth.displayName"),
        paidTiersList: _get(state, "creatorOnboarding.paidTiersList"),
        fullName: _get(state, "auth.fullName"),
        numberOfFans: _get(state, "creatorOnboarding.user."),
        numberOfSubscribers: _get(state, "creatorOnboarding.user.numberOfSubscribers"),
        profile: _get(state, "creatorOnboarding.user.profile"),
        isAuthenticated: _get(state, "auth.isAuthenticated", "test"),
        clearPathToPushOn: _get(state, "location.pushAfterAuth.clearOn", "test"),
        posts: _get(state, "feedPost.feed"),

        // import { Preview } bypasses connect-hoc
    }
}

const mapDispatchToProps = dispatch => ({
    getPaidTiersList: () => dispatch(getPaidTiersList()),
    getLoggedInUser: () => dispatch(getLoggedInUser()),
    setTierFormOpen: boolean => dispatch(setTierFormOpen(boolean)),
    // setToPushAfterAuth: (...params) => dispatch(setToPushAfterAuth(...params)),
    
    // import { Preview } bypasses connect-hoc
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Preview)
