import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import _get from 'lodash/get'
import _forEach from 'lodash/forEach'

import { setFewerCommentsForPost } from 'src/redux/feed-post/actions'

import { HashLink } from 'react-router-hash-link'
import Box from '@material-ui/core/Box'
import useStyles from 'src/styles/components/feed-post/post.js'
import { Typography } from '@material-ui/core'

const CommentsListMeta = ({
    postId,
    comments,
    commentCount,
    handleLoadMoreComments,
}) => {
    const [ visibleCount, setVisibleCount ] = useState(null)

    useEffect(() => {
        const getVisibleCount = () => {
            let count = _get(comments, "length")
            _forEach(comments, comment =>{
                if(_get(comment, "childComments.length")) {
                    count += _get(comment, "childComments.length")
                }
            })
            return count
        }
        setVisibleCount(getVisibleCount())

        // eslint-disable-next-line 
    }, [comments?.length, commentCount])

    const dispatch = useDispatch()
    const match = useRouteMatch()

    const handleViewFewerComments = useCallback(() => {
        dispatch(setFewerCommentsForPost(postId))
        if(_get(match, "path") === "/home") postLinkRef.current.click()

        // eslint-disable-next-line
    }, [postId])

    const handleScrollWithOffset = useCallback((el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -84; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }, [])

    const postLinkRef = useRef(null)

	const { t } = useTranslation("feed-post")

    const classes = useStyles()
    if(visibleCount === null) return null
    return (
        <Box className={classes.commentListMeta}>
        {
            (commentCount - visibleCount > 0) 
            ?  
            <Typography 
                className={clsx(classes.commentListMetaLabel, classes.pointer)} 
                component="span"
                onClick={handleLoadMoreComments}
            >
                {t("check-more-messages")}
            </Typography>
            : 
            (_get(comments, "length") > 3)
            ?
            <Typography 
                className={clsx(classes.commentListMetaLabel, classes.pointer)} 
                component="span"
                onClick={handleViewFewerComments}
            >
                {t("view-fewer-messages")}
            </Typography>
            : null
        }
        <HashLink 
            ref={postLinkRef} 
            to={`/home#${postId}`} 
            smooth={true}
            scroll={handleScrollWithOffset}
        ></HashLink>
        <Typography className={clsx(classes.commentListMetaLabel, classes.commentListCount)} variant="body2" component="span">
            {(visibleCount > commentCount) ? commentCount : visibleCount} / {commentCount}
        </Typography>
    </Box>
    )
}

export default CommentsListMeta
