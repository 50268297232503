import React from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _get from "lodash/get";
import _random from "lodash/random";

import {
  setCroppedImageLoading,
  uploadFile,
} from "src/redux/creatorOnboarding/actions";
import LoadingButton from "src/components/LoadingButton";

import { useTranslation } from "react-i18next";

import ReactCrop from "react-image-crop";
import clsx from "clsx";

import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "src/styles/components/creator-onboarding/cropUploadImage.js";
import { useSnackbar } from "notistack";
import "react-image-crop/dist/ReactCrop.css";

export default function CropDialog({
  open,
  handleOpen,
  handleClose,
  followUploadedUrl,
  cropData,
  droppedFile,
  accept,
  updateBtnLabel,
  aspectRatio = 1 / 1,
}) {
  const { t } = useTranslation("creator-onboarding");
  const classes = useStyles();
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    aspect: aspectRatio,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [fileType, setFileType] = useState(null);
  const dispatch = useDispatch();
  const isCroppedImageLoading = useSelector((state) =>
    _get(state, "creatorOnboarding.isCroppedImageLoading")
  );

  const onSelectFile = (e) => {
    console.log("crop: cropCroppedImage input onChange");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        console.log(
          "crop: reader.addEventListener for load"
          // reader.result
        );
        setUpImg(reader.result);
      });
      console.log(
        "crop: calling reader.readAsDataURL with e.target.files[0]",
        e.target.files[0]
      );
      // get file type
      console.log("crop: setting fileType", _get(e, "target.files[0].type"));
      setFileType(_get(e, "target.files[0].type"));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (droppedFile) {
      console.log("crop: useEffect for droppedFile", droppedFile);
      handleOpen();
      setUpImg(droppedFile.data);
      setFileType(droppedFile.fileType);
    }

    // eslint-disable-next-line
  }, [droppedFile]);

  const onLoad = useCallback((img) => {
    console.log("crop: onLoad img", img);
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const { enqueueSnackbar } = useSnackbar();

  function handleConfirm(canvas, crop) {
    console.log("canvas:", canvas, "crop:", crop);
    if (!crop || !canvas || !_get(crop, "height") || !_get(crop, "width")) {
      return;
    }
    dispatch(setCroppedImageLoading(true));
    canvas.toBlob(
      async (blob) => {
        try {
          const blobUrl = window.URL.createObjectURL(blob);
          console.log("crop: blobUrl: ", blobUrl, "state fileType", fileType);
          const file = await fetch(blobUrl)
            .then((r) => r.blob())
            .then(
              (blobFile) =>
                new File([blobFile], `${_random(0, 9999)}`, { type: fileType })
            );

          const image = await dispatch(uploadFile(file));
          followUploadedUrl(image);
          dispatch(setCroppedImageLoading(false));
          setUpImg("");
          setCompletedCrop("");
          handleClose();
        } catch (err) {
          console.log(err);
          enqueueSnackbar(err, { variant: "error" });
        }
      },
      fileType,
      1
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paper }}
        scroll="body"
        maxWidth="md"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6" className={classes.header} align="center">
            {updateBtnLabel}
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentRoot }}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.mainContainer}>
            <Button
              component="label"
              color="primary"
              variant="contained"
              className={clsx(classes.button, classes.uploadButtonRoot)}
            >
              <Typography variant="body1">{t("upload-photo")}</Typography>
              <input
                type="file"
                accept={accept}
                onChange={onSelectFile}
                className={classes.uploadInput}
              />
            </Button>
            <ReactCrop
              src={upImg} // set image here on drag
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              className={classes.reactCrop}
            />
            <div hidden>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {completedCrop && (
            <LoadingButton
              variant="contained"
              color="primary"
              className={clsx(classes.confirmButton, classes.button)}
              onClick={() =>
                handleConfirm(previewCanvasRef.current, completedCrop)
              }
              isLoading={isCroppedImageLoading}
            >
              <Typography variant="body1">{t("complete-cropping")}</Typography>
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
