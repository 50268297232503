import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useQuery from "src/lib/paths/useQuery";
import useStyles from 'src/styles/pages/validateEmail';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Typography,
  Paper,
  Button,
  CircularProgress
} from "@material-ui/core";
import { ReactComponent as AlertIcon } from 'src/images/common/icon_alert_triangle.svg';
import clsx from 'clsx';
import env from 'src/env';

export default function ValidateEmail() {
  const { t } = useTranslation("validate-email");
  const classes = useStyles();
  let query = useQuery();
  let email = query.get('email');
  let validateCode = query.get('validate-code');
  const dispatch = useDispatch();

  console.log('email: ', email);
  console.log('validateCode: ', validateCode);

  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const validateEmail = async () => {

      if (!email || !validateCode) {
        setSuccess(false);
        return;
      }

      const res = await fetch(`https://${env.apiDomain}/validate-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          validatecode: validateCode
        })
      })

      const result = await res.json();

      if (result.success) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    }

    validateEmail();

  }, [email, validateCode])

  return (
    <div className={classes.mainContainer}>
      <Paper classes={{ root: classes.paperRoot }}>
        {
          success === null ?
            <CircularProgress /> :
            success === true ?
              (
                <>
                  <Typography variant="h2" align="center">
                    {t('success.header')}
                  </Typography>
                  <Typography variant="body1" align="center">
                    {t('success.description')}
                  </Typography>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => dispatch(push('/'))}
                  >
                    <Typography variant="body1">
                      {t('success.continue')}
                    </Typography>
                  </Button>
                </>
              ) :
              (
                <>
                  <div className={clsx(classes.flexContainer, classes.header)}>
                    <AlertIcon className={classes.icon} />
                    <Typography
                      variant="h2"
                      align="center"
                      className={classes.failureHeader}
                    >
                      {t('failure.header')}
                    </Typography>
                  </div>
                  <Typography variant="body1">
                    {t('failure.description')}
                  </Typography>
                </>
              )
        }
      </Paper>
    </div>
  )
}