import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    dialog: {
        position: "absolute",
        bottom: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#9900FF",
        color: "#FFFFFF",
        padding: "20px 16px",
    },
    dialogBtn: {
        backgroundColor: "#FFFFFF",
        color: "#9900FF",
        fontWeight: 700,
        width: 343,
        height: 44,
        borderRadius: 22,
        alignSelf: "center",
        marginTop: 30,
        '&:hover': {
            backgroundColor: '#e4bcff',
        },
    }
}))

export default useStyles