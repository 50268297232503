import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    background: 'white',
    zIndex: 5
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    justifyContent: 'space-between'
  },
  logo: {
    width: 51,
    height: 50,
    cursor: "pointer",
  },
  a: {
    // textDecoration: "underline"
    padding: "3px 0px",
    borderBottom: "solid 3px black",
    fontSize: 16,
    fontFamily: "Noto Sans CJK TC",
    cursor: "pointer",
  }
}), { index: 1 });

export default useStyles;