import {
    GET_PAID_TIER,
    SET_CURRENT_FORM_TAB,
    SET_IDENTITY_PROOF_LOADING,
    SET_PROFILE_IMAGE_LOADING,
    SET_TIER_FORM_OPEN,
    SET_EDIT_TIER_ID,
    SET_REMOVE_TIER_DIALOG,
    SET_PUTTING_CREATOR_ONBOARDING,
    CLEAR_CREATOR_ONBOARDING_STATE,
} from './actionTypes'
import { initialState } from './initialState'
import { creatorOnboardingFormTabs } from 'src/pages/CreatorOnboardingForm'


export function creatorOnboardingReducer(state = initialState, action) {
    switch(action.type) {
        case SET_CURRENT_FORM_TAB:
            return {
                ...state,
                currentFormTab: action.payload,
            }
        case SET_IDENTITY_PROOF_LOADING:
            return {
                ...state,
                isIdentityProofLoading: action.payload,
            }
        case SET_PROFILE_IMAGE_LOADING:
            return {
                ...state,
                isCroppedImageLoading: action.payload
            }
        case GET_PAID_TIER:
            return {
                ...state,
                paidTiersList: action.payload
            }
        case SET_TIER_FORM_OPEN:
            return {
                ...state,
                isTierFormOpen: action.payload,
            }
        case SET_EDIT_TIER_ID:
            return {
                ...state,
                editTierId: action.payload,
                isTierFormOpen: action.payload ? true : false,
            }
        case SET_REMOVE_TIER_DIALOG:
            return {
                ...state,
                isRemoveTierDialogOpen: action.payload,
            }
        case SET_PUTTING_CREATOR_ONBOARDING:
            return {
                ...state,
                isPuttingCreatorOnboarding: action.payload,
            }
        case CLEAR_CREATOR_ONBOARDING_STATE:
            return {
                ...state,
                currentFormTab: creatorOnboardingFormTabs[0],
                isIdentityProofLoading: false,
                isProfileImageLoading: false,
                paidTiersList: [],
                isCroppedImageLoading: false,
                isTierFormOpen: false,
                editTierId: null,
                isRemoveTierDialogOpen: false,
                isPuttingCreatorOnboarding: false,
            }
        default:
            return state
    }
}