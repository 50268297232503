import React from 'react'
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import useStyles from "src/styles/components/header/popUpMenu";
import { useDispatch } from 'react-redux';
import { logoutThunk } from 'src/redux/auth/thunks';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as LogoutIcon } from "src/images/common/icon_logout.svg"

export default function PopUpMenu({
  anchorEl,
  setAnchorEl,
  fullName,
  displayName,
  avatar
}) {
  const classes = useStyles();
  const open = !!anchorEl;
  const dispatch = useDispatch();
  const { t } = useTranslation("header");

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        elevation={4}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={open}
        onClose={closeMenu}
        TransitionComponent={Fade}
      >
        <MenuItem
          className={clsx(classes.menuItem)}
          onClick={closeMenu}
        >
          <NavLink to="/personal-profile" className={clsx(classes.flexContainer, classes.navLink)}>
            <img
              src={avatar}
              alt="user avatar"
              className={classes.avatar}
            />
            {/* <Typography variant="body1">
              {fullName}
            </Typography> */}
            <Typography variant="body1">
              {displayName}
            </Typography>
          </NavLink>
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            dispatch(logoutThunk());
          }}
          className={clsx(classes.menuItem, classes.flexContainer)}
        >
          <LogoutIcon className={classes.icon} />
          <Typography variant="body1">
            {t("menu.logout")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}