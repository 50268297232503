import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "src/styles/components/personal-profile/cropImageDialog";
import { useTranslation } from "react-i18next";
import "react-image-crop/dist/ReactCrop.css";
import {
  setToPushAfterAuth,
  getSubscribeQuery,
} from "src/redux/location/actions";
import _get from "lodash/get";
import { replace } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

export default function LockedplanDailog({
  props,
  open,
  handleClose,
  handleSubscribe,
}) {
  const { t } = useTranslation("edit-personal-profile");
  const classes = useStyles();
  const replaceToTierPayment = useSelector((state) =>
    _get(state, "location.pushAfterAuth.replaceToTierPayment")
  );
  const replaceToPaymentFor = useSelector((state) =>
    _get(state, "location.pushAfterAuth.tierId")
  );
  const dispatch = useDispatch();
  const clearPathToPushOn = useSelector((state) =>
    _get(state, "location.pushAfterAuth.clearOn")
  );

  useEffect(() => {
    if (
      replaceToTierPayment &&
      _get(props, "item.tierId") === replaceToPaymentFor &&
      !_get(props, "item.userSubscribed")
    ) {
      console.log("replacing to payment for tier", replaceToPaymentFor);
      dispatch(
        replace(
          `/subscribe/?${dispatch(
            getSubscribeQuery(_get(props, "item.tierId"))
          )}`
        )
      );

      // check to clear pushAfterAuth on current component
      clearPathToPushOn === "PaidTierView" &&
        dispatch(setToPushAfterAuth(null));
    }
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: classes.paper }}
        scroll="body"
        maxWidth="xs"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6" className={classes.header} align="center">
            {t("locked-plan")}
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.contentRoot }}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.mainContainer}>
            <Typography variant="body1">{t("plan-description")}</Typography>
          </div>
        </DialogContent>
        <div style={{ paddingTop: 20 }}>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button
              style={{ width: "150px", backgroundColor: "transparent" }}
              variant="outlined"
              className={classes.button}
              color="black"
              onClick={handleClose}
            >
              <Typography variant="body1">{t("locked-cancel")}</Typography>
            </Button>
            <Button
              style={{ width: "150px" }}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubscribe}
            >
              <Typography variant="body1">
                {t("locked-Confirmation")}
              </Typography>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Grid>
  );
}
