import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    borderRadius: 20,
    padding: 20,
    width: 270,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    height: 'fit-content'
  },
  textBold: {
    fontWeight: 'bold'
  },
  header: {
    marginBottom: 20
  },
  button: {
    borderRadius: 100,
    marginTop: 40,
    height: 38
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginRight: 10
  }
}), { index: 1 });

export default useStyles;