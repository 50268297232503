import React, { useState, useEffect } from 'react'
import useStyles from "src/styles/components/dashboard/subscriberList";
import { ReactComponent as SortingIcon } from 'src/images/common/icon_sorting.svg';
import { ReactComponent as ArrowDownIcon } from 'src/images/common/icon_arrow_down.svg';
import { ReactComponent as FilterIcon } from 'src/images/common/icon_filter.svg';
import { ReactComponent as PriceTagIcon } from 'src/images/common/icon_price_tag.svg';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import SortingPopUpMenu from 'src/components/dashboard/SortingPopUpMenu';
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { countriesMap } from 'src/info/countries';
import defaultAvatar from 'src/images/common/default_avatar.png';
import SearchFilterPopUpPanel from 'src/components/dashboard/SearchFilterPopUpPanel';
import InfoDialog from 'src/components/InfoDialog';
import LoadingButton from 'src/components/LoadingButton';
import env from 'src/env';

export default function SubscriberList({
  subscribers,
  tiers,
  stat,
  setStat,
  setSubscribers
}) {
  console.log('subscribers: ', subscribers);
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const { t: te } = useTranslation("error");
  const [filteredSubscribers, setFilteredSubscribers] = useState(subscribers);
  const [sortingAnchorEl, setSortingAnchorEl] = useState(null);
  const [sortingOption, setSortingOption] = useState({
    field: 'displayName',
    order: 'asc',
    text: t('tab-2.sorting.display-name-asc')
  });
  const [filterDisplayNameAnchorEl, setFilterDisplayNameAnchorEl] = useState(null);
  const [displayNameSearchTerm, setDisplayNameSearchTerm] = useState('');
  const [filterRegionAnchorEl, setFilterRegionAnchorEl] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState(null);
  const [filterTierAnchorEl, setFilterTierAnchorEl] = useState(null);
  const [selectedTiers, setSelectedTiers] = useState(null);
  const [refundTarget, setRefundTarget] = useState();
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [isRefunding, setIsRefunding] = useState(false);
  const [afterRefundMessage, setAfterRefundMessage] = useState();

  const [page, setPage] = useState(1);
  const rowPerPage = 10;
  const startIndex = (page - 1) * rowPerPage;
  const endIndex = page * rowPerPage;

  const clickSortingButton = (event) => {
    setSortingAnchorEl(event.currentTarget);
  }

  const clickFilter = (event, type) => {
    console.log('event: ', event);
    switch (type) {
      case 'displayName':
        setFilterDisplayNameAnchorEl(event.currentTarget);
        break;
      case 'region':
        setFilterRegionAnchorEl(event.currentTarget);
        break;
      case 'tier':
        setFilterTierAnchorEl(event.currentTarget);
        break;
      default:
        break;
    }
  }

  const sortingOptions = [
    {
      field: 'displayName',
      order: 'asc',
      text: t('tab-2.sorting.display-name-asc')
    },
    {
      field: 'displayName',
      order: 'desc',
      text: t('tab-2.sorting.display-name-desc')
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const avatarError = (e) => {
    e.target.onerror = null;
    e.target.src = defaultAvatar;
  }

  const adjustSortingValue = (item, field) => {
    if (field === 'displayName') {
      return item[field].toLowerCase();
    } else {
      return item[field];
    }
  }

  let uniqueRegions = new Set();
  for (const subscriber of subscribers) {
    uniqueRegions.add(subscriber.countryCode);
  }

  let regionOptions = [];
  uniqueRegions.forEach((region) => {
    regionOptions.push({
      value: region,
      label: countriesMap[region]
    })
  })

  let tierOptions = tiers.map((tier) => {
    return {
      value: tier.tierId,
      label: tier.title
    }
  })

  useEffect(() => {
    let newSubscribers = JSON.parse(JSON.stringify(subscribers));

    if (displayNameSearchTerm) {
      const adjustedSearchTerm = displayNameSearchTerm.toLowerCase();
      newSubscribers = newSubscribers.filter((subscriber) => {
        return subscriber.displayName.toLowerCase().includes(adjustedSearchTerm) ||
          subscriber.fullName.toLowerCase().includes(adjustedSearchTerm);
      })
    }

    if (selectedRegions) {
      newSubscribers = newSubscribers.filter((subscriber) => selectedRegions.includes(subscriber.countryCode));
    }

    if (selectedTiers) {
      newSubscribers = newSubscribers.filter((subscriber) => {
        for (const tier of subscriber.subscribedTiers) {
          if (selectedTiers.includes(tier.tierId)) {
            return true
          }
        }
        return false
      })
    }

    if (sortingOption.order === 'asc') {
      newSubscribers.sort((a, b) => {
        if (adjustSortingValue(a, sortingOption.field) > adjustSortingValue(b, sortingOption.field)) {
          return 1
        } else if (adjustSortingValue(a, sortingOption.field) < adjustSortingValue(b, sortingOption.field)) {
          return -1
        } else {
          return 0
        }
      })
    } else {
      newSubscribers.sort((a, b) => {
        if (adjustSortingValue(a, sortingOption.field) > adjustSortingValue(b, sortingOption.field)) {
          return -1
        } else if (adjustSortingValue(a, sortingOption.field) < adjustSortingValue(b, sortingOption.field)) {
          return 1
        } else {
          return 0
        }
      })
    }
    setFilteredSubscribers(newSubscribers);
  }, [sortingOption, subscribers, displayNameSearchTerm, selectedRegions, selectedTiers])

  const clickRefund = (subscriber) => {
    setRefundTarget(subscriber);
    setAfterRefundMessage(null);
    setRefundDialogOpen(true);
  }

  const closeRefundDialog = () => {
    setRefundDialogOpen(false);
    setRefundTarget(null);
  }

  const confirmRefund = async () => {
    console.log('refund target: ', refundTarget);
    setIsRefunding(true);

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const res = await fetch(`https://${env.apiDomain}/refund/${refundTarget.userId}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `${userId}_${token}`
        },
      }
    );
    const result = await res.json();
    console.log('result1: ', result);
    if (result.success) {
      setAfterRefundMessage(t('tab-2.refund-dialog.success-message'));
      let newSubscribers = JSON.parse(JSON.stringify(subscribers));
      let targetSubscriberIndex = newSubscribers.findIndex((subscriber) => subscriber.userId === refundTarget.userId);
      newSubscribers.splice(targetSubscriberIndex, 1);
      setSubscribers(newSubscribers);

      let subscribedTiers = refundTarget.subscribedTiers;
      let newStat = JSON.parse(JSON.stringify(stat));
      for (const subscribedTier of subscribedTiers) {
        let targetTier = newStat.tiers.find((tier) => tier.tierId === subscribedTier.tierId);
        targetTier.subscriberCount = targetTier.subscriberCount - 1;
        targetTier.monthlyRevenue = targetTier.monthlyRevenue - targetTier.price;
      }
      setStat(newStat);
    } else {
      console.log('error code: ', result.code);
      setAfterRefundMessage(te('title') + '： ' + (result.code ? te(`code.${result.code}`) : te('code.UNEXPECTED_ERROR')));
    }

    setIsRefunding(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="h5" align="left">
          {t('tab-2.header')}
        </Typography>
        <div className={classes.sortingButton} onClick={clickSortingButton}>
          <SortingIcon className={classes.sortingIcon} />
          <Typography variant="caption" className={classes.sortingText}>
            {sortingOption.text}
          </Typography>
          <ArrowDownIcon className={classes.arrowDownIcon} />
        </div>
        <SortingPopUpMenu
          anchorEl={sortingAnchorEl}
          setAnchorEl={setSortingAnchorEl}
          setSortingOption={setSortingOption}
          sortingOptions={sortingOptions}
        />
      </div>

      <TableContainer component={Paper} classes={{ root: classes.tableRoot }}>
        <Table>
          <colgroup>
            <col style={{ width: "25%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <div className={classes.flexContainer}>
                  <Typography variant="h6" className={clsx(classes.textBold, classes.columnHeader)}>
                    {t('tab-2.display-name')}
                  </Typography>
                  <FilterIcon className={classes.filterIcon} onClick={(e) => clickFilter(e, 'displayName')} />
                  <SearchFilterPopUpPanel
                    anchorEl={filterDisplayNameAnchorEl}
                    setAnchorEl={setFilterDisplayNameAnchorEl}
                    placeholder={t('tab-2.filters.search-by-full-name-or-display-name')}
                    setFilter={setDisplayNameSearchTerm}
                    type="search"
                  />
                </div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.flexContainer}>
                  <Typography
                    variant="h6"
                    className={clsx(classes.textBold, classes.columnHeader)}
                    align="center"
                  >
                    {t('tab-2.region')}
                  </Typography>
                  <FilterIcon className={classes.filterIcon} onClick={(e) => clickFilter(e, 'region')} />
                  <SearchFilterPopUpPanel
                    anchorEl={filterRegionAnchorEl}
                    setAnchorEl={setFilterRegionAnchorEl}
                    placeholder={t('tab-2.filters.search-by-region')}
                    setFilter={setSelectedRegions}
                    type="select"
                    options={regionOptions}
                  />
                </div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.flexContainer}>
                  <Typography
                    variant="h6"
                    className={clsx(classes.textBold, classes.columnHeader)}
                    align="center"
                  >
                    {t('tab-2.tiers')}
                  </Typography>
                  <FilterIcon className={classes.filterIcon} onClick={(e) => clickFilter(e, 'tier')} />
                  <SearchFilterPopUpPanel
                    anchorEl={filterTierAnchorEl}
                    setAnchorEl={setFilterTierAnchorEl}
                    placeholder={t('tab-2.filters.search-by-tier-name')}
                    setFilter={setSelectedTiers}
                    type="select"
                    options={tierOptions}
                  />
                </div>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" className={classes.textBold}>
                  {t('tab-2.monthly-amount')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" className={classes.textBold}>
                  {t('tab-2.action')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubscribers.slice(startIndex, endIndex).map((subscriber) => (
              <TableRow
                key={subscriber.userId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  <div className={classes.flexContainer}>
                    <img
                      src={subscriber.avatar ? subscriber.avatar : defaultAvatar}
                      alt={'subscriber avatar'}
                      className={classes.avatar}
                      onError={(e) => avatarError(e)}
                    />
                    <Typography variant="body1" className={classes.textBold}>
                      {subscriber.displayName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {countriesMap[subscriber.countryCode]}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  <div className={classes.tiersCell}>
                    <PriceTagIcon className={classes.priceTagIcon} />
                    {subscriber.subscribedTiers.sort((a, b) => a.price - b.price).map(tier => tier.title).join('，')}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" className={classes.textBold}>
                    {"$" + Intl.NumberFormat('zh-HK').format(subscriber.monthlyAmount)}
                    <Typography component="span" variant="caption">
                      USD
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={clsx(classes.refundButton, classes.button)}
                    onClick={() => clickRefund(subscriber)}
                  >
                    <Typography variant="body2" className={classes.textBold}>
                      {t('tab-2.refund')}
                    </Typography>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.paginationContainer}>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(filteredSubscribers.length / rowPerPage)}
            siblingCount={1}
            onChange={handleChangePage}
            page={page}
          />
        </div>
      </TableContainer>
      <InfoDialog
        open={refundDialogOpen}
        title={
          <Typography
            variant="h6"
            align="center"
            className={clsx(classes.textBold, classes.dialogTitle)}
          >
            {t('tab-2.refund')}
          </Typography>
        }
        handleClose={closeRefundDialog}
        button={
          <div className={classes.buttonContainer}>
            {
              !afterRefundMessage ?
                <>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    className={clsx(classes.dialogButton, classes.button)}
                    onClick={confirmRefund}
                    loadingProps={{
                      size: 24
                    }}
                    isLoading={isRefunding}
                  >
                    {t('tab-2.refund-dialog.confirm')}
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    color="default"
                    className={clsx(classes.dialogButton, classes.button)}
                    onClick={closeRefundDialog}
                    loadingProps={{
                      size: 24
                    }}
                    isLoading={isRefunding}
                  >
                    {t('tab-2.refund-dialog.cancel')}
                  </LoadingButton>
                </> :
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={closeRefundDialog}
                >
                  {t('tab-2.refund-dialog.confirm')}
                </Button>
            }
          </div>

        }
      >
        <div className={classes.dialogContent}>
          <Typography variant="body1" align="left">
            {
              !afterRefundMessage ? t('tab-2.refund-dialog.confirm-message') : afterRefundMessage
            }
          </Typography>
        </div>
      </InfoDialog>
    </div>
  )
}