import React from 'react'
import AutocompleteMui from '@material-ui/lab/Autocomplete'

const Autocomplete = ({options = [], renderInput = () => <input />, ...props}) => {
    return <AutocompleteMui
        options={options}
        renderInput={renderInput}
        {...props}
    />
    
}

export default Autocomplete
