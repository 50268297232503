import React from 'react'
import useStyles from "src/styles/components/personal-profile/inviteCreatorCard";
import {
  Typography,
  Paper,
  Button
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";

export default function InviteCreatorCard({
  referralCode
}) {
  const classes = useStyles();
  const { t } = useTranslation("personal-profile");

  const copyReferralCode = () => {
    navigator.clipboard.writeText(referralCode);
  }

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <Typography variant="h6" className={clsx(classes.textBold, classes.header)}>
        {t('invite-friend-to-be-creator')}
      </Typography>
      <Typography variant="body1" className={clsx(classes.textBold, classes.description)}>
        {t('invite-friend-to-be-creator-description')}
      </Typography>
      <Typography variant="h3">
        {referralCode}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={copyReferralCode}
      >
        <Typography variant="body2" className={classes.textBold}>
          {t('copy-referral-code')}
        </Typography>
      </Button>
    </Paper>
  )
}