import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Login from 'src/pages/Login';
import ContactUs from 'src/pages/ContactUs';
import TermsAndConditions from 'src/pages/TermsAndConditions';
import PlatformUsageGuide from 'src/pages/PlatformUsageGuide';
import ForgotPassword from 'src/pages/ForgotPassword';
import ResetPassword from 'src/pages/ResetPassword';
import Registration from 'src/pages/Registration';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import ValidateEmail from 'src/pages/ValidateEmail';
import Home from 'src/pages/Home';
import PersonalProfile from 'src/pages/PersonalProfile';
import EditPersonalProfile from 'src/pages/EditPersonalProfile';
import PrivateRoute from 'src/components/PrivateRoute';
import CreatorOnboardingForm from 'src/pages/CreatorOnboardingForm'
import CreatorOnboardingPreview from 'src/components/creator-onboarding/Preview'
import Deeplink from 'src/pages/Deeplink'
import Dashboard from 'src/pages/Dashboard';
import ChangePassword from 'src/pages/ChangePassword';
import CreatorProfile from 'src/pages/CreatorProfile';
import Subscribe from 'src/pages/Subscribe';
import PostPage from 'src/pages/PostPage';
import NotificationSetting from 'src/pages/NotificationSetting'
import NotificationCenter from 'src/pages/NotificationCenter';
import TierForm from 'src/components/creator-onboarding/TierForm';
import ChatRoute from 'src/pages/ChatRoute';
import TermsAndConditionsForMobileApp from 'src/pages/mobile-content/TermsAndConditionsForMobileApp';
import PrivacyPoliceMobile from 'src/pages/mobile-content/PrivacyPoliceMobile';
import PlatformUsageGuideMobile from 'src/pages/mobile-content/PlatformUsageGuideMobile';

export default function Routes() {

  return (
    <Switch>
      <Route path="/" exact={true} component={Login} />   
      <Route path="/contact-us" exact={true} component={ContactUs} />
      <Route path="/terms-conditions" exact={true} component={TermsAndConditions} />
      <Route path="/platform-usage-guide" exact={true} component={PlatformUsageGuide} />
      <Route path="/privacy-policy" exact={true} component={PrivacyPolicy} />
      <Route path="/forgot-password" exact={true} component={ForgotPassword} />
      <Route path="/reset-password" exact={false} component={ResetPassword} />
      <Route path="/registration" exact={true} component={Registration} />
      <Route path="/validate-email" exact={false} component={ValidateEmail} />
      <Route path="/applink" exact={true} component={Deeplink} />
      <Route path="/subscribe" exact={true} component={Subscribe} />
      <Route path="/terms-conditions-mobile" exact={true} component={TermsAndConditionsForMobileApp} />
      <Route path="/platform-usage-guide-mobile" exact={true} component={PlatformUsageGuideMobile}/>
      <Route path="/privacy-policy-mobile" exact={true} component={PrivacyPoliceMobile}/>
   
      <PrivateRoute path="/home" exact={true} component={Home} />
      <PrivateRoute path="/personal-profile" exact={true} component={PersonalProfile} />
      <PrivateRoute path="/tier-management" exact={true} component={PersonalProfile} />
      <PrivateRoute path="/saved-posts" exact={true} component={PersonalProfile} />
      <PrivateRoute path="/edit-personal-profile" exact={true} component={EditPersonalProfile} />
      <PrivateRoute path="/creator/onboarding/form" exact={true} component={CreatorOnboardingForm} />
      <PrivateRoute path="/creator/onboarding/preview" exact={true} component={CreatorOnboardingPreview} />
      <PrivateRoute path="/dashboard" exact={true} component={Dashboard} />
      <PrivateRoute path="/my-creator-profile" exact={true} component={CreatorOnboardingPreview} /> {/* api /idols/:idolId/posts must give comments in response */}
      <PrivateRoute path="/tier/form" exact={true} component={TierForm} />
      <PrivateRoute path="/tier/form/:tierId" exact={true} component={TierForm} />
      <PrivateRoute path="/change-password" exact={true} component={ChangePassword} />
      <Route path="/creator/:fanshipPageName" exact={false} component={CreatorProfile} />
      <PrivateRoute path="/posts/:postId" exact={true} component={PostPage} />
      <PrivateRoute path="/notification-setting" exact={true} component={NotificationSetting} />
      <PrivateRoute path="/activity" exact={true} component={NotificationCenter} />
      <PrivateRoute path="/chat" exact={true} component={ChatRoute} />
 
    </Switch>
  )
}