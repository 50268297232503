import React from 'react'

import _map from 'lodash/map'
import _includes from 'lodash/includes'
import _replace from 'lodash/replace'

import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import useStyles from 'src/styles/components/feed-post/mediaList'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'



const changeMovToMp4 = videoUrl => {
    console.log("changeMovToMp4", videoUrl, _replace(videoUrl, ".mov", "_convert.mp4"))
    return _replace(videoUrl, ".mov", "_convert.mp4")
}

const getThumbnaiUrl = videoUrl => {
    if(_includes(videoUrl, ".mp4")) return _replace(videoUrl, ".mp4", "_720.jpg")

    if(_includes(videoUrl, ".mov")) return _replace(videoUrl, ".mov", "_720.jpg")

    return null
}

const MediaList = ({
    media: mediaList,
    postType,
}) => {

    const classes = useStyles()
    return (
        <Box 
            className={classes.container}
        >
            <Carousel 
                className={classes.mediaList}
                showThumbs={false}
                // dynamicHeight={true}
            >
                {   
                    _map(
                        mediaList,
                        (mediaItem, index) => <Box
                            key={index}
                            className={clsx(
                                (postType === "image") && classes.imageHolder,
                                (postType === "video") && classes.videoHolder
                            )}
                        >
                            {
                                postType === "image"
                                ?
                                <img 
                                    src={mediaItem}
                                    alt="media"
                                    className={classes.image}
                                />
                                : 
                                postType === "video"
                                ? <video
                                    className={classes.video}
                                    controls={true}
                                    // poster={getThumbnaiUrl(mediaItem)}
                                    controlsList="nodownload"
                                >
                                    <source
                                        src={changeMovToMp4(mediaItem)}
                                        // src={mediaItem}
                                        type='video/mp4'
                                    />
                                </video>
                                : null
                            }
                        </Box>

                    )
                }
            </Carousel>
            {/* <Box className={classes.dummy}>

            </Box> */}
        </Box>
    )
}

export default MediaList
