import React from 'react'
import { ReactComponent as PersonalInfoIcon } from 'src/images/sidebar/icon_update_personal_info.svg';
import { ReactComponent as PasswordIcon } from 'src/images/sidebar/icon_password.svg';
import { ReactComponent as ActivityIcon } from 'src/images/sidebar/icon_activity.svg';
import { ReactComponent as ChargeIcon } from 'src/images/sidebar/icon_charge.svg';
import { ReactComponent as PaymentIcon } from 'src/images/sidebar/icon_payment.svg';
import { ReactComponent as SupportIcon } from 'src/images/sidebar/icon_support.svg';
import { ReactComponent as TAndCIcon } from 'src/images/sidebar/icon_t&c.svg';
import { ReactComponent as GuideIcon } from 'src/images/sidebar/icon_guide.svg';
import useStyles from "src/styles/components/sidebar";
import { useTranslation } from 'react-i18next';
import Item from "src/components/sidebar/Item";
import clsx from "clsx";
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function SettingSidebar({ open }) {
  const classes = useStyles();
  const { t } = useTranslation("sidebar");
  const currentPathname = useSelector((state) => state.router.location.pathname);
  const isIdol = useSelector((state) => state.auth.isIdol);

  const idolItems = [
    {
      icon: <PersonalInfoIcon />,
      text: t('update-personal-info'),
      href: '/edit-personal-profile'
    },
    {
      icon: <PasswordIcon />,
      text: t('change-password'),
      href: '/change-password'
    },
    {
      icon: <ActivityIcon />,
      text: t('notification-setting'),
      href: '/notification-setting'
    },
    {
      icon: <ChargeIcon />,
      text: t('change-charging-method'),
      href: '/change-charging-method'
    },
    {
      icon: <PaymentIcon />,
      text: t('change-payment-method'),
      href: '/change-payment-method'
    },
    {
      icon: <SupportIcon />,
      text: t('support'),
      href: '/contact-us'
    },
    {
      icon: <TAndCIcon />,
      text: t('terms-and-conditions'),
      href: '/terms-conditions'
    },
    {
      icon: <GuideIcon />,
      text: t('platform-usage-guide'),
      href: '/platform-usage-guide'
    },
  ]

  const fanItems = [
    {
      icon: <PersonalInfoIcon />,
      text: t('update-personal-info'),
      href: '/edit-personal-profile'
    },
    {
      icon: <PasswordIcon />,
      text: t('change-password'),
      href: '/change-password'
    },
    {
      icon: <ActivityIcon />,
      text: t('notification-setting'),
      href: '/notification-setting'
    },
    {
      icon: <PaymentIcon />,
      text: t('change-payment-method'),
      href: '/change-payment-method'
    },
    {
      icon: <SupportIcon />,
      text: t('support'),
      href: '/contact-us'
    },
    {
      icon: <TAndCIcon />,
      text: t('terms-and-conditions'),
      href: '/terms-conditions'
    },
    {
      icon: <GuideIcon />,
      text: t('platform-usage-guide'),
      href: '/platform-usage-guide'
    },
  ]

  const items = isIdol ? idolItems : fanItems;

  return (
    <div className={clsx(classes.root, open ? classes.openSidebar : classes.closedSettingSidebar)}>
      <div className={classes.mainContainer}>
        {
          items.map((item, index) => (
            <NavLink
              key={`item_${index}`}
              to={item.href}
              className={classes.navLink}
            >
              <Item
                icon={item.icon}
                text={item.text}
                extraClassName={currentPathname.includes(item.href) && classes.currentNavLink}
              />
            </NavLink>
          ))
        }
      </div>
    </div>
  );
}