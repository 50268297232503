import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    boxSizing: 'border-box'
  },
  textContainer: {
    maxWidth: 700
  },
  header: {
    marginBottom: 40
  }
}), { index: 1 });

export default useStyles;