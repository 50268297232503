import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    background: theme.palette.grey[100],
    width: '100%',
    padding: 30,
    height: '100%'
  },
  mobileRoot: {
    padding: '30px 16px'
  },
  paperRoot: {
    borderRadius: 20,
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: 510
  },
  mobilePaper: {
    width: 320,
    padding: '40px 15px'
  },
  topContainer: {
    marginBottom: 40
  },
  description: {
    marginTop: 15
  },
  form: {
    width: '100%',
  },
  textField: {
    marginBottom: 15
  },
  confirmPassword: {
    marginBottom: 15
  },
  password: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    }
  },
  button: {
    marginTop: 25,
    borderRadius: '100px',
    padding: '10px',
  }
}), { index: 1 })

export default useStyles;