import { makeStyles } from "@material-ui/styles";
import { newWidth } from "../components/screenStyle";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    boxSizing: 'border-box',
    backgroundColor: 'white',
    gap: 16,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingContainer: {
    alignItems: 'center',
    height: 600
  },
  middleColumn: {
    maxWidth: newWidth(562),
    flexGrow: 3,
  },
  space: {
    [theme.breakpoints.up('lg')]: {
      flexGrow: 1,
    }
  },
}), { index: 1 });

export default useStyles;