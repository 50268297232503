import {
    SET_CHAT_CLIENT, 
} from "./actionTypes";
import initialState from "./initialState";

export const chatReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CHAT_CLIENT:
            return {
                ...state,
                chatClient: action.chatClient,
            }
        default:
            return state
    }
}
