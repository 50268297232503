import { makeStyles } from '@material-ui/styles'
import { newWidth } from '../screenStyle'

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    coverPhotoBox: {
        minHeight: 100,
        marginTop: 20,
        borderRadius: "20px 20px 0px 0px",
        overflow: "hidden",
        minWidth:"1200px",
        height: "350px"
    },
    avatarContainer: {
        position: "relative",
    },
    avatarBox: {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 44,
        overflow: "hidden",
        padding: 2,
        // border: `2px solid ${theme.palette.grey[500]}`,
        backgroundImage: "linear-gradient(180deg, #9900FF, #FFA300, #FDC500)",
    },
    avatarHolderOut: {
        borderRadius: 42,
        padding: 2,
        backgroundColor: "#FFFFFF"
    },
    avatarHolder: {
        borderRadius: 40,
        overflow: "hidden",
        height: 100,
        width: 100,
    },
    followersCount: {
        display: "flex",
        justifyContent: "center",
        margin: 15,
    },
    countGroup: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    count: {
        fontFamily: "SF Pro Text",
        fontWeight: 700,
        fontSize: 18,
        color: theme.palette.grey[900],
    },
    countLabel: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.grey[600],
    },
    profile: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 600,
        marginBottom: 50,
    },
    displayName: {
        fontFamily: "SF Pro Text",
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.grey[900],
        textAlign: "center",
    },
    details: {
        marginBottom: 20,
        position: 'relative',
        "& p": {
            fontFamily: "Noto Sans CJK TC",
            fontWeight: 400,
            fontSize: 16,
            color: theme.palette.grey[600],
            textAlign: "center",
        }
    },
    button: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 700,
        fontSize: 16,
        height: 44,
        width: 150,
        borderRadius: 22,
    },
    main: {
        width: "100%",
        minHeight: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    tabs: {
    },
    tab: {
        fontFamily: "Noto Sans CJK TC",
        fontWeight: 700,
        fontSize: 16,
    },
    tiersList: {
        width: "100%",
        margin: "20px 0px",
    },
    preview: {
        // height: props => props.showFullPreview ? "fit-content" : 24,
        maxHeight: props => props.showFullPreview ? "fit-content" : 48,
        overflow: "hidden",
    },
    showPreviewBtn: {
        position: 'absolute',
        padding: "0px 10px",
        bottom: 0,
        right: 0,
        color: theme.palette.grey[500],
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    verifyStatusContainer: {
        marginLeft: 5
    },
    rightSpace: {
        marginRight: 15
    },
    moreIconCircle: {
        cursor: 'pointer'
    },
    alertTriangleIcon: {
        marginRight: 15,
        '& path': {
            stroke: 'black'
        }
    },
    menuPaper: {
        borderRadius: 20,
        padding: 0,
        width: 'fit-content',
        minWidth: 150,
        '& $menuItem:last-child': {
            borderBottom: 'none'
        }
    },
    menuList: {
        padding: 0,
    },
    menuItem: {
        padding: '10px 15px',
        "&.Mui-focusVisible": {
            backgroundColor: "white !important",
        },
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    loadingContainer: {
        height: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    postList: {
        width: "100%",
        margin: "20px 0px",
        [theme.breakpoints.up('lg')]: {
            width: newWidth(562),
        },
    },
    addCard: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        minHeight: 200,
        height: "100%",
        borderRadius: 20,
        border: `dashed 1px ${theme.palette.grey[500]}`,
        boxSizing: "border-box",
    },
    addButton: {
        width: 154,
        height: 44,
        borderRadius: 100,
    },
}))

export default useStyles