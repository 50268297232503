import axios from 'axios'
import _get from 'lodash/get'

import env from 'src/env';

const baseUrl = `https://${env.apiDomain}`

export const getUserById = userId => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/users/${userId}`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getUserById response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getFollowedIdols = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/followed-idols`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getFollowedIdols response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getMyMembershipList = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/my-membership`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getMyMembership response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}


export const getSavedPosts = () => async (dispatch, getState) => {
    try {
        const auth = _get(getState(), "auth")

        const { data } = await axios.get(
            `${baseUrl}/saved-posts`,
            {
                headers: {
                    Authorization: `${_get(auth, "userId")}_${_get(auth, "access_token")}`
                }
            }
        )

        console.log("getSavedPosts response", data)

        if(_get(data, "errorMessage") || _get(data, "errorType") || (_get(data, "success") === false)) {
            return Promise.reject(_get(data, "errorMessage", _get(data, "code", "Something went wrong")))
        }

        return _get(data, "data")
    } catch(err) {
        console.log(err)
        return Promise.reject(_get(err, "message", "Something went wrong"))
    }
}
