import React from 'react'
import { Typography, Paper } from '@material-ui/core';
import useStyles from 'src/styles/components/dashboard/performance';
import LineChart from 'src/components/dashboard/LineChart';

export default function OtherStatCard({
  title,
  counts,
  chartColors,
  borderColor
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <Typography variant="h5" align="left" className={classes.chartHeading}>
        {title}
      </Typography>
      <LineChart
        labels={Object.keys(counts)}
        chartValues={Object.values(counts)}
        chartColors={chartColors}
        borderColor={borderColor}
      />
    </Paper>
  )
}