import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    boxSizing: 'border-box'
  },
  mainContainer: {
    maxWidth: 700,
    width: '100%',
  },
  form: {
    width: '100%'
  },
  header: {
    marginBottom: 40
  },
  subHeader: {
    marginBottom: 20
  },
  textField: {
    marginBottom: 15
  },
  button: {
    width: 150,
    height: 44,
    borderRadius: 100,
    marginTop: 40,
    marginBottom: 20
  },
  textBold: {
    fontWeight: 'bold'
  },
  confirmButton: {
    borderRadius: 100,
    marginTop: 40,
  },
  dialogTitle: {
    paddingBottom: 15,
  },
  dialogContent: {
    paddingTop: 15,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    maxWidth: 400,
    minWidth: 250
  }
}), { index: 1 });

export default useStyles;