import { makeStyles } from "@material-ui/styles";
import { newHeight } from "../components/screenStyle";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    loadingContainer: {
        alignItems: 'center',
        height: 600
    },
    button: {
        margin: "0px 5px",
        width: 100,
        height: 38,
        borderRadius: 100
    },
    formContainer: {
        margin: 10,
    },
    formElement: {
        margin: "40px 0px",
    },
    formElementTitle: {
        fontSize: 18,
        fontFamily: "Noto Sans CJK TC",
        margin: "20px 0px",
        color: theme.palette.grey[900],
    },
    formElementBody: {
        borderRadius: 20,
        // flexGrow: 1,
        // width: props => props.formElementBodyWidth || "30%",
        // display:'flex',
        // flexDirection:"column",
    },
    addCard: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // width: props => props.formElementBodyWidth || "30%",
        minHeight: 200,
        height: "100%",
        borderRadius: 20,
        border: `dashed 1px ${theme.palette.grey[500]}`,
        boxSizing: "border-box",
    },
    condition: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "Noto Sans CJK TC",
        color: theme.palette.grey[500],
    },
    inputBox: {
        flexGrow: 2,
        display: "flex",
        alignItems: "center",
    },
    flexContainer1: {
        display: 'flex',
    },
    flexContainerMain: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
        // gap: props => props.gap || 15,
        justifyContent: 'flex-start',
        width: props => props.flexContainerMainWidth || "80vw",
        maxWidth: 1200,
        left: "50%",
        transform: "translate(-50%)",
        // padding: "0px 50px",
        position: "relative",
    },
    flexStartHolder: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
        justifyContent: 'left',
        width: "90vw",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%)",
        padding: "0px 50px",
    },
    flexPaidContainerMain: {
        display: 'flex',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: newHeight(36)
    },
    nonTierOwnerTierHeading: {
        justifyContent: 'center'
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row '
    },
    textInput: {
        fontWeight: 700,
        fontSize: 12,
        fontFamily: "Noto Sans CJK TC",
        color: "#212529",
    },
    description: {
        fontWeight: 400,
        fontSize: 14,
        fontFamily: "Noto Sans CJK TC",
        color: "#212529",
    },
    priceFont: {
        fontWeight: 700,
        fontSize: 28,
        fontFamily: "Noto Sans CJK TC",
        color: "#212529",
    },
    addButton: {
        width: 154,
        height: 44,
        borderRadius: 100,
        // alignSelf:'center',
        // justifyContent:"center",
        // left: 101,
        // top: 305,
    },
    a: {
        // textDecoration: "underline"
        padding: "0px 0px",
        borderBottom: "solid 3px black",
        fontSize: 16,
        fontFamily: "Noto Sans CJK TC",
    },
    textBold: {
        fontWeight: 'bold'
    },
    lockedButton: {
        borderRadius: 100,
        color: 'white',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.light
        },
        display: 'flex',
        alignItems: 'center',
    },
    unlockedButton: {
        borderRadius: 100,
        color: 'white',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.light
        },
        display: 'flex',
        alignItems: 'center',
    },
    buttonContainer: {
        padding: 10
    },
    lockIcon: {
        marginRight: 5
    },
    mailIcon: {
        marginRight: 5
    },
    quotaContainer: {
        borderRadius: 100,
        padding: '5px 10px',
        width: 'fit-content',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        margin: 5
    }
}))

export default useStyles