import React from 'react'
import useStyles from 'src/styles/pages/privacyPolicy';
import {
  Typography
} from "@material-ui/core";

import { useTranslation } from 'react-i18next';
import { getMultiLanguageValue } from './Language';
import { useSelector } from 'react-redux';


export default function PrivacyPoliceMobile() {
  const classes = useStyles();
  const query = useSelector((state) => state.router.location.query); 
  const code=query?.lang;
  
  return (
    <div className='top-margin-mobilepage'>
    <div className={classes.mainContainer}>
      <div className={classes.textContainer}>
        <div className={classes.header}>
          <Typography variant="body1">
        
          {getMultiLanguageValue("p1",code)}
          </Typography>        
        </div>
        <div>
          <Typography variant="body1">
            {getMultiLanguageValue("p2",code)}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1">
            {getMultiLanguageValue("p3",code)}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="body1">
            {getMultiLanguageValue("p4",code)}
          </Typography>
        </div>
        <br />       
        <div>
          <Typography variant='body1'>
          {getMultiLanguageValue("p5",code)}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant='body1'>
          {getMultiLanguageValue("p6",code)}
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant='body1'>
          {getMultiLanguageValue("p7",code)}          
          </Typography>
        </div>
        <div>
          <Typography variant='body1'>
          {getMultiLanguageValue("p8",code)}
          </Typography>
        </div>
      </div>
    </div>
    </div>
  )
}