import React, { useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

import _get from 'lodash/get'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import useStyles from 'src/styles/components/feed-post/post.js'

import MediaList from './MediaList'
import PostStatus from './PostStatus'
import CommentsHolder from './CommentsHolder'
import PostHead from './PostHead'

const Post = ({ 
    post,
    onClickofEdit,
    onClickofDelete,
    onDeletecomment
}) => {
  
    
    const postRef = useRef(null)
    const {
        inViewport,
        enterCount,
        leaveCount,
    } = useInViewport(
        postRef,
        {
            // root: document.body,
            root: null,
            rootMargin: '300px',
            threshold: 0.0,
        },
        { disconnectOnLeave: false },
        {post},
    )

    console.log("useInViewport", _get(post, "postId"), inViewport, enterCount, leaveCount)

    const classes = useStyles()
    return (
        <Card 
            ref={postRef}
            id={_get(post, "postId")}
            className={classes.root}
        >
            {
            (!enterCount && !inViewport) ? 
            <Box style={{
                minHeight: 400
            }} />
            : <>
            <PostHead
                post={post}
                onClickofEdit={(data) =>onClickofEdit(data)}
                onClickofDelete={(data) =>onClickofDelete(data)}
            />

            {
                (_get(post, "title") || _get(post, "description")) &&
                <Box className={classes.titleBar}>
                {
                    _get(post, "title") &&
                    <Typography variant="body1" className={classes.title} component="h4">
                        {_get(post, "title")}
                    </Typography>
                }
                {
                    _get(post, "description") && 
                    <Box className={classes.descriptionHolder}>
                        <LocalOfferOutlinedIcon  className={classes.descriptionIcon} />
                        <Typography variant="body1" className={classes.description} component="span"> 
                            {_get(post, "description")}
                        </Typography>
                    </Box>
                }
                </Box>
            }
          <MediaList 
                media={_get(post, "media")} 
                postType={_get(post, "postType")}
                className={classes.mediaList}
            />

            <PostStatus 
                postId={_get(post, "postId")}
                likeCount={_get(post, "likeCount")}
                commentCount={_get(post, "commentCount")}
                userSaved={_get(post, "userSaved")}
                userLiked={_get(post, "userLiked")}
            />

            <Box className={classes.divider} />

            <CommentsHolder
                postId={_get(post, "postId")}
                commentCount={_get(post, "commentCount")}
                postOwnerName={_get(post, "idol.displayName")}
                comments={_get(post, "comments")}
                onDeletecomment={onDeletecomment}
            />

                
                </>
            }
        </Card>

    )
}

export default Post
