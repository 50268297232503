import {
    SET_LOGGED_IN_USER,
} from './actionTypes'
import initialState from "./initialState"

export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_LOGGED_IN_USER:
            return {
                ...state,
                loggedInUser: action.payload,
            }
        default:
            return state
    }
}