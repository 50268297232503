import React from 'react'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import env from "src/env";
import Payment from "src/components/Payment";

const initialOptions = {
  "client-id": env.clientId,
  currency: "USD",
  intent: "subscription",
  vault: true,
};

export default function Subscribe() {
  console.log('subscribe')
  return (
    <PayPalScriptProvider options={initialOptions}>
      <Payment />
    </PayPalScriptProvider>
  )
}