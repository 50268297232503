import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    position: 'sticky',
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 10
  },
  expand: {
    width: 364
  },
  root: {
    width: 260,
    height: '100vh',
    transition: 'all 0.5s ease 0s',
    boxSizing: 'border-box',
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    overflowX: 'hidden',
  },
  mainContainer: {
    width: 260,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    boxSizing: 'border-box',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    width: 220,
    transition: 'all 0.5s ease 0s'
  },
  logo: {
    marginBottom: 20,
    width: 50,
    height: 66,
    cursor: "pointer"
  },
  item: {
    width: 220,
    height: 44,
    marginBottom: 15,
    cursor: 'pointer',
    padding: 10,
    borderRadius: 5,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    },
  },
  icon: {
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  accordion: {
    width: '100%',
    boxShadow: "none",
    margin: "5px 0 0 !important",
    border: "none",
    "&.MuiAccordion-root:before": {
      height: 0,
      backgroundColor: 'white'
    },
    '& $item': {
      width: 'auto',
      marginBottom: 0
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  accordionSummary: {
    cursor: "pointer",
    pointerEvents: "auto",
    padding: '0 !important',
    minHeight: "48px !important",
    height: "51px !important",
  },
  accordionExpandIcon: {
    cursor: "pointer",
    pointerEvents: "auto",
    "& path": {
      stroke: theme.palette.primary.main,
    },
    "&:hover": {
      borderRadius: "50%",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  accordionDetails: {
    margin: "0 -20px -20px",
  },
  arrowDownIcon: {
    '& path': {
      stroke: 'rgb(33, 37, 41) !important'
    }
  },
  subItem: {
    width: '100%',
    marginBottom: 15,
    cursor: 'pointer',
    padding: '10px 50px',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  subItemContainer: {
    width: '100%'
  },
  bottomContainer: {
    paddingTop: 20,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 20,
    boxSizing: 'border-box',
    width: '100%'
  },
  closedSettingSidebar: {
    width: 0,
    border: 'none'
  },
  openSidebar: {
    width: 260,
  },
  narrowSidebar: {
    width: 104,
    '& $item': {
      width: 64
    },
    '& $bottomContainer': {
      width: 64
    },
    '& $logoContainer': {
      width: 64
    }
  },
  navLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
  currentNavLink: {
    backgroundColor: theme.palette.grey[100]
  },
  accordionHeader: {
    width: '100% !important',
  }
}), { index: 1 });

export default useStyles;