import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useLocation, useRouteMatch } from 'react-router-dom'

import _includes from 'lodash/includes'

import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import useStyles from 'src/styles/components/mobileAppRedirect'

const platforms = ["android", "iOS"]

const MobileAppRedirect = () => {
    const [currentPlatform, setCurrentPlatform] = useState(null)
    const [ open, setOpen ] = useState(false)
    const [ closedByUser, setClosedByUser ] = useState(false)

    const { pathname } = useLocation()

    const { path: creatorPath, url: creatorUrl } = (useRouteMatch("/creator/:fanshipPageName") || {})

    const exceptions = useMemo(() => [
        "/applink",
        "/",
        "/registration",
        "/reset-password",
        "/forgot-password",
        "/terms-conditions",
        "/platform-usage-guide",
        "/privacy-policy",
        "/subscribe",
        '/platform-usage-guide-mobile',
        '/privacy-policy-mobile',
          "/terms-conditions-mobile",
          
        creatorUrl
    ], [creatorUrl])

    useEffect(() => {
        console.log(
            "isAndroid", isAndroid,
            "isIOS", isIOS
        )
        if (isAndroid) setCurrentPlatform(platforms[0])
        else if (isIOS) setCurrentPlatform(platforms[1])

    }, [])

    useEffect(() => {
        console.log("MobileAppRedirect: \npathname", pathname, 
        "\ncreatorPath", creatorPath, "\nexceptions", exceptions, 
        "\nexception", _includes(exceptions, pathname))
        
        if(
            currentPlatform &&
            !closedByUser && 
            !_includes(exceptions, pathname)
        ) {
            console.log("MobileAppRedirect: opening the dialog")
            setTimeout(() => {
                setOpen(true)
            }, 3000)
        }
    }, [currentPlatform, pathname, exceptions, creatorPath, closedByUser])

    const onClose = useCallback(() => {
        setOpen(false)
        setClosedByUser(true)
    }, [])

    const handleRoute = useCallback(() => {
        if (currentPlatform === platforms[0]) {
            console.log("redirect to android: play-store-link")
            // window.location.replace(`android: play-store-link`)
        }
        else if (currentPlatform === platforms[1]) {
            console.log("redirect to iOS: app-store-link")
            window.location.replace("https://apps.apple.com/us/app/fanship/id1580018765")
        }

    }, [currentPlatform])

    const { t } = useTranslation('mobile-app-redirect')
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.dialog
            }}
        >
            <Typography variant="h4">
                {t("dialog-title")}
            </Typography>
            <Typography variant="h6">
                {t("dialog-content")}
            </Typography>
            <Button
                className={classes.dialogBtn}
                onClick={handleRoute}    
            >{t("btn-label")}</Button>
        </Dialog>
    )
}

export default MobileAppRedirect
