import crypto from 'crypto';
import env from 'src/env';

export function encodeMessage(message) {
  let cipher = crypto.createCipheriv(
    env.algorithm, Buffer.from(env.securitykey), Buffer.from(env.iv));
  let encrypted = cipher.update(message);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('hex');
}
