import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 16,
    top: 16,
    padding: 0,
    color: "#999999",
    [theme.breakpoints.down("xs")]: {
      right: 8,
      top: 8,
    },
  },
  dialogTitle: {
    padding: 0,
    textAlign: "left",
  },
  paper: {
    borderRadius: "20px !important",
    padding: 20,
    textAlign: "center",
    minWidth: 300,
    boxSizing: 'border-box'
  },
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  header: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    fontWeight: 'bold'
  },
  mainContainer: {
    marginTop: 15
  },
  button: {
    borderRadius: 100,
    height: 44,
    width: 150
  },
  uploadButtonRoot: {
    position: "relative",
  },
  uploadInput: {
    position: "absolute",
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  confirmButton: {
    marginTop: 40
  },
  reactCrop: {
    marginTop: 15
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
}), { index: 1 });

export default useStyles;