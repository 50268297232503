import React from 'react'
import useStyles from "src/styles/components/errorHelperText";
import { Typography } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "src/images/common/icon_error_info.svg";
import clsx from "clsx";

export default function ErrorHelperText({
  text
}) {
  const classes = useStyles();

  return (
    <span className={clsx(classes.flexContainer, classes.root)}>
      <InfoIcon
        className={classes.icon}
      />
      <Typography variant="caption" className={classes.errorText}>
        {text}
      </Typography>
    </span>
  )
}