import React, { useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _trim from 'lodash/trim'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import defaultAvatar from 'src/images/common/default_avatar.png'
import useStyles from 'src/styles/components/feed-post/post.js'


const LikesDialog = ({
    open,
    onClose,
    likes,
}) => {
    const [ filter, setFilter ] = useState("")
    const classes = useStyles({
        avatarHolderHeight: 50,
        avatarHolderBorderRadius: 20,
    })

	const { t } = useTranslation("feed-post")

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{paper: clsx(classes.dialog, classes.likesDialog)}}
        >
            <DialogTitle
                className={classes.dialogTitle}
            >
                <Typography variant="h6">{t("liked-users")}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} 
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <TextField
                value={filter}
                onChange={event => setFilter(_get(event, "target.value"))}
                InputProps={{
                    className: classes.searchInput,
                    classes: {
                        notchedOutline: classes.noBorder,
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                fullWidth={true}
                placeholder={t("search")}
            />

            <Box className={classes.likesList}>
                {_map(
                    _filter(
                        likes,
                        like => {
                            const regex = new RegExp(_trim(filter), "i")
                            return regex.test(_get(like, "user.displayName")) || regex.test(_get(like, "user.countryName")) || regex.test(_get(like, "user.countryCode"))
                        }
                    ),
                    (like, index) => <Box 
                        key={_get(like, "userId", index)} 
                        className={classes.likesListItem}
                    >
                        <Box className={classes.avatarHolder}>
                            <img 
                                src={_get(like, "user.avatar") || defaultAvatar}
                                alt="avatar"
                                placeholder={defaultAvatar}
                            />
                        </Box>
                        <Box>
                            <Typography variant="h6" className={classes.name}>
                                {_get(like, "user.displayName")}
                            </Typography>
                            <Typography variant="body1" component="span" className={classes.likeMeta}>
                                {_get(like, "user.countryName", _get(like, "user.countryCode"))}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box className={classes.progressHolder}>
                {!likes && 
                    <CircularProgress />
                }
            </Box>
            {/* Test users liked
            {likes ? (likes.length ? JSON.stringify(likes) : "Empty") : "Loading..."} */}
        </Dialog>
    )
}

export default LikesDialog
