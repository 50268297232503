import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import _get from 'lodash/get'
import _slice from 'lodash/slice'
import _map from 'lodash/map'

import { useSnackbar } from 'notistack'

import { getFollowedIdols } from 'src/redux/account/actions';

import CreatorCard from 'src/components/personal-profile/CreatorCard';
import { CircularProgress } from "@material-ui/core";
import Box from '@material-ui/core/Box'
import Pagination from "@material-ui/lab/Pagination";
import useStyles from 'src/styles/components/personal-profile/followedIdols';

function FollowedIdols({
  getFollowedIdols,
}) {
  const [ followedIdols, setFollowedIdols ] = useState(null)
  const [page, setPage] = useState(1);

  useEffect(() => {
    getFollowedIdols()
    .then(followedIdols => {
      setFollowedIdols(followedIdols)
    }).catch(err => {
      console.log(err)
      enqueueSnackbar("Something went wrong", { variant: "error", })
    })

    // eslint-disable-next-line
  }, [getFollowedIdols])

  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles();

  const rowPerPage = 10;
  const startIndex = (page - 1) * rowPerPage;
  const endIndex = page * rowPerPage;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  return (
    <>
      {
        followedIdols ?
          <Box>
            {
              _map(
                _slice(followedIdols, startIndex, endIndex),
                (idol) => {
                  return (
                    <CreatorCard
                      key={`idol_${idol.userId}`}
                      avatar={idol.avatar}
                      displayName={idol.displayName}
                      countryCode={idol.countryCode}
                    />
                  )
                }
              )
            }
            {_get(followedIdols, "length") ? <Box className={classes.paginationContainer}>
              <Pagination
                className={classes.pagination}
                count={Math.ceil(followedIdols.length / rowPerPage)}
                siblingCount={1}
                onChange={handleChangePage}
                page={page}
              />
            </Box> : null}
          </Box> :
          <Box className={classes.progressHolder}>
            <CircularProgress />
          </Box>
      }
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  getFollowedIdols: () => dispatch(getFollowedIdols()),
})

export default connect(
  null,
  mapDispatchToProps,
)(FollowedIdols)