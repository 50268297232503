import React from 'react'

import _get from 'lodash/get'

import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as UnlockIcon} from 'src/images/common/icon_unlock.svg'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined'
import useStyles from 'src/styles/components/personal-profile/membership'

const Membership = ({
    membership,
}) => {
    console.log("Membership: render", membership)
    const classes = useStyles({
        image: _get(membership, "image"),
    })

    const { t } = useTranslation('personal-profile')

    return (
        <Box className={classes.item}>
            <Box className={classes.statusBox}>
                <UnlockIcon />
                <Typography variant="body2" component="span" className={classes.statusText}>{t("unlocked")}</Typography>
            </Box>
            <Box className={classes.detailsBox}>
                <Box className={classes.detailsHead}>
                    <Box className={classes.detailsHeadLeft}>
                        <Typography className={classes.title} component="span">{_get(membership, "title")}</Typography>
                        <Box className={classes.postCount}>
                            <DraftsOutlinedIcon className={classes.postIcon}/>
                            <Typography className={classes.postCountNumber} component="span">{`${_get(membership, "postCount")} ${t("posts")}`}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.detailsHeadRight}>
                        <Typography component="span" className={classes.priceNumber}>{`$${_get(membership, "price")}`}</Typography>
                        <Typography component="span" className={classes.priceLabel}>{`/${t("per-month")}`}</Typography>
                    </Box>
                </Box>
                <Box className={classes.detailsBody}>
                    {_get(membership, "description")}
                </Box>
            </Box>
        </Box>
    )
}

export default Membership
