import React, { useState } from 'react';
import InfoDialog from 'src/components/InfoDialog';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from "src/styles/components/header/languageDialog";
import LoadingButton from 'src/components/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import env from 'src/env';
import { updateLanguage } from 'src/redux/auth/actions';

export default function LanguageDialog({
  open,
  handleClose
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation("header");
  const [language, setLanguage] = useState(i18n.language);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isLoading, setIsLoading] = useState(false);

  console.log('i18n: ', i18n);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const submit = async () => {
    i18n.changeLanguage(language);

    if (isAuthenticated) {
      console.log('do something');
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      setIsLoading(true);
      const res = await fetch(
        `https://${env.apiDomain}/language`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${userId}_${token}`,
          },
          body: JSON.stringify({ language })
        }
      );

      const result = await res.json();
      console.log('result: ', result);
      setIsLoading(false);
      dispatch(updateLanguage(language));
    }

    handleClose();
  }

  return (
    <InfoDialog
      open={open}
      handleClose={handleClose}
      title={
        <Typography
          variant="h6"
          className={clsx(classes.textBold, classes.header)}
          align="center"
        >
          {t('language-dialog.header')}
        </Typography>
      }
      button={
        <LoadingButton
          fullWidth
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={submit}
          loadingProps={{
            size: 24
          }}
          isLoading={isLoading}
        >
          {t('language-dialog.confirm')}
        </LoadingButton>
      }
    >
      <div className={classes.mainContainer}>
        <RadioGroup name="language" value={language} onChange={handleChangeLanguage}>
          <FormControlLabel
            value="en"
            control={<Radio color="primary" />}
            label="English"
          />
          <FormControlLabel
            value="zh-CHT"
            control={<Radio color="primary" />}
            label="繁體中文"
          />
          <FormControlLabel
            value="ja"
            control={<Radio color="primary" />}
            label="日本語"
          />
        </RadioGroup>
      </div>
    </InfoDialog>
  )

}