import React from 'react'
import { Typography, Paper, useTheme } from '@material-ui/core';
import useStyles from 'src/styles/components/dashboard/performance';
import { useTranslation } from 'react-i18next';
import DoughnutChart from 'src/components/dashboard/DoughnutChart';
import formatNumber, { formatDecimal } from 'src/lib/formatters/number';
import moment from 'moment';

export default function RevenueCard({
  stat,
  referrals
}) {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const totalTiersRevenue = stat.tiers.reduce((previous, current) => {
    return previous + current.monthlyRevenue
  }, 0)
  const totalReferralsRevenue = referrals.filter((referral) => !moment(referral.expiryDate, 'YYYY-MM-DD').isBefore(moment()))
    .reduce((previous, current) => {
      return previous + current.referredIdol.sharedRevenue
    }, 0)

  const totalRevenue = totalTiersRevenue + totalReferralsRevenue

  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.success.light,
    theme.palette.primary.dark,
    theme.palette.secondary.light,
    theme.palette.error.light
  ];

  const Legend = ({
    number,
    textColor,
    dotColor,
    title
  }) => {
    return (
      <div className={classes.legend}>
        <div>
          <Typography variant="h4" align="left" style={{ color: textColor }}>
            {"$" + formatNumber(number)}
          </Typography>
          <div className={classes.flexContainer}>
            <div className={classes.dot} style={{ backgroundColor: dotColor }} />
            <Typography variant="body2" color="textSecondary">
              {title}
            </Typography>
          </div>
        </div>
      </div>
    )
  }


  return (
    <Paper className={classes.paperRoot}>
      <Typography variant="h5" align="left" className={classes.chartHeading}>
        {t('tab-1.main-stat.current-month-revenue')}
      </Typography>
      <DoughnutChart
        labels={[...stat.tiers.map(tier => tier.title), t('tab-1.main-stat.referral-scheme')]}
        chartValues={[...stat.tiers.map(tier => formatDecimal(tier.monthlyRevenue)), formatDecimal(totalReferralsRevenue)]}
        chartColors={colors}
        bigFigure={
          <Typography variant="h3">
            {'$' + formatNumber(totalRevenue)}
          </Typography>
        }
        centerSubHeader={t('tab-1.main-stat.total-monthly-revenue')}
      />
      <div className={classes.legendSection}>
        {
          stat.tiers.map((tier, index) => {
            return (
              <Legend
                key={`legend_${index}`}
                number={tier.monthlyRevenue}
                textColor={colors[index]}
                dotColor={colors[index]}
                title={tier.title}
              />
            )
          })
        }
        <Legend
          number={totalReferralsRevenue}
          textColor={colors[stat.tiers.length]}
          dotColor={colors[stat.tiers.length]}
          title={t('tab-1.main-stat.referral-scheme')}
        />
      </div>
    </Paper>
  )
}