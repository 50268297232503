import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.grey[100]
  },
  paperRoot: {
    width: 510,
    padding: 40,
    borderRadius: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    marginBottom: 10,
  },
  button: {
    padding: 10,
    borderRadius: 100,
    marginTop: 40
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  failureHeader: {
    color: theme.palette.error.main
  },
  icon: {
    marginRight: 5
  }
}), { index: 1 });

export default useStyles;