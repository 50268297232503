import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'

import {
    getPostFeed,
    clearPostFeed,
} from 'src/redux/feed-post/actions'

import Post from './Post'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import useStyles from 'src/styles/components/feed-post/feed'
import env from 'src/env';
import axios from 'axios'
let firedFeedReq = false

const Feed = ({
    feed,
    isRefresh,
    handleCloseRefresh,
    isFeedLoading,
    getPostFeed,
    clearPostFeed,
    onClickofEdit
}) => {
   
    useEffect(() => {
       getPostFeed()
        setTimeout(() => {
            window.addEventListener('scroll', handleScroll)
        }, 2000)

        return () => {
            clearPostFeed()
            window.removeEventListener('scroll', handleScroll)
        }

        // eslint-disable-next-line
    }, [])

    const onRefresh = () => {
        console.log("isRefresh123", isRefresh);
        handleCloseRefresh()
        clearPostFeed()
        getPostFeed().then((res) => {
            console.log("resres",res);
            firedFeedReq = false;
        })
        firedFeedReq = true
       
    }

    const handleScroll = useCallback(() => {
        if (
            !firedFeedReq
            &&
            !isFeedLoading
            &&
            ((document.body.offsetHeight - (window.scrollY + window.innerHeight)) < 100)
        ) {
            getPostFeed().then(() => {
                firedFeedReq = false;
            })
            firedFeedReq = true
        }
        // eslint-disable-next-line
    }, [isFeedLoading])

    const classes = useStyles()
    const handleOnDelete = (data) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const header = {
            headers: {
                'content-type': 'application/json',
                Authorization: `${userId}_${token}`
            }
        }
        let url = `https://${env.apiDomain}/posts/${data.postId}`
        axios.delete(url, header)
            .then((resp) => {
                console.log("post",resp);
                if (resp.data.success) {
                    clearPostFeed()
                    getPostFeed().then(() => {
                        firedFeedReq = false;
                    })
                    firedFeedReq = true
                } else {
                    throw new Error("Server returned error while calling /posts")
                }
            }).catch((error) => {
                console.log("/posts error: " + error)
            })
    }
 


feed.sort((a,b) => b.updatedAt - a.updatedAt);

    return (

        <Box
            className={classes.root}
        >
            {isRefresh && onRefresh()}

            {_map(
            feed,
                (post, index) =>
                    <Post
                        key={_get(post, "postId")}
                        post={post}
                        onClickofEdit={(data) => onClickofEdit(data)}
                        onClickofDelete={(data) => { handleOnDelete(data) }}
                        onDeletecomment={()=>onRefresh()}
                    />
            )}
            <Box className={classes.progressHolder}>
                {isFeedLoading &&
                    <CircularProgress />
                }
            </Box>
        </Box>
    )
}

const mapStateToProps = state => ({
    feed: _get(state, "feedPost.feed"),
    isFeedLoading: _get(state, "feedPost.isFeedLoading"),
})

const mapDispatchToProps = dispatch => ({
    getPostFeed: feedCount => dispatch(getPostFeed(feedCount)),
    clearPostFeed: payload => dispatch(clearPostFeed(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Feed)
