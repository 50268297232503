import { makeStyles } from "@material-ui/styles";
import { newWidth } from "../components/screenStyle";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",        
        marginTop: 16,
        gap: "0px 16px"
    },
    space: {
        flexGrow: 1,
    },
    main: {
        // flexGrow: 1,
        width: newWidth(562),
    },
}))

export default useStyles