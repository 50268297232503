import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 748,
        gap: 40,
        paddingTop: 20,
        color: theme.palette.grey[900],
    },
    settingCategory: {
        display: "flex",
        flexDirection: "column",
    },
    settingItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 0px",
    },
    grey500: {
        color: theme.palette.grey[500],
    },
    bottomShadow: {
        boxShadow: `0px 1px ${theme.palette.grey[200]}`
    }
}));

export default useStyles;
