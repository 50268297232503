import React from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import Comment from './Comment'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from 'src/styles/components/feed-post/post.js'


const CommentsList = ({
    postId,
    comments,
    isCommentsListLoading,
    onDeletecomment
}) => {
    const classes = useStyles()

    return <>
    <Box className={classes.commentsList}>
        {
            _map(comments, (comment, index) => 
            <Comment 
                key={_get(comment, "commentId")}
                postId={postId}
                comment={comment}
               onDeletecomment={onDeletecomment}
            />)
        }
    </Box>
    <Box className={classes.progressHolder}>
        {isCommentsListLoading && 
            <CircularProgress />
        }
    </Box>
    </>
}

export default CommentsList
