export function checkHintTypeBySecurityLevel(level) {
  switch (level) {
    case 'strong':
      return 'success';
    case 'moderate':
      return 'warning';
    case 'weak':
      return 'error';
    default:
      break
  }
}