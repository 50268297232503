export function loginSuccess(
  userId,
  email,
  displayName,
  fullName,
  countryCode,
  avatar,
  language,
  isIdol,
  stream_token,
  access_token,
) {
  return {
    type: "LOGIN_SUCCESS",
    userId,
    email,
    displayName,
    fullName,
    countryCode,
    avatar,
    language,
    isIdol,
    stream_token,
    access_token,
  };
}

export function loginFail(msg) {
  return {
    type: "LOGIN_FAIL",
    msg,
  };
}

export function logout() {
  return {
    type: "LOGOUT"
  };
}

export function updatePersonalProfile(
  email,
  displayName,
  fullName,
  avatar
) {
  return {
    type: 'UPDATE_PERSONAL_PROFILE',
    email,
    displayName,
    fullName,
    avatar
  }
}

export function updateLanguage(
  language
) {
  return {
    type: 'UPDATE_LANGUAGE',
    language
  }
}

export function updateCreatorProfile(
  fullName
) {
  return {
    type: 'UPDATE_CREATOR_PROFILE',
    fullName
  }
}