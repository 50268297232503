import React, { useState } from "react";
import useStyles from "src/styles/components/personal-profile/profileCard";
import {
  Typography,
  Paper
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import { NavLink } from 'react-router-dom';

export default function ProfileCard({
  fullName,
  displayName,
  joinYear,
  profile
}) {
  console.log("displayName",displayName);
  const classes = useStyles();
  const { t } = useTranslation("personal-profile");
  let profileTooLong = profile && profile.length > 60;
  const [showFullProfile, setShowFullProfile] = useState(!profileTooLong ? null : false);

  let displayProfile;

  if (showFullProfile === false) {
    displayProfile = profile.substring(0, 60) + '...';
  } else {
    displayProfile = profile
  }

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      {/* <Typography variant="h4">
        {t('greeting', { fullName })}
      </Typography> */}
      <Typography variant="h4">
        {t('greeting1', { displayName })}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t('registration-time', { year: joinYear })}
      </Typography>
      <Typography variant="body1" className={clsx(classes.edit, classes.textBold)}>
        <NavLink to="edit-personal-profile" className={classes.navLink}>
          {t('edit-personal-info')}
        </NavLink>
      </Typography>
      <Typography variant="h6" className={classes.profileHeader}>
        {t('profile')}
      </Typography>
      <Typography variant="body1">
        {profile ? displayProfile : t('no-profile')}
        {
          profileTooLong &&
          <Typography
            variant="body1"
            component="span"
            color="textSecondary"
            className={classes.showFullProfile}
            onClick={() => setShowFullProfile(!showFullProfile)}>
            {showFullProfile ? t('shrink') : t('more')}
          </Typography>
        }
      </Typography>
    </Paper>
  )
}