import React from 'react';
import useStyles from 'src/styles/components/statusWidget';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export default function StatusWidget({
  status
}) {
  const classes = useStyles();
  const { t } = useTranslation('status');

  const getStatusClass = (status) => {
    switch (status) {
      case 'on-going':
        return classes.green
      case 'finished':
        return classes.grey
      default:
        break;
    }
  }

  const getStatusDotClass = (status) => {
    switch (status) {
      case 'on-going':
        return classes.greenDot
      case 'finished':
        return classes.greyDot
      default:
        break;
    }
  }

  return (
    <div className={clsx(classes.root, getStatusClass(status))}>
      <div className={clsx(classes.dot, getStatusDotClass(status))} />
      <Typography variant="body2" className={classes.textBold}>
        {t(status)}
      </Typography>
    </div>
  )
}