import React from 'react'
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import useStyles from "src/styles/components/header/popUpMenu";
import { useDispatch } from 'react-redux';
import { logoutThunk } from 'src/redux/auth/thunks';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as LogoutIcon } from "src/images/common/icon_logout.svg"
import Images from 'src/helper/Images';
import close from 'src/images/icons/close.png'

export  function DialogView({
    icon,
  text,
  anchorEl,
  setAnchorEl,
  onPress,
 
}) {
  const classes = useStyles();
  const open = anchorEl

  // const { t } = useTranslation("header");
// console.log("DialogView---------------",icon,text)
  const closeMenu = () => {
    setAnchorEl(false);
  };

  return (
    <>
      <Menu
        elevation={4}
        style={{top:'100px'}}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={open}
        onClose={()=>closeMenu()}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onPress}
          className={clsx(classes.menuItem, classes.flexContainer)} >
              <img src={icon}  height={20} />
            <Typography variant="body1">
            {/* {t(text)} */}
            {text}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setAnchorEl(false)}
          className={clsx(classes.menuItem, classes.flexContainer)} >
              <img src={close}  height={20} />
            <Typography variant="body1">
            {/* {t("Cancel")} */}
            {"Cancel"}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}