import React, { useEffect } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@material-ui/core";
import themeConfig from "src/styles/theme";
import { ConnectedRouter } from "connected-react-router";
import { history } from "src/redux/store";
import { useSelector, useDispatch } from "react-redux";
import { restoreLoginThunk } from "src/redux/auth/thunks";
import Layout from "src/components/Layout";
import MobileAppRedirect from "src/components/MobileAppRedirect";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import "moment/locale/zh-cn";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";
import useStyles from "./styles/app";

moment.locale("zh-cn");

function App() {
  const { i18n } = useTranslation();
  const theme = createTheme(themeConfig);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    if (isAuthenticated === null) {
      dispatch(restoreLoginThunk());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const classes = useStyles();

  return (
    <Router>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.successMsg,
              variantError: classes.errorMsg,
              variantWarning: classes.warningMsg,
              variantInfo: classes.infoMsg,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MobileAppRedirect />
            {isAuthenticated === null && <div />}

            {isAuthenticated !== null && (
              <Layout isAuthenticated={isAuthenticated} />
            )}
          </SnackbarProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Router>
  );
}

export default App;
