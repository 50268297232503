import React from 'react'

import RenderSwitch from "src/components/renderSwitch/FormField";
import useStyles from "src/styles/components/notification-setting/switch";

import DoneIcon from '@material-ui/icons/Done'

export default function RenderSwitchWrapper(props) {
    const classes = useStyles();

    return (
        <RenderSwitch
            checkedIcon={
                <DoneIcon
                    // fontSize="small"
                    className={classes.switchIcon}
                />
            }
            classes={{
                root: classes.switchRoot,
                switchBase: classes.switchBase,
                thumb: classes.switchThumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
}
