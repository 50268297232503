import React from 'react'
import TextAreaMui from '@material-ui/core/TextareaAutosize'

const TextArea = (props) => {
    return <TextAreaMui
        {...props}
    />
}

export default TextArea
