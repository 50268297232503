import React, { useEffect, useCallback } from 'react'
import { reduxForm, Field } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'

import _get from 'lodash/get'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { getMyNotificationSetting, putMyNotificationSetting } from 'src/redux/notification-setting/actions'
import RenderSwitch from 'src/components/notification-setting/StyledRenderSwitch'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import useStyles from 'src/styles/pages/notificationSetting'

export const notificationSettingFormName = "notification-setting"

const NotificationSetting = ({initialize, handleSubmit, submitFailed, ...props}) => {

    useEffect(() => {
        dispatch(getMyNotificationSetting()).then(setting => {
            initialize?.(setting)
        })

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        submitFailed && enqueueSnackbar("Something went wrong", { variant: "error", })

        // eslint-disable-next-line
    }, [submitFailed])

    const isIdol = useSelector(state => _get(state, "auth.isIdol"))
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const handleChange = useCallback(() => {
        setTimeout(() => handleSubmit?.(), 500)

        // eslint-disable-next-line
    }, [])

    const classes = useStyles()

    const { t } = useTranslation('notification-setting')

    return (
        <Container maxWidth='md' className={classes.container}>
            <Typography variant="h3">
                {t("notification-settings")}
            </Typography>
            <Box className={classes.settingCategory}>
                <Typography variant="h5">
                    {t("fanship-update")}
                </Typography>
                <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                    <Box>
                        <Typography variant="body1">
                            {t("e-mail")}
                        </Typography>
                    </Box>
                    <Field
                        name="fanshipUpdate.email"
                        component={RenderSwitch}
                        onChange={handleChange}
                    />
                </Box>
                <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                    <Box>
                        <Typography variant="body1">
                            {t("sms")}
                        </Typography>
                    </Box>
                    <Field
                        name="fanshipUpdate.sms"
                        component={RenderSwitch}
                        onChange={handleChange}
                    />
                </Box>
                <Box className={classes.settingItem}>
                    <Box>
                        <Typography variant="body1">
                            {t("browser-notification")}
                        </Typography>
                        <Typography variant="body2" className={classes.grey500}>
                            {t("browser-notification-sub-title")}
                        </Typography>
                    </Box>
                    <Field
                        name="fanshipUpdate.pushNotification"
                        component={RenderSwitch}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            {
                (isIdol === false) && 
                <Box className={classes.settingCategory}>
                    <Typography variant="h5">
                        {t("creator-message")}
                    </Typography>
                    <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                        <Box>
                            <Typography variant="body1">
                                {t("e-mail")}
                            </Typography>
                        </Box>
                        <Field
                            name="idolMessage.email"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                        <Box>
                            <Typography variant="body1">
                                {t("sms")}
                            </Typography>
                        </Box>
                        <Field
                            name="idolMessage.sms"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={classes.settingItem}>
                        <Box>
                            <Typography variant="body1">
                                {t("browser-notification")}
                            </Typography>
                            <Typography variant="body2" className={classes.grey500}>
                                {t("browser-notification-sub-title")}
                            </Typography>
                        </Box>
                        <Field
                            name="idolMessage.pushNotification"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                </Box>
            }
            {
                (isIdol === true) && 
                <Box className={classes.settingCategory}>
                    <Typography variant="h5">
                        {t("post-comments")}
                    </Typography>
                    <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                        <Box>
                            <Typography variant="body1">
                                {t("e-mail")}
                            </Typography>
                        </Box>
                        <Field
                            name="postComment.email"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                        <Box>
                            <Typography variant="body1">
                                {t("sms")}
                            </Typography>
                        </Box>
                        <Field
                            name="postComment.sms"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={classes.settingItem}>
                        <Box>
                            <Typography variant="body1">
                                {t("browser-notification")}
                            </Typography>
                            <Typography variant="body2" className={classes.grey500}>
                                {t("browser-notification-sub-title")}
                            </Typography>
                        </Box>
                        <Field
                            name="postComment.pushNotification"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                </Box>
            }
            {
                (isIdol === true) && 
                <Box className={classes.settingCategory}>
                    <Typography variant="h5">
                        {t("user-message")}
                    </Typography>
                    <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                        <Box>
                            <Typography variant="body1">
                                {t("e-mail")}
                            </Typography>
                        </Box>
                        <Field
                            name="fanMessage.email"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={clsx(classes.settingItem, classes.bottomShadow)}>
                        <Box>
                            <Typography variant="body1">
                                {t("sms")}
                            </Typography>
                        </Box>
                        <Field
                            name="fanMessage.sms"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={classes.settingItem}>
                        <Box>
                            <Typography variant="body1">
                                {t("browser-notification")}
                            </Typography>
                            <Typography variant="body2" className={classes.grey500}>
                                {t("browser-notification-sub-title")}
                            </Typography>
                        </Box>
                        <Field
                            name="fanMessage.pushNotification"
                            component={RenderSwitch}
                            onChange={handleChange}
                        />
                    </Box>
                </Box>
            }
        </Container>
    )
}

const onSubmit = (values, dispatch, props) => {
    console.log("NotificationSettings onSubmit values", values)
    return dispatch(putMyNotificationSetting?.(values))
}

export default reduxForm({
    form: notificationSettingFormName,
    onSubmit,
})(NotificationSetting)
