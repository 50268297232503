import React from 'react'
import { Typography, Paper, useTheme } from '@material-ui/core';
import useStyles from 'src/styles/components/dashboard/performance';
import { useTranslation } from 'react-i18next';
import DoughnutChart from 'src/components/dashboard/DoughnutChart';
import formatNumber from 'src/lib/formatters/number';

export default function FollowersAndSubscribersCard({
  stat
}) {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const totalSubscribers = stat.tiers.reduce((previous, current) => {
    return previous + current.subscriberCount
  }, 0)
  const totalFollowersAndSubscribers = (stat.followerCount || 0) + totalSubscribers;

  const colors = [
    theme.palette.grey[400],
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.success.light,
    theme.palette.primary.dark,
    theme.palette.secondary.light,
    theme.palette.error.light
  ];

  const Legend = ({
    number,
    textColor,
    dotColor,
    title
  }) => {
    return (
      <div className={classes.legend}>
        <div>
          <Typography variant="h4" align="left" style={{ color: textColor }}>
            {formatNumber(number)}
          </Typography>
          <div className={classes.flexContainer}>
            <div className={classes.dot} style={{ backgroundColor: dotColor }} />
            <Typography variant="body2" color="textSecondary">
              {title}
            </Typography>
          </div>
        </div>
      </div>
    )
  }


  return (
    <Paper className={classes.paperRoot}>
      <Typography variant="h5" align="left" className={classes.chartHeading}>
        {t('tab-1.main-stat.number-of-followers-and-subscribers')}
      </Typography>
      <DoughnutChart
        labels={[t('tab-1.main-stat.follow'), ...stat.tiers.map(tier => tier.title)]}
        chartValues={[stat.followerCount, ...stat.tiers.map(tier => tier.subscriberCount)]}
        chartColors={colors}
        bigFigure={
          <Typography variant="h3">
            {Intl.NumberFormat('zh-HK').format(totalFollowersAndSubscribers)}
          </Typography>
        }
        centerSubHeader={t('tab-1.main-stat.number-of-followers-and-subscribers')}
      />
      <div className={classes.legendSection}>
        <Legend
          number={stat.followerCount || 0}
          textColor="black"
          dotColor={colors[0]}
          title={t('tab-1.main-stat.follow')}
        />
        {
          stat.tiers.map((tier, index) => {
            return (
              <Legend
                key={`legend_${index + 1}`}
                number={tier.subscriberCount}
                textColor={colors[index + 1]}
                dotColor={colors[index + 1]}
                title={tier.title}
              />
            )
          })
        }
      </div>
    </Paper>
  )
}
