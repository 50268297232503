import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: 20,
    padding: 0,
    width: 'fit-content',
    minWidth: 190,
    '& $menuItem:last-child': {
      borderBottom: 'none'
    }
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: '10px 15px',
    "&.Mui-focusVisible": {
      backgroundColor: "white !important",
    },
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
}), { index: 1 });

export default useStyles;